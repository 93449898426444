import { useForm } from 'react-hook-form';

import { useIntegrationTermsOfService } from 'src/api/queries';
import {
  Button,
  Form,
  Input,
  NotificationBox,
  Sheet,
  Spinner,
} from 'src/components';
import { LinkExternal } from 'src/components/LinkExternal';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { Description } from './Description';

export const TermsOfServiceForm = () => {
  const form = useForm();
  const tos = useIntegrationTermsOfService();

  if (tos.status.isPending) return <Spinner />;
  if (tos.status.isError)
    return (
      <NotificationBox variant="error" title="Something went wrong">
        We were not able to get status for terms of service. Please reload the
        page or contact support if the problem persists.
      </NotificationBox>
    );

  if (tos.hasAccepted) return null;

  return (
    <Sheet className="mb-40">
      <Description />
      <Form onSubmit={() => tos.accept.mutate()} {...form}>
        <p className="body-md-regular">
          We encourage you to read the full terms of service below.
        </p>
        <Input
          type="checkbox"
          name="accept"
          className="!mt-16"
          required
          data-cy="accept-tos-checkbox"
        >
          <div className="body-md-regular mt-4 cursor-pointer [&>*]:my-2 [&>*]:inline">
            I accept the{' '}
            <LinkExternal
              data-cy="tos-integrations-link"
              to={SUPPORT_URL.TERMS_AND_CONDITIONS_INTEGRATIONS}
            >
              Terms and Conditions
            </LinkExternal>
          </div>
        </Input>
        <Button
          type="submit"
          loading={tos.accept.isPending}
          className="mt-24"
          data-cy="accept-tos-confirm-button"
        >
          Confirm
        </Button>
        {tos.accept.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            className="my-16"
          >
            We were not able to accept the terms of service. Please try again
            later or contact support if the problem persists.
          </NotificationBox>
        )}
      </Form>
    </Sheet>
  );
};
