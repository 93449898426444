import { createFileRoute } from '@tanstack/react-router';

import { createQueryAccountInfo } from 'src/api/queries';
import { AccessDeniedError } from 'src/utils/errors';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth/_layout/account/delete')({
  async beforeLoad({ context }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw new Error('User not found');
    }

    const accountInfo = await context.queryClient.ensureQueryData(
      createQueryAccountInfo()
    );

    if (userHelpers.hasSAMLIdentity(accountInfo.identities)) {
      throw new AccessDeniedError(
        'The account cannot be deleted because it is linked to an external identity provider. Please contact your IT admin to manage your account.'
      );
    }
  },
});
