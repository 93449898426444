import { Divider } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';

import { Modal } from 'src/components';

import { InviteByEmail } from './InviteByEmail';

const subscriptionMemersRouteApi = getRouteApi(
  '/_auth/_layout/subscriptions/members'
);

export const InviteModal = () => {
  return (
    <Modal.Content title="Invite">
      {/* It is not fully decided if we want this navbar or not so I have just commented it out for the purpose of user testing. */}
      {/* <Navbar
        className="my-32"
        asSearchParam={searchParamName}
        data-cy="invite-modal-navbar"
        links={[
          {
            label: 'Email',
            url: 'email',
            dataCy: 'invite-modal-navbar-link-to-email-page',
          },
          {
            label: 'Link',
            url: 'link',
            dataCy: 'invite-modal-navbar-link-to-link-page',
          },
          {
            label: 'Import file',
            url: 'import',
            dataCy: 'invite-modal-navbar-link-to-file-import-page',
          },
        ]}
      /> */}

      <Divider className="my-16" />

      <ModalRouter />
    </Modal.Content>
  );
};

const ModalRouter = () => {
  const { invite_by } = subscriptionMemersRouteApi.useSearch();

  switch (invite_by) {
    case 'email':
      return <InviteByEmail />;
    case 'import':
      return (
        <div className="flex h-full min-h-[230px] items-center justify-center">
          <div className=" text-gray-500">
            This option will be available soon
          </div>
        </div>
      );
    default:
      return null;
  }
};
