import { useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  APPLE_ITUNES_URL,
  GOOGLE_PLAY_URL,
} from 'src/apps/device/utils/constants';
import { getMobileLink } from 'src/apps/device/utils/utils';
import appStoreLogo from 'src/assets/img/app-store.svg';
import googlePlayLogo from 'src/assets/img/google-play.svg';
import { RemarkableLogo } from 'src/components/RemarkableLogo';

export const MobileAppRedirect = () => {
  useEffect(() => {
    window.location.href = getMobileLink();
  }, []);

  return (
    <div className="w-full bg-neutral-light-2">
      <div className="m-auto flex max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128">
        <RemarkableLogo color={'black'} className="w-full max-w-[250px]" />
        <Typography variant="body-lg-regular">
          Download the reMarkable mobile app
        </Typography>
        <div className="mt-24 flex w-full flex-col items-center justify-center gap-32 md:flex-row">
          <DownloadMobileAppButton link={APPLE_ITUNES_URL} />
          <DownloadMobileAppButton link={GOOGLE_PLAY_URL} />
        </div>
      </div>
    </div>
  );
};

const DownloadMobileAppButton = ({ link }: { link: string }) => {
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        onClick={() =>
          tracker.trackEvent(
            new ButtonClicked({
              action:
                link === APPLE_ITUNES_URL
                  ? 'download ios app'
                  : 'download android app',
              component_location:
                ComponentLocations.OPEN_PAGES.DOWNLOAD_MOBILE_APP,
              text:
                link === APPLE_ITUNES_URL
                  ? 'App Store logo'
                  : 'Google Play logo',
            })
          )
        }
      >
        <img
          className="h-full w-[200px]"
          alt="Download on the App Store"
          src={link === APPLE_ITUNES_URL ? appStoreLogo : googlePlayLogo}
        />
      </a>
    </div>
  );
};
