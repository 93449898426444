import { Typography } from '@remarkable/ark-web';
import { DownloadSimple } from 'phosphor-react';
import QRCode from 'react-qr-code';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { getMobileLink } from 'src/apps/device/utils/utils';
import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { DesktopAppLink } from './DesktopAppLink';
import { Divider } from './Divider';
import { Sheet } from './Sheet';

interface DownloadAppsProps {
  to: string;
}

export const DownloadApps = ({ to }: DownloadAppsProps) => {
  const mobileAppRedirectUrl = new URL(
    URLS.DOWNLOAD_MOBILE_APP_REDIRECT,
    window.location.href
  );

  return (
    <Sheet className="flex max-w-lxl flex-col gap-16 text-left">
      <div className="flex flex-col gap-16">
        <Typography variant="responsive-heading-sm">
          Take notes from anywhere
        </Typography>
        <Typography variant="body-md-regular">
          View, organize, and import files with our mobile and desktop apps.
          Connect subscribers can also take and edit notes in the apps.
        </Typography>
      </div>
      <Divider className="h-2" />
      <div className="flex flex-col-reverse lm:flex-row">
        <div className="flex-col justify-between lm:flex lm:w-1/2 lm:border-r-2 lm:pr-16">
          <Typography variant="heading-xs"> Desktop app</Typography>
          <Typography variant="body-md-regular">
            Access, refine, and organize files on your computer or mac
          </Typography>
          <div className="mt-12 flex gap-24">
            <DesktopAppLink platform="macos" />
            <DesktopAppLink platform="windows" />
          </div>
        </div>
        <Divider className="my-16 h-2 lm:hidden" />
        <div className="flex flex-col justify-between lm:w-1/2 lm:pl-16">
          <Typography variant="heading-xs" className="mt-0">
            Mobile app
          </Typography>
          <div className="flex w-full flex-row justify-between">
            <div className="lm:w-3/4">
              <Typography variant="body-md-regular">
                Access notes while you&apos;re on the go with the mobile app.
              </Typography>
              <Typography variant="body-md-regular" className="hidden lm:block">
                Scan the QR code with your phone
              </Typography>
              <Button
                as="a"
                to={getMobileLink()}
                variant="tertiary"
                size="medium"
                className="lm:hidden"
                analytics={{
                  location: ComponentLocations.SETUP.DOWNLOAD_APPS,
                  text: 'Download',
                  action: 'download mobile app',
                }}
              >
                <DownloadSimple size={24} />
                <span>Download</span>
              </Button>
            </div>

            <QRCode
              size={64}
              value={mobileAppRedirectUrl.href}
              className="hidden w-fit lm:block"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Button
          as="a"
          to={to}
          variant="primary"
          className="float-right w-full lm:w-fit"
          analytics={{
            location: ComponentLocations.SETUP.DOWNLOAD_APPS,
            text: 'Next',
            action: 'go to next step',
          }}
        >
          Next
        </Button>
      </div>
    </Sheet>
  );
};
