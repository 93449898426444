import { Typography } from '@remarkable/ark-web';
import { cx } from 'class-variance-authority';
import { Desktop, DeviceMobile } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import dropbox from 'src/assets/icons/dropbox.svg';
import googleChrome from 'src/assets/icons/googleChrome.svg';
import googleDrive from 'src/assets/icons/googledrive.svg';
import msPowerPoint from 'src/assets/icons/msPowerPoint.svg';
import msWord from 'src/assets/icons/msWord.svg';
import oneDrive from 'src/assets/icons/onedrive.svg';
import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { Sheet } from './Sheet';

export const MyRmExperienceCardRow = ({
  className = '',
}: {
  className?: string;
}) => {
  const deviceDesktopHref = URLS.DEVICE_DESKTOP;
  const remarkableIntegrationsHref = URLS.INTEGRATIONS;
  const remarkableBrowserHref = URLS.DEVICE_BROWSER;
  return (
    <div
      data-cy="myrm-universe-cards"
      className={cx(
        'grid gap-24 text-left  ll:grid-cols-2 lxl:grid-cols-3',
        className
      )}
    >
      <Sheet>
        <Typography
          variant="interface-sm-caps-medium"
          className="text-gray-600"
        >
          Applications
        </Typography>
        <Typography
          as="h3"
          variant="heading-subtle-2xl"
          className="font-medium"
        >
          reMarkable apps
        </Typography>
        <Typography variant="body-md-regular" className="grow">
          View, organize, and import files with our mobile and desktop apps.
          Connect subscribers can also take and edit notes in the apps.
        </Typography>

        <div className="flex flex-row items-center">
          <DeviceMobile size={32} className="mr-12" />
          <Desktop size={32} />
        </div>
        <Button
          as="a"
          to={deviceDesktopHref}
          variant="secondary"
          size="medium"
          data-cy="remarkableAppsBtn"
          className="text-14"
          onClick={(e) => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.EXPLORE.REMARKABLE_APPLICATIONS,
                text: e.currentTarget.innerText,
                link_source: deviceDesktopHref,
              })
            );
          }}
        >
          Go to reMarkable apps
        </Button>
      </Sheet>
      <Sheet>
        <Typography
          variant="interface-sm-caps-medium"
          className="text-gray-600"
        >
          Cloud Storage
        </Typography>
        <Typography
          as="h3"
          variant="heading-subtle-2xl"
          className="font-medium"
        >
          Integrations
        </Typography>
        <Typography variant="body-md-regular" className="grow">
          Easily access, sync, and store files with cloud storage services such
          as Google Drive, Dropbox, and OneDrive.
        </Typography>

        <div className="flex flex-row items-center">
          <img
            className="mr-16"
            height="40"
            width="32"
            src={dropbox}
            alt="Dropbox icon"
          ></img>
          <img
            className="mr-16"
            height="32"
            width="26"
            src={googleDrive}
            alt="Google Drive Icon"
          ></img>
          <img
            className="mr-16"
            height="30"
            width="44"
            src={oneDrive}
            alt="OneDrive Icon"
          ></img>
        </div>
        <Button
          as="a"
          to={remarkableIntegrationsHref}
          variant="secondary"
          size="medium"
          onClick={(e) => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.EXPLORE.REMARKABLE_INTEGRATIONS,
                text: e.currentTarget.innerText,
                link_source: remarkableIntegrationsHref,
              })
            );
          }}
        >
          Go to integrations
        </Button>
      </Sheet>
      <Sheet className="ll:col-span-2 lxl:col-span-1">
        <Typography
          variant="interface-sm-caps-medium"
          className="text-gray-600"
        >
          Extensions
        </Typography>
        <Typography
          as="h3"
          variant="heading-subtle-2xl"
          className="font-medium"
        >
          Read on reMarkable
        </Typography>
        <Typography variant="body-md-regular" className="grow">
          Send Microsoft Office files and web articles to your paper tablet as
          PDFs.
        </Typography>

        <div className="flex flex-row items-center">
          <img
            className="mr-16"
            height="34"
            width="34"
            src={googleChrome}
            alt="Google Chrome"
          ></img>
          <img
            className="mr-16"
            height="34"
            width="34"
            src={msWord}
            alt="Word"
          ></img>
          <img
            className="mr-16"
            height="34"
            width="34"
            src={msPowerPoint}
            alt="PowerPoint"
          ></img>
        </div>
        <Button
          as="a"
          to={remarkableBrowserHref}
          variant="secondary"
          size="medium"
          onClick={(e) => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.EXPLORE.REMARKABLE_EXTENSIONS,
                text: e.currentTarget.innerText,
                link_source: remarkableBrowserHref,
              })
            );
          }}
        >
          Go to Read on reMarkable
        </Button>
      </Sheet>
    </div>
  );
};
