import { Typography } from '@remarkable/ark-web';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { useAcceptFreeConnectTerms } from 'src/api/queries';
import { Button, Link } from 'src/components';
import { subscriptionIsFreeConnect } from 'src/utils/subscriptionisFreeConnect';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { NotificationBanner } from '../../../components/NotificationBanner';

interface FreeConnectTermsAndConditionsProps {
  subscription?: Subscription;
}

export const FreeConnectTermsHomeBanner = ({
  subscription,
}: FreeConnectTermsAndConditionsProps) => {
  const acceptFreeConnectTerms = useAcceptFreeConnectTerms();

  if (!subscription) return null;

  if (!subscriptionIsFreeConnect(subscription)) return null;

  if (subscription.hasAcceptedTermsAndConditions) return null;

  return (
    <NotificationBanner>
      <div>
        <Typography variant="body-lg-bold" as="h3">
          Accept the terms and conditions of Connect
        </Typography>
        <Typography variant="body-md-regular" className="mt-8" as="p">
          Please accept our{' '}
          <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
            terms and conditions
          </Link>{' '}
          to use your free Connect subscription, which includes seamless
          note-taking across your other devices, and unlimited cloud storage and
          sync.
        </Typography>
        <Button
          data-cy="accept-terms-and-conditions-button"
          variant="primary"
          className="mt-24"
          onClick={() => acceptFreeConnectTerms.mutate()}
          loading={acceptFreeConnectTerms.isPending}
        >
          I accept
        </Button>
      </div>
    </NotificationBanner>
  );
};
