import { RMpp } from '@remarkable/ark-icons';
import { Typography } from '@remarkable/ark-web';
import { ArrowRight, CloudArrowUp, Folder } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { FlexDivider } from './FlexDivider';
import { Sheet } from './Sheet';

export const ActiveSubscriptionHero = () => {
  return (
    <Sheet
      className="flex flex-col justify-between !px-0 !py-32 text-left lm:flex-row"
      data-cy="hero-dashboard"
    >
      <div className="w-full flex-1 flex-col gap-16 border-neutral-light-8 px-32">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <RMpp className="text-muted" size={24} />
            Paper tablet
          </span>
        </Typography>
        <Typography variant="body-sm-regular" className="text-muted">
          Add or edit devices connected to your account.
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          className="mt-16"
          as="a"
          to={URLS.DEVICE_REMARKABLE}
          analytics={{
            location: ComponentLocations.FRONT_PAGE.DASHBOARD,
            text: 'Manage devices',
            action: 'Go to device page',
          }}
        >
          <span>Manage devices</span>
          <ArrowRight />
        </Button>
      </div>

      <FlexDivider className="mx-32 my-16 lm:m-0" />

      <div className="w-full flex-1 flex-col px-32">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <CloudArrowUp size={28} className="inline-block text-muted" />{' '}
            Connect
          </span>
        </Typography>
        <Typography variant="body-sm-regular" className="text-muted">
          Enjoy unlimited cloud storage and other features.
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          className="mt-16"
          as="a"
          to={URLS.SUBSCRIPTION}
          analytics={{
            location: ComponentLocations.FRONT_PAGE.DASHBOARD,
            text: 'Manage subscription',
            action: 'Go to subscription page',
          }}
        >
          <span>Manage subscription </span>
          <ArrowRight />
        </Button>
      </div>

      <FlexDivider className="mx-32 my-16 lm:m-0" />

      <div className="w-full flex-1 flex-col px-32">
        <Typography variant="heading-subtle-xl">
          <span className="flex items-center gap-8">
            <Folder size={28} className="inline-block text-muted" /> My files
          </span>
        </Typography>
        <Typography variant="body-sm-regular" className="text-muted">
          View, manage and upload files to your paper tablet.
        </Typography>
        <Button
          variant="secondary"
          size="medium"
          className="mt-16"
          as="a"
          to={URLS.MYFILES}
          analytics={{
            location: ComponentLocations.FRONT_PAGE.DASHBOARD,
            text: 'Go to my files',
            action: 'Go to my files',
          }}
        >
          <span>Go to my files</span>
          <ArrowRight />
        </Button>
      </div>
    </Sheet>
  );
};
