import { Typography } from '@remarkable/ark-web';
import { useLocation } from '@tanstack/react-router';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { NotificationBox } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { formatDate } from 'src/utils/formatDate';
import { URLS } from 'src/utils/urls/urls';

interface PausedSubscriptionNotificationProps {
  subscription?: Subscription;
}

export const PausedSubscriptionNotification = ({
  subscription,
}: PausedSubscriptionNotificationProps) => {
  const { pathname } = useLocation();
  if (
    subscription?.type === 'free-connect' ||
    !subscription?.paymentInformation?.isDeactivated
  ) {
    return null;
  }

  if (subscription.access.canChangePaymentInformation) {
    return (
      <NotificationBox
        variant="error"
        title="Your subscription is on hold"
        className="mb-32"
      >
        <Typography variant="body-sm-regular" as="p">
          There was an error with your payment, and your subscription is on
          hold. Your team can still access their files, but subscription
          benefits are unavailable. <br />
          To restore access, please change your card or update your payment
          details. Your subscription will be canceled{' '}
          <Typography as="span" variant="body-sm-bold">
            {formatDate(subscription.paymentInformation.cancellationAt)}
          </Typography>
          , if you don&apos;t update your payment details.
        </Typography>
        {pathname === URLS.SUBSCRIPTION_PAYMENT ? null : (
          <Link2 inline className="mt-8" to="/subscriptions/payment">
            Update payment
          </Link2>
        )}
      </NotificationBox>
    );
  }
  return (
    <NotificationBox
      variant="warning"
      title="Your company's subscription is on hold"
      className="mb-32"
    >
      <Typography variant="body-sm-regular" as="p">
        Your subscription features are unavailable, but you can still access
        your files. Please ask your admin to update their payment details.
      </Typography>
    </NotificationBox>
  );
};
