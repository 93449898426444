import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { SubscriptionMember } from 'src/api/endpoints/storeApi.types';
import { useUpdateMemberRole } from 'src/api/queries';
import { Button, Modal } from 'src/components';

export const RoleEnum = {
  OWNER: 'owner',
  MEMBER: 'member',
} as const;

type Role = (typeof RoleEnum)[keyof typeof RoleEnum];

const isRole = (role: string): role is Role => {
  return Object.values(RoleEnum).includes(role as Role);
};

const accessOptions = {
  editPaymentMethod: 'Edit payment method',
  viewBilingInfo: 'View billing information',
  seeReceipts: 'See receipts',
  inviteAndRemoveMembers: 'Invite and remove members',
  assignOrRemoveOwnerAccess: 'Assign or remove owner access',
  cancelSubscription: 'Cancel your subscription',
} as const;

type AccessOption = (typeof accessOptions)[keyof typeof accessOptions];

const roleAccessOptions: Record<Role, AccessOption[]> = {
  [RoleEnum.OWNER]: [
    accessOptions.editPaymentMethod,
    accessOptions.viewBilingInfo,
    accessOptions.seeReceipts,
    accessOptions.inviteAndRemoveMembers,
    accessOptions.assignOrRemoveOwnerAccess,
    accessOptions.cancelSubscription,
  ],
  [RoleEnum.MEMBER]: [],
};

interface ChangeRoleProps {
  member: SubscriptionMember;
  toRole: string;
  onSuccess?: () => void;
}

export function UpdateMemberRole(props: ChangeRoleProps) {
  const updateMemberRole = useUpdateMemberRole();

  if (!isRole(props.toRole)) {
    // Fallback so that we can still have a functional component when adding new roles that
    // isn't fully defined yet
    return (
      <div className="flex flex-col gap-16">
        <Typography as="h3" variant="heading-md">
          Assign {props.toRole} access
        </Typography>

        <div className="flex justify-center gap-16">
          <Modal.Close asChild>
            <Button variant="secondary">
              <span>Cancel</span>
            </Button>
          </Modal.Close>

          <Button
            loading={updateMemberRole.isPending}
            onClick={() => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                  text: 'Assign ' + props.toRole + ' access',
                  action: 'change member role to ' + props.toRole,
                })
              );

              updateMemberRole.mutate(
                {
                  memberId: props.member.id,
                  newRole: props.toRole,
                },
                { onSuccess: props.onSuccess }
              );
            }}
          >
            Assign {props.toRole} access
          </Button>
        </div>
      </div>
    );
  }

  const accessOptions =
    props.member.role in roleAccessOptions
      ? roleAccessOptions[props.member.role as Role]
      : [];

  if (props.toRole === RoleEnum.MEMBER) {
    return (
      <div className="flex flex-col gap-16">
        <Typography as="h3" variant="heading-md">
          Remove {props.member.role} access
        </Typography>

        <Typography variant="body-md-regular">
          If you remove {props.member.role} access from this member,
          they&apos;ll no longer be able to:
        </Typography>

        <ul className="mx-auto list-disc columns-2 gap-16">
          {accessOptions.map((accessOption) => (
            <li key={accessOption} className="text-left">
              <Typography variant="body-md-regular">{accessOption}</Typography>
            </li>
          ))}
        </ul>

        <div className="flex justify-center gap-16">
          <Modal.Close asChild>
            <Button variant="secondary">
              <span>Cancel</span>
            </Button>
          </Modal.Close>

          <Button
            loading={updateMemberRole.isPending}
            onClick={() => {
              tracker.trackEvent(
                new ButtonClicked({
                  component_location:
                    ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                  text: 'Remove ' + props.member.role + ' access',
                  action: 'change member role to ' + props.toRole,
                })
              );

              updateMemberRole.mutate(
                {
                  memberId: props.member.id,
                  newRole: props.toRole,
                },
                { onSuccess: props.onSuccess }
              );
            }}
          >
            Remove {props.member.role} access
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-16">
      <Typography as="h3" variant="heading-md">
        Assign {props.toRole} access
      </Typography>

      <Typography variant="body-md-regular">
        If you assign {props.toRole} access to this member, they&apos;ll be able
        to:
      </Typography>

      <ul className="mx-auto list-disc columns-2 gap-16">
        {roleAccessOptions[props.toRole].map((accessOption) => (
          <li key={accessOption} className="text-left">
            <Typography variant="body-md-regular">{accessOption}</Typography>
          </li>
        ))}
      </ul>

      {props.toRole === RoleEnum.OWNER && (
        <Typography variant="body-md-regular">
          You won&apos;t lose any of your user privileges by giving owner access
          to other members.
        </Typography>
      )}

      <div className="flex justify-center gap-16">
        <Modal.Close asChild>
          <Button variant="secondary">
            <span>Cancel</span>
          </Button>
        </Modal.Close>

        <Button
          loading={updateMemberRole.isPending}
          onClick={() => {
            tracker.trackEvent(
              new ButtonClicked({
                component_location:
                  ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                text: 'Assign ' + props.toRole + ' access',
                action: 'change member role to ' + props.toRole,
              })
            );

            updateMemberRole.mutate(
              {
                memberId: props.member.id,
                newRole: props.toRole,
              },

              { onSuccess: props.onSuccess }
            );
          }}
        >
          Assign {props.toRole} access
        </Button>
      </div>
    </div>
  );
}
