import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { AccountMigrate } from 'src/apps/account/app/pages/AccountMigrate';

const accountMigrateSearchSchema = z.object({
  confirm: z.boolean().optional(),
  accountLinkingToken: z.string().optional(),
});

export const Route = createFileRoute('/_auth/_layout/account/migrate/')({
  validateSearch: zodSearchValidator(accountMigrateSearchSchema),
  component: () => <AccountMigrate />,
});
