import { CardHeading } from './CardHeading';
import { PaymentHistoryList } from './PaymentHistoryList';

export const PaymentHistoryCard = () => {
  return (
    <div className="flex flex-col gap-16 py-16">
      <CardHeading className="mb-24">Payment history</CardHeading>

      <PaymentHistoryList showSeatsColumn />
    </div>
  );
};
