const formatTimeString = (number: number, str: string) =>
  `${number} ${str}${number > 1 ? 's' : ''}`;

export const formatRemainingTime = (expirationTime: string | Date) => {
  const expirationDate = new Date(expirationTime);
  const now = new Date();
  const diff = expirationDate.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);

  const dayString = formatTimeString(days, 'day');
  const hourString = formatTimeString(hours, 'hour');
  const minuteString = formatTimeString(minutes, 'minute');

  if (days === 0 && hours === 0 && minutes === 0) {
    return 'less than a minute';
  }

  if (days === 0 && hours === 0) {
    return minuteString;
  }

  if (days === 0) {
    return `${hourString}`;
  }

  return `${dayString}, ${hourString}`;
};
