import { Button } from 'src/components';

const surveyAnswers = {
  DONT_USE: "I don't use the features enough",
  EXPENSIVE: "It's too expensive",
  RETURN: 'I am returning my reMarkable',
  BOUGHT_FOR_OTHER: 'I bought Connect for someone else',
  SELLING: 'I am selling my reMarkable',
  OTHER: 'Other',
};

export interface MrfSurveyProps {
  selectedAnswer: string | null;
  setSelectedAnswer: (answer: string) => void;
}

export const MrfSurvey = ({
  selectedAnswer,
  setSelectedAnswer,
}: MrfSurveyProps) => {
  return (
    <div className="flex flex-col space-y-8">
      {Object.entries(surveyAnswers).map(([key, value]) => (
        <Button
          key={key}
          size="medium"
          onClick={() => setSelectedAnswer(key)}
          variant={selectedAnswer === key ? 'tertiary' : 'secondary'}
          className="w-full"
          data-cy={key}
        >
          {value}
        </Button>
      ))}
    </div>
  );
};
