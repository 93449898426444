import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { storeApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const useBillingInfo = () =>
  useQuery({
    queryKey: queryKeys.billingInfo.details,
    queryFn: storeApi.getBillingInfo,
    staleTime: Infinity,
  });

export const useUpdateBillingInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: storeApi.updateBillingInfo,
    onSuccess: (res) => {
      if (!res) {
        return queryClient.invalidateQueries({
          queryKey: queryKeys.billingInfo.details,
        });
      }

      queryClient.setQueryData(queryKeys.billingInfo.details, res);
    },
  });
};
