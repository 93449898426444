import { atom } from 'jotai';

export const atomWithSessionStorage = <T = unknown>(
  key: string,
  initialValue: T
) => {
  const getInitialValue = () => {
    const item = sessionStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item) as T;
    }
    if (typeof initialValue === 'function') {
      return (initialValue as () => T)();
    }
    return initialValue;
  };
  const baseAtom = atom<T>(getInitialValue());
  return atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === 'function'
          ? (update(get(baseAtom)) as T)
          : (update as T);
      set(baseAtom, nextValue);
      sessionStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
};
