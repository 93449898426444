import { useMemo } from 'react';

import { useLocation } from '@tanstack/react-router';

/**
 * Get URL search parameters.
 * Can be expanded to return setters as well
 */
export function useUrlSearchParams<
  T extends Record<string, string> = Record<string, string>
>() {
  const { searchStr } = useLocation();

  const mapToObject = (paramList: string[][]) =>
    paramList.reduce((mapped, [key, value]) => {
      if (!key) return mapped;

      return { ...mapped, [key]: value };
    }, {} as T); // TODO: Make this partial Jørgen!

  const params = useMemo(
    () => mapToObject(Array.from(new URLSearchParams(searchStr))),
    [searchStr]
  );

  return [params];
}
