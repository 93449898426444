import { Typography } from '@remarkable/ark-web';

import { Button, ErrorPage } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const InvitationNotFound = () => {
  return (
    <ErrorPage.Container data-cy="invitation-not-found-page">
      <ErrorPage.ErrorIcon />
      <ErrorPage.Heading>Invitation not found</ErrorPage.Heading>

      <ErrorPage.Content>
        <div className="mx-auto flex h-full max-w-lm flex-col items-center justify-center gap-32">
          <Typography variant="body-md-regular">
            Your invitation to Connect for business couldn&apos;t be found. It
            may already have been accepted. To join, please contact the person
            who invited you.
          </Typography>

          <Button as="a" to={URLS.HOME} variant="secondary">
            <span>Back to home</span>
          </Button>
        </div>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
