import { Typography } from '@remarkable/ark-web';

import { Button, Modal } from 'src/components';

export const EditEmailModal = () => {
  return (
    <Modal.Content data-cy="edit-email-modal">
      <div className="flex flex-col items-center">
        <Typography variant="heading-lg">Remove login</Typography>

        <Typography variant="body-md-regular" className="mt-32">
          You have a social login method that’s active. Remove this from the
          Login and security page before you can change your email address in
          contact details.
        </Typography>

        <Modal.Close asChild>
          <Button variant="secondary" className="mt-32">
            Close
          </Button>
        </Modal.Close>
      </div>
    </Modal.Content>
  );
};
