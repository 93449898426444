import { Subscription } from 'src/api/endpoints/storeApi.types';

export const getPaymentCardExpiresInNumMonths = (
  sub?: Subscription
): number | null => {
  if (
    sub?.type !== 'stripe' ||
    !sub?.paymentInformation ||
    sub.paymentInformation.payment?.type !== 'card'
  )
    return null;

  const { expireMonth, expireYear } =
    sub.paymentInformation.payment?.cardInformation || {};

  if (!expireMonth || !expireYear) return null;

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  if (
    expireYear < currentYear ||
    (expireYear === currentYear && expireMonth < currentMonth)
  ) {
    return 0;
  }

  return expireMonth - currentMonth + 12 * (expireYear - currentYear);
};
