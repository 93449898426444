import { HTTPError } from './createApiClient.types';
import { HttpStatus } from './httpStatus';

type Handler<T> = (error: T) => NonVoid<unknown>;
type NonFunction =
  | string
  | number
  | boolean
  | symbol
  | null
  | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { [key: string | number | symbol]: any };

async function resolveHandlersOrValue<
  T extends (Handler<HTTPError> | NonFunction)[]
>(handlersOrValue: T, error: HTTPError) {
  type ResolverReturnType = ReturnTypeUnion<typeof handlersOrValue>;
  for (const handlerOrValue of handlersOrValue) {
    if (typeof handlerOrValue === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const result = await handlerOrValue(error);
      if (result !== undefined) return result as ResolverReturnType;
    } else {
      return handlerOrValue as ResolverReturnType;
    }
  }

  return undefined as ResolverReturnType;
}

/**
 * Catch an HTTPError and handle it with one or more handlers
 * Handlers can:
 * - return a value to convert error to a successful response and stop handling
 * - throw to change the original error and stop handling
 * - return `void | undefined` to continue to the next handler
 */
export const catchHttpError = async <
  T,
  THandlers extends Array<(error: HTTPError) => unknown>
>(
  promise: Promise<T>,
  ...handlers: THandlers
) => {
  try {
    return await promise;
  } catch (error) {
    // Only handle HTTPErrors
    if (!(error instanceof HTTPError)) throw error;

    for (const handler of handlers) {
      const result = (await handler(error)) as NonVoid<
        Awaited<ReturnTypeUnion<THandlers>>
      >;
      if (result !== undefined) return result;
    }

    // If no handler handled the error, rethrow it
    throw error;
  }
};

function onStatus<T extends (Handler<HTTPError> | NonFunction)[]>(
  status: number | HttpStatus,
  ...handlersOrValue: T
) {
  return async (error: HTTPError) => {
    let statusCode = status;
    if (typeof status === 'string') {
      statusCode = HttpStatus[status];
    }
    if (error.response.status !== statusCode) return;

    return await resolveHandlersOrValue(handlersOrValue, error);
  };
}

function onResponseText<T extends (Handler<HTTPError> | NonFunction)[]>(
  text: string,
  ...handlersOrValue: T
) {
  return async (error: HTTPError) => {
    const responseText = await error.response.text();
    if (responseText !== text) return;

    return await resolveHandlersOrValue(handlersOrValue, error);
  };
}

type ErrorResponseData = {
  reason?: string;
  context?: {
    reason?: string;
  };
};

/**
 * We have a pattern of returning a JSON object with a `reason` field when
 * an error occurs. This function allows us to handle those errors in a
 * consistent, type-safe way.
 */
function onResponseReason<T extends (Handler<HTTPError> | NonFunction)[]>(
  match: unknown,
  ...handlersOrValue: T
) {
  return async (error: HTTPError) => {
    let data: null | ErrorResponseData = null;
    try {
      data =
        (await error.response.json()) as unknown as ErrorResponseData | null;
    } catch (e) {
      // If the response is not JSON, we can't handle it
      return;
    }

    if (!data) return;

    const reason = data.reason ?? data.context?.reason;

    if (!reason) {
      console.warn('Response data does not contain a reason field');
      return;
    }

    if (reason !== match) return;

    return await resolveHandlersOrValue(handlersOrValue, error);
  };
}

function onResponseData<THandlers extends (Handler<HTTPError> | NonFunction)[]>(
  conditionHandler: (data: unknown) => boolean,
  ...handlersOrValue: THandlers
) {
  return async (error: HTTPError) => {
    const data = (await error.response.json()) as unknown;

    if (!conditionHandler(data)) return;

    return await resolveHandlersOrValue(handlersOrValue, error);
  };
}

function throwError<T>(data: T) {
  return () => {
    throw data;
  };
}

export {
  onStatus,
  onResponseText,
  onResponseReason,
  onResponseData,
  throwError,
};

// I was not able to get this to work without the use of any, so I disabled the rule.
// Final result is still fully typed however, it just doesn't care when you pass them in.

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Takes an array of functions and returns a union of their return types
 */
type ReturnTypeUnion<T extends (Handler<HTTPError> | NonFunction)[]> = {
  [K in keyof T]: T[K] extends (...args: any[]) => infer R ? R : T[K];
}[number];

type NonVoid<T> = T extends void ? never : T;
