import { paths } from 'apis/cloud-consent-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { setTectonicUrl } from './middleware/setTectonicUrl';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudConsentApi = createClient<paths>({
  baseUrl: CONFIG.CloudApiUrl,
});

cloudConsentApi.use(addAuthHeader, throwOnErrorResponse, setTectonicUrl);

export { cloudConsentApi };
