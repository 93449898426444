import { HTMLAttributes } from 'react';

import { Label } from '@remarkable/ark-web';
import { CaretDown } from 'phosphor-react';
import { useFormContext } from 'react-hook-form';

import { CA_PROVINCES, US_STATES } from 'src/utils/countryList';

import { cn } from '../utils/classNamesHelper';

interface StatePickerProps {
  disabled?: boolean;
  name?: string;
  isEditable?: boolean;
  countryFieldName?: string;
}

export const StatePicker = ({
  name = 'state',
  countryFieldName = 'country',
  isEditable = true,
  disabled = false,
  ...props
}: HTMLAttributes<HTMLDivElement> & StatePickerProps) => {
  const { register, watch, formState } = useFormContext();
  const country = watch(countryFieldName) as string;
  const value = watch(name) as string;
  const defaultValue = formState.defaultValues?.[name] as string;

  const states: Record<string, typeof US_STATES | typeof CA_PROVINCES> = {
    US: US_STATES,
    CA: CA_PROVINCES,
  };
  const activeStates = states[country] ?? [];
  const isCanada = country === 'CA';

  const displayValue = activeStates.find((c) => c.value === value)?.label;

  if (country in states === false) {
    return null;
  }

  return (
    <div {...props}>
      <Label htmlFor={name}>{country === 'CA' ? 'Province' : 'State'}</Label>
      {isEditable ? (
        <div className={cn('relative block rounded bg-white')}>
          <select
            className="h-48 w-full cursor-pointer appearance-none rounded border border-neutral-light-8 bg-none px-16 py-8"
            id={name}
            {...register(name, {
              required: true,
            })}
            defaultValue={defaultValue}
            disabled={disabled}
          >
            {!value && (
              <option value="">
                {isCanada ? 'Choose province...' : 'Choose state...'}
              </option>
            )}

            {activeStates.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <CaretDown
            size={32}
            className="pointer-events-none absolute right-12 top-0 h-full text-pen-blue"
          />
        </div>
      ) : (
        <p className="min-h-[16px]">{displayValue ?? '-'}</p>
      )}
    </div>
  );
};
