import { Typography } from '@remarkable/ark-web';
import { CheckCircle } from 'phosphor-react';

import { NotificationBanner } from './NotificationBanner';

export const PairingCompleteNotification = () => {
  return (
    <div className="mb-16">
      <NotificationBanner>
        <CheckCircle
          weight="fill"
          className="mr-16 text-feedback-green-500"
          size={32}
        />
        <Typography variant="body-md-bold" className="mr-8" as="p">
          Pairing complete!
        </Typography>
        <Typography variant="body-md-regular" className="mr-8" as="p">
          You&apos;ve successfully paired your device.
        </Typography>
      </NotificationBanner>
    </div>
  );
};
