import { Subscription } from 'src/api/endpoints/storeApi.types';

import { isActiveStripeSubscription } from './subscriptionIsActive';

export const subscriptionTrialWillCancel = (sub: Subscription) => {
  return (
    isActiveStripeSubscription(sub) &&
    sub.status == 'trialing' &&
    sub.paymentInformation?.payment?.type == 'card' &&
    !sub.paymentInformation?.payment?.details
  );
};
