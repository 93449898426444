import { useEffect } from 'react';

import { getRouteApi } from '@tanstack/react-router';
import toast from 'react-hot-toast';

import { useChangePaymentMethodVerifySetupIntent } from 'src/api/queries';

const routeApi = getRouteApi('__root__');

/**
 * This hook is used to verify a payment method after a user has been redirected
 * back to the site from the payment provider.
 */
export const useVerifyPaymentMethod = () => {
  const navigate = routeApi.useNavigate();
  const search = routeApi.useSearch();
  const changePaymentMethodVerifySetupIntent =
    useChangePaymentMethodVerifySetupIntent();

  useEffect(() => {
    if (
      'subscription_id' in search === false ||
      !search.setup_intent_client_secret
    )
      return;

    changePaymentMethodVerifySetupIntent.mutate(
      {
        subscriptionId: search.subscription_id,
        clientSecret: search.setup_intent_client_secret,
      },
      {
        onSuccess: () => {
          void navigate({
            search: {
              subscription_id: undefined,
              setup_intent_client_secret: undefined,
              setup_intent: undefined,
              redirect_status: undefined,
            },
          });
          toast.success('Payment method updated');
        },
      }
    );
  }, []);

  return changePaymentMethodVerifySetupIntent;
};
