import {
  getClientTypeFromV1,
  getClientTypeFromV2,
  isClientPaperTablet,
} from 'src/apps/device/utils/utils';

import { getDevicesV1, getProfileV1 } from './cloudApi';
import {
  ClientInformation,
  ClientSource,
  DeviceV1,
  ProfileDeviceV1,
} from './cloudApi.types';

const mapDeviceV1ToClient = (deviceV1: DeviceV1): ClientInformation => {
  return {
    clientName: deviceV1.DeviceDesc,
    clientId: deviceV1.DeviceID,
    clientType: getClientTypeFromV1(deviceV1),
    registeredAt: deviceV1.Modified,
    deletedAt: deviceV1.DeletedAt ?? null,
    clientSource: ClientSource.DeviceV1,
  };
};

const mapProfileDeviceV1ToClient = (
  profileDeviceV1: ProfileDeviceV1
): ClientInformation => {
  return {
    clientName: profileDeviceV1.deviceType,
    clientId: profileDeviceV1.deviceId,
    clientType: getClientTypeFromV2(profileDeviceV1),
    registeredAt: profileDeviceV1.registeredAt,
    // As of July 5th, 2024, only active devices are returned from the profile/devices endpoint
    deletedAt: null,
    clientSource: ClientSource.ProfileV1,
  };
};

export const getMergedDevices = async (): Promise<ClientInformation[]> => {
  const [devicesV1, profileV1] = await Promise.all([
    getDevicesV1(),
    getProfileV1(),
  ]);

  const mappedDevicesV1 = devicesV1.map(mapDeviceV1ToClient);
  const mappedProfileV1 = profileV1.devices.map(mapProfileDeviceV1ToClient);

  const devices = [
    ...mappedDevicesV1.filter((deviceV1) => {
      // Automatic migration from devicesV1 to profileV1 is planned after rollout of Auth2.0.
      // If the device is registered within deviceV1 and profileV1, we only wish to list the (newer) profileV1 registration.
      const profileV1Exists = mappedProfileV1.some(
        (profileV1) => profileV1.clientId === deviceV1.clientId
      );
      return !profileV1Exists;
    }),
    ...mappedProfileV1,
  ];
  return devices;
};

export const getHasEverPairedDevice = async (): Promise<boolean> => {
  const [devicesV1, profileV1] = await Promise.all([
    getDevicesV1(),
    getProfileV1(),
  ]);
  const hasV1Device = devicesV1
    .map(mapDeviceV1ToClient)
    .some((device) => isClientPaperTablet(device));
  const hasProfileV1Device = profileV1.isTabletOwner;
  return hasV1Device || hasProfileV1Device;
};
