import { Typography } from '@remarkable/ark-web';
import { CheckCircle } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { PaymentInterval } from 'src/api/endpoints/storeApi.types';
import {
  usePaymentIntervals,
  useStripeSubscription,
  useUpdatePaymentInterval,
} from 'src/api/queries';
import { Button, Link, Modal, NotificationBox } from 'src/components';
import { formatDate } from 'src/utils/formatDate';
import { formatAmountBrowserDefaultLocale as formatPrice } from 'src/utils/productUtils';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

export const ChangePaymentIntervalForm = () => {
  const subscription = useStripeSubscription();
  const sub = subscription.data;
  const paymentIntervals = usePaymentIntervals();
  const updatePaymentInterval = useUpdatePaymentInterval();

  if (!paymentIntervals.data || !sub) return null;

  const isMonthly = paymentIntervals.data.isMonthly;

  const newInterval = isMonthly
    ? PaymentInterval.ANNUALLY
    : PaymentInterval.MONTHLY;

  const nextBillingDate = sub.paymentInformation?.nextInvoice?.dueDate
    ? formatDate(sub.paymentInformation.nextInvoice.dueDate)
    : 'No invoice available';

  const currentIntervalTitle = isMonthly ? 'monthly' : 'annual';
  const newIntervalTitle = isMonthly ? 'annual' : 'monthly';

  const newIntervalOption = paymentIntervals.data.options[newInterval];

  const subAmount = sub.paymentInformation?.nextInvoice?.amount;

  if (!subAmount) {
    return (
      <NotificationBox variant="error" title="Something went wrong">
        No payment or invoice information available. Please try again later or
        contact support if the problem persists.
      </NotificationBox>
    );
  }

  const currentPrice = formatPrice(subAmount.total, subAmount.currency);
  const currentPricePerMonth = formatPrice(
    Number((subAmount.total / 12).toFixed(2)),
    subAmount.currency
  );
  const currentPricePerYear = formatPrice(
    subAmount.total * 12,
    subAmount.currency
  );

  if (!newIntervalOption) {
    return (
      <NotificationBox title="Something went wrong" variant="error">
        Try again later or contact support if the problem persists
      </NotificationBox>
    );
  }

  const optionPrice = formatPrice(
    newIntervalOption.amount.total,
    newIntervalOption.amount.currency
  );

  const confirmText = `Switch to ${newIntervalTitle} billing`;

  const onSubmit = (text: string) => () => {
    tracker.trackEvent(
      new ButtonClicked({
        component_location:
          ComponentLocations.MANAGE_CONNECT.PAYMENT_INTERVAL_MODAL,
        text,
        action: 'confirm change payment interval',
      })
    );

    updatePaymentInterval.mutate({ newInterval, sub });
  };

  if (updatePaymentInterval.isSuccess) {
    // updatePaymentInterval invalidates the subscriptions query and waits for the
    // subscriptions to be updated before it resolves to success. This means that
    // when we finally get here (isSuccess), what was previously the option price
    // has now become the current price.
    return (
      <>
        <CheckCircle
          data-cy="payment-interval-confirmation-icon"
          weight="fill"
          size={86}
          className="mx-auto mb-24 text-feedback-green-500"
        />
        <Typography as="h2" variant="heading-xs">
          You&apos;ve switched to{' '}
          <span className="whitespace-nowrap">
            {currentIntervalTitle} billing
          </span>
        </Typography>

        <Typography as="p" variant="body-md-regular" className="mt-16">
          You&apos;ll be charged {currentPrice} on{' '}
          <span className="whitespace-nowrap font-bold">{nextBillingDate}</span>
        </Typography>

        <Modal.Close asChild>
          <Button
            className="mx-auto mt-24 w-full xs:w-auto"
            data-cy="close-payment-interval-confirmation-button"
          >
            Close
          </Button>
        </Modal.Close>
      </>
    );
  }

  return (
    <>
      <Typography as="h2" variant="heading-xs">
        Switch to {newIntervalTitle} billing
      </Typography>

      <div className="my-16">
        {isMonthly ? (
          <Typography as="p" variant="body-md-regular">
            Pay <strong>{optionPrice}/year</strong> instead of
            <br />
            {currentPrice}/month{' '}
            <span className="whitespace-nowrap">
              ({currentPricePerYear}/year)
            </span>
          </Typography>
        ) : (
          <Typography as="p" variant="body-md-regular">
            Pay <strong>{optionPrice}/month</strong> instead of
            <br />
            {currentPrice}/year{' '}
            <span className="whitespace-nowrap">
              ({currentPricePerMonth}/month)
            </span>
          </Typography>
        )}
      </div>

      <Typography as="p" variant="body-md-regular" className="mb-32">
        Starts from{' '}
        <strong data-cy="change-payment-next-billing-date">
          {nextBillingDate}
        </strong>
      </Typography>

      {updatePaymentInterval.isError && (
        <NotificationBox
          variant="error"
          className="mt-16"
          title="Something went wrong"
          data-cy="error-message"
        >
          <span className="mt-4">
            Please try again. If the problem keeps happening,{' '}
            <Link inline to={SUPPORT_URL.CONTACT_SUPPORT}>
              contact Support
            </Link>
            {'.'}
          </span>
        </NotificationBox>
      )}

      <div className="mt-24 flex w-full flex-col flex-wrap justify-center gap-16 xs:flex-row">
        <Button
          onClick={onSubmit(confirmText)}
          loading={updatePaymentInterval.isPending}
          className="w-full xs:w-fit"
          data-cy="confirm-change-payment-interval-button"
        >
          <span>{confirmText}</span>
        </Button>

        <Modal.Close asChild>
          <Button
            type="button"
            variant="secondary"
            className="w-full xs:w-fit"
            disabled={updatePaymentInterval.isPending}
            data-cy="cancel-change-payment-interval-button"
          >
            <span>Cancel</span>
          </Button>
        </Modal.Close>
      </div>
    </>
  );
};

export const ChangePaymentIntervalModal = () => {
  return (
    <Modal.Content>
      <ChangePaymentIntervalForm />
    </Modal.Content>
  );
};
