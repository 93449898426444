import { Typography } from '@remarkable/ark-web';
import { paths } from 'apis/cloud-consent-api';
import { Check, Globe, X } from 'phosphor-react';

import {
  useConsentStatus,
  useCreateConsent,
  useRevokeConsent,
} from 'src/api/queries/consent';
import {
  Button,
  Divider,
  NotificationBox,
  Sheet,
  Spinner,
  Tag,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';

export const UserConsentForSupportApp = () => {
  const consentStatus = useConsentStatus();
  const updateConsent = useCreateConsent();
  const revokeConsent = useRevokeConsent();

  type CONSENT_STATUS =
    | paths['/consent/v1']['post']['requestBody']['content']['application/json']['level']
    | 'none';

  const handleGrantMetaDataAccess = () =>
    updateConsent.mutate({
      level: 'meta-data',
      type: 'sync-content',
    });
  const handleGrantFullAccess = () =>
    updateConsent.mutate({
      level: 'full',
      type: 'sync-content',
    });

  const isGrantFullAccessLoading =
    updateConsent.isPending && updateConsent.variables?.level === 'full';
  const isGrantMetaDataLoading =
    updateConsent.isPending && updateConsent.variables?.level === 'meta-data';

  const getConsentStatusTag = (consentStatus?: CONSENT_STATUS | null) => {
    switch (consentStatus) {
      case 'full':
        return (
          <Tag>
            <Check />
            Full access
          </Tag>
        );
      case 'meta-data':
        return (
          <Tag>
            <Globe />
            Metadata access
          </Tag>
        );
      case undefined:
        return (
          <Tag>
            <X /> No Access
          </Tag>
        );
      default:
        return null;
    }
  };

  return (
    <AppPage.Container>
      <AppPage.Header
        title="Manage consent choices"
        subtitle="Review and manage your consent options."
      />
      <AppPage.Content className="max-w-lxl">
        {consentStatus.isError && (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Something went wrong getting your consent status"
          />
        )}
        {(updateConsent.isError || revokeConsent.isError) && (
          <NotificationBox
            className="my-32"
            variant="error"
            title="Something went wrong updating your consent status"
          />
        )}
        <Sheet>
          <div className="flex flex-col gap-16 lm:flex-row lm:items-center lm:justify-between">
            <Typography variant="heading-subtle-xl">
              Your consent status
            </Typography>
            <div className="w-fit">
              {consentStatus.isPending ? (
                <Spinner inline />
              ) : (
                getConsentStatusTag(consentStatus.data?.level)
              )}
            </div>
          </div>
          <Divider className="my-16" />
          <div className="flex flex-col items-center justify-center gap-32 text-center">
            {consentStatus.data === null ? (
              <div>
                <Typography variant="body-md-regular">
                  By granting access to metadata you give us access to
                  non-sensitive data on your paper tablet and reMarkable apps.
                </Typography>
                <Typography variant="body-md-regular">
                  Full access gives permission to view and edit all files on
                  your paper tablet and apps. You can revoke access anytime.
                </Typography>
              </div>
            ) : consentStatus.data?.level === 'meta-data' ? (
              <Typography variant="body-md-regular">
                To grant full access, first revoke metadata access, then choose
                full access.
              </Typography>
            ) : (
              <Typography variant="body-md-regular">
                To grant metadata access, first revoke access, then permit
                metadata access.
              </Typography>
            )}

            <>
              {consentStatus.isPending ? (
                <Spinner inline />
              ) : consentStatus.data === null ? (
                <div className="flex w-full flex-col justify-center gap-16 lm:flex-row">
                  <Button
                    className="w-full lm:w-fit"
                    variant="secondary"
                    onClick={handleGrantMetaDataAccess}
                    loading={isGrantMetaDataLoading}
                  >
                    Grant metadata access
                  </Button>
                  <Button
                    className="w-full lm:w-fit"
                    onClick={handleGrantFullAccess}
                    variant="secondary"
                    loading={isGrantFullAccessLoading}
                  >
                    Grant full access
                  </Button>
                </div>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => revokeConsent.mutate()}
                  loading={revokeConsent.isPending}
                  className="w-full lm:w-fit"
                >
                  Revoke access
                </Button>
              )}
            </>
          </div>
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
};
