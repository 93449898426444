import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!clickedOutsideRef(ref, event)) {
        return;
      }

      handler(event); // Call the handler only if the click is outside of the element passed.
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const clickedOutsideRef = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  event: Event
) => {
  const arr = Array.isArray(ref) ? ref : [ref];

  const matchIndex = arr.findIndex((r) => {
    const el = r?.current;
    if (!el) {
      return false;
    }

    if (el.contains((event.target as Node) || null)) {
      return true;
    }

    return false;
  });

  return matchIndex === -1;
};

export { useOnClickOutside, clickedOutsideRef };
