import {
  StripeSubscription,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

import { isActiveStripeSubscription } from './subscriptionIsActive';

export const subscriptionIsConnectForBusiness = (
  sub?: Subscription
): sub is StripeSubscription => {
  if (!isActiveStripeSubscription(sub)) return false;
  if (sub.managementType === 'seats') return true;
  return false;
};

export const subscriptionIsConnectForBusinessOwner = (
  sub?: Subscription
): sub is StripeSubscription => {
  if (!subscriptionIsConnectForBusiness(sub)) return false;
  if (sub.access.canReactivate || sub.access.canCancel) return true;
  return false;
};
