import { ComponentLocations } from 'src/analytics/trackingTypes';
import { AppPage } from 'src/components/AppPage';
import { Offer } from 'src/components/Offers/Offer';

export function ConnectOfferLandingPage() {
  return (
    <AppPage.Content className="max-w-l2xl">
      <Offer
        checkoutType="connectOffer"
        componentLocation={ComponentLocations.CHECKOUT.LANDING}
      />
    </AppPage.Content>
  );
}
