import { StripeElementsOptions } from '@stripe/stripe-js';

export const appearance: StripeElementsOptions['appearance'] = {
  variables: {
    fontFamily: 'reMarkableSans, Helvetica, sans-serif',
    fontSizeBase: '16px',
    spacingGridRow: '1.5rem',
    spacingGridColumn: '1.5rem',
    fontSizeSm: '16px', // label size
    borderRadius: '4px',
    tabSpacing: '12px',
  },
  rules: {
    '.Input': {
      padding: '16px',
      border: '1px solid #D2CABC',
      boxShadow: 'none',
      lineHeight: '16px',
    },
    '.Input:focus': {
      outline: '2px solid initial inset',
      boxShadow: 'none',
    },
    '.Input--invalid': {
      boxShadow: '0 0 0 2px var(--colorDanger) inset',
    },
    '.Label': {
      color: 'rgb(34, 34, 34)',
      fontWeight: 'medium',
    },
    '.Error': {
      color: 'rgb(237, 27, 35)',
    },
    '.Tab': {
      marginTop: '8px',
      border: '1px solid #D2CABC',
    },
  },
};
