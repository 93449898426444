import { Typography } from '@remarkable/ark-web';
import { Link } from '@tanstack/react-router';
import { AppleLogo } from 'phosphor-react';

import { MACOS_ITUNES_URL } from 'src/apps/device/utils/constants';
import windowsColor from 'src/assets/icons/windowsColor.svg';
import { CONFIG } from 'src/config';

export const DesktopAppLink = ({
  platform,
}: {
  platform: 'windows' | 'macos';
}) => (
  <Link
    className="flex h-40 w-fit items-center gap-8 rounded-[10px] bg-neutral-light-4/100 p-8"
    to={
      platform === 'macos'
        ? MACOS_ITUNES_URL
        : `${CONFIG.DownloadsURL}/latest/windows`
    }
    data-cy={`download-desktop-app-${platform}`}
  >
    {platform == 'macos' ? (
      <AppleLogo weight="fill" size={22} />
    ) : (
      <img height="22" width="22" src={windowsColor} alt="Word"></img>
    )}
    <div className="flex flex-col">
      <Typography className="m-0" variant="interface-xs-regular">
        {platform == 'macos' ? 'Download on the' : 'Get it from'}
      </Typography>
      <Typography className="m-0" variant="interface-md-semibold">
        {platform == 'macos' ? 'Mac App store' : 'Microsoft'}
      </Typography>
    </div>
  </Link>
);
