import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { useStripeSubscription } from 'src/api/queries';
import { BetaTag, Divider, Label } from 'src/components';
import { formatDate } from 'src/utils/formatDate';
import { isCanceledSubscription } from 'src/utils/isCanceledSubscription';
import { valueProps } from 'src/utils/valueProps';

import { CardHeading } from './CardHeading';
import { StatusTag } from './StatusTag';

const getActivationDate = (sub?: Subscription) => {
  switch (sub?.type) {
    case 'stripe':
      return formatDate(sub.createdAt);
    case 'free-connect':
      return null; // TODO: Update this when backend ships the data
    default:
      return null;
  }
};

export const ConnectSeatsDetails = () => {
  const subscription = useStripeSubscription();
  const activationDate = getActivationDate(subscription.data);

  const status = useMemo(() => {
    return isCanceledSubscription(subscription.data)
      ? 'canceled'
      : subscription.data?.status;
  }, [subscription.data]);

  const endDate = useMemo(() => {
    if (!subscription.data) return null;

    if (
      subscription.data.paymentInformation?.isDeactivated ||
      subscription.data.paymentInformation?.isCancelled
    ) {
      return formatDate(subscription.data.paymentInformation.cancellationAt);
    }

    return null;
  }, [subscription.data]);

  return (
    <div>
      <div
        data-cy="connect-for-business-card"
        className="flex flex-col items-start gap-8 lm:flex-row lm:justify-between"
      >
        <div>
          <CardHeading>
            Connect for business <BetaTag />
          </CardHeading>
          <Label
            className="inline-block"
            data-cy="subscription-card-activated-label"
          >
            {endDate ? (
              <>
                All members will loose access to Connect by the end of the
                billing cycle ({endDate})
              </>
            ) : (
              <>Activated {activationDate}</>
            )}
          </Label>
        </div>

        <StatusTag status={status} />
      </div>

      <Divider className="my-24" />

      <div
        data-cy="connect-offer-perks"
        className="flex w-full flex-col gap-16"
      >
        {subscription.data?.access.canSeeMembers ? (
          <Typography variant="body-lg-regular">
            Manage multiple subscriptions from one account. Your team gets
            access to the following features and benefits:
          </Typography>
        ) : (
          <Typography variant="body-lg-regular">
            Here&apos;s what your subscription includes:
          </Typography>
        )}

        <ul className="grid gap-8 lm:grid-cols-2">
          {Object.values(valueProps.connectForBusiness).map((valueProp) => (
            <li key={valueProp.id} className="flex items-center gap-8">
              <div className="flex items-center gap-8">
                <valueProp.icon className="text-pen-blue" size={20} />
                <Typography variant="body-md-regular">
                  {valueProp.title}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
