import { useState } from 'react';

import { DotsThreeVertical } from 'phosphor-react';
import toast from 'react-hot-toast';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useCreateSeatForMember,
  useCreateSeatInvitationsByEmail,
  useRemoveSeatForMember,
} from 'src/api/queries';
import { useDeleteInvitation } from 'src/api/queries/invitations';
import { Button, Modal } from 'src/components';
import { DropdownMenu, DropdownMenuItem } from 'src/components/DropdownMenu';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';

import {
  Member,
  isInvitationMember,
  isSubscriptionMember,
} from '../utils/useMembersList';
import {
  CancelInvitationModal,
  useCancelInvitationModal,
} from './CancelInvitationModal';
import { RoleEnum, UpdateMemberRole } from './ChangeRole';
import {
  RemoveUserFromSubscriptionModal,
  useRemoveUserFromSubscriptionModal,
} from './RemoveUserFromSubscriptionModal';

export const MemberActionMenu = ({ member }: { member: Member }) => {
  const removeUserFromSeatModal = useRemoveUserFromSubscriptionModal();
  const deleteInvitation = useDeleteInvitation();
  const cancelInvitationModal = useCancelInvitationModal();
  const createSeatInvitationsByEmail = useCreateSeatInvitationsByEmail();

  const memberId = isSubscriptionMember(member) ? member.data.id : 'N/A';
  const createSeatForMember = useCreateSeatForMember({ memberId });
  const removeSeatForMember = useRemoveSeatForMember({ memberId });

  const copyToClipboard = useCopyToClipboard();
  const [isOpen, setIsOpen] = useState(false);

  const onResend = () => {
    if (!isInvitationMember(member)) return;
    if (createSeatInvitationsByEmail.isPending) return;

    tracker.trackEvent(
      new ButtonClicked({
        text: 'Resend invitation',
        component_location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
        action: 'resend invitation',
      })
    );
    createSeatInvitationsByEmail.mutate(
      {
        emails: [member.data.email],
        targetRole: member.data.targetRole,
      },
      {
        onError() {
          toast.error('Could not send invitation');
        },
        onSuccess() {
          toast.success('Invitation sent');
        },
      }
    );
  };

  return (
    <>
      <DropdownMenu
        open={isOpen}
        onOpenChange={setIsOpen}
        trigger={
          <Button
            variant="tertiary-neutral"
            className="!rounded data-[state=open]:bg-interaction-tertiary-active"
            size="small"
            data-cy={`actions-button-cy_${member.title}`}
            analytics={{
              text: 'DotsThreeVertical icon',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'open member actions menu',
            }}
          >
            <DotsThreeVertical />
          </Button>
        }
      >
        {isSubscriptionMember(member) ? (
          <>
            <DropdownMenuItem
              data-cy="remove-member-button"
              disabled={!member.data.access.canRemoveUser}
              onClick={() => {
                if (!isSubscriptionMember(member)) return;

                tracker.trackEvent(
                  new ButtonClicked({
                    text: 'Remove member',
                    component_location:
                      ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                    action: 'open remove member modal',
                  })
                );

                if (!member.data.access.canRemoveUser) {
                  return;
                }

                removeUserFromSeatModal.open(member.data);
              }}
            >
              <span>Remove member</span>
            </DropdownMenuItem>

            {!member.data.seat && (
              <DropdownMenuItem
                data-cy="create-seat-button"
                disabled={!member.data.access.canGiveConnect}
                onClick={() => {
                  if (!isSubscriptionMember(member)) return;

                  tracker.trackEvent(
                    new ButtonClicked({
                      text: 'Create seat',
                      component_location:
                        ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                      action: 'create seat for member',
                    })
                  );

                  if (!member.data.access.canGiveConnect) {
                    return;
                  }

                  createSeatForMember.mutate({ memberId: member.data.id });
                }}
              >
                <span>Activate seat</span>
              </DropdownMenuItem>
            )}

            {member.data.seat && (
              <>
                <DropdownMenuItem
                  data-cy="remove-seat-button"
                  disabled={!member.data.access.canRemoveConnect}
                  onClick={() => {
                    if (!isSubscriptionMember(member)) return;

                    tracker.trackEvent(
                      new ButtonClicked({
                        text: 'Remove seat',
                        component_location:
                          ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                        action: 'remove seat for member',
                      })
                    );

                    if (!member.data.access.canRemoveConnect) {
                      return;
                    }

                    removeSeatForMember.mutate({ memberId: member.data.id });
                  }}
                >
                  <span>Deactivate seat</span>
                </DropdownMenuItem>

                {member.data.access.canChangeRoles &&
                  member.data.access.canChangeRoleTo.map((role) => (
                    <Modal.Root
                      key={role}
                      onOpenChange={(isOpen) => {
                        if (isOpen) return;

                        // Close the dropdown menu when the modal closes
                        setIsOpen(false);
                      }}
                    >
                      <Modal.Trigger asChild>
                        <DropdownMenuItem
                          onSelect={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {role === RoleEnum.MEMBER
                            ? `Remove ${member.role} access`
                            : `Make ${role}`}
                        </DropdownMenuItem>
                      </Modal.Trigger>

                      <Modal.Content>
                        <UpdateMemberRole
                          member={member.data}
                          toRole={role}
                          onSuccess={() => {
                            setIsOpen(false);
                          }}
                        />
                      </Modal.Content>
                    </Modal.Root>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            {!member.data.isDeclined && !member.data.isExpired && (
              <DropdownMenuItem
                className="lm:hidden"
                onClick={() => {
                  if (!isInvitationMember(member)) return;

                  tracker.trackEvent(
                    new ButtonClicked({
                      text: 'Copy link',
                      component_location:
                        ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                      action: 'copy invitation link',
                    })
                  );

                  copyToClipboard.mutate(member.data.invitationLink);
                }}
              >
                <span>Copy link</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              data-cy="resend-invitation"
              onClick={() => onResend()}
            >
              <span>Resend invitation</span>
            </DropdownMenuItem>

            {member.data.isDeclined || member.data.isExpired ? (
              <DropdownMenuItem
                onClick={() => {
                  if (!isInvitationMember(member)) return;

                  tracker.trackEvent(
                    new ButtonClicked({
                      text: 'Delete invitation',
                      component_location:
                        ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                      action: 'open delete invitation modal',
                    })
                  );

                  deleteInvitation.mutate(member.data.invitationLink);
                }}
              >
                <span>Delete invitation</span>
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem
                data-cy="cancel-invitation"
                onClick={() => {
                  if (!isInvitationMember(member)) return;

                  tracker.trackEvent(
                    new ButtonClicked({
                      text: 'Cancel invitation',
                      component_location:
                        ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                      action: 'open cancel invitation modal',
                    })
                  );

                  cancelInvitationModal.open({
                    id: member.data.invitationLink,
                  });
                }}
              >
                <span>Cancel invitation</span>
              </DropdownMenuItem>
            )}
          </>
        )}
      </DropdownMenu>

      {member.type === 'subscription' && (
        <RemoveUserFromSubscriptionModal id={member.data.id} />
      )}

      {member.type === 'invitation' && (
        <CancelInvitationModal id={member.data.invitationLink} />
      )}
    </>
  );
};
