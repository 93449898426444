import { useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import clsx from 'clsx';
import { atom, useAtom } from 'jotai';
import { CaretDown, CaretUp, UserCircle, X } from 'phosphor-react';

import { Link, Tag } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { CONFIG } from 'src/config';
import { isUserRemarkableInternal } from 'src/utils/auth';
import { useIsFleetUser } from 'src/utils/isFleetUser';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';
import { useOnClickOutside } from 'src/utils/useOnClickOutside';

const sideMenuAtom = atom<boolean>(false);
export function useSideMenuState() {
  const [isOpen, setIsOpen] = useAtom(sideMenuAtom);
  return {
    isOpen,
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
    toggle() {
      setIsOpen(!isOpen);
    },
  };
}

export const SideMenu = () => {
  const sideMenu = useSideMenuState();
  const menuRef = useRef<HTMLDivElement>(null);
  const isFleetUser = useIsFleetUser();
  const auth = useAuth0();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => sideMenu.toggle();
  useOnClickOutside(menuRef, () => sideMenu.close());

  if (!auth.user || auth.user?.email_verified === false) return null;

  return (
    <div
      ref={menuRef}
      className={`fixed left-0 top-0 z-50 flex h-full w-auto flex-col bg-neutral-light-3 p-6 px-24 transition-transform duration-300 lm:px-64 ${
        sideMenu.isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="mt-16 flex items-center justify-between gap-16">
        <UserCircle size={30} />{' '}
        <Typography
          variant="body-md-regular"
          className="max-w-[240px] break-words text-black"
          data-cy="logged-in-user"
        >
          {auth.user.email?.split(/(?=@)/g).map((s, index) => (
            <span
              className={clsx({
                'whitespace-nowrap': index > 0,
              })}
              key={s}
            >
              {s}
            </span>
          ))}
        </Typography>
        <button onClick={toggleMenu}>
          <X size={32} />
        </button>
      </div>

      <nav className=" flex grow flex-col justify-between gap-56 overflow-y-auto">
        <ul className="mt-64 flex flex-col gap-16 text-24 font-semibold lm:gap-24">
          <li>
            <Link2
              inline
              to="/"
              onClick={toggleMenu}
              data-cy="nav-home"
              className="no-underline hover:underline"
            >
              Home
            </Link2>
          </li>

          {CONFIG.Environment === 'development' &&
            isFleetUser.data === true && (
              <li>
                <Link
                  inline
                  to={URLS.FLEET_MEMBERS}
                  onClick={toggleMenu}
                  data-cy="nav-fleet"
                  className="no-underline hover:underline"
                >
                  Fleet
                </Link>
              </li>
            )}

          <li>
            <Link2
              inline
              to="/account"
              onClick={toggleMenu}
              data-cy="nav-account"
              className="no-underline hover:underline"
            >
              Account
            </Link2>
          </li>
          <li>
            <Link2
              inline
              to="/subscriptions"
              onClick={toggleMenu}
              data-cy="nav-subscription"
              className="no-underline hover:underline"
            >
              Subscription
            </Link2>{' '}
            <Tag className="ml-8">New</Tag>
          </li>
          <li>
            <Link
              inline
              to={URLS.DEVICE}
              onClick={toggleMenu}
              data-cy="nav-device-and-apps"
              className="no-underline hover:underline"
            >
              Devices and apps
            </Link>
          </li>
          <li>
            <Link
              inline
              to={URLS.INTEGRATIONS}
              onClick={toggleMenu}
              data-cy="nav-integrations"
              className="no-underline hover:underline"
            >
              Integrations
            </Link>
          </li>
          {isUserRemarkableInternal(auth.user) && (
            <li>
              <Link
                inline
                to={URLS.SCREENSHARE_HOME}
                onClick={toggleMenu}
                data-cy="nav-screen-share"
                className="no-underline hover:underline"
              >
                Screen Share
              </Link>
            </li>
          )}
          <li className="relative">
            <div className="flex items-center space-x-16">
              <Link
                to={URLS.MYFILES}
                onClick={toggleMenu}
                data-cy="nav-my-files"
                inline
                className="no-underline hover:underline"
              >
                My files
              </Link>
              {isDropdownOpen ? (
                <CaretUp
                  className="inline-block cursor-pointer select-none"
                  onClick={toggleDropdown}
                />
              ) : (
                <CaretDown
                  className="inline-block cursor-pointer select-none"
                  data-cy="nav-my-files-expand"
                  onClick={toggleDropdown}
                />
              )}
            </div>
            {isDropdownOpen && (
              <ul className="rounded-md absolute left-16 mt-2">
                <li>
                  <Link
                    to={URLS.MYFILES_TRASH}
                    onClick={toggleMenu}
                    data-cy="nav-my-files"
                    className="inline-block"
                  >
                    <Typography
                      variant="body-md-regular"
                      className="text-black"
                    >
                      Trash
                    </Typography>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>

        <ul className="mb-24 flex flex-col gap-16 text-18 font-semibold lm:mb-64 lm:gap-24">
          <li>
            <LinkExternal
              inline
              to={SUPPORT_URL.HOME}
              onClick={toggleMenu}
              data-cy="nav-support"
              className="no-underline hover:underline"
            >
              <Typography variant="body-md-regular" className="text-black">
                Support and FAQ
              </Typography>
            </LinkExternal>
          </li>
          <li>
            <LinkExternal
              inline
              to={URLS.RM_COM_USING_REMARKABLE}
              onClick={toggleMenu}
              data-cy="nav-using-remarkable"
              className="no-underline hover:underline"
            >
              <Typography variant="body-md-regular" className="text-black">
                Using reMarkable
              </Typography>
            </LinkExternal>
          </li>
          <li>
            <Link2
              inline
              to="/logout"
              data-cy="nav-log-out"
              className="no-underline hover:underline"
            >
              <Typography variant="body-md-regular" className="text-black">
                Log out
              </Typography>
            </Link2>
          </li>
        </ul>
      </nav>
    </div>
  );
};
