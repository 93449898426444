import { getStripeSubscriptionV2 } from '../../apps/manageSubscription/app/utils/getStripeSubscriptionV2';
import {
  SubscriptionPaymentIntervalOption,
  Subscription,
} from '../endpoints/storeApi.types';

type PaymentIntervals = {
  [K in SubscriptionPaymentIntervalOption as K['interval']]: K;
};

export const transformPaymentIntervals = (
  response: Subscription
): {
  canChangePaymentInterval: boolean;
  currentInterval: SubscriptionPaymentIntervalOption;
  options: PaymentIntervals;
  optionsList: SubscriptionPaymentIntervalOption[];
  isMonthly: boolean;
  isAnnual: boolean;
} | null => {
  const stripeSub = getStripeSubscriptionV2(response);
  const currentInterval =
    stripeSub?.paymentInformation?.paymentIntervalOptions?.find(
      (o) => o.interval === stripeSub.paymentInformation?.paymentInterval
    );

  if (
    !stripeSub ||
    !stripeSub.paymentInformation?.paymentInterval ||
    !currentInterval
  )
    return null;

  return {
    canChangePaymentInterval: stripeSub.access.canChangePaymentInterval,
    currentInterval,
    options: (stripeSub.paymentInformation.paymentIntervalOptions ?? []).reduce(
      (acc, option) => ({
        ...acc,
        [option.interval]: option,
      }),
      {} as PaymentIntervals
    ),
    optionsList: stripeSub.paymentInformation.paymentIntervalOptions ?? [],
    isMonthly: stripeSub.paymentInformation.paymentInterval === 'monthly',
    isAnnual: stripeSub.paymentInformation.paymentInterval === 'annually',
  };
};
