import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  EnrolledToConnectForBusiness,
  InvitationAccepted,
  InvitationSent,
  SeatUpdated,
} from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { getStripeSubscriptionV2 } from 'src/apps/manageSubscription/app/utils/getStripeSubscriptionV2';
import { subscriptionIsFreeConnect } from 'src/utils/subscriptionisFreeConnect';

import { storeApi } from '../endpoints';
import {
  SubscriptionManagementType,
  SubscriptionRole,
} from '../endpoints/storeApi.types';
import { queryKeys } from './queryKeys';
import {
  useCurrentMember,
  useStripeSubscription,
  useSubscription,
} from './subscriptions';
import {
  MutationOnSuccessOption,
  NoStripeSubscriptionException,
} from './types';

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();

  return useMutation({
    mutationKey: queryKeys.subscriptions.cancel,
    mutationFn: () => {
      if (!subscription.data?.id) {
        throw new NoStripeSubscriptionException();
      }

      return storeApi.cancelSubscription({
        subscriptionId: subscription.data.id,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};

export const useAbortCancelSubscription = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();

  return useMutation({
    mutationKey: queryKeys.subscriptions.cancel,
    mutationFn: () => {
      if (!subscription.data?.id) {
        throw new NoStripeSubscriptionException();
      }

      return storeApi.abortCancelSubscription({
        subscriptionId: subscription.data.id,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};

interface CreateEmailsResult {
  successful: {
    email: string;
    response: Awaited<ReturnType<typeof storeApi.createSeatInvitationEmail>>;
  }[];
  unsuccessful: {
    email: string;
    error: unknown;
  }[];
}

export class CreateEmailsError extends Error {
  constructor(public result: CreateEmailsResult) {
    super('An error occurred while sending out invitations.');
  }
}

export const useCreateSeatInvitationsByEmail = () => {
  const subscriptions = useSubscription();
  const sub = getStripeSubscriptionV2(subscriptions.data);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      emails,
      targetRole,
    }: {
      emails: string[];
      targetRole: SubscriptionRole;
    }) => {
      if (!sub) {
        throw new Error(
          useCreateSeatInvitationsByEmail.name + ': No subscription found'
        );
      }

      // Fetch all the emails in parallel and wait for them to settle by catching
      // the errors. This way we can return a list of both successful and
      // unsuccessful emails.
      const response = await Promise.all(
        emails.map((email) => {
          return storeApi
            .createSeatInvitationEmail({
              subscriptionId: sub.id,
              email,
              targetRole,
            })
            .then((response) => ({
              type: 'fulfilled' as const,
              email,
              response,
            }))
            .catch((error: unknown) => ({
              type: 'rejected' as const,
              email,
              error,
            }));
        })
      );

      const sortedResponse = response.reduce<CreateEmailsResult>(
        (acc, result) => {
          if (result.type === 'fulfilled') {
            acc.successful.push(result);
          } else {
            acc.unsuccessful.push(result);
          }

          return acc;
        },
        {
          successful: [],
          unsuccessful: [],
        }
      );

      if (sortedResponse.unsuccessful.length > 0) {
        throw new CreateEmailsError(sortedResponse);
      }

      return sortedResponse.successful;
    },
    onSuccess: (_, variables) => {
      tracker.trackEvent(
        new InvitationSent({
          number_of_invitations: variables.emails.length,
          invitation_type: 'connect for business',
        })
      );
      return queryClient.invalidateQueries({
        queryKey: queryKeys.invitations.all,
      });
    },
  });
};

export const useGetSeatInvitation = (invitationTokenUUID = '') =>
  useSuspenseQuery({
    queryKey: queryKeys.subscriptions.v2.getSeatInvitation(invitationTokenUUID),
    queryFn: () =>
      storeApi.getSeatInvitation({
        invitationTokenUUID,
      }),
  });

export const useAcceptSeatInvitation = (
  options?: MutationOnSuccessOption<
    Awaited<ReturnType<typeof storeApi.acceptSeatInvitation>>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: storeApi.acceptSeatInvitation,
    onSuccess: async (response) => {
      await options?.onSuccess?.(response);
      await queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
      tracker.trackEvent(
        new InvitationAccepted({
          invitation_type: 'connect for business',
        })
      );
    },
  });
};

export const useDeclineSeatInvitation = (
  options?: MutationOnSuccessOption<
    Awaited<ReturnType<typeof storeApi.declineSeatInvitation>>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: storeApi.declineSeatInvitation,
    onSuccess: async (response) => {
      await options?.onSuccess?.(response);
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};

export const useRemoveUserFromSubscription = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();

  return useMutation({
    mutationFn: ({ subscriptionRoleId }: { subscriptionRoleId: string }) => {
      if (!subscription.data?.id) {
        throw new Error('No subscription found or is still loading');
      }

      const subscriptionId = subscription.data.id;
      return storeApi.removeUserFromSubscription({
        subscriptionRoleId,
        subscriptionId,
      });
    },
    onSuccess: () => {
      tracker.trackEvent(
        new SeatUpdated({
          action: 'removed',
        })
      );
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.members(subscription.data?.id ?? ''),
      });
    },
  });
};

export const useLeaveSubscription = () => {
  const queryClient = useQueryClient();
  const subscription = useStripeSubscription();

  return useMutation({
    mutationFn: ({ subscriptionRoleId }: { subscriptionRoleId: string }) => {
      if (!subscription.data?.id) {
        throw new Error('No subscription found or is still loading');
      }

      const subscriptionId = subscription.data.id;
      return storeApi.removeUserFromSubscription({
        subscriptionRoleId,
        subscriptionId,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};

export const useUpdateSubscriptionManagementTypeAllowed = (
  type?: SubscriptionManagementType
) => {
  const subscription = useStripeSubscription();
  const seat = useCurrentMember();

  const managementType =
    type ?? subscription.data?.managementType === 'simple' ? 'seats' : 'simple';

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey:
      queryKeys.subscriptions.v2.updateSubscriptionManagementTypeAllowed(
        managementType
      ),
    queryFn: () => {
      if (!subscription.data?.id) {
        throw new NoStripeSubscriptionException();
      }

      return storeApi.updateSubscriptionManagementTypeAllowed({
        subscriptionId: subscription.data.id,
        managementType,
      });
    },
    enabled: subscription.isSuccess && seat !== null,
    retry: false, // TODO: revert this once we are done with the seats pilot
  });
};

export const useUpdateSubscriptionManagementType = () => {
  const subscriptions = useSubscription();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ type }: { type: SubscriptionManagementType }) => {
      const subscriptionId =
        subscriptions.data?.type === 'stripe' ? subscriptions.data.id : null;

      if (!subscriptionId) {
        throw new Error('No subscription found or is still loading');
      }

      return storeApi.updateSubscriptionManagementType({
        managementType: type,
        subscriptionId,
      });
    },
    onSuccess(_data, variables) {
      if (variables.type === 'seats') {
        tracker.trackEvent(
          new EnrolledToConnectForBusiness({
            previous_subscription_status: subscriptions.data?.status ?? 'none',
            previous_subscription_type: subscriptions.data?.type
              ? subscriptionIsFreeConnect(subscriptions.data)
                ? 'free connect'
                : 'connect'
              : 'none',
            subscription_created_at: subscriptions.data?.createdAt ?? 'none',
          })
        );
      }
      return queryClient.invalidateQueries({
        queryKey: queryKeys.subscriptions.all,
      });
    },
  });
};
