import { redirect } from '@tanstack/react-router';

import {
  CheckoutAllowedResponse,
  CheckoutType,
} from 'src/api/endpoints/storeApi.types';

export function handleCheckoutAllowedRedirect(
  checkoutType: CheckoutType,
  data: CheckoutAllowedResponse['data'] | null
) {
  if (!data) {
    throw redirect({ to: '/' });
  }

  if (checkoutType in data === false) {
    throw new Error('Unable to check if checkout is allowed');
  }

  const checkout = data[checkoutType];

  if (checkout.allowed !== true) {
    if (checkout.reason === 'NO_OFFER') {
      throw redirect({ to: '/' });
    }
    throw redirect({ to: '/subscriptions' });
  }
}
