import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { Spinner } from 'src/components';

const loginSearchSchema = z.object({
  redirect: z.string().startsWith('/').optional(),
  connection: z.string().optional(),
  login_hint: z.string().optional(),
  screen_hint: z.enum(['signup', 'login']).optional(),
  organization: z.string().optional(),
  prompt: z.enum(['none']).optional(),
});

export const Route = createFileRoute('/(public)/login')({
  validateSearch: zodSearchValidator(loginSearchSchema),
  component: () => {
    const auth = useAuth0();
    const search = Route.useSearch();

    void auth.loginWithRedirect({
      authorizationParams: {
        prompt: search.prompt,
        organization: search.organization,
        connection: search.connection,
        login_hint: search.login_hint,
        screen_hint: search.screen_hint,
      },
      appState: {
        returnTo: search.redirect,
      },
    });

    return <Spinner />;
  },
});
