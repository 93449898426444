import { Typography } from '@remarkable/ark-web';

import { useDeleteIntegration } from 'src/api/queries';
import { Button, Modal } from 'src/components';

export const RemoveIntegrationModal = ({
  integrationId,
  integrationName,
}: {
  integrationId: string;
  integrationName: string;
}) => {
  const deleteIntegration = useDeleteIntegration();

  return (
    <Modal.Content>
      <div className="flex flex-col items-center">
        <Typography variant="heading-sm">Remove integration</Typography>

        <Typography variant="body-md-regular">
          Are you sure you want to remove the integration &quot;
          {integrationName}&quot;?
        </Typography>

        <div className="mt-32 flex justify-center gap-16">
          <Button
            data-cy="remove-integration-modal-confirm"
            onClick={() => deleteIntegration.mutate(integrationId)}
            loading={deleteIntegration.isPending}
          >
            Yes, remove
          </Button>

          <Modal.Close asChild>
            <Button variant="secondary">No, cancel</Button>
          </Modal.Close>
        </div>
      </div>
    </Modal.Content>
  );
};
