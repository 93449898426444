import { paths } from 'apis/cloud-enterprise-fleet-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudEnterpriseFleetApiClient = createClient<paths>({
  baseUrl: CONFIG.CloudEnterpriseApiUrl,
});

cloudEnterpriseFleetApiClient.use(addAuthHeader, throwOnErrorResponse);

export { cloudEnterpriseFleetApiClient };
