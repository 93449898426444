export const NotFoundPage = () => {
  return (
    <div data-cy="404-page" className="w-full bg-neutral-light-2">
      <div className="m-auto flex max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128">
        <h1 className="heading-lg text-center">Oops!</h1>
        <p className="heading-sm my-0 text-center">404 - Page not found</p>
        <p className="max-w-[540px] text-center">
          Are you sure the website URL is correct? The page you are looking for
          might have been removed, had it&apos;s name changed or is temporarly
          unavailable.
        </p>
      </div>
    </div>
  );
};
