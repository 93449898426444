// Import the generated route tree
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import { Pageview } from './ampli';
import { tracker } from './analytics/tracker';
import { App } from './app';
import { CONFIG } from './config';
import { router } from './router';
import './scss/app.scss';
import './scss/tailwind.css';

function trackPageView() {
  tracker.trackEvent(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    new Pageview({
      url:
        window.location.origin +
        router.history.location.pathname +
        router.history.location.search +
        router.history.location.hash,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  );
}
// Track landing page (first page view only)
trackPageView();

router.history.subscribe(() => {
  // Track route changes
  trackPageView();
});

try {
  Sentry.init({
    dsn: 'https://56f51ac528f948519fea1f531da7211a@o100763.ingest.sentry.io/1418691',
    integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
    environment: CONFIG.Environment ?? 'development',
    release: CONFIG.Release,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (
        breadcrumb.category === 'ui.click' &&
        hint &&
        'target' in hint &&
        hint.target instanceof Element
      ) {
        const elem = hint.target.closest('[data-cy]');
        if (elem && elem instanceof HTMLElement) {
          return {
            ...breadcrumb,
            data: {
              ...breadcrumb.data,
              target: elem.dataset.cy,
            },
          };
        }
      }
      return breadcrumb;
    },
  });
} catch (e) {
  console.error('Sentry init failed', e);
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

async function main() {
  if (CONFIG.Environment !== 'production') {
    await import('./apps/devtool/app/init').then(async ({ initDevtools }) => {
      await initDevtools();
    });
  }

  // Render the app
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElement = document.getElementById('root')!;
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    // TODO: Enable StrictMode when all components are compatible (checkout confirmation page is not)
    root.render(<App />);
  }
}

void main();
