import { getRouteApi } from '@tanstack/react-router';

import { Modal } from 'src/components';

import { MrfStep1 } from './MrfStep1';
import { MrfStep2 } from './MrfStep2';
import { MrfStep3 } from './MrfStep3';
import { MrfStep4 } from './MrfStep4';
import { MrfStep5 } from './MrfStep5';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

export const MrfModal = () => {
  return (
    <Modal.Content className="flex h-full max-h-[700px] max-w-l2xl flex-col justify-between">
      <ModalRouter />
    </Modal.Content>
  );
};

const ModalRouter = () => {
  const search = routeApi.useSearch();
  switch (search.cancel_subscription) {
    case 'step-1':
      return <MrfStep1 />;
    case 'step-2':
      return <MrfStep2 />;
    case 'step-3':
      return <MrfStep3 />;
    case 'step-4':
      return <MrfStep4 />;
    case 'step-5':
      return <MrfStep5 />;
    default:
      return null;
  }
};
