import { useEffect, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { CheckCircle } from 'phosphor-react';

import { useUnlinkAccount } from 'src/api/queries';
import { Button, Modal2, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

import { getAuthProviderDisplayName } from './LinkedAccounts';

export const useConfirmUnlinkModal = createModalHook<{
  unlinkUserId: string;
  provider: string;
}>('UnlinkSocialAccount');

export const ConfirmUnlinkModal = () => {
  const modal = useConfirmUnlinkModal();
  const unlinkAccount = useUnlinkAccount();
  const [hasUnlinkedSuccessfully, setHasUnlinkedSuccessfully] = useState(false);

  // on modal close, reset state
  useEffect(() => {
    if (!modal.isOpen) {
      setHasUnlinkedSuccessfully(false);
    }
  }, [modal.isOpen]);

  if (modal.data === undefined) return null;

  return (
    <Modal2 dataCy="confirm-unlink-modal" onClose={modal.close}>
      {unlinkAccount.isError && (
        <NotificationBox
          variant="error"
          title={'Oops! Something went wrong'}
          data-cy="confirm-unlink-modal"
        >
          We couldn{"'"}t unlink your account. Please try again later.
          <Button
            onClick={() => {
              modal.close();
            }}
          >
            Close
          </Button>
        </NotificationBox>
      )}

      {hasUnlinkedSuccessfully ? (
        <div className="flex flex-col items-center justify-center">
          <CheckCircle
            size={48}
            weight="fill"
            color="#009245"
            className="mb-16 shrink-0"
          />
          <Typography variant="heading-lg" className="mb-32">
            Social login removed
          </Typography>
          <Typography variant="body-md-regular">
            You{'´'}ve removed {getAuthProviderDisplayName(modal.data.provider)}{' '}
            as a login option. To log in, use your email address and password.
          </Typography>
          <Button variant="secondary" onClick={modal.close} className="mt-32">
            Continue
          </Button>
        </div>
      ) : (
        <>
          <Typography variant="heading-lg" className="mb-32">
            Remove social login
          </Typography>
          <Typography variant="body-md-regular">
            Are you sure you want to remove{' '}
            {getAuthProviderDisplayName(modal.data.provider)} as a login option?
            You can still use your email address and password to log in.
          </Typography>
          <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
            <Button
              variant="secondary"
              size="medium"
              className="w-full xs:w-fit"
              onClick={modal.close}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              className="w-full xs:w-fit"
              loading={unlinkAccount.isPending}
              onClick={() => {
                unlinkAccount.mutate(
                  {
                    unlinkUserId: modal.data.unlinkUserId,
                    provider: modal.data.provider,
                  },
                  {
                    onSuccess: () => {
                      setHasUnlinkedSuccessfully(true);
                    },
                  }
                );
              }}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
    </Modal2>
  );
};
