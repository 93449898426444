import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCurrentMember, useLeaveSubscription } from 'src/api/queries';
import {
  Button,
  Modal2,
  NotificationBox,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useLeaveSubscriptionModal = createModalHook('LeaveSubscription');

export const LeaveSubscriptionModal = () => {
  const leaveSubscription = useLeaveSubscription();
  const modal = useLeaveSubscriptionModal({
    onClose: () => {
      leaveSubscription.reset();
    },
  });
  const currentMember = useCurrentMember();

  if (modal.isOpen === false) return null;

  if (currentMember.isPending) {
    return (
      <Modal2 onClose={modal.close}>
        <Spinner />
      </Modal2>
    );
  }

  if (currentMember.isError || !currentMember.data) {
    return (
      <Modal2 onClose={modal.close}>
        <SomethingWentWrong />
      </Modal2>
    );
  }

  return (
    <Modal2 onClose={modal.close}>
      <Typography variant="heading-md" className="mb-24">
        Leave subscription?
      </Typography>

      <Typography variant="body-md-regular" className="mb-24">
        Are you sure you want to leave this subscription?
      </Typography>

      <Typography variant="body-md-regular">
        You&apos;ll lose access to all subscription features and benefits.
      </Typography>

      {leaveSubscription.isError && (
        <NotificationBox
          variant="error"
          title="Something went wrong"
          className="my-24"
        >
          Try again or contact support if the problem persists.
        </NotificationBox>
      )}

      <div className="mt-24 flex justify-center gap-16">
        <Button
          variant="secondary"
          loading={leaveSubscription.isPending}
          onClick={() => {
            if (!currentMember.data?.id) return;

            leaveSubscription.mutate(
              {
                subscriptionRoleId: currentMember.data?.id,
              },
              {
                onSuccess: () => {
                  modal.close();
                },
              }
            );
          }}
          disabled={!currentMember.data?.seat?.id}
          analytics={{
            text: 'Leave subscription',
            location: ComponentLocations.MANAGE_CONNECT.MAIN,
            action: 'leave subscription',
          }}
        >
          Leave subscription
        </Button>

        <Button
          onClick={modal.close}
          analytics={{
            text: 'Stay',
            location: ComponentLocations.MANAGE_CONNECT.MAIN,
            action: 'stay (close modal)',
          }}
        >
          Stay
        </Button>
      </div>
    </Modal2>
  );
};
