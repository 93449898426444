import {
  StripeSubscription,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

export const subscriptionIsActive = (sub?: Subscription): boolean => {
  if (!sub) return false;

  if (sub.type === 'free-connect') return true;

  switch (sub.status) {
    case 'active':
    case 'trialing':
    case 'past_due':
      return true;
    default:
      return false;
  }
};

export const isActiveStripeSubscription = (
  sub?: Subscription
): sub is StripeSubscription =>
  subscriptionIsActive(sub) && sub?.type === 'stripe';
