import { createFileRoute, Outlet } from '@tanstack/react-router';

import {
  createQueryPaymentHistory,
  createQuerySubscriptions,
  usePaymentHistory,
  useSubscriptionSuspense,
} from 'src/api/queries';
import { PausedSubscriptionNotification } from 'src/apps/manageSubscription/app/components/PausedNotification';
import { SubscriptionCanceledNotification } from 'src/apps/manageSubscription/app/components/SubscriptionCanceledNotification';
import { AppPage } from 'src/components/AppPage';
import { Navbar, NavTab } from 'src/components/NavBar';

export const Route = createFileRoute('/_auth/_layout/subscriptions')({
  loader: async ({ context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(createQueryPaymentHistory()),
      context.queryClient.ensureQueryData(createQuerySubscriptions()),
    ]);
  },
  component: () => <SubscriptionPage />,
});

const SubscriptionPage = () => {
  const subscription = useSubscriptionSuspense();
  const paymentHistory = usePaymentHistory();

  const isSeatManagement =
    subscription.data?.type === 'stripe' &&
    subscription.data.managementType === 'seats';

  const access = subscription.data?.access;

  const canSeeMemebersPage = isSeatManagement && access?.canSeeMembers;

  const hasPaymentHistory = (paymentHistory.data?.length ?? 0) > 0;

  const canSeePaymentPage =
    hasPaymentHistory ||
    access?.canSeeFailedPaymentInformation ||
    access?.canSeePaymentInformation ||
    access?.canSeeNextInvoiceAmount ||
    access?.canSeePaymentHistory ||
    access?.canChangePaymentInterval;

  const showNavbar = canSeeMemebersPage || canSeePaymentPage;

  return (
    <AppPage.Container>
      <div className="mx-auto max-w-lxl px-16 lm:px-32">
        <SubscriptionCanceledNotification />
      </div>

      <AppPage.Header title="Subscription" />

      <AppPage.Content className="max-w-lxl">
        {showNavbar && (
          <div className="mx-auto mb-24 w-full max-w-lxl">
            <Navbar className="mb-40">
              {canSeeMemebersPage && (
                <NavTab to="/subscriptions/members" data-cy="members-tab-link">
                  Members
                </NavTab>
              )}
              <NavTab to="/subscriptions/plan" data-cy="plan-tab-link">
                Subscription
              </NavTab>
              <NavTab to="/subscriptions/payment" data-cy="payment-tab-link">
                Payment
              </NavTab>
            </Navbar>
          </div>
        )}
        <PausedSubscriptionNotification subscription={subscription.data} />

        <Outlet />
      </AppPage.Content>
    </AppPage.Container>
  );
};
