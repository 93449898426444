import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { PaymentWithDisplay, usePaymentHistory } from 'src/api/queries';
import { NotificationBox, Spinner, Table, Tooltip } from 'src/components';
import { TablePagination } from 'src/components/TablePagination';
import { formatShortDate } from 'src/utils/formatDate';
import { useWindowSize } from 'src/utils/useWindowSize';

import { ReceiptLink } from './ReceiptLink';

const columnHelper = createColumnHelper<PaymentWithDisplay>();

export const PaymentHistoryList = ({
  showSeatsColumn = false,
}: {
  showSeatsColumn?: boolean;
}) => {
  const paymentHistory = usePaymentHistory();
  const windowSize = useWindowSize();

  const shouldRenderSeatsColumn = showSeatsColumn && windowSize.width > 540;

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('display.date', {
          header: 'Date',
          meta: { colWidth: 'w-[110px]' },
          sortingFn: (a, b) =>
            new Date(a.original.createdAt).getTime() -
            new Date(b.original.createdAt).getTime(),
          cell(info) {
            return (
              <Tooltip
                title={formatShortDate(info.row.original.createdAt, {
                  showTime: true,
                  showYear: true,
                })}
              >
                <Typography variant="body-sm-regular" className="truncate">
                  {info.row.original.display.date}
                </Typography>
              </Tooltip>
            );
          },
        }),
        columnHelper.accessor('display.description', { header: 'Description' }),
        shouldRenderSeatsColumn &&
          columnHelper.accessor('seats', {
            header: 'Members',
          }),
        columnHelper.accessor('display.total', {
          header: 'Total',
          meta: { colWidth: 'w-1/4' },
        }),
        columnHelper.display({
          header: 'Receipt',
          meta: {
            align: 'center',
            colWidth: 'w-64',
            truncate: false, // Prevents overflow hidden from clipping focus state
          },
          cell: (info) =>
            info.row.original.receiptUrl && (
              <ReceiptLink payment={info.row.original} />
            ),
        }),
      ].filter(Boolean) as ColumnDef<PaymentWithDisplay>[],
    []
  );

  const table = useReactTable({
    columns: columns,
    initialState: {
      pagination: {
        pageSize: 8,
      },
    },
    data: paymentHistory.data ?? [],
    getRowId: ({ createdAt }) => createdAt,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    renderFallbackValue: () => '-',
  });

  if (paymentHistory.isPending) {
    return <Spinner />;
  }

  if (paymentHistory.isError) {
    return (
      <NotificationBox
        title="Failed to load your payment history"
        variant="error"
      >
        <Typography variant="body-sm-regular" as="p">
          Sorry about the inconvenience. Please try again later.
        </Typography>
      </NotificationBox>
    );
  }

  if (paymentHistory.data?.length === 0) {
    return (
      <NotificationBox
        title="You haven't made any payments yet"
        variant="info"
        data-cy="no-payments-notification"
      >
        <Typography variant="body-sm-regular" as="p">
          Any purchases that you make while using this account will appear here.
        </Typography>
      </NotificationBox>
    );
  }

  return (
    <>
      <Table
        table={table}
        data-cy="payment-history-table"
        className="hidden ls:table"
        pagination
      />

      <ul
        data-cy="payment-history-list-mobile"
        className="border-t border-gray-100 ls:hidden"
      >
        {table.getRowModel().rows.map((row) => (
          <li
            data-cy={row.id}
            key={row.id}
            className="border-b border-gray-100 py-12"
          >
            <div className="flex items-center justify-between gap-16">
              <div className="flex min-w-0 flex-col">
                <Typography variant="body-sm-regular" className="truncate">
                  {row.original.display.date}
                </Typography>

                <Typography variant="body-sm-regular" className="italic">
                  {row.original.display.description}
                  <span>
                    {' ⋅ '}
                    {row.original.display.total}
                  </span>
                </Typography>
              </div>

              {row.original.receiptUrl && (
                <ReceiptLink payment={row.original} />
              )}
            </div>
          </li>
        ))}
      </ul>

      <TablePagination table={table} compact className="ls:hidden" />
    </>
  );
};
