import { useEffect, useState } from 'react';

import { Dialog, DialogContent, Overlay } from '@radix-ui/react-dialog';
import { Typography } from '@remarkable/ark-web';
import { useNavigate, useSearch } from '@tanstack/react-router';
import clsx from 'clsx';
import { CaretDown, CaretUp } from 'phosphor-react';
import { Collapse } from 'react-collapse';

import { useCookies } from 'src/apps/header/cookies';
import { ConsentLevel } from 'src/consent';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

import { Button } from './Button';
import { Link } from './Link';

export const CookieBanner = () => {
  const [showDetails, setShowDetails] = useState(false);
  const search = useSearch({ strict: false });
  const navigate = useNavigate({ from: '/' });
  const [cookiesAccepted, acceptCookies] = useCookies();

  // Automatically close the dialog if cookies are already accepted
  useEffect(() => {
    if (!cookiesAccepted) {
      void navigate({ search: { show_cookie_banner: true } });
    }
  }, [cookiesAccepted]);

  const handleAcceptCookies = (level: ConsentLevel) => {
    acceptCookies(level);
    void navigate({ search: { show_cookie_banner: undefined } });
  };

  return (
    <Dialog
      open={search.show_cookie_banner}
      onOpenChange={(newState) => {
        // Don't close the banner if cookie level is not set
        if (newState === false && !cookiesAccepted) return;

        void navigate({
          replace: true,
          search: { show_cookie_banner: newState ? true : undefined },
        });
      }}
    >
      <Overlay
        className={clsx(
          'fixed inset-0 bg-gray-900/60 transition-all',
          'motion-safe:data-[state=closed]:animate-fade-out motion-safe:data-[state=open]:animate-fade-in',
          'motion-reduce:data-[state=open]:opacity-1 motion-reduce:data-[state=closed]:opacity-0'
        )}
      />
      <DialogContent
        className={clsx(
          'fixed left-1/2 top-1/2 z-[61] max-h-[calc(100%-32px)] w-[calc(100%-32px)] max-w-lm translate-x-1/2 translate-y-1/2 overflow-y-auto rounded-[16px] bg-neutral-light-2 px-24 pb-32 pt-48 text-center shadow-xl focus:outline-none ls:py-48 lm:px-32',
          'motion-safe:data-[state=closed]:animate-modal-out motion-safe:data-[state=open]:animate-modal-in',
          'motion-reduce:-translate-x-1/2 motion-reduce:-translate-y-1/2'
        )}
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <div
          className="flex  flex-col gap-24 lm:max-w-5xl"
          data-cy="cookie-banner"
        >
          <div>
            <Typography variant="heading-subtle-xl" className="text-center">
              Cookies
            </Typography>
            <Typography variant="body-md-regular" className="mt-16  ">
              We use cookies to make sure we can continuously improve the user
              experience, by continuing to use this website you consent to our
              necessary cookies.
            </Typography>
            <div className="mt-16 flex flex-row justify-center gap-4">
              <Button
                variant="tertiary-neutral"
                onClick={() => setShowDetails((showDetails) => !showDetails)}
                data-cy="cookie-banner-show-details"
              >
                {showDetails ? (
                  <span>
                    <Typography
                      variant="interface-md-semibold"
                      className="inline"
                    >
                      Hide details
                    </Typography>
                    <CaretUp className="ml-4 inline max-w-20" />
                  </span>
                ) : (
                  <span>
                    <Typography
                      variant="interface-md-semibold"
                      className="inline"
                    >
                      Show details
                    </Typography>
                    <CaretDown className="ml-4 inline max-w-20" />
                  </span>
                )}
              </Button>
            </div>
            <Collapse isOpened={showDetails}>
              <div className="m-0 py-8 " data-cy="cookie-banner-details">
                <Typography variant="body-md-regular" className="mt-16">
                  Necessary cookies allow basic functions like page navigation
                  and access to secure areas of the website, without them it
                  won&apos;t work properly.
                </Typography>
                <Typography variant="body-md-regular" className="mt-16">
                  Statistic cookies are cookies used to understand our customer
                  needs through statistical analysis. The data are pseudonymised
                  and will not be used by reMarkable to identify you. Selecting
                  ‘accept all’, will enable both necessary and statistic
                  cookies.
                </Typography>
                <Typography variant="body-md-regular" className="mt-16">
                  See our{' '}
                  <Link
                    to={SUPPORT_URL.COOKIE_POLICY}
                    data-cy="cookie-policy-link"
                    inline
                  >
                    cookie policy
                  </Link>{' '}
                  for more information.
                </Typography>
              </div>
            </Collapse>
          </div>

          <div className="flex w-full flex-col justify-center gap-16 lm:flex-row ">
            <Button
              size="medium"
              variant="secondary"
              data-cy="cookie-banner-necessary"
              className="w-full lm:w-fit"
              onClick={() => handleAcceptCookies(ConsentLevel.NECESSARY)}
            >
              Use necessary only
            </Button>
            <Button
              size="medium"
              className="w-full lm:w-fit"
              data-cy="cookie-banner-all"
              onClick={() => handleAcceptCookies(ConsentLevel.ALL)}
            >
              Accept all
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
