import { HTMLProps, ReactNode, useEffect } from 'react';

import { Checkbox, Label, Typography } from '@remarkable/ark-web';
import { cva } from 'class-variance-authority';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { cn } from 'src/utils/classNamesHelper';

import { createErrorMessages } from '../utils/createErrorMessages';

interface InputPropsBase extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  children?: ReactNode;
  className?: string;
  type?: string;
  id?: string;
  name?: string;
  required?: boolean;
  isEditable?: boolean;
  focusOnIsEditable?: boolean;
  inputClassName?: string;
  description?: ReactNode;
  validate?: RegisterOptions['validate'];
  /**
   * Will unregister the input from the form state when it unmounts
   */
  shouldUnregister?: RegisterOptions['shouldUnregister'];
}

interface InputPropsWithLabel extends InputPropsBase {
  label: string;
}

interface InputPropsWithId extends InputPropsBase {
  id: string;
  label?: ReactNode;
}

interface InputPropsWithName extends InputPropsBase {
  name: string;
  label?: ReactNode;
}

// This makes sure that at least one identifier is provided
export type InputProps =
  | InputPropsWithLabel
  | InputPropsWithId
  | InputPropsWithName;

const inputFieldVariants = cva('h-48 w-full shrink-0 rounded border px-16', {
  variants: {
    error: {
      true: 'outline outline-2 -outline-offset-1 outline-feedback-red-500',
      false: 'border-neutral-light-8',
    },
  },
});

export const Input = ({
  children,
  className = '',
  type = 'text',
  id,
  label,
  name,
  required = false,
  validate,
  description,
  shouldUnregister = false,
  inputClassName = '',
  isEditable = true,
  focusOnIsEditable = false,
  ...rest
}: InputProps) => {
  const context = useFormContext();

  const stringLabel = typeof label === 'string' ? label : '';
  const identifier = name ?? id ?? stringLabel ?? '';
  const formError = context?.formState?.errors[identifier];

  // Get default error messages
  const errorMessage = createErrorMessages()(formError);

  const ChildrenWrapper = type === 'checkbox' ? 'label' : 'div';

  useEffect(() => {
    if (focusOnIsEditable && isEditable) {
      const input = document.getElementById(
        identifier
      ) as HTMLInputElement | null;

      input?.focus();
      input?.select();
    }
  }, [focusOnIsEditable, isEditable, identifier, context?.setFocus]);

  return (
    <div className={cn('w-full', className)}>
      <Label htmlFor={identifier}>{label}</Label>
      {description && (
        <div className="mb-4 w-full">
          {typeof description === 'string' ? (
            <Typography variant="interface-xs-regular" className="mb-8">
              {description}
            </Typography>
          ) : (
            description
          )}
        </div>
      )}

      <div className="flex">
        {type === 'checkbox' ? (
          <Checkbox
            id={identifier}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as any)}
            className="mr-8 cursor-pointer"
          />
        ) : isEditable ? (
          <input
            id={identifier}
            type={type}
            className={inputFieldVariants({
              className: inputClassName,
              error: !!errorMessage,
            })}
            required={required}
            {...context?.register?.(identifier, {
              required: {
                value: required,
                message: '',
              },
              validate,
              shouldUnregister,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as unknown as any)}
          />
        ) : (
          <p className="truncate" {...rest}>
            {(context?.getValues(identifier) ?? rest.value) || '-'}
          </p>
        )}

        {children && (
          <ChildrenWrapper htmlFor={identifier}>{children}</ChildrenWrapper>
        )}
      </div>

      {errorMessage && (
        <Typography
          variant="interface-xs-regular"
          className="mt-8 text-feedback-red-500"
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
