import { CONFIG } from 'src/config';

import { addAuthHeaders } from '../addAuthHeaders';
import { catchHttpError, onStatus } from '../catchHttpError';
import { createApiClient } from '../createApiClient';
import { removeUndefinedFields } from '../removeUndefinedFields';
import { setTectonicDomain } from '../setTectonicDomain';
import {
  DevicesV1ConnectedResponse,
  IntegrationException,
  IntegrationListResponse,
  IntegrationTermsOfServiceStatusResponse,
  IntegrationToSave,
  PasscodeResetsPendingResponse,
  ProfileV1ConnectedResponse,
  ProviderDetails,
} from './cloudApi.types';

// =============== API Client =================

const cloudApi = createApiClient({
  prefixUrl: CONFIG.CloudApiUrl,
  hooks: { beforeRequest: [addAuthHeaders, setTectonicDomain] },
});

// PASSCODE RESET ------------------------------

export const getPasscodeResetRequests = async () =>
  cloudApi
    .get('/passcode/v1/resets?pending')
    .json<PasscodeResetsPendingResponse>();

export const passcodeResetApprove = async (requestId: string) =>
  cloudApi
    .post(`/passcode/v1/resets/${requestId}/approve`)
    .json<PasscodeResetsPendingResponse>();

export const passcodeResetDeny = async (requestId: string) =>
  cloudApi
    .post(`/passcode/v1/resets/${requestId}/deny`)
    .json<PasscodeResetsPendingResponse>();

// DEVICE --------------------------------------

// DEVICE V1 --------------------------------------

export const getDevicesV1 = async () =>
  cloudApi.get('/devices/v1?includeDeleted').json<DevicesV1ConnectedResponse>();

export const deleteDeviceV1 = async (deviceId: string, modified: string) =>
  cloudApi
    .delete('/devices/v1', {
      json: { DeviceID: deviceId, Modified: modified },
    })
    .text();

// PROFILE DEVICE V1 --------------------------------------

export const getProfileV1 = async () =>
  catchHttpError(
    cloudApi.get('/profile/v1').json<ProfileV1ConnectedResponse>(),
    onStatus('TooManyRequests', {
      devices: [],
      isTabletOwner: false,
    } as ProfileV1ConnectedResponse)
  );

export const deleteProfileDeviceV1 = async (deviceId: string) => {
  await cloudApi.delete(`/profile/v1/devices/${deviceId}`);
};

// INTEGRATIONS -------------------------------

export const getProviders = async () =>
  cloudApi.get('/integrations/v1/providers').json<ProviderDetails[]>();

export const getIntegrations = async () =>
  cloudApi
    .get('/integrations/v1')
    .json<IntegrationListResponse>()
    .then((res) => res.integrations);

export const getProviderAuthURI = async (providerPath: string) =>
  cloudApi.get(`/integrations/v1/${providerPath}/authcode`).text();

export const deleteIntegration = async (integrationId: string) =>
  cloudApi.delete(`/integrations/v1/${integrationId}`).text();

export const saveIntegration = async (vars: {
  integration: IntegrationToSave;
  providerPath: string;
}) =>
  catchHttpError(
    cloudApi
      .post(`/integrations/v1/${vars.providerPath}/callback`, {
        searchParams: removeUndefinedFields(vars.integration),
      })
      .text(),
    onStatus(409, async (error) => {
      const data = (await error.response.json()) as { message: string };

      if (data.message.includes('integration already exists')) {
        throw new IntegrationException('ALREADY_EXISTS');
      } else if (data.message.includes('Conflict in updating integration')) {
        throw new IntegrationException('ACCOUNT_MISMATCH');
      }
    })
  );

export const updateIntegration = async (id: string, name: string) =>
  catchHttpError(cloudApi.patch(`/integrations/v1/${id}`, { json: { name } }));

export const getIntegrationTermsOfServiceStatus = async () =>
  cloudApi
    .get('/integrations/v1/tos')
    .json<IntegrationTermsOfServiceStatusResponse>();

export const acceptIntegrationTermsOfService = async ({
  version,
}: {
  version: number;
}) =>
  cloudApi
    .put('/integrations/v1/tos', {
      searchParams: { tosVersion: version },
    })
    .json<IntegrationTermsOfServiceStatusResponse>();

// OTP
export const getOneTimeCode = async () => cloudApi.post('/devices/v1').text();
