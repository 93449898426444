import { Accordion, Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { FAQBlock } from 'src/constants/FAQ';

import { Divider } from './Divider';

interface FaqSectionProps {
  title?: string | false;
  sections: FAQBlock[];
  componentLocation?: string;
  className?: string;
  dataCy?: string;
}
export const FaqSection = (props: FaqSectionProps) => {
  const title = props.title ?? 'Frequently asked questions';
  const dataCy = props.dataCy ?? 'faq-section';

  if (props.sections.length < 1) {
    return <></>;
  }

  return (
    <div className={props.className} data-cy={dataCy}>
      {!!title && (
        <>
          <Typography as="h2" variant="heading-sm" className="text-center">
            {title}
          </Typography>

          <Divider className="mb-4 mt-32" />
        </>
      )}
      {props.sections.map((section) => (
        <Accordion
          data-cy={'faq-item ' + section.id}
          key={section.id}
          variant="secondary"
          onClick={() => {
            if (!props.componentLocation) return;

            tracker.trackEvent(
              new ButtonClicked({
                component_location: props.componentLocation,
                action: 'toggle faq',
                text: section.title,
              })
            );
          }}
        >
          {/* We are not using typography components here by design as children are styled by Accordion. */}
          <h3>{section.title}</h3>
          {section.body}
        </Accordion>
      ))}
    </div>
  );
};
