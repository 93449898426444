import clsx from 'clsx';

import { Member, isInvitationMember } from '../utils/useMembersList';

export const MemberInvitationStatusBar = ({ member }: { member: Member }) => {
  return (
    <div
      className={clsx(
        '-ml-8 h-24 w-2',
        isInvitationMember(member)
          ? {
              'bg-pen-blue': !(member.data.isExpired || member.data.isDeclined),
              'bg-gray-600': member.data.isExpired,
              'bg-feedback-red-500': member.data.isDeclined,
            }
          : 'invisible'
      )}
    />
  );
};
