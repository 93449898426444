import { useEffect } from 'react';

import { SelectField, Typography } from '@remarkable/ark-web';
import { Controller, useForm } from 'react-hook-form';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { SubscriptionRole } from 'src/api/endpoints/storeApi.types';
import {
  CreateEmailsError,
  useCreateSeatInvitationsByEmail,
} from 'src/api/queries';
import { useSubscription } from 'src/api/queries/subscriptions';
import { Button, Form, NotificationBox } from 'src/components';
import { capitalize } from 'src/utils/capitalize';
import { useFeatureToggles } from 'src/utils/useFeatureToggles';
import { validateEmail } from 'src/utils/validateEmail';

import { getStripeSubscriptionV2 } from '../utils/getStripeSubscriptionV2';
import {
  InputMultiple,
  ValidationError,
  validateEmails,
} from './InputMultiple';

const inputEmailsFormKey = 'emails';
const inputRoleFormKey = 'targetRole';
interface InviteByEmailForm {
  [inputEmailsFormKey]: { email: string }[];
  [inputRoleFormKey]: SubscriptionRole;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InviteByEmail = () => {
  const createSeatInvitationsByEmail = useCreateSeatInvitationsByEmail();
  const subscription = useSubscription();
  const stripeSubscription = getStripeSubscriptionV2(subscription.data);
  const availableRoles = stripeSubscription?.access.canInviteRoles;
  const featureToggles = useFeatureToggles();

  const form = useForm<InviteByEmailForm>({
    defaultValues: {
      [inputEmailsFormKey]: [],
      [inputRoleFormKey]: 'member',
    },
  });

  const {
    formState: { isSubmitted, isSubmitSuccessful },
  } = form;

  const emails = form.watch(inputEmailsFormKey);
  const numberOfEmails = emails?.length ?? 0;

  const onSubmit = (data: InviteByEmailForm) => {
    if (createSeatInvitationsByEmail.isPending) return;

    // Remove success notification if the form has already been submitted
    if (createSeatInvitationsByEmail.isSuccess) {
      createSeatInvitationsByEmail.reset();
    }

    return createSeatInvitationsByEmail.mutate(
      {
        emails: data.emails?.map((item) => item.email) ?? [],
        [inputRoleFormKey]: data[inputRoleFormKey],
      },
      {
        onError(error) {
          if (error instanceof CreateEmailsError) {
            // Reset the form with the emails that failed, so that the user can
            // try to resend them.
            form.reset({
              [inputEmailsFormKey]: error.result.unsuccessful.map((item) => ({
                email: item.email,
              })),
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (
      isSubmitted &&
      isSubmitSuccessful &&
      createSeatInvitationsByEmail.isSuccess
    ) {
      // Reset the form if the submission was successful.
      form.reset({ [inputEmailsFormKey]: [] });
    }
  }, [isSubmitted, isSubmitSuccessful, createSeatInvitationsByEmail.isSuccess]);

  const emailsSubmittedCount =
    createSeatInvitationsByEmail.variables?.emails.length ?? 0;

  const failedEmails =
    createSeatInvitationsByEmail.error instanceof CreateEmailsError
      ? createSeatInvitationsByEmail.error.result.unsuccessful.map(
          (item) => item.email
        )
      : [];

  return (
    <div className="text-left">
      <Typography variant="body-md-regular" className="mb-16">
        Invite people via email to join your subscription.
      </Typography>
      <Form
        onSubmit={onSubmit}
        onInvalid={() => {
          // Remove query success and error states if the user interacts with
          // the form again.
          if (
            createSeatInvitationsByEmail.isSuccess ||
            createSeatInvitationsByEmail.isError
          ) {
            createSeatInvitationsByEmail.reset();
          }
        }}
        {...form}
      >
        <Controller
          name={inputEmailsFormKey}
          control={form.control}
          rules={{
            required: 'Please enter at least one email address',
            validate: (value) => {
              const hasInvalidEmail =
                value.findIndex((item) => {
                  return validateEmail(item.email) !== true;
                }) !== -1;

              if (hasInvalidEmail) {
                return 'One or more email addresses are invalid.';
              }

              return true;
            },
          }}
          render={({ field, fieldState }) => {
            const mappedValues = field.value.map((item) => item.email);
            const globalError: ValidationError | null = fieldState.error
              ?.message
              ? {
                  message: fieldState.error.message,
                  type: 'error',
                }
              : null;
            const errors = fieldState.error
              ? [globalError, ...validateEmails(mappedValues)]
              : undefined;

            return (
              <InputMultiple
                value={mappedValues}
                errors={errors}
                placeholder="Enter email addresses"
                onValueChange={(value) => {
                  field.onChange(value.map((email) => ({ email })));
                }}
                onBlur={field.onBlur}
              />
            );
          }}
        />
        {featureToggles.EnableInviteWithRole &&
        availableRoles &&
        availableRoles.length > 1 ? (
          <Controller
            name={inputRoleFormKey}
            control={form.control}
            render={({ field }) => (
              <>
                <SelectField
                  {...field}
                  label="Choose a role for the new members"
                  id="role-selector"
                  className="my-16"
                  defaultValue={
                    form.formState.defaultValues?.[inputRoleFormKey]
                  }
                >
                  {availableRoles.map((role) => (
                    <option id={role} key={role} value={role}>
                      {capitalize(role)}
                    </option>
                  ))}
                </SelectField>
              </>
            )}
          />
        ) : null}
        {createSeatInvitationsByEmail.isError &&
          (emailsSubmittedCount === failedEmails.length ? (
            <NotificationBox
              data-cy="email-invitations-error"
              variant="error"
              className="my-16"
              title="Something went wrong"
            >
              Please check that the addresses are correct and try resending the
              remaining invitations.
            </NotificationBox>
          ) : (
            <NotificationBox
              data-cy="email-invitations-warning"
              variant="warning"
              className="my-16"
              title={`Couldn't send ${failedEmails.length} of ${emailsSubmittedCount} invitations`}
            >
              Please check that the addresses are correct and try resending the
              remaining invitations.
            </NotificationBox>
          ))}

        {createSeatInvitationsByEmail.isSuccess && (
          <NotificationBox
            data-cy="email-invitations-sent-notification"
            variant="success"
            className="my-16"
            title={`Invitations sent`}
          />
        )}

        <div className="flex justify-center">
          <Button
            data-cy="send-invitations-button"
            type="submit"
            size="large"
            disabled={createSeatInvitationsByEmail.isPending}
            loading={createSeatInvitationsByEmail.isPending}
            analytics={{
              text: 'Send invitation',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'send invitation',
            }}
          >
            Send {numberOfEmails > 1 ? numberOfEmails : ''} invitation
            {numberOfEmails > 1 && 's'}
          </Button>
        </div>
      </Form>
    </div>
  );
};
