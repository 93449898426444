import { EventHandler, MouseEvent } from 'react';

import { LinkProps, Link as RouterLink } from '@tanstack/react-router';
import { cva, VariantProps } from 'class-variance-authority';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { cn } from 'src/utils/classNamesHelper';

const linkVariants = cva('', {
  variants: {
    as: {
      button: 'ark-button',
      link: 'ark-link',
    },
    inline: {
      true: 'ark-link--inline',
    },
    variant: {
      primary: 'ark-button--primary',
      'primary-neutral': 'ark-button--primary-neutral',
      secondary: 'ark-button--secondary',
      tertiary: 'ark-button--tertiary',
      'tertiary-neutral': 'ark-button--tertiary-neutral',
    },
    loading: {
      true: 'ark-button--loading',
    },
    size: {
      small: 'ark-button-small',
      medium: 'ark-button-medium',
      large: 'ark-button-large',
    },
  },

  defaultVariants: {
    as: 'link',
  },
});

export type Link2Props = {
  /** Enables LinkClicked tracking events when set */
  componentLocation?: string;
  className?: string;
  onClick?: EventHandler<MouseEvent<HTMLAnchorElement>>;
} & VariantProps<typeof linkVariants> &
  LinkProps;

// TODO: Rename to Link, when the old Link is removed
export function Link2({
  componentLocation,
  onClick,
  variant,
  inline,
  loading,
  size,
  as,
  className,
  ...props
}: Link2Props) {
  return (
    <RouterLink
      className={cn(
        linkVariants({ variant, inline, loading, size, as }),
        className
      )}
      onClick={(event) => {
        if (componentLocation) {
          tracker.trackEvent(
            new LinkClicked({
              component_location: componentLocation,
              text: (event.nativeEvent.currentTarget as HTMLAnchorElement)
                .innerText,
              link_source: props.to ?? '',
            })
          );
        }
        onClick?.(event);
      }}
      {...props}
    />
  );
}
