import { getCountryDataForCountryCodeWithFallback } from 'src/utils/countryDetector';

import { catchHttpError } from '../catchHttpError';
import { createApiClient } from '../createApiClient';
import { CheckCountryResponse } from './ipstackApi.types';

// =============== API Client ================

const ipstackApi = createApiClient({
  prefixUrl: 'https://api.ipstack.com',
  searchParams: { access_key: import.meta.env.PUBLIC_IPSTACK_API_KEY ?? '' },
});

export const checkCountry = () =>
  catchHttpError(
    ipstackApi
      .get('check')
      .json<CheckCountryResponse>()
      .then((res) => {
        return getCountryDataForCountryCodeWithFallback(res.country_code);
      })
  ).catch(() => {
    return getCountryDataForCountryCodeWithFallback('US');
  });
