export const APPLE_ITUNES_URL =
  'https://itunes.apple.com/us/app/remarkable-paper-tablet-app/id1274957816?ls=1&mt=8';
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.remarkable.mobile';
export const MACOS_ITUNES_URL =
  'https://apps.apple.com/us/app/remarkable-desktop/id1276493162';

export const CHROME_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/read-on-remarkable/bfhkfdnddlhfippjbflipboognpdpoeh';
export const OFFICE_EXTENSION_URL =
  'https://appsource.microsoft.com/nb-NO/product/office/WA200003915';

export const MAXIMUM_DEVICES_ALLOWED = 3;
