import {
  StripeSubscription,
  SubscriptionCanceledPaymentInformation,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

interface MyRmGetSubscriptionsStripeSubscriptionCancelled
  extends StripeSubscription {
  paymentInformation: SubscriptionCanceledPaymentInformation;
}

export const isCanceledSubscription = (
  subscription?: Subscription
): subscription is MyRmGetSubscriptionsStripeSubscriptionCancelled => {
  if (!subscription) return false;

  if (subscription.type === 'free-connect') return false;

  if (!subscription.paymentInformation) return false;

  return subscription.paymentInformation.isCancelled;
};

export const getCanceledSubscriptionInformation = (
  subscription?: Subscription
) => {
  if (!isCanceledSubscription(subscription)) return null;

  return subscription.paymentInformation?.isCancelled
    ? subscription.paymentInformation
    : null;
};
