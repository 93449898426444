import { SubscriptionMemberSeat } from 'src/api/endpoints/storeApi.types';

export function isBillableSeat(seat?: SubscriptionMemberSeat | null) {
  if (!seat) {
    return false;
  }

  if (seat.status === 'created') {
    return true;
  }

  return seat.status === 'pending' && seat.pendingUntil === null;
}
