import { HTMLAttributes, useEffect, useState } from 'react';

import {
  useStripe as useStripeHook,
  useElements,
  PaymentElementProps,
} from '@stripe/react-stripe-js';
import { Appearance, ConfirmPaymentData, StripeError } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';

import { appearance } from './appearance';
import { paymentElementOptions } from './paymentElementOptions';

export class CustomStripeError extends Error {
  readonly data: StripeError;

  constructor(message: string, data: StripeError) {
    super(message);

    this.data = data;
  }
}

export enum StripeIntentType {
  Payment = 'payment',
  Setup = 'setup',
}

export const useStripe = (
  type: StripeIntentType,
  customAppearance?: Appearance
) => {
  const stripe = useStripeHook();
  const elements = useElements();
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);

  // Apply stripe appearance
  useEffect(() => {
    if (!elements) return;
    elements.update({
      appearance: customAppearance ?? appearance,
      locale: 'en',
    });
  }, [elements]);

  const paymentElementProps: HTMLAttributes<HTMLDivElement> &
    PaymentElementProps = {
    style: { display: isPaymentElementReady ? 'block' : 'none' },
    options: paymentElementOptions,
    onReady: () => setIsPaymentElementReady(true),
  };

  const checkout = useMutation<unknown, Error, ConfirmPaymentData>({
    mutationFn: async (confirmParams) => {
      if (!stripe || !elements) {
        throw new Error(
          'Stripe or StripeElements does not exists, but are required for checkout.'
        );
      }

      let response;
      if (type === StripeIntentType.Payment) {
        response = await stripe.confirmPayment({
          elements,
          confirmParams,
        });
      } else {
        response = await stripe.confirmSetup({
          elements,
          confirmParams,
        });
      }

      if (response?.error) {
        throw new CustomStripeError('Stripe error', response.error);
      }
    },
  });

  return {
    stripe,
    elements,
    isPaymentElementReady,
    paymentElementProps,
    checkout,
  };
};
