import { Typography } from '@remarkable/ark-web';
import { useMutationState } from '@tanstack/react-query';
import { Navigate } from '@tanstack/react-router';
import { Check } from 'phosphor-react';

import { InvoiceCheckoutResponseBody } from 'src/api/endpoints/storeApi.types';
import { queryKeys } from 'src/api/queries';
import { useStripeSubscriptionSuspense } from 'src/api/queries/subscriptions';
import { StatusTag } from 'src/apps/manageSubscription/app/components/StatusTag';
import { Button, CardContent, Sheet, Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { formatDate } from 'src/utils/formatDate';
import { formatAmount } from 'src/utils/productUtils';
import { URLS } from 'src/utils/urls/urls';

export const DmOfferConfirmationPage = () => {
  const subscription = useStripeSubscriptionSuspense();
  const [checkoutData] = useMutationState({
    filters: { mutationKey: queryKeys.checkout.withoutCard },
    select: (mutation) =>
      mutation.state.data as { data: InvoiceCheckoutResponseBody },
  });

  if (subscription.isPending) {
    return <Spinner />;
  }

  if (!subscription) {
    throw new Error('Subscription not found');
  }

  let orderData = checkoutData;
  if (!orderData) {
    // for testing mode only
    const orderFromStorage = localStorage.getItem('dmOrder');
    if (!orderFromStorage) {
      return <Navigate to="/subscriptions" />;
    }
    orderData = JSON.parse(orderFromStorage) as {
      data: InvoiceCheckoutResponseBody;
    };
  }

  return (
    <AppPage.Content className="max-w-ll">
      <Sheet data-cy="confirmation-card" className="w-full">
        <div>
          <CardContent>
            <div className="flex flex-col gap-12 pb-32">
              <Typography variant="heading-lg">Welcome to Connect</Typography>
              <Typography variant="body-md-regular">
                You&apos;ve successfully activated your Connect subscription.
                Now you can take your notes even further.{' '}
                <Link2 to="/store/connect" inline>
                  Read more
                </Link2>
              </Typography>
            </div>
          </CardContent>
          <CardContent borderTop>
            <div className="flex flex-row flex-wrap items-center">
              <Typography
                className="w-full lm:w-fit"
                variant="heading-subtle-2xl"
                data-cy="confirmation-card-heading-title"
              >
                30-day free trial
              </Typography>
              <StatusTag className="mr-auto lm:ml-12" status="active" />
              <Typography
                variant="interface-xl-semibold"
                data-cy="confirmation-card-heading-amount"
              >
                {formatAmount(
                  orderData.data.data.orderItem.amount,
                  orderData.data.data.orderItem.currency
                )}
              </Typography>
            </div>
          </CardContent>

          <CardContent borderTop>
            <div className="flex flex-row items-center justify-between">
              <p className="m-0 text-16">Your trial will end:</p>
              <p
                data-cy="checkout-summary-billing-start-date"
                className="m-0 text-16"
              >
                {formatDate(
                  subscription.data?.paymentInformation?.nextInvoice?.dueDate
                )}
              </p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="m-0 whitespace-nowrap text-16">Order id:</p>
              <p className="m-0 text-right text-16" data-cy="checkout-order-id">
                {orderData.data.data.orderId}
              </p>
            </div>
          </CardContent>
        </div>
        <CardContent borderTop>
          <div className="flex flex-col items-center justify-between gap-32">
            <p className="m-0 text-16">
              We&apos;ll send you a reminder email before your free subscription
              period ends. View your current subscription or update your payment
              card details in{' '}
              <Link2 to="/subscriptions" inline>
                Subscription
              </Link2>
              .
            </p>
            <Button
              type="button"
              as="a"
              className="ml-auto w-full lm:w-fit"
              variant="primary"
              to={URLS.STORE_DM_OFFER_SETUP_COMPLETE}
            >
              <span>Next</span>
              <Check />
            </Button>
          </div>
        </CardContent>
      </Sheet>
    </AppPage.Content>
  );
};
