/* eslint-disable react/prop-types */
import { ForwardedRef, forwardRef, RefAttributes } from 'react';

import {
  // eslint-disable-next-line no-restricted-imports
  Button as ArkButton,
  ButtonProps as ArkButtonProps,
} from '@remarkable/ark-web';
import { Link as RouterLink } from '@tanstack/react-router';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';

// We limit the element type of the `as` prop to 'a' and 'button' to avoid a huge TS perf hit.
// Using `keyof JSX.IntrinsicElements` would be more correct, but it's too slow and
// we don't need that many options for button anyway.
type ElementTypes = 'a' | 'button';

type ButtonAsButtonProps = {
  to?: never;
  as?: 'button';
} & JSX.IntrinsicElements['button'] &
  RefAttributes<HTMLButtonElement>;

type ButtonAsLinkProps = {
  to: string;
  as: 'a';
} & JSX.IntrinsicElements['a'] &
  RefAttributes<HTMLAnchorElement>;

type ButtonProps = ArkButtonProps &
  (ButtonAsButtonProps | ButtonAsLinkProps) & {
    disabled?: boolean; // disabled is allowed for both elements, but isn't defined in ArkButtonProps so we have to fix the type here
    analytics?: {
      /** Represents the text content of the button. */
      text: string;
      location: string;
      action: string;
    };
  };

const isLink = (props: ButtonProps): props is ButtonAsLinkProps =>
  props.as === 'a';

const ButtonInner = function renderButton<T extends ElementTypes>(
  { analytics, ...props }: ButtonProps,
  ref: ForwardedRef<T extends 'a' ? HTMLAnchorElement : HTMLButtonElement>
) {
  const onClick = (event: unknown) => {
    if (analytics) {
      tracker.trackEvent(
        new ButtonClicked({
          component_location: analytics.location,
          text: analytics.text,
          action: analytics.action,
        })
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    props.onClick?.(event as any);
  };

  if (!isLink(props)) {
    // ArkButton props doesn't infer entirely correct regarding the `as` prop, so we have to cast it to any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <ArkButton {...(props as any)} ref={ref} onClick={onClick} />;
  }

  const { children, to, ...rest } = props;
  let isRemarkableDomain = false;
  const isExternal = to.startsWith('http');
  if (isExternal) {
    try {
      isRemarkableDomain = new URL(to).hostname.endsWith('remarkable.com');
    } catch {
      console.error('Invalid URL: ', to);
      // to might not be a valid URL, ignore and keep isRemarkableDomain false
    }
  }

  const addNoReferrerOpener = !isRemarkableDomain && isExternal;

  let externalLinkProps: Partial<ButtonProps> = {
    target: isExternal ? '_blank' : '',
    rel: addNoReferrerOpener ? 'noreferrer noopener' : 'noopener',
  };

  if (isExternal) {
    externalLinkProps = {
      ...externalLinkProps,
      href: to,
      as: 'a',
    };
  } else {
    externalLinkProps = {
      ...externalLinkProps,
      to: to,
      as: RouterLink as unknown as 'a',
    };
  }

  // ArkButton props doesn't infer entirely correct regarding the `as` prop, so we have to cast it to unknown
  return (
    <ArkButton
      ref={ref as unknown}
      {...rest}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(externalLinkProps as any)}
      onClick={onClick}
    >
      {children}
    </ArkButton>
  );
};

export const Button = forwardRef(ButtonInner) as (
  props: ButtonProps & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof ButtonInner>;
