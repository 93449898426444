import { useQuery, useQueryClient } from '@tanstack/react-query';

import { CountryData } from 'src/utils/countryList';

import { ipstackApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const useCountry = () => {
  const setCountry = useSetCountry();

  return {
    ...useQuery({
      queryKey: queryKeys.country.detected,
      queryFn: () => ipstackApi.checkCountry(),
      staleTime: Infinity,
    }),
    setCountry,
  };
};

export const useSetCountry = () => {
  const queryClient = useQueryClient();

  return (country: CountryData) =>
    queryClient.setQueryData(queryKeys.country.detected, country);
};
