import { useLayoutEffect, useState } from 'react';

import { CaretDown } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { Button, Step, Stepper } from 'src/components';
import { cn } from 'src/utils/classNamesHelper';
import { useWindowSize } from 'src/utils/useWindowSize';

import { StepNames, useCheckoutProgress } from '../useCheckoutProgress';

export const CheckoutSteps = ({
  secondDevice = false,
}: {
  secondDevice: boolean;
}) => {
  const progress = useCheckoutProgress();
  const windowSize = useWindowSize();
  const [orientaion, setOrientation] = useState<'horizontal' | 'vertical'>(
    'horizontal'
  );
  const isVertical = orientaion == 'vertical';

  useLayoutEffect(() => {
    // force horizontal when desktop
    if (windowSize.width > 640 && isVertical) {
      setOrientation('horizontal');
    }
  }, [windowSize.width]);

  return (
    <div
      className={cn('mx-auto max-w-ll', {
        'max-w-lm': secondDevice,
      })}
    >
      <div className="full flex flex-row items-center justify-between gap-4 px-32 lm:hidden lm:px-64">
        <Typography
          variant="body-md-regular"
          className="font-medium text-pen-blue"
        >
          {isVertical ? null : progress.title}
        </Typography>
        <Button
          size="small"
          variant="tertiary-neutral"
          onClick={() => setOrientation(isVertical ? 'horizontal' : 'vertical')}
        >
          <span>All steps</span>
          <CaretDown
            className={cn('transition', {
              'rotate-180': isVertical,
            })}
          />
        </Button>
      </div>
      <Stepper
        className={cn({ '-mt-48': isVertical })}
        orientation={orientaion}
      >
        <Step checked>Pair your device</Step>
        <Step {...progress[StepNames.ACTIVATE_CONNECT]}>Activate Connect</Step>
        {secondDevice ? null : (
          <Step {...progress[StepNames.DOWNLOAD_APPS]}>Download Apps</Step>
        )}
        <Step {...progress[StepNames.COMPLETE_SETUP]}>Setup complete</Step>
      </Stepper>
    </div>
  );
};
