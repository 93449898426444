// Members ---------------------------------------------------------------------
import { catchHttpError, onStatus } from '../catchHttpError';
import { cloudEnterpriseEnrolmentApiClient as enrollmentApiClient } from '../clients/cloudEnterpriseEnrollmentApiClient';

export const getStatus = async () =>
  catchHttpError(
    enrollmentApiClient
      .GET('/enrollment/v1/status')
      .then((res) => res.data ?? null),
    onStatus('NotFound', null) // Returns 404 when no enrollment is initiated
  );

export const initEnrollment = async (vars: {
  orgName: string;
  domain: string;
}) =>
  await enrollmentApiClient.POST('/enrollment/v1/init', {
    body: {
      displayName: vars.orgName,
      domain: vars.domain,
    },
    parseAs: 'text',
  });

export const submitConnection = async (vars: {
  certificate: string;
  signInUrl: string;
}) =>
  enrollmentApiClient.POST('/enrollment/v1/connection', {
    body: {
      b64Certificate: vars.certificate,
      signInUrl: vars.signInUrl,
    },
    parseAs: 'text',
  });

export const verifyDomain = async () =>
  enrollmentApiClient.POST('/enrollment/v1/verify', {
    parseAs: 'text',
  });

export const cancelEnrollment = async () =>
  enrollmentApiClient.DELETE('/enrollment/v1/cancel', { parseAs: 'text' });

export const finalizeEnrollment = async () =>
  enrollmentApiClient.POST('/enrollment/v1/finalize', { parseAs: 'text' });
