import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useAbortCancelSubscription } from 'src/api/queries';
import { Button, Modal2, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useAbortCancelSubscriptionModal = createModalHook(
  'AbortCancelSubscription'
);

export const AbortCancelSubscriptionModal = () => {
  const modal = useAbortCancelSubscriptionModal();
  const abortCancelSubscription = useAbortCancelSubscription();

  if (!modal.isOpen) return null;

  return (
    <Modal2 onClose={modal.close}>
      <Typography variant="heading-md" className="mb-24 ls:mb-32">
        Undo cancellation?
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        Undo your cancelation if you would like to keep subscription features
        for you and your team.
      </Typography>

      {abortCancelSubscription.isError && (
        <NotificationBox
          variant="error"
          title="Something went wrong"
          className="mt-16"
        >
          Please try again or contact support if the problem persists.
        </NotificationBox>
      )}

      <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
        <Button
          variant="secondary"
          size="medium"
          className="w-full xs:w-fit"
          onClick={modal.close}
          analytics={{
            text: 'Close',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'close undo cancelation modal',
          }}
        >
          Close
        </Button>

        <Button
          size="medium"
          className="w-full xs:w-fit"
          loading={abortCancelSubscription.isPending}
          onClick={() => {
            abortCancelSubscription.mutate(undefined, {
              onSuccess: () => {
                modal.close();
              },
            });
          }}
          analytics={{
            text: 'Undo cancelation',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'undo cancelation',
          }}
        >
          Undo cancelation
        </Button>
      </div>
    </Modal2>
  );
};
