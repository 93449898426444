import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useStripeSubscription } from 'src/api/queries';
import { Input, SomethingWentWrong, Table } from 'src/components';
import { CONFIG } from 'src/config';
import {
  createSelectColumn,
  useMultiRowSelect,
} from 'src/utils/createTableSelectColumn';

import {
  Member,
  isInvitationMember,
  isSubscriptionMember,
  useMembersList,
} from '../utils/useMembersList';
import { InvitationStatus } from './InvitationStatus';
import { InviteToSeatsButton } from './InviteToSeatsButton';
import { MemberActionMenu } from './MemberActionMenu';
import { MemberInvitationStatusBar } from './MemberInvitationStatusBar';
import { MembersBulkActionPanel } from './MembersBulkActionPanel';
import { SeatStatus } from './SeatStatus';

const columnHelper = createColumnHelper<Member>();

export const MembersTable = () => {
  const members = useMembersList();
  const subscription = useStripeSubscription();

  const columns = useMemo(
    () =>
      [
        CONFIG.EnableSeatsBulkActions && createSelectColumn(columnHelper),
        columnHelper.accessor('status', {
          id: 'status-bar',
          header: '',
          meta: {
            truncate: false, // prevents overflow hidden
            colWidth: 'w-0',
          },
          cell: (info) => (
            <MemberInvitationStatusBar member={info.row.original} />
          ),
        }),
        columnHelper.accessor('title', { header: 'Title' }),
        columnHelper.accessor('role', {
          header: 'Role',
          meta: { colWidth: 'w-[19%]', truncate: false },
        }),
        columnHelper.accessor('status', {
          header: 'Status',
          meta: { colWidth: 'w-[20%] lm:w-[180px] ll:w-1/4' },
          cell: (info) => {
            if (isInvitationMember(info.row.original)) {
              return <InvitationStatus invitation={info.row.original.data} />;
            }

            if (isSubscriptionMember(info.row.original)) {
              return (
                <SeatStatus
                  member={info.row.original.data}
                  subscription={subscription.data}
                />
              );
            }

            return info.getValue();
          },
        }),
        columnHelper.display({
          header: 'Edit',
          meta: {
            align: 'center',
            colWidth: 'w-[50px]',
            truncate: false,
          },
          cell: (info) => <MemberActionMenu member={info.row.original} />,
        }),
      ].filter(Boolean),
    [CONFIG.EnableSeatsBulkActions]
  );

  const table = useReactTable({
    data: members.data,
    columns: columns as ColumnDef<Member>[],
    meta: useMultiRowSelect<Member>(),
    getRowId: ({ id }) => id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    renderFallbackValue: () => '-',
  });

  if (members.isError) return <SomethingWentWrong />;

  return (
    <div className="flex flex-col gap-24 py-16">
      <div className="flex flex-col-reverse justify-between gap-16 ls:flex-row">
        <Input
          id="search"
          placeholder="Search"
          className="w-full ls:max-w-[300px]"
          onChange={(e) => table.setGlobalFilter(e.currentTarget.value)}
        />

        <InviteToSeatsButton />
      </div>

      <Table
        table={table}
        data-cy="members-table"
        className="hidden ls:table"
      />

      <ul
        data-cy="members-list-mobile"
        className="border-t border-gray-100 ls:hidden"
      >
        {table.getRowModel().rows.map((row) => (
          // We use the table's row state because it is already filtered and sorted
          <li
            data-cy={row.id}
            key={row.id}
            className="border-b border-gray-100 py-12"
          >
            <div className="flex items-center justify-between gap-16">
              <div className="flex min-w-0 flex-col">
                <Typography variant="body-sm-regular" className="truncate">
                  {row.original.title}
                </Typography>

                <div className="opacity-70">
                  {row.original.type === 'invitation' && (
                    <InvitationStatus invitation={row.original.data} />
                  )}

                  {row.original.type === 'subscription' && (
                    <Typography variant="body-sm-regular" className="italic">
                      {row.original.status}
                    </Typography>
                  )}
                </div>
              </div>

              <MemberActionMenu member={row.original} />
            </div>
          </li>
        ))}
      </ul>

      <MembersBulkActionPanel table={table} />
    </div>
  );
};
