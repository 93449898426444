import { PropsWithChildren, ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';
import { Info } from 'phosphor-react';

const Content = ({ children }: PropsWithChildren) => (
  <div className="w-full">{children}</div>
);

export const Container = ({ children, ...rest }: PropsWithChildren) => (
  <div
    className="m-auto flex max-w-screen-xl flex-col items-center gap-24 px-24 py-64 text-center md:px-64 lg:py-128"
    {...rest}
  >
    {children}
  </div>
);

export const ErrorIcon = ({ children }: { children?: ReactNode }) => (
  <div className="rounded-full bg-neutral-dark-1 p-4 text-center text-white">
    {children || <Info size={32} />}
  </div>
);

export const Heading = ({ children }: PropsWithChildren) => (
  <Typography variant="heading-lg">{children}</Typography>
);

export const ErrorPage = {
  Content,
  Container,
  ErrorIcon,
  Heading,
};
