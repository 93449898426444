import { HTMLAttributes, HTMLProps, ReactNode, useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';

import { cn } from 'src/utils/classNamesHelper';
import { withMaxWidth } from 'src/utils/withMaxWidth';

import { BackLink } from './BackLink';
import { Navbar } from './NavBar';

const Container = ({
  children,
  className,
  backLink = false,
  ...rest
}: {
  children: ReactNode;
  backLink?: boolean;
  className?: string;
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cn('mx-auto h-full w-full grow', className)} {...rest}>
      {backLink && <BackLink className="absolute left-16 top-80" />}
      {children}
    </div>
  );
};

interface HeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  className?: string;
}
const Header = (props: HeaderProps) => (
  <div
    className={cn(
      'm-auto my-32 px-16 text-center lm:mt-56 ll:mb-48 ll:mt-64',
      withMaxWidth('max-w-lm', props.className)
    )}
  >
    <Typography
      variant="responsive-heading-lg"
      className="mb-16 mt-0 ls:mb-20"
      as="h1"
    >
      {props.title}
    </Typography>
    {props.subtitle && (
      <p className="responsive-body-md my-0">{props.subtitle}</p>
    )}
    {props.children}
  </div>
);

const NavBar = (props: HTMLProps<HTMLDivElement>) => (
  <div className="flex justify-center">
    <div
      className={cn(
        'w-full flex-col px-16',
        withMaxWidth('max-w-3xl', props.className)
      )}
    >
      <Navbar {...props} className="mb-16 justify-center ls:mb-24 ll:mb-32" />
    </div>
  </div>
);

const Content = ({
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'm-auto flex flex-col px-16 pb-64 lm:px-32',
      withMaxWidth('max-w-lm', className)
    )}
  >
    <div className="w-full" {...rest}>
      {children}
    </div>
  </div>
);

export const AppPage = { Container, Header, NavBar, Content };
