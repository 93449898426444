import { createFileRoute } from '@tanstack/react-router';

import { createQueryAccountInfo } from 'src/api/queries';
import { Account } from 'src/apps/account/app/components/Account';

export const Route = createFileRoute('/_auth/_layout/account/')({
  loader: async ({ context }) => {
    await Promise.all([
      context.queryClient.ensureQueryData(createQueryAccountInfo()),
    ]);
  },
  component: () => <Account />,
});
