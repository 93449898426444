import { HTMLAttributes } from 'react';

import { getRouteApi } from '@tanstack/react-router';
import clsx from 'clsx';
import { X } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button, Modal } from 'src/components';

import { MrfModal } from './MrfModal';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

export const CancelSubscriptionButton = ({
  className,
  ...rest
}: HTMLAttributes<HTMLButtonElement>) => {
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  return (
    <Modal.Root
      onOpenChange={(open) => {
        if (open) {
          void navigate({ search: { cancel_subscription: 'step-1' } });
        } else {
          void navigate({ search: { cancel_subscription: undefined } });
          return;
        }
      }}
      open={
        search.cancel_subscription &&
        search.cancel_subscription !== 'confirmation'
      }
    >
      <Modal.Trigger asChild>
        <Button
          size="medium"
          variant="secondary"
          className={clsx('w-full ls:w-auto', className)}
          data-cy="cancel-subscription"
          analytics={{
            text: 'Cancel subscription',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'open cancel subscription flow',
          }}
          {...rest}
        >
          <X />
          <span>Cancel subscription</span>
        </Button>
      </Modal.Trigger>

      <MrfModal />
    </Modal.Root>
  );
};
