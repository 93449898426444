import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  createConsent,
  getConsent,
  revokeConsent,
} from '../endpoints/cloudConsentApi';
import { queryKeys } from './queryKeys';

export const useConsentStatus = () =>
  useQuery({
    queryKey: queryKeys.consent.details,
    queryFn: getConsent,
    staleTime: Infinity,
  });

export const useCreateConsent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      type,
      level,
    }: {
      type: 'sync-content';
      level: 'meta-data' | 'full';
    }) => createConsent(type, level),
    onSuccess: (response) => {
      if (response && response.response.status === 201) {
        void queryClient.invalidateQueries({
          queryKey: queryKeys.consent.details,
        });
      }
    },
  });
};

export const useRevokeConsent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => revokeConsent(),
    onSuccess: (response) => {
      if (response && response.response.ok) {
        void queryClient.invalidateQueries({
          queryKey: queryKeys.consent.details,
        });
      }
    },
  });
};
