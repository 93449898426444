import { Typography } from '@remarkable/ark-web';
import { FolderOpen } from 'phosphor-react';

export const DropAccept = () => {
  return (
    <div
      data-cy="drop-accepted"
      className="flex h-full w-full flex-row items-center justify-center  bg-interaction-primary-defaultSubtle p-32 text-left"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B61FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e")`,
      }}
    >
      <div className="flex flex-col items-center gap-8 lm:flex-row lm:items-start">
        <FolderOpen size={32} className="mt-4 text-text-muted" />
        <div className="w-4/5 lm:w-auto">
          <Typography variant="body-2xl-regular" className="hidden md:block">
            Drag and drop or <strong>browse your files</strong>
          </Typography>

          <Typography
            variant="body-sm-regular"
            className="text-center md:text-left"
          >
            Supports PDF, JPG, PNG, and EPUB files up to 100 MB.
          </Typography>
        </div>
      </div>
    </div>
  );
};
