import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight } from '@phosphor-icons/react';
import { Trash } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { HTTPError } from 'src/api/createApiClient.types';
import { HttpStatus } from 'src/api/httpStatus';
import { useDeleteAccount } from 'src/api/queries';
import { DeleteAccountWrapper } from 'src/apps/account/app/components/DeleteAccount/DeleteAccountWrapper';
import { DeleteAccountProps } from 'src/apps/account/app/components/DeleteAccount/types';
import { Button, NotificationBox } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export function DeleteAccountConfirmation(props: DeleteAccountProps) {
  const { variant } = props;

  const deleteAccount = useDeleteAccount();
  const auth = useAuth0();

  const reAuthenticationRequired =
    deleteAccount.isError &&
    deleteAccount.error instanceof HTTPError &&
    deleteAccount.error.response.status === HttpStatus.Forbidden;

  if (deleteAccount.isSuccess) {
    const email = auth.user?.email;

    return (
      <DeleteAccountWrapper
        title="Your account will soon be deleted"
        showTrashIcon={true}
      >
        <>
          <div className="flex flex-col space-y-32">
            <p className="responsive-body-md my-0">
              Your account will be deleted within 7 days.
            </p>
            {email && (
              <p>
                We&apos;ll send an email confirmation to{' '}
                <span className="whitespace-nowrap">{email}</span>.
              </p>
            )}
          </div>

          <div className="flex flex-row space-x-16">
            {variant === 'browser' && (
              <Button
                as="button"
                variant="primary"
                size="medium"
                onClick={async (e) => {
                  e.preventDefault();
                  await auth.logout();
                }}
              >
                <span>Log out</span>
              </Button>
            )}
          </div>
        </>
      </DeleteAccountWrapper>
    );
  }

  if (reAuthenticationRequired) {
    return (
      <DeleteAccountWrapper title="Log in to confirm account deletion">
        <>
          <div className="flex flex-row space-x-16">
            {variant === 'browser' && (
              <Button as="a" to={URLS.HOME} variant="primary" size="medium">
                <span>Keep my account</span>
              </Button>
            )}
            <Button
              as="button"
              variant="secondary"
              size="medium"
              onClick={async (e) => {
                e.preventDefault();

                return auth.loginWithRedirect({
                  authorizationParams: {
                    max_age: 0,
                  },
                });
              }}
            >
              <span>Log in</span> <ArrowRight />
            </Button>
          </div>
        </>
      </DeleteAccountWrapper>
    );
  }

  return (
    <DeleteAccountWrapper
      title="Confirm account deletion"
      showDiamondIcon={true}
    >
      <>
        <div className="flex flex-col space-y-32">
          <p className="responsive-body-md my-0">
            If you proceed, your account will be deleted within 7 days. This
            can&apos;t be undone.
          </p>
        </div>

        <div className="flex flex-row space-x-16">
          {variant === 'browser' && (
            <Button as="a" to={URLS.HOME} variant="primary" size="medium">
              <span>Keep my account</span>
            </Button>
          )}
          <Button
            variant="tertiary"
            className="w-full self-start ls:w-fit"
            size="medium"
            data-cy="delete-account-button"
            onClick={() => {
              deleteAccount.mutate();

              tracker.trackEvent(
                new ButtonClicked({
                  component_location: ComponentLocations.ACCOUNT.DELETION,
                  text: 'Delete account',
                  action: 'Delete account',
                })
              );
            }}
            disabled={deleteAccount.isPending || deleteAccount.isSuccess}
            loading={deleteAccount.isPending}
          >
            <Trash /> <span>Delete account</span>
          </Button>
        </div>

        {deleteAccount.isError && (
          <NotificationBox
            variant="error"
            className="mb-16 w-full max-w-md"
            title={<>We couldn&apos;t start the account deletion process</>}
          >
            Please try again or contact support for help.
          </NotificationBox>
        )}
      </>
    </DeleteAccountWrapper>
  );
}
