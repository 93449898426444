import { HTMLAttributes } from 'react';

// TODO: Merge this with Divider.tsx, if possible
/**
 * A simple divider that automatically works with flex directions (horizontal vs vertical).
 */
export const FlexDivider = ({
  className = '',
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`self-stretch border-l border-t border-neutral-light-6 ${className}`}
    {...props}
  />
);
