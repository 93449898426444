import { MouseEvent } from 'react';

import { ArrowCircleUpRight } from 'phosphor-react';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';

import { Link } from './Link';

export const FAQLine = ({
  text,
  target,
}: {
  text: string | JSX.Element;
  target: string;
}) => {
  return (
    <li className="text-line border-b border-b-gray-200 py-24 leading-6">
      <Link
        to={target}
        target="_blank"
        rel="noreferrer"
        className="body-md flex w-full justify-between"
        onClick={(event: MouseEvent<HTMLLinkElement>) => {
          tracker.trackEvent(
            new LinkClicked({
              component_location: ComponentLocations.FAQ.MAIN,
              text: event.currentTarget.innerText,
              link_source: event.currentTarget.href,
            })
          );
        }}
      >
        <span className="max-w-[90%]">{text}</span>
        <ArrowCircleUpRight className="h-20 w-20 min-w-[20px] max-w-[20px] self-center text-gray-400 group-hover:text-gray-600" />
      </Link>
    </li>
  );
};
