import { createFileRoute, Outlet } from '@tanstack/react-router';

import {
  createQueryCheckoutAllowed,
  useActivePaperTabletsSuspense,
} from 'src/api/queries';
import { AppPage } from 'src/components/AppPage';
import { handleCheckoutAllowedRedirect } from 'src/utils/getCheckoutAllowedRedirect';

export const Route = createFileRoute('/_auth/_layout/store/connect-offer')({
  async beforeLoad({ context }) {
    handleCheckoutAllowedRedirect(
      'connectOffer',
      await context.queryClient.ensureQueryData(createQueryCheckoutAllowed())
    );
  },
  component: () => {
    const activeTablets = useActivePaperTabletsSuspense();

    return (
      <AppPage.Container>
        <AppPage.Header
          title={
            activeTablets.data.length > 1
              ? 'Sync and transfer files'
              : 'Setting up'
          }
        />

        <Outlet />
      </AppPage.Container>
    );
  },
});
