import { ComponentProps } from 'react';

import { Typography } from '@remarkable/ark-web';

import { cn } from '../../../../utils/classNamesHelper';

export const CardHeading = ({
  children,
  className,
  ...props
}: Partial<ComponentProps<typeof Typography>>) => (
  <h2
    className={cn('heading-subtle-xl lm:heading-subtle-2xl', className)}
    {...props}
  >
    {children}
  </h2>
);
