import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  ONE_TIME_CODE_EXPIRATION_TIME,
  ONE_TIME_CODE_POLLING_INTERVAL,
} from 'src/api/queries/devices.config';
import {
  filterActivePaperTabletClients,
  isActiveClient,
  isClientPaperTablet,
} from 'src/apps/device/utils/utils';

import { cloudApi, storeApi } from '../endpoints';
import { ClientInformation, ClientSource } from '../endpoints/cloudApi.types';
import {
  getHasEverPairedDevice,
  getMergedDevices,
} from '../endpoints/cloudApi.utils';
import { queryKeys } from './queryKeys';
import { RetryException } from './types';

interface useDevicesOptions {
  enabled?: boolean;
  select?: (clients: ClientInformation[]) => ClientInformation[];
}

export const createQueryDevices = () =>
  queryOptions({
    queryKey: queryKeys.devices.all,
    queryFn: getMergedDevices,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });

export const useDevices = (options?: useDevicesOptions) =>
  useQuery({
    ...createQueryDevices(),
    ...options,
  });

export const useDevicesSuspense = () => useSuspenseQuery(createQueryDevices());

export const useActivePaperTablets = () =>
  useDevices({
    select: filterActivePaperTabletClients,
  });

export const useActivePaperTabletsSuspense = () =>
  useSuspenseQuery({
    ...createQueryDevices(),
    select: filterActivePaperTabletClients,
  });

export const useActiveDevices = () =>
  useDevices({
    select: (devices) => devices.filter(isActiveClient),
  });

export const createQueryHasEverPairedDevice = () =>
  queryOptions({
    queryKey: queryKeys.devices.hasEverPairedDevice,
    queryFn: getHasEverPairedDevice,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
export const useHasEverPairedDevice = () => {
  return useQuery(createQueryHasEverPairedDevice());
};

export const useDeleteDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (device: ClientInformation) => {
      switch (device.clientSource) {
        case ClientSource.DeviceV1:
          return cloudApi.deleteDeviceV1(device.clientId, device.registeredAt);
        case ClientSource.ProfileV1:
          return cloudApi.deleteProfileDeviceV1(device.clientId);
        default:
          throw new Error('Unsupported device source');
      }
    },

    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.devices.all,
      });
    },
  });
};

// ============================= Device pairing =============================

export const usePollForNewDevice = (oneTimeCode: string | undefined) => {
  const queryClient = useQueryClient();
  const devices = useDevices();
  const prevDeviceList = devices.data ?? null;

  const MAX_POLLING_ATTEMPTS = Math.ceil(
    Math.max(0, ONE_TIME_CODE_EXPIRATION_TIME / ONE_TIME_CODE_POLLING_INTERVAL)
  );

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.devices.poll(oneTimeCode),
    queryFn: async () => {
      const response = await getMergedDevices();
      // Check if the device list has changed
      if (JSON.stringify(prevDeviceList) === JSON.stringify(response)) {
        // Trigger a retry, this exception is handled in our global react query config
        throw new RetryException('Data has not changed', MAX_POLLING_ATTEMPTS);
      }

      // Since we are pulling the entire device list, we can update the cache
      queryClient.setQueryData(queryKeys.devices.all, response);

      // But for this query, we only want to return the new device
      if (!prevDeviceList) {
        return response[0];
      }
      const newDevice = response.find((newDevice) => {
        // Return the first device that is not in the original list, or has been modified
        return !prevDeviceList.find(
          (originalDevice) =>
            originalDevice.clientId === newDevice.clientId &&
            originalDevice.registeredAt === newDevice.registeredAt
        );
      });

      return newDevice || null;
    },
    enabled: !!oneTimeCode && !!devices.data, // Only poll when we have a oneTimeCode and devices have been fetched
    staleTime: Infinity, // We only want to fetch this once per queryKey
    retryDelay: ONE_TIME_CODE_POLLING_INTERVAL,
  });
};

// Used only with deviceV1
// If the user is pairing a remarkable device, we need to poll the store api to
// check if the device is eligible for either a Subscription offer, or a retail
// offer. This decides where the user should be redirected after successful pairing.
export const usePollDeviceActivationStatus = (
  device?: ClientInformation | null
) => {
  const queryClient = useQueryClient();

  const MAX_POLLING_ATTEMPTS = Math.ceil(
    Math.max(0, ONE_TIME_CODE_EXPIRATION_TIME / ONE_TIME_CODE_POLLING_INTERVAL)
  );

  return useQuery({
    queryKey: queryKeys.devices.activationStatus(device),
    queryFn: async () => {
      if (!device) {
        // This should never happen because we only enable this query when we have a device
        throw new Error('Device is not defined');
      }

      // Device activation is only relevant for remarkable devices
      if (device.clientSource !== ClientSource.DeviceV1) {
        return null;
      }

      if (!isClientPaperTablet(device)) {
        return null;
      }

      const response = await storeApi.getDeviceActivationStatus(
        device.clientId
      );

      if (!response.processed) {
        // Trigger a retry, this exception is handled in our global react query config
        throw new RetryException(
          'Data not processed yet',
          MAX_POLLING_ATTEMPTS
        );
      }

      // Reset checkout cache to prevent stale data from causing a new redirect
      // when going to the checkout page
      await queryClient.resetQueries({ queryKey: queryKeys.checkoutBaseKey });
      return response;
    },
    staleTime: Infinity, // We only want to fetch this once per queryKey
    enabled: isClientPaperTablet(device),
    retryDelay: ONE_TIME_CODE_POLLING_INTERVAL,
  });
};
