import { createFileRoute } from '@tanstack/react-router';

import { Desktop } from 'src/apps/device/components/Desktop';

export const Route = createFileRoute('/_auth/pair/app')({
  component: () => (
    <div className="flex h-[100vh] w-full flex-col items-center justify-center py-64">
      <Desktop showOtp={true} appMode={true} />
    </div>
  ),
});
