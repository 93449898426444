// Poor man's email validation, but it is also a man that has better things to do
export const validateEmail = (email?: string) => {
  if (!email) return;

  const emailFormat = /\S+@\S+\.\S+/;
  const containsSpaces = /\s/.test(email);
  const multipleAtSigns = email.split('@').length > 2;

  if (multipleAtSigns) {
    return 'Email addresses can only contain one at sign (@).';
  }

  if (containsSpaces) {
    return "Email addresses can't contain spaces.";
  }

  if (!emailFormat.test(email)) {
    return 'The email address is not valid.';
  }

  return true;
};
