import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { storeApi } from 'src/api/endpoints';
import { queryKeys } from 'src/api/queries';

export const useVerifyEmail = (urlToken: string) => {
  const queryClient = useQueryClient();

  const useEmailChangeForToken = (urlToken: string) => {
    return useQuery({
      queryKey: queryKeys.email.changeWithToken(urlToken),
      queryFn: () => storeApi.getEmailChangeStatusForToken({ urlToken }),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity, // this is manually invalidated/reset in cancel and confirm email change
    });
  };

  const useCancelEmailChange = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: storeApi.cancelEmailChangeRequest,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.email.changeWithTokeBaseKey,
        });
      },
    });
  };

  const useConfirmEmailChange = () => {
    const navigate = useNavigate();
    return useMutation({
      mutationFn: storeApi.confirmEmailChange,
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.email.changeWithTokeBaseKey,
        });

        // Wait 10 seconds
        await new Promise((resolve) => setTimeout(resolve, 10_000));

        return navigate({ to: '/logout' });
      },
    });
  };

  const emailChangeQuery = useEmailChangeForToken(urlToken);
  const cancelEmailMutation = useCancelEmailChange();
  const confirmEmailMutation = useConfirmEmailChange();
  const emailChangeData = emailChangeQuery.data;

  const isLoading =
    emailChangeQuery.isPending ||
    cancelEmailMutation.isPending ||
    confirmEmailMutation.isPending;
  const isError =
    emailChangeQuery.isError ||
    cancelEmailMutation.isError ||
    confirmEmailMutation.isError;

  const cancelEmailChange = () => cancelEmailMutation.mutate();
  const confirmEmailChange = () =>
    confirmEmailMutation.mutate({ token: urlToken });

  return {
    emailChangeData,
    cancelEmailChange,
    confirmEmailChange,
    urlToken,
    isLoading,
    isError,
  };
};
