import { ReactNode, useEffect, useRef, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import clsx from 'clsx';
import { Warning, X } from 'phosphor-react';

import { Tooltip } from 'src/components';
import { cn } from 'src/utils/classNamesHelper';
import { validateEmail } from 'src/utils/validateEmail';

export interface ValidationError {
  index?: number | undefined | null;
  type: 'error' | 'warning';
  message?: string;
}

interface InputMultipleProps {
  value: string[];
  onValueChange: (value: string[]) => void;
  onPaste?: (value: string, prev: string[]) => string[];
  errors?: (ValidationError | null | undefined | false)[];
  onBlur?: () => void;
  placeholder?: ReactNode;
}

export const InputMultiple = (props: InputMultipleProps) => {
  const [inputValue, setInputValue] = useState('');
  const [highlights, setHighlights] = useState<string[]>([]);
  const highlightTimeout = useRef<NodeJS.Timeout | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const errorArray = Array.isArray(props.errors)
    ? (props.errors.filter((err) => !!err) as unknown as ValidationError[]) // When is Typescript going to infer this?
    : [];
  const isError = errorArray.length > 0;
  const globalError =
    errorArray.find(
      (error) =>
        typeof error.index === 'undefined' ||
        error.index === null ||
        error.index === -1
    ) || null;

  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, props.value.length);
  }, [props.value]);

  useEffect(() => {
    if (highlights.length > 0) {
      highlightTimeout.current = setTimeout(() => {
        setHighlights([]);
      }, 300);

      return () => {
        if (highlightTimeout.current) {
          clearTimeout(highlightTimeout.current);
        }
      };
    }
  }, [highlights]);

  return (
    <div>
      <div
        className={clsx(
          'my-16 flex max-h-[40vh] min-h-[110px] w-full cursor-text flex-col overflow-x-hidden overflow-y-scroll rounded border px-8 py-12',
          {
            'border-neutral-light-8 focus-within:border-pen-blue': !isError,
            'border-feedback-red-500': isError,
          }
        )}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className="flex flex-wrap gap-8">
          {props.value.map((value, index) => {
            const itemError = errorArray.find((error) => error.index === index);
            const isDuplicate = highlights.includes(value);

            // Input fields doesn't grow by default so we trick it by having an invisible span with
            // the same value as the input field that forces the parent to grow. The input field
            // width can then be inherited from the parent. The "-" fallback is to prevent the
            // height from collapsing when the input field is empty.
            return (
              <Tooltip
                key={index}
                title={itemError?.message}
                enabled={!!itemError}
              >
                <div
                  data-cy="email-field"
                  className={cn(
                    'flex max-w-full cursor-text items-center gap-4 rounded-full border border-transparent bg-neutral-light-4 pl-16 text-16 transition-all focus-within:border focus-within:border-pen-blue hover:bg-neutral-light-6',
                    {
                      'border-feedback-red-100 bg-feedback-red-100 focus-within:border-feedback-red-500 focus-within:bg-feedback-red-100/60 hover:border-feedback-red-500 hover:bg-feedback-red-100/60':
                        itemError?.type === 'error',
                      'border-feedback-orange-500 !bg-feedback-orange-500/10':
                        itemError?.type === 'warning' || isDuplicate,
                    }
                  )}
                >
                  {itemError && <Warning size={16} className="mr-4" />}
                  <div className="relative min-w-0">
                    <span className="invisible whitespace-nowrap text-14">
                      {/* Replace spaces with non breaking spaces (\u00A0) to prevent mismatch between the value and what is rendered (browsers only render 1 space) */}
                      {value.replaceAll(' ', '\u00A0') || '-'}
                    </span>
                    <input
                      data-cy="email-field-input"
                      className="absolute left-0 top-2 w-full truncate bg-transparent text-14 outline-none"
                      value={value}
                      ref={(el) => (itemsRef.current[index] = el)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // Delete field if it is empty
                        if (e.currentTarget.value === '') {
                          // First move the focus
                          if (props.value.length <= 1) {
                            // If there is only one field left, focus on the input field
                            inputRef.current?.focus();
                          } else if (index === 0) {
                            // If we are on the first field, focus on the next field
                            const next = itemsRef.current[index + 1];
                            next?.focus();
                            next?.select();
                          } else {
                            // Focus on the previous field
                            const prev = itemsRef.current[index - 1];
                            prev?.focus();
                            prev?.select();
                          }
                          // Finally, remove the field
                          props.onValueChange(
                            props.value.filter((_, i) => i !== index)
                          );
                          return;
                        }
                        props.onValueChange(
                          props.value.map((v, i) =>
                            i === index ? e.currentTarget.value : v
                          )
                        );
                      }}
                    />
                  </div>
                  <button
                    data-cy="delete-email-button"
                    className={clsx(
                      'm-4 flex h-[28px] w-[28px] shrink-0 items-center justify-center rounded-full transition-all  focus:outline-none',
                      {
                        'text-grayscale-gray-500 hover:bg-grayscale-gray-200 hover:text-grayscale-gray-900 focus:bg-grayscale-gray-200 focus:text-grayscale-gray-800':
                          !isError,
                        'hover:bg-feedback-red-500/20 hover:text-black':
                          isError,
                      }
                    )}
                    type="button" // Prevents this button from engaging on submit
                    onClick={() => {
                      props.onValueChange(
                        props.value.filter((_, i) => i !== index)
                      );
                    }}
                    tabIndex={-1}
                  >
                    <X />
                  </button>
                </div>
              </Tooltip>
            );
          })}
        </div>
        <div className="relative flex h-[38px] min-w-[80px] grow items-center pl-8">
          {/* Same input grow trick as above. The input value fallbacks prevents vertical collapse. */}
          <span className="invisible">
            {inputValue === ' ' ? '-' : inputValue || '-'}
          </span>
          <input
            data-cy="email-input"
            ref={inputRef}
            value={inputValue}
            className={clsx(
              'absolute left-8 top-4 w-full bg-transparent py-4 text-14 focus:outline-none'
            )}
            onChange={(event) => {
              setInputValue(event.currentTarget.value);
            }}
            onBlur={(event) => {
              if (event.currentTarget.value !== '') {
                const sanitizedValue = event.currentTarget.value
                  .trim()
                  .replace(/,$/, '');

                if (props.value.includes(sanitizedValue)) {
                  setHighlights([...highlights, sanitizedValue]);
                } else {
                  props.onValueChange([...props.value, sanitizedValue]);
                }
                setInputValue('');
              }
              props.onBlur?.();
            }}
            onKeyDown={(event) => {
              // Add email when pressing enter, tab or space
              if (
                (event.key === 'Tab' ||
                  event.key === 'Enter' ||
                  event.key === ' ') &&
                !event.shiftKey &&
                event.currentTarget.value !== ''
              ) {
                event.preventDefault();

                // Remove whitespace and commas from the end of the value
                const sanitizedValue = event.currentTarget.value
                  .trim()
                  .replace(/,$/, '');

                if (props.value.includes(sanitizedValue)) {
                  setHighlights([...highlights, sanitizedValue]);
                } else {
                  props.onValueChange([...props.value, sanitizedValue]);
                }
                setInputValue('');
              }

              // Focus on the last field when pressing backspace on an empty input field
              if (event.key === 'Backspace' && inputValue === '') {
                event.preventDefault();

                const item = itemsRef.current[itemsRef.current.length - 1];
                item?.focus();
                item?.select();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();

              const clipboardData = event.clipboardData.getData('Text');
              if (props.onPaste) {
                const newValue = props.onPaste?.(clipboardData, props.value);
                props.onValueChange(newValue);
              } else {
                const newValue = clipboardData.split(/[\s,;]+/);
                const valueSet = new Set(props.value);
                const duplicates = new Set<string>();

                newValue.forEach((substring) => {
                  const candidate = substring.trim();

                  if (candidate === '') {
                    return;
                  }

                  if (valueSet.has(candidate)) {
                    duplicates.add(candidate);
                  }
                  valueSet.add(candidate);
                });

                if (duplicates.size > 0) {
                  setHighlights(Array.from(duplicates));
                }
                props.onValueChange(Array.from(valueSet));
              }
            }}
          />

          {/* Custom placeholder to support line breaking */}
          {props.value.length === 0 && !inputValue && props.placeholder && (
            <div className="pointer-events-none absolute left-8 top-[5px]">
              <Typography
                variant="interface-sm-regular"
                className="leading-5 text-grayscale-gray-400"
              >
                {props.placeholder}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {globalError?.message && (
        <Typography
          data-cy="email-validation-error"
          variant="interface-md-regular"
          className="mb-16 text-feedback-red-500"
        >
          {globalError.message}
        </Typography>
      )}
    </div>
  );
};

export function validateEmails(values: string[]): ValidationError[] {
  const errors: ValidationError[] = [];

  values.forEach((value, index) => {
    const validationResult = validateEmail(value);
    if (validationResult !== true) {
      errors.push({
        index,
        type: 'error',
        message: validationResult || 'Invalid email address',
      });
    }
  });

  return errors;
}
