import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { useSaveIntegration } from 'src/api/queries';
import { Button, Form, Input, Modal, NotificationBox } from 'src/components';

import { getProviderDisplayName } from '../utils/getProviderDisplayName';
import { providerIconByNameMap } from '../utils/iconMap';
import { useProviderCallback } from '../utils/useProviderCallback';

interface SaveIntegrationForm {
  name: string;
}

const routeApi = getRouteApi('/_auth/_layout/integrations');

export const SaveIntegrationModal = () => {
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  const form = useForm<SaveIntegrationForm>();
  const { isCallbackUrl, data, error } = useProviderCallback();

  const saveNewIntegration = useSaveIntegration();

  const onSubmit = (formData: SaveIntegrationForm) => {
    if (!data?.provider) return;

    saveNewIntegration.mutate(
      {
        provider: data.provider,
        integration: {
          name: formData.name || getProviderDisplayName(data.provider),
          code: data.code,
          state: data.state,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          scope: data.scope ? data.scope : undefined,
        },
      },
      {
        onSuccess() {
          void navigate({ search: { callback: undefined } });
        },
      }
    );
  };

  const icon = data?.provider
    ? providerIconByNameMap[data.provider] ?? null
    : null;

  if (!isCallbackUrl) return null;

  return (
    <Modal.Root
      open={search.callback === true}
      onOpenChange={(isOpen) => {
        void navigate({ search: { callback: isOpen ? true : undefined } });
      }}
    >
      <Modal.Content>
        <div data-cy="save-integration-modal">
          {error ? (
            <NotificationBox
              variant="error"
              title={error.title}
              data-cy="save-integration-error"
            >
              {error.message}
            </NotificationBox>
          ) : (
            <>
              <Typography variant="heading-sm" className="mb-32">
                Name your service
              </Typography>
              <p>Your service was successfully connected</p>
              <p className="my-16">
                If you want to, you can give it a name, for example “Work”, to
                easily tell the difference between the services you have
                connected.
              </p>
              <p> You can set maximum 14 characters.</p>
              {saveNewIntegration.error instanceof Error && (
                <NotificationBox
                  variant="error"
                  className="mt-16"
                  title={'Oops! Something went wrong'}
                  data-cy="save-integration-name-message"
                >
                  {saveNewIntegration.error.message}
                </NotificationBox>
              )}
              <Form
                {...form}
                onSubmit={onSubmit}
                dataCy="save-integration-modal-form"
              >
                <div className="mt-32 flex items-start justify-center gap-16">
                  {icon !== null && (
                    <div className="flex h-48 items-center">
                      <img
                        src={icon}
                        alt="Integration provider"
                        className="h-32 w-32"
                      />
                    </div>
                  )}
                  <Input
                    name="name"
                    placeholder="Name"
                    className="!my-0 max-w-[200px] text-left"
                    inputClassName="border border-gray-800"
                    maxLength={14}
                    data-cy="save-integration-name-input"
                  />
                  <Button
                    type="submit"
                    loading={saveNewIntegration.isPending}
                    className="h-48"
                    data-cy="integration-name-submit-button"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
