import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { storeApi } from '../endpoints';
import { queryKeys } from './queryKeys';
import { useStripeSubscription } from './subscriptions';

export const useInvitations = () => {
  const subscription = useStripeSubscription();

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.invitations.all,
    queryFn: () =>
      storeApi.getInvitations({ subscriptionId: subscription.data?.id ?? '' }),
    enabled: !!subscription.data?.id,
  });
};

export const useDeleteInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (invitationId: string) => {
      // temporary fix for backend not returning the invitationId, parse it from the link
      // TODO: cleanup this as soon as backend returns the invitationId
      const link = new URL(invitationId);
      const token = link.searchParams.get('token');

      if (!token) {
        throw new Error('Invalid invitation link');
      }

      return storeApi.deleteSeatInvitation({
        invitationTokenUUID: token,
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.invitations.all,
      });
    },
  });
};
