import { ReactNode } from 'react';

import {
  Close,
  Content as DialogContent,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import { Spinner } from '@remarkable/ark-web';
import clsx from 'clsx';
import { X } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';
import { withMaxWidth } from 'src/utils/withMaxWidth';

import { Button } from './Button';

export interface ModalProps {
  children: ReactNode;
  className?: string;
  title?: ReactNode;
  loading?: boolean;
}

export const exitAnimationDuration = 200; // ms

const Content = (props: ModalProps) => {
  return (
    <Portal>
      <Overlay
        className={clsx(
          'fixed inset-0 z-[60] bg-gray-900/60 transition-all',
          'motion-safe:data-[state=closed]:animate-fade-out motion-safe:data-[state=open]:animate-fade-in',
          'motion-reduce:data-[state=open]:opacity-1 motion-reduce:data-[state=closed]:opacity-0'
        )}
        onClick={(e) => e.stopPropagation()}
      />
      <DialogContent
        className={cn(
          'fixed left-1/2 top-1/2 z-[61] max-h-[calc(100%-32px)] w-[calc(100%-32px)] translate-x-1/2 translate-y-1/2 overflow-y-auto rounded bg-neutral-light-2 px-16 pb-32 pt-48 text-center shadow-xl focus:outline-none data-[state=open]:-translate-x-1/2 data-[state=open]:-translate-y-1/2 ls:py-48 lm:px-32',
          'motion-safe:data-[state=closed]:animate-modal-out motion-safe:data-[state=open]:animate-modal-in',
          'motion-reduce:-translate-x-1/2 motion-reduce:-translate-y-1/2',
          withMaxWidth('max-w-lm', props.className)
        )}
      >
        {props.loading ? (
          <Spinner />
        ) : (
          <>
            {props.title && (
              <Title className="responsive-heading-md mb-24 text-center">
                {props.title}
              </Title>
            )}

            {props.children}
          </>
        )}

        <Close asChild>
          <Button
            className="absolute right-[10px] top-[10px]"
            variant="tertiary-neutral"
            size="medium"
            data-cy="close-modal"
            aria-label="Close"
            onClick={(e) => e.stopPropagation()}
          >
            <X />
          </Button>
        </Close>
      </DialogContent>
    </Portal>
  );
};

export { Close, Content, Root, Trigger };
