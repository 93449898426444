import { ReactNode } from 'react';

// Create a content switch function that first takes a union type and then a partial config object
// where each key is a value of the union type and the value is a ReactNode or a function that returns a ReactNode.
export const createContentSwitch =
  <T extends string | null | undefined>(type: T) =>
  <C extends Record<NonNullable<T>, ReactNode>>(
    config: Partial<C & { default: ReactNode }>
  ) => {
    if (!type) return null;

    const content = config[type];
    if (!content) return config.default ?? null;

    return content;
  };
