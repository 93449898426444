import { Typography } from '@remarkable/ark-web';

import { Button, ErrorPage } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const InvitationExpired = () => {
  return (
    <ErrorPage.Container data-cy="seat-invitation-expired-page">
      <ErrorPage.ErrorIcon />
      <ErrorPage.Heading>Your invitation expired</ErrorPage.Heading>

      <ErrorPage.Content>
        <div className="flex flex-col items-center justify-center">
          <Typography variant="body-md-regular">
            Your invitation to join Connect for business has expired. To join,
            please contact the person who invited you.
          </Typography>

          <Button as="a" to={URLS.HOME} variant="secondary" className="mt-24">
            Back to home
          </Button>
        </div>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
