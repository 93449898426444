import { useLocation } from '@tanstack/react-router';

import { URLS } from 'src/utils/urls/urls';

export enum StepNames {
  ACTIVATE_CONNECT = 'ACTIVATE_CONNECT',
  DOWNLOAD_APPS = 'DOWNLOAD_APPS',
  COMPLETE_SETUP = 'COMPLETE_SETUP',
}

export const useCheckoutProgress = () => {
  const location = useLocation();
  // TODO: replace case matches with generic paths that suit all flows
  switch (location.pathname) {
    case URLS.STORE_CONNECT_OFFER_LANDING:
    case URLS.STORE_CONNECT_OFFER_CHECKOUT:
    case URLS.STORE_DM_OFFER_LANDING:
    case URLS.STORE_DM_OFFER_CHECKOUT:
      return {
        [StepNames.ACTIVATE_CONNECT]: { active: true },
        title: 'Activate Connect',
      };
    case URLS.STORE_CONNECT_OFFER_CONFIRMATION:
    case URLS.STORE_DM_OFFER_CONFIRMATION:
      return {
        [StepNames.ACTIVATE_CONNECT]: { active: true, checked: true },
        title: 'Activate Connect',
      };
    case URLS.STORE_CONNECT_OFFER_DOWNLOAD_APPS:
    case URLS.STORE_DM_OFFER_DOWNLOAD_APPS:
      return {
        [StepNames.ACTIVATE_CONNECT]: { checked: true },
        [StepNames.DOWNLOAD_APPS]: { active: true },
        title: 'Download Apps',
      };
    case URLS.STORE_CONNECT_OFFER_SETUP_COMPLETE:
    case URLS.STORE_DM_OFFER_SETUP_COMPLETE:
      return {
        [StepNames.ACTIVATE_CONNECT]: { checked: true },
        [StepNames.DOWNLOAD_APPS]: { checked: true },
        [StepNames.COMPLETE_SETUP]: { checked: true, active: true },
        title: 'Setup complete',
      };
    default:
      return {};
  }
};
