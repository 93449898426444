import { Subscription } from 'src/api/endpoints/storeApi.types';

export const subscriptionGetStatus = (
  sub?: Subscription
):
  | 'active'
  | 'trialing'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'incomplete'
  | undefined => {
  if (!sub) return undefined;

  if (sub.type === 'free-connect') return 'active';

  if (sub.paymentInformation?.isCancelled) return 'canceled';

  switch (sub.status) {
    case 'active':
    case 'trialing':
    case 'past_due':
    case 'canceled':
    case 'unpaid':
    case 'incomplete':
      return sub.status;
    default:
      return undefined;
  }
};
