import PropTypes from 'prop-types';

import './MultiStepProcess.scss';
import { Step } from './Step';

export const MultiStepProcess = ({ steps }) => {
  return (
    <div className="msp-container">
      <div className="msp-line"></div>
      {steps.map((step, index) => {
        return (
          <Step key={index} number={index + 1}>
            {step}
          </Step>
        );
      })}
    </div>
  );
};

MultiStepProcess.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.node),
};
