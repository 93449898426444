import { ReactNode, useState } from 'react';

import { X } from '@phosphor-icons/react';
import { ButtonProps, Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';

import {
  useEnterpriseCancelEnrollment,
  useEnterpriseEnrollmentStatus,
} from 'src/api/queries/enterpriseEnrollment';
import { Button, Modal, NotificationBox, Tooltip } from 'src/components';

export function CancelSetupModal(props: {
  buttonText?: ReactNode;
  variant?: ButtonProps['variant'];
  disableTooltip?: boolean;
}) {
  const navigate = useNavigate();
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const cancelEnrollment = useEnterpriseCancelEnrollment();
  const [open, setOpen] = useState(false);

  if (enrollmentStatus.isSuccess && !enrollmentStatus.data) {
    // Enrollment not yet initialised
    return null;
  }

  return (
    <Modal.Root open={open} onOpenChange={(newState) => setOpen(newState)}>
      <Tooltip title="Cancel setup" asChild enabled={!props.disableTooltip}>
        <Modal.Trigger asChild>
          <Button variant={props.variant ?? 'tertiary-neutral'} size="medium">
            {props.buttonText ?? <X />}
          </Button>
        </Modal.Trigger>
      </Tooltip>

      <Modal.Content className="flex flex-col gap-24">
        <Typography variant="heading-md" as="h2">
          Cancel enterprise setup?
        </Typography>

        <Typography variant="body-md-regular">
          If you cancel the setup, all the information entered will be lost.
        </Typography>

        <div className="flex gap-16 self-center">
          <Modal.Close asChild>
            <Button variant="tertiary">Close</Button>
          </Modal.Close>

          <Button
            disabled={cancelEnrollment.isPending}
            loading={cancelEnrollment.isPending}
            onClick={() => {
              cancelEnrollment.mutate(undefined, {
                onSuccess() {
                  setOpen(false);

                  return navigate({ to: '/enterprise/setup' });
                },
              });
            }}
          >
            Cancel setup
          </Button>
        </div>

        {cancelEnrollment.isError && (
          <NotificationBox variant="error" title={'Something went wrong'}>
            {cancelEnrollment.error.message ||
              'Please try again later or contact support if the problem persists.'}
          </NotificationBox>
        )}
      </Modal.Content>
    </Modal.Root>
  );
}
