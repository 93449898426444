import { lazy, useEffect } from 'react';

import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { CookieBanner, Toast } from 'src/components';
import { CONFIG } from 'src/config';
import { SearchParamSchemas } from 'src/utils/searchParamSchemas';

export interface RouterContext {
  auth: Promise<Auth0ContextInterface<User>>;
  queryClient: QueryClient;
}

export const validateSearch = zodSearchValidator(
  z.union([
    SearchParamSchemas.EditPaymentMethodResponse,
    SearchParamSchemas.AccessDenied,
    SearchParamSchemas.ShowCookieBanner,
    z.object({}),
  ])
);

export const Route = createRootRouteWithContext<RouterContext>()({
  validateSearch,
  component: Root,
});

const Devtool = lazy(() =>
  import('../apps/devtool/Root').then((m) => ({ default: m.Root }))
);

function Root() {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  useEffect(() => {
    if ('access_denied' in search) {
      toast.custom(({ visible }) => (
        <Toast
          variant="error"
          title="Access Denied"
          className={visible ? 'animate-fade-in-down' : 'animate-fade-out-up'}
        >
          {search.access_denied}
        </Toast>
      ));

      void navigate({
        search: {
          access_denied: undefined,
        },
      });
    }
  }, [search]);
  return (
    <>
      <Outlet />
      <CookieBanner />
      {CONFIG.Environment !== 'production' && (
        <>
          <TanStackRouterDevtools />
          <Devtool />
        </>
      )}
    </>
  );
}
