import { createRouter } from '@tanstack/react-router';

import { NotFoundPage, SomethingWentWrong, Spinner } from './components';
import { queryClient } from './queryClient';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <Spinner />,
  defaultErrorComponent: ({ error }) => <SomethingWentWrong error={error} />,
  defaultNotFoundComponent: () => <NotFoundPage />,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  context: { auth: undefined!, queryClient },
});
