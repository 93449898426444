import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { useStripeSubscription, useSubscriptionMembers } from 'src/api/queries';
import { Spinner } from 'src/components';
import { FlexDivider } from 'src/components/FlexDivider';
import { TextWithLabel } from 'src/components/TextWithLabel';
import { isBillableSeat } from 'src/utils/isBillableSeat';
import { getCanceledSubscriptionInformation } from 'src/utils/isCanceledSubscription';
import { subscriptionTrialWillCancel } from 'src/utils/subscriptionTrialWillCancel';

import { formatDate } from '../../../../utils/formatDate';
import { useFormattedPaymentInfo } from '../utils/getFormattedPaymentInfo';

export const NextBillingDetails = () => {
  const subscriptions = useStripeSubscription();
  const members = useSubscriptionMembers();
  const cardDetails = useFormattedPaymentInfo(subscriptions.data);
  const billableSeats = useMemo(
    () => members.data?.filter((member) => isBillableSeat(member.seat)) ?? [],
    [members.data]
  );

  const canceledInfo = getCanceledSubscriptionInformation(subscriptions.data);

  if (subscriptions.isPending) {
    return <Spinner />;
  }
  if (subscriptions.isError || !subscriptions.data) {
    return (
      <Typography variant="body-md-regular">
        No billing details available
      </Typography>
    );
  }

  if (canceledInfo) {
    return (
      <Typography variant="body-md-regular">
        Your subscription has been cancelled and will expire on{' '}
        {formatDate(canceledInfo.cancellationAt)}
      </Typography>
    );
  }

  if (subscriptionTrialWillCancel(subscriptions.data)) {
    return (
      <Typography variant="body-md-regular">
        Your subscription ends{' '}
        {formatDate(
          subscriptions.data.paymentInformation?.nextInvoice?.dueDate
        )}
      </Typography>
    );
  }

  if (!cardDetails) {
    return (
      <Typography variant="body-md-regular">
        No billing details available
      </Typography>
    );
  }

  return (
    <div className="flex w-full flex-col flex-wrap gap-16 lm:flex-row">
      <TextWithLabel label="Date" className="flex-1">
        {cardDetails?.nextBilling}
      </TextWithLabel>

      <FlexDivider />

      <TextWithLabel label="Subscription" className="flex-1">
        {subscriptions.data?.managementType === 'seats'
          ? 'Connect for business'
          : 'Connect'}
      </TextWithLabel>

      <FlexDivider />

      {subscriptions.data?.managementType === 'seats' && (
        <>
          <TextWithLabel
            data-cy="seats-on-next-billing"
            label="Seats"
            className="flex-1"
          >
            {billableSeats.length}
          </TextWithLabel>

          <FlexDivider />
        </>
      )}

      <TextWithLabel label="Amount" className="flex-1">
        {cardDetails?.nextBillingAmount}
      </TextWithLabel>
    </div>
  );
};
