import { ArrowSquareOut } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Payment } from 'src/api/endpoints/storeApi.types';
import { Button, Tooltip } from 'src/components';

export const ReceiptLink = ({ payment }: { payment: Payment }) => {
  return (
    <Tooltip title="See receipt on Stripe">
      <Button
        as="a"
        to={payment.receiptUrl ?? ''} // We check for null in the parent component
        target="_blank"
        rel="noreferrer"
        variant="tertiary-neutral"
        size="medium"
        analytics={{
          text: 'arrow-square-out icon',
          location: ComponentLocations.MANAGE_CONNECT.MAIN,
          action: 'open receipt on stripe',
        }}
      >
        <ArrowSquareOut size={24} />
      </Button>
    </Tooltip>
  );
};
