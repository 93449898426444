import { useAuth0 } from '@auth0/auth0-react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { auth0Client } from 'src/auth0Client';
import { CONFIG } from 'src/config';

import { HTTPError } from '../createApiClient.types';
import { storeApi } from '../endpoints';
import { InvalidEmailException } from '../endpoints/storeApi.types';
import { getWebAuth } from '../webAuth';
import { queryKeys } from './queryKeys';

export const createQueryAccountInfo = () =>
  queryOptions({
    queryKey: queryKeys.settings.accountInfo,
    queryFn: storeApi.getAccountInfo,
    staleTime: 10000,
  });

export const useAccountInfo = () => useQuery(createQueryAccountInfo());
export const useAccountInfoSuspense = () =>
  useSuspenseQuery(createQueryAccountInfo());

export const useSAMLIdentity = () =>
  useQuery({
    ...createQueryAccountInfo(),
    select: (response) =>
      response.identities.find((i) => i.provider === 'samlp'),
  });

export const useRequestMultiFactorAuth = () =>
  useMutation({
    mutationFn: storeApi.enrollMFA,
  });

export const useResetMultiFactorAuth = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: storeApi.resetMFA,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys.settings.accountInfo,
      });
    },
  });
};

export const useUpdateAccountInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { firstName: string; lastName: string }) =>
      storeApi.updateAccountInfo(vars.firstName, vars.lastName),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.settings.accountInfo,
      });
    },
  });
};

export const useUnlinkAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { unlinkUserId: string; provider: string }) =>
      storeApi.unlinkAccount(vars.unlinkUserId, vars.provider),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.settings.accountInfo,
      });
    },
  });
};

export const useDeleteAccount = () =>
  useMutation({
    mutationFn: () => storeApi.deleteAccount(),
  });

export const useRequestEmailChange = () =>
  useMutation<
    unknown,
    HTTPError | InvalidEmailException,
    Parameters<typeof storeApi.requestEmailChange>[0]
  >({ mutationFn: (vars) => storeApi.requestEmailChange(vars) });

export const useEmailChangeStatus = () =>
  useQuery({
    queryKey: queryKeys.email.changeStatus,
    queryFn: storeApi.getEmailChangeStatus,
    staleTime: 10000,
    select: (r) => r.data,
  });

export const useCancelEmailChangeRequest = () =>
  useMutation({ mutationFn: storeApi.cancelEmailChangeRequest });

export const useConfirmEmailChange = () =>
  useMutation({ mutationFn: storeApi.confirmEmailChange });

export const useEmailVerified = () => {
  const auth = useAuth0();

  return useQuery({
    queryKey: queryKeys.email.verified,
    queryFn: () => storeApi.getEmailVerified(),
    staleTime: 1000,
    enabled: auth.user?.email_verified === false,
    refetchInterval: 5000,
    refetchOnWindowFocus: 'always',
  });
};

// Rip Auth0-js types...
export const useChangePassword = () => {
  if (!CONFIG.Auth0Domain || !CONFIG.Auth0ClientID) {
    throw new Error('useChangePassword | Auth0 domain or client ID is missing');
  }

  return useMutation({
    mutationFn: async () => {
      const auth0 = await auth0Client;
      const user = auth0.user;

      const webAuth = getWebAuth();

      if (typeof user?.email !== 'string') {
        throw new Error('useChangePassword | User is not logged in');
      }

      return await new Promise((resolve, reject) =>
        webAuth.changePassword(
          {
            connection: 'Username-Password-Authentication',
            email: user.email ?? '',
          },
          (response) => {
            if (response?.error) {
              reject(response);
            } else {
              resolve(response);
            }
          }
        )
      );
    },
  });
};
