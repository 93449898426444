import { Typography } from '@remarkable/ark-web';

import { Button, FlexDivider } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const NoSubscriptionNotPairedDeviceCard = () => (
  <div data-cy="subscription-no-plan-no-paired-device">
    <div className="my-24 flex flex-col gap-32 lm:flex-row">
      <div className="flex flex-1 flex-col justify-between gap-24">
        <Typography variant="body-sm-regular" className="my-0">
          If your reMarkable was purchased{' '}
          <Typography variant="body-sm-bold" as="strong">
            after November 7th, 2022
          </Typography>
          , simply pair your device and follow the steps to activate your
          Connect trial.
        </Typography>

        <Button
          data-cy="pair-device-button"
          as="a"
          to={URLS.DEVICE_REMARKABLE_CONNECT}
          className="cursor-pointer"
        >
          Pair your device
        </Button>
      </div>

      <FlexDivider />

      <div className="flex flex-1 flex-col justify-between gap-24">
        <Typography variant="body-sm-regular" className="my-0">
          If you want to subscribe to Connect without a trial, you can do so
          here:
        </Typography>

        <Button
          as="a"
          to={URLS.STORE_CONNECT_LANDING}
          variant="primary"
          data-cy="buy-connect"
          className="cursor-pointer"
        >
          Buy Connect
        </Button>
      </div>
    </div>
  </div>
);
