import { Typography } from '@remarkable/ark-web';
import { FolderOpen } from 'phosphor-react';

interface DropInfoProps {
  importFiles: () => void;
}

export const DropInfo = ({ importFiles }: DropInfoProps) => {
  return (
    <button
      data-cy="drop-info"
      className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-16 p-32 text-left  transition-all hover:bg-neutral-light-3/50 md:flex-row"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23767676' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e")`,
      }}
      onClick={importFiles}
    >
      <div className="flex flex-col items-center gap-8 lm:flex-row lm:items-start">
        <FolderOpen size={32} className="mt-4 text-text-muted" />
        <div className="w-4/5 lm:w-auto">
          <Typography variant="body-2xl-regular" className="hidden md:block">
            Drag and drop or <strong>browse your files</strong>
          </Typography>

          <Typography
            variant="body-sm-regular"
            className="text-center md:text-left"
          >
            Supports PDF, JPG, PNG, and EPUB files up to 100 MB.
          </Typography>
        </div>
      </div>
      <span className="w-full sm:w-auto md:hidden">Browse files</span>
    </button>
  );
};
