export const RemarkableLogo = ({
  color,
  className = '',
}: {
  color: 'white' | 'black';
  className?: string;
}) => {
  const svgUrl =
    color === 'white'
      ? 'https://cdn.sanity.io/images/xpujt61d/production/6ddd583eba2a9498876035d9a18d7dc039b7d8f1-145x20.svg'
      : 'https://cdn.sanity.io/images/xpujt61d/production/127edfaec4f0a2fd14fb229ef0aa148a9049ed89-145x20.svg';

  return (
    <img
      src={svgUrl}
      alt="Remarkable Logo"
      width={145}
      height={20}
      className={className}
    />
  );
};
