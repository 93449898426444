import { HTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import {
  useCheckoutAllowedSuspense,
  useDevicesSuspense,
} from 'src/api/queries';
import { Link, Sheet } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

import { ConnectOfferInfo } from './ConnectOfferInfo';
import { NoSubscriptionNotPairedDeviceCard } from './NoSubscriptionNotPairedDeviceCard';
import { NoSubscriptionPairedDeviceCard } from './NoSubscriptionPairedDeviceCard';

export const NoSubscriptionCard = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Sheet {...props}>
      <NoSubscriptionDetails />
    </Sheet>
  );
};

export const NoSubscriptionDetails = () => {
  const devices = useDevicesSuspense();
  const checkoutAllowed = useCheckoutAllowedSuspense();
  const retailOfferAllowedStatus = checkoutAllowed.data?.retailOffer;
  const connectOfferAllowedStatus = checkoutAllowed.data?.connectOffer;
  const subscriptionOfferAllowedStatus =
    checkoutAllowed.data?.subscriptionOffer;
  const dmOfferAllowedStatus = checkoutAllowed.data?.dmOffer;

  const hasDevices = !!devices.data?.length;

  if (retailOfferAllowedStatus?.allowed) {
    return <ConnectOfferInfo offerType="retailOffer" />;
  }

  if (subscriptionOfferAllowedStatus?.allowed) {
    return <ConnectOfferInfo offerType="subscriptionOffer" />;
  }

  if (connectOfferAllowedStatus?.allowed) {
    return <ConnectOfferInfo offerType="connectOffer" />;
  }

  if (dmOfferAllowedStatus?.allowed) {
    return <ConnectOfferInfo offerType="dmOffer" />;
  }

  return (
    <>
      <Typography as="h2" variant="heading-subtle-2xl">
        You have no active subscription
      </Typography>

      <Typography as="p" variant="body-md-bold" className="mt-16">
        Connect gives you access to seamless note-taking with unlimited cloud
        storage and sync.{' '}
        <Link inline to={URLS.STORE_CONNECT_LANDING}>
          Read more
        </Link>
      </Typography>

      {hasDevices ? (
        <NoSubscriptionPairedDeviceCard />
      ) : (
        <NoSubscriptionNotPairedDeviceCard />
      )}
    </>
  );
};
