import { AnalyticsCoupons } from 'src/analytics';
import { Subscription } from 'src/api/endpoints/storeApi.types';
import { subscriptionGetPaymentInterval } from 'src/utils/subscriptionGetPaymentInterval';
import { subscriptionGetStatus } from 'src/utils/subscriptionGetStatus';
import { subscriptionIsConnectForBusiness } from 'src/utils/subscriptionIsConnectForBusiness';
import { subscriptionIsFreeConnect } from 'src/utils/subscriptionisFreeConnect';

import { UserPropertiesSubscription } from './trackingTypes';

export const checkoutTypeToCoupon = (type?: string) => {
  if (!type) {
    return AnalyticsCoupons.UNKNOWN;
  }
  switch (type) {
    case 'retailOffer':
      return AnalyticsCoupons.RETAIL_OFFER;
    case 'subscriptionOffer':
      return AnalyticsCoupons.SUBSCRIPTION_OFFER;
    case 'store':
      return AnalyticsCoupons.NONE;
    default:
      return AnalyticsCoupons.UNKNOWN;
  }
};

export const MONTHLY_CONNECT_PRICE_IN_USD_FOR_TRACKING = 2.99;

export const getSubscriptionForTracking = (
  subscription?: Subscription
): Omit<UserPropertiesSubscription, 'subscription_role'> => {
  if (!subscription) {
    return {
      subscription_type: 'none',
      subscription_status: 'none',
      subscription_payment_interval: 'none',
    };
  }

  if (subscriptionIsFreeConnect(subscription)) {
    return {
      subscription_type: 'free connect',
      subscription_status: 'active',
      subscription_payment_interval: 'none',
    };
  }

  const paymentInterval = subscriptionGetPaymentInterval(subscription);
  const isConnectForBusiness = subscriptionIsConnectForBusiness(subscription);
  const subscriptionStatus = subscriptionGetStatus(subscription);

  if (!isConnectForBusiness) {
    return {
      subscription_type: 'connect',
      subscription_status: subscriptionStatus || 'none',
      subscription_payment_interval: paymentInterval || 'none',
    };
  }

  if (subscriptionIsConnectForBusiness(subscription)) {
    return {
      subscription_type: 'connect for business',
      subscription_status: subscriptionStatus || 'none',
      subscription_payment_interval: paymentInterval || 'none',
    };
  }

  return {
    subscription_type: 'none',
    subscription_status: 'none',
    subscription_payment_interval: 'none',
  };
};
