import { Component } from 'react';

import { XCircle } from 'phosphor-react';
import PropTypes from 'prop-types';

import './Modal2.scss';

const KEY_CODE_ESCAPE = 27;

/** @deprecated use `Modal` instead */
export class Modal2 extends Component {
  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    if (document && this.props.onClose) {
      document.addEventListener('keydown', this.onKeyDown, false);
    }
  }

  componentWillUnmount() {
    if (document && this.props.onClose) {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }
  }

  onKeyDown(e) {
    if (e.keyCode === KEY_CODE_ESCAPE && this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { children, className, onClose } = this.props;
    return (
      <div
        data-cy={this.props.dataCy || 'modal'}
        className="modal2--overlay"
        onClick={onClose}
      >
        <div
          className={`modal2--box ${className}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div role="dialog" aria-modal={true} className="modal2--content">
            {onClose && (
              <XCircle
                className="modal2--close"
                onClick={onClose}
                weight="fill"
                data-cy="close-modal"
              />
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Modal2.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  dataCy: PropTypes.string,
};
