import { Subscription } from 'src/api/endpoints/storeApi.types';

import { isStripeSubscription } from './isStripeSubscription';

export const subscriptionHasNoCard = (subscription?: Subscription | null) => {
  if (isStripeSubscription(subscription)) {
    return (
      !(subscription.paymentInformation?.payment?.type === 'card') ||
      !subscription.paymentInformation?.payment?.details
    );
  }
  return false;
};
