import { FAQ, FAQLine } from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

export const PaymentHistoryFAQ = () => {
  return (
    <FAQ>
      <FAQLine
        text="What different payment options do I have?"
        target={SUPPORT_URL.ARTICLES.PAYMENT_OPTIONS_FOR_CONNECT}
      />

      <FAQLine
        text="Why was my payment method rejected?"
        target={SUPPORT_URL.ARTICLES.REJECTED_PAYMENT}
      />

      <FAQLine
        text="When and how often will I be charged?"
        target={SUPPORT_URL.ARTICLES.CONNECT_BILLING_DATE}
      />

      <FAQLine
        text="Why was my billing different than I expected?"
        target={SUPPORT_URL.ARTICLES.UNEXPECTED_SUBSCRIPTION_CHARGES}
      />
    </FAQ>
  );
};
