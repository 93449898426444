import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { cloudApi } from '../endpoints';
import { IntegrationToSave } from '../endpoints/cloudApi.types';
import { queryKeys } from './queryKeys';

export const useIntegrationProviders = () =>
  useQuery({
    queryKey: queryKeys.integrations.providers,
    queryFn: () => cloudApi.getProviders(),
    staleTime: Infinity,
  });

export const useIntegrations = () =>
  useQuery({
    queryKey: queryKeys.integrations.all,
    queryFn: () => cloudApi.getIntegrations(),
    staleTime: Infinity,
  });

export const useGetProviderAuthURI = () =>
  useMutation({
    mutationFn: (providerPath: string) =>
      cloudApi.getProviderAuthURI(providerPath),
  });

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (integrationId: string) =>
      cloudApi.deleteIntegration(integrationId),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.integrations.all,
      });
    },
  });
};

export const useSaveIntegration = () => {
  const providers = useIntegrationProviders();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      provider,
      ...vars
    }: {
      integration: IntegrationToSave;
      provider: string;
    }) => {
      const providerPath = providers.data?.find(
        (p) => p.typeName === provider
      )?.subPath;

      if (!providerPath) {
        throw new Error(`Provider path not found for "${provider}"`);
      }

      return cloudApi.saveIntegration({ ...vars, providerPath });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.integrations.all,
      });
    },
  });
};

export const useUpdateIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { id: string; name: string }) =>
      cloudApi.updateIntegration(vars.id, vars.name),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.integrations.all,
      });
    },
  });
};

export const useIntegrationTermsOfService = () => {
  const queryClient = useQueryClient();
  const version = 1; // current version of the terms of service

  const status = useQuery({
    queryKey: queryKeys.integrations.termsOfServiceStatus,
    queryFn: () => cloudApi.getIntegrationTermsOfServiceStatus(),
  });

  const accept = useMutation({
    mutationFn: () => {
      return cloudApi.acceptIntegrationTermsOfService({ version });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.integrations.termsOfServiceStatus,
      });
    },
  });

  return {
    status,
    hasAccepted: status.data?.version === version,
    version,
    accept,
  };
};
