import { auth0Client } from 'src/auth0Client';
import { CONFIG } from 'src/config';

import { BeforeRequestHook } from './createApiClient.types';

export const setTectonicDomain: BeforeRequestHook = async (requestConfig) => {
  const auth0 = await auth0Client;
  const claims = await auth0.getIdTokenClaims();
  const tectonic = claims?.[CONFIG.TectonicTokenKey] as string;

  if (tectonic && typeof tectonic === 'string' && tectonic != '') {
    return {
      ...requestConfig,
      prefixUrl: `https://web.${tectonic}.tectonic.remarkable.com`,
    };
  }

  return requestConfig;
};
