import { HTMLAttributes, ReactNode } from 'react';

import { CheckCircle, Info, Warning, X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { cva, VariantProps } from 'class-variance-authority';

import { createContentSwitch } from 'src/utils/createContentSwitch';

import { Button } from './Button';

const toastStyles = cva('max-w-320 rounded p-16 text-left shadow-sheet', {
  variants: {
    variant: {
      success: 'bg-green-light-4',
      error: 'bg-feedback-red-100',
      warning: 'bg-brand-orange-200',
      info: 'bg-pen-blue-light',
    },
  },
});
type ToastProps = VariantProps<typeof toastStyles> &
  HTMLAttributes<HTMLDivElement> & { title?: ReactNode; onClose?: () => void };

export function Toast({
  children,
  title,
  className,
  onClose,
  ...props
}: ToastProps) {
  const variantSwitch = createContentSwitch(props.variant);
  return (
    <div className={toastStyles({ ...props, className })} {...props}>
      <div className="flex w-full items-center gap-8">
        {variantSwitch({
          info: <Info size={24} />,
          success: <CheckCircle size={24} />,
          error: <Warning size={24} />,
          warning: <Warning size={24} />,
          default: null,
        })}
        <Typography variant="body-md-bold">{title}</Typography>

        {onClose && (
          <Button
            variant="tertiary-neutral"
            className="ml-auto"
            size="small"
            onClick={onClose}
          >
            <X />
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}
