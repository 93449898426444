import { useRouter } from '@tanstack/react-router';

import { Integration } from 'src/api/endpoints/cloudApi.types';
import {
  useGetProviderAuthURI,
  useIntegrationProviders,
} from 'src/api/queries';
import { Button } from 'src/components';

export const ReAuthorizeIntegrationButton = ({
  integration,
  providerAuthUri,
  dataCy,
}: {
  integration: Integration;
  providerAuthUri: ReturnType<typeof useGetProviderAuthURI>;
  dataCy: string;
}) => {
  const router = useRouter();
  const providers = useIntegrationProviders();

  if (providers.isPending) return null;

  const provider = providers.data?.find(
    (provider) => provider.typeName === integration.provider
  );

  if (!provider) return null;

  const onSubmit = () => {
    providerAuthUri.mutate(provider.subPath, {
      onSuccess: (authUrl) => {
        // External url to integration provider auth
        router.history.push(authUrl);
      },
    });
  };

  return (
    <Button
      loading={providerAuthUri.isPending}
      onClick={onSubmit}
      data-cy={dataCy}
      variant="secondary"
      size="small"
    >
      Renew access
    </Button>
  );
};
