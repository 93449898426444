import { PrimitiveAtom, atom, useAtom } from 'jotai';

export type ModalIds =
  | 'RemoveUserFromSubscription'
  | 'CancelSubscription'
  | 'AbortCancelSubscription'
  | 'SaveIntegration'
  | 'LeaveSubscription'
  | 'Invite'
  | 'DisableMfa'
  | 'CancelInvitation'
  | 'UnlinkSocialAccount'
  | 'RemoveIntegration';

interface ModalState<T = undefined> {
  id: ModalIds;
  data: T;
}

interface UseModalOptions<T> {
  onClose?: (data: T) => void;
  onOpen?: (data: T) => void;
}

// Witchcraft needed for never check
type ExtendsNever<T> = [T] extends [never] ? true : false;

const modalAtom = atom<ModalState<unknown> | null>(null);

export const useModal = <TData>(
  modalId: ModalIds,
  options?: UseModalOptions<TData>
): /**
 * Return type is specified to fix Typescript not able to infer that
 * data will be defined when isOpen is 'true' which then forces the user
 * to check both isOpen and data to be defined when using the hook.
 */
| {
      isOpen: true;
      data: TData;
      open: ExtendsNever<TData> extends false
        ? (data: TData) => void
        : () => void;
      close: () => void;
    }
  | {
      isOpen: false;
      data: undefined;
      open: ExtendsNever<TData> extends false
        ? (data: TData) => void
        : () => void;
      close: () => void;
    } => {
  const [state, setState] = useAtom(
    modalAtom as PrimitiveAtom<ModalState<TData> | null>
  );

  const open = (data?: TData) => {
    options?.onOpen?.(data as TData);

    setState({ id: modalId, data: data as TData });
  };

  const close = () => {
    options?.onClose?.(state?.data as TData); // The state dictates that data will be defined when isOpen is true
    setState(null);
  };

  if (!state || state.id !== modalId) {
    return {
      isOpen: false,
      data: undefined,
      open,
      close,
    };
  }

  return {
    isOpen: true,
    data: state.data,
    open,
    close,
  };
};

export const createModalHook = <TData = never>(modalId: ModalIds) => {
  return (options?: UseModalOptions<TData>) => {
    return useModal<TData>(modalId, options);
  };
};
