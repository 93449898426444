import { createFileRoute, Outlet } from '@tanstack/react-router';
import { z } from 'zod';

import { createQueryHasEverPairedDevice } from 'src/api/queries';
import { NavTab } from 'src/components';
import { AppPage } from 'src/components/AppPage';

const searchSchema = z.object({ showOtp: z.boolean().optional().catch(false) });

export const Route = createFileRoute('/_auth/_layout/device')({
  component: () => <DeviceApp />,
  validateSearch: searchSchema,
  async loader({ context }) {
    return context.queryClient.ensureQueryData(
      createQueryHasEverPairedDevice()
    );
  },
});

export const DeviceApp = () => {
  const titleElement = document.getElementsByTagName('title')[0];
  if (titleElement) {
    titleElement.innerHTML = 'reMarkable &bull; Device and Apps';
  } else {
    document.title = 'reMarkable - Device and Apps';
  }

  return (
    <AppPage.Container>
      <AppPage.Header
        title="Devices and apps"
        subtitle="Pair a new device, manage our apps, or get Read on reMarkable for
        Microsoft Office and Google Chrome."
      />
      <AppPage.NavBar>
        <NavTab data-cy="device-link-Tablet" to="/device/remarkable">
          tablet
        </NavTab>
        <NavTab data-cy="device-link-Desktopapp" to="/device/desktop">
          desktop app
        </NavTab>
        <NavTab data-cy="device-link-Mobileapp" to="/device/mobile">
          mobile app
        </NavTab>
        <NavTab data-cy="device-link-ReadOnReMarkable" to="/device/browser">
          read on remarkable
        </NavTab>
      </AppPage.NavBar>
      <AppPage.Content className="max-w-lxl gap-24">
        <Outlet />
      </AppPage.Content>
    </AppPage.Container>
  );
};
