import { Typography } from '@remarkable/ark-web';
import { CheckCircle } from 'phosphor-react';

import { Sheet } from 'src/components';
import { RemarkableLogo } from 'src/components/RemarkableLogo';

export function EmailVerified() {
  return (
    <div className="flex h-[100vh] w-[100vw] items-center justify-center px-16 py-64">
      <Sheet className="w-full max-w-ls justify-center gap-32  text-center">
        <RemarkableLogo color="black" className="mx-auto mt-64 h-20" />

        <CheckCircle
          size={70}
          className="mx-auto text-feedback-green-500"
          weight="thin"
        />

        <Typography variant="heading-lg" className="text-center">
          Email verified
        </Typography>

        <Typography variant="body-md-regular" className="mb-64 text-center">
          Return to the previous tab to continue.
        </Typography>
      </Sheet>
    </div>
  );
}
