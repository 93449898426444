import { HTMLAttributes } from 'react';

import { cn } from 'src/utils/classNamesHelper';

export const Sheet = ({
  className = '',
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col gap-16 rounded-2 bg-neutral-light-1 p-24 shadow-sheet ls:p-32',
      className
    )}
    {...rest}
  >
    {children}
  </div>
);
