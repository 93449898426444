import { ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';

interface FAQProps {
  title?: string;
  dataCy?: string;
  children: ReactNode;
}

export const FAQ = ({
  children,
  title = 'Frequently asked questions',
  dataCy = 'faq-card',
}: FAQProps) => {
  return (
    <div data-cy={dataCy} className="mt-96">
      <Typography variant="heading-xs" as="h2" className="mb-32 text-center">
        {title}
      </Typography>
      <div className="border-t-2">
        <ul className="m-0 mb-32 list-none p-0">{children}</ul>
      </div>
    </div>
  );
};
