import { useMutation } from '@tanstack/react-query';

import { copyToClipboard } from './copyToClipboard';

export const useCopyToClipboard = () =>
  useMutation({
    mutationFn: (text?: string) => {
      if (!text) throw new Error('No text provided');

      return copyToClipboard(text);
    },
  });
