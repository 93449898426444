import { ReactNode } from 'react';

import { Link, TextBody } from 'src/components';
import { formatAmount } from 'src/utils/productUtils';

export interface FAQBlock {
  title: string;
  id: string;
  body: ReactNode;
}

type FAQConfig = {
  [group: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [name: string]: (...args: any[]) => FAQBlock;
  };
};

export const FAQ = {
  account: {
    whatIsMFA: () => ({
      title: 'What is multi-factor authentication?',
      id: 'account.what-is-mfa',
      body: (
        <TextBody>
          Multi-factor authentication (MFA) is an authentication method that
          requires you to provide two or more verification factors to log in to
          My reMarkable. This reduces the risk of someone being able to hack
          your account by getting hold of your password.
        </TextBody>
      ),
    }),
    whatHappensIfIEnableMFA: () => ({
      title: 'What happens if I enable MFA?',
      id: 'account.what-is-happens-when-mfa-is-enabled',
      body: (
        <TextBody>
          When you log in, you’ll have to provide a security code from an
          authenticator app — like Authy or Google Authenticator — as well as
          your password. This helps us verify that it’s really you trying to log
          in.
        </TextBody>
      ),
    }),
  },
  connectForBusiness: {
    canOtherUsersGetAccessToMyDocuments: () => ({
      title: 'Can other users get access to my documents?',
      id: 'connectForBusiness.can-other-users-get-access-to-my-documents',
      body: (
        <TextBody>
          No, only you can access your notes and documents. If you&apos;d like
          to share a document, you can send it by email.
        </TextBody>
      ),
    }),
    invitedToConnectForBusinessWillPriceChange: () => ({
      title:
        "I've been invited to Connect for business. Will the price of my subscription change?",
      id: 'connectForBusiness.invited-to-connect-for-business-will-price-change',
      body: (
        <TextBody>
          When you join Connect for business, your subscription fees will be
          covered by your company, and you won&apos;t need to make any payments.
        </TextBody>
      ),
    }),
    featuresAndBenefits: () => ({
      title: 'Which features and benefits are included?',
      id: 'connectForBusiness.features-and-benefits',
      body: (
        <TextBody>
          With Connect for business, you&apos;ll get access to unlimited cloud
          storage, note-taking in the mobile and desktop apps, exclusive offers,
          and the reMarkable Protection Plan.
        </TextBody>
      ),
    }),
    whatHappensToMyFilesIfIJoin: () => ({
      title: 'What happens to my files if I join?',
      id: 'connectForBusiness.what-happens-to-my-files-if-i-join',
      body: (
        <TextBody>
          Nothing will happen to your notes and files. All your documents will
          remain private, and you&apos;ll still have access to any files that
          have been synced to the cloud.
        </TextBody>
      ),
    }),
    whatHappensToTeamMembersFiles: () => ({
      title:
        'When new team members join my subscription, do they keep their files?',
      id: 'connectForBusiness.what-happens-to-team-members-files',
      body: (
        <TextBody>
          Yes. When a member joins your subscription, no changes are made to
          their files.
        </TextBody>
      ),
    }),
    canIAccessTeamMembersFiles: () => ({
      title:
        'When I invite new team members to my subscription, can I access their files?',
      id: 'connectForBusiness.can-i-access-team-members-files',
      body: (
        <TextBody>
          No, notes or documents are not shared when new members join your
          subscription. All files remain private. If a member leaves your
          subscription, they&apos;ll keep access to their files.
        </TextBody>
      ),
    }),
    alreadyHaveConnect: () => ({
      title: 'I already have Connect. What happens to my current subscription?',
      id: 'connectForBusiness.already-have-connect',
      body: (
        <TextBody>
          If you accept the invitation, your company will pay for your
          subscription. You&apos;ll still have access to all benefits and
          features that come with a regular Connect subscription.
        </TextBody>
      ),
    }),
    alreadyHaveFreeAccess: () => ({
      id: 'i-have-free-access-to-connect-what-happens-if-i-subscribe-to-connect-for-business',
      title:
        'I have free access to Connect. What happens if I subscribe to Connect for business?',
      body: (
        <span>
          By subscribing to Connect for business, you&apos;ll no longer have
          free access to Connect. To keep free access, use a different account
          for your business subscription.
        </span>
      ),
    }),
    whatHappensWithTrial: () => ({
      title: 'What happens if I have a free trial?',
      id: 'connectForBusiness.what-happens-with-trial',
      body: (
        <TextBody>
          You&apos;ll keep your free trial if you join Connect for business.
          Once your trial ends, your employer will pay for your subscription. If
          you leave Connect for business, your free trial can&apos;t be resumed.
        </TextBody>
      ),
    }),
    whyDoYouNeedMyCompanyDetails: () => ({
      title: 'Why do you need my company details?',
      id: 'why-do-you-need-my-company-details',
      body: (
        <span>
          We need your company details to ensure accurate billing and to provide
          sales documentation.
        </span>
      ),
    }),
    howDoesBillingWork: (price?: { amount?: number; currency?: string }) => ({
      id: 'how-does-billing-work',
      title: 'How does billing work?',
      body: (
        <span>
          The subscription price is linked to your team&apos;s size and updates
          automatically when you add or remove members. New team members can be
          added for{' '}
          {price?.currency && price?.amount
            ? formatAmount(price.amount, price.currency)
            : '[N/A]'}
          /mo. each, and are free until the next billing period. You won&apos;t
          be charged for members who have a free Connect trial. We offer payment
          by card.
        </span>
      ),
    }),
    whenWillIBeCharged: () => ({
      id: 'when-will-i-be-charged',
      title: 'When will I be charged?',
      body: (
        <span>
          You&apos;ll be charged on the same day each month as when you signed
          up for Connect for business. You can view your billing date in Payment
          history. You won&apos;t be charged for members who have a free Connect
          trial.
        </span>
      ),
    }),
    whatHappensIfIRemoveAMember: () => ({
      id: 'what-happend-if-i-remove-a-member',
      title: 'What happens if I remove a member from my subscription?',
      body: (
        <span>
          Removed members keep access to their files, but lose access to all
          subscription features and benefits. On the next billing date, you
          won&apos;t be charged for members who have been removed from your
          subscription. Members who had a free trial before they joined Connect
          for business won&apos;t be able to resume their trial if they&apos;re
          removed from your subscription.
        </span>
      ),
    }),
    doYouOfferDeviceManagement: () => ({
      id: 'do-you-offer-device-management',
      title: 'Do you offer device management?',
      body: (
        <span>
          Device management isn&apos;t currently available, but this is
          something we&apos;re working on.{' '}
        </span>
      ),
    }),
    whatPaymentOptionsDoYouOffer: () => ({
      title: 'What payment options do you offer?',
      id: 'what-payment-options-do-you-offer',
      body: (
        <span>
          You can pay by credit or debit card.
          <br />
          Under <b>Payment</b>, you can change cards and view your payment
          history.
        </span>
      ),
    }),
    whatDoesActiveSeatMean: () => ({
      id: 'what-does-active-seat-mean',
      title: 'What does “active seat” mean?',
      body: (
        <TextBody>
          The number of active seats shows how many users have access to
          subscription features.
        </TextBody>
      ),
    }),
    whyWasMyPaymentDeclined: () => ({
      id: 'why-was-my-payment-declined',
      title: 'Why was my payment declined?',
      body: (
        <TextBody>
          There are several reasons why a payment may be declined. Usually, it
          happens because the payment card lacked sufficient funds, or because
          the card has expired.
        </TextBody>
      ),
    }),
    howOftenWillIBeChargedForConnectForBusiness: () => ({
      id: 'how-often-will-i-be-charged-for-connect-for-business',
      title: 'How often will I be charged for Connect for business?',
      body: (
        <TextBody>
          You&apos;ll be charged monthly. Your billing date is the same date as
          when you subscribed to for Connect for business.
        </TextBody>
      ),
    }),
    whyWasMyBillingDifferentThanExpected: () => ({
      id: 'why-was-my-billing-different-than-expected',
      title: 'Why was my billing different than I expected?',
      body: (
        <TextBody>
          The subscription price is linked to your team&apos;s size and updates
          automatically when you add or remove members. If you have unexpected
          charges, check if you&apos;ve added new members to your subscription.
        </TextBody>
      ),
    }),
    willThePriceChangeWhenTheBetaPeriodEnds: () => ({
      id: 'will-the-price-change-when-the-beta-period-ends',
      title: 'Will the price change when the beta period ends?',
      body: (
        <TextBody>
          The price and the terms of use may change after the beta period. You
          can cancel your subscription at any time.
        </TextBody>
      ),
    }),
    asAnAdminIDontNeedAccessToSubscriptionFeatures: () => ({
      id: 'as-an-admin-i-dont-need-access-to-subscription-features-can-i-remove-these',
      title:
        "As an admin, I don't need access to subscription features. Can I remove these?",
      body: (
        <TextBody>
          Yes, you can remove subscription features from your seat. In the
          <b>Members</b> tab, click <b>Deactivate</b> next to your email
          address. You&apos;ll no longer have access to unlimited cloud storage,
          mobile and desktop note-taking, the reMarkable Protection Plan, or
          exclusive offers. If you had a free trial before joining Connect for
          business, it will end if you remove your subscription features.
        </TextBody>
      ),
    }),
    myPaperTabletIsPairedWithPersonalAccount: () => ({
      id: 'my-paper-tablet-is-paired-with-personal-account',
      title:
        'My paper tablet is paired with a personal account. How do I join Connect for business without losing my documents?',
      body: (
        <TextBody>
          You don&apos;t need to re-pair your device with a new account to join
          a business subscription, just change the email address associated with
          your existing account. On{' '}
          <Link inline to="https://my.remarkable.com">
            my.remarkable.com
          </Link>
          , click <b>Account</b> in the menu in the upper-left corner. Under
          Contact details, change your email to the one invited to Connect for
          business.
        </TextBody>
      ),
    }),
    whatHappensWith1YearFreeTrial: () => ({
      id: 'what happens-if-i-have-a-free-connect-trial',
      title: 'What happens if I have a free trial? ',
      body: (
        <TextBody>
          You&apos;ll keep your free trial if you join Connect for business. If
          you&apos;re eligible for a free trial, it will be started when you
          join. Once a trial ends, the employer will pay for the subscription.
          If a member leaves Connect for business, their free trial can&apos;t
          be resumed.
        </TextBody>
      ),
    }),
    canIManageASubscriptionWithoutPayingForConnectForMyself: () => ({
      id: 'can-i-manage-a-subscription-without-paying-for-connect-for-myself',
      title:
        'Can I manage a subscription without paying for Connect for myself?',
      body: (
        <TextBody>
          Yes. If you don&apos;t need access to subscription features, you can
          choose to deactivate these. To remove subscription features from your
          seat, click the <b>Members</b> tab, then <b>Deactivate</b> next to
          your email address. You&apos;ll then no longer have access to
          unlimited cloud storage, mobile and desktop note-taking, the
          reMarkable Protection Plan, or exclusive offers.
        </TextBody>
      ),
    }),
  },
  enterprise: {
    whatHappensToMyFilesIfIActivateMyEnterpriseAccount: () => ({
      id: 'what-happens-to-my-files-if-i-activate-my-enterprise-account',
      title: 'What happens to my files if I activate my enterprise account?',
      body: (
        <TextBody>
          No changes are made to the content of your files. When you start using
          your enterprise account, control will be transferred to your employer.
          Your employer will then have administrative control of your account,
          and can delete it, which will erase all its files. Your employer
          won&apos;t be able to see or read your files.
        </TextBody>
      ),
    }),
    canAnyoneElseGetAccessToMyDocuments: () => ({
      id: 'can-anyone-else-get-access-to-my-documents',
      title: 'Can anyone else get access to my documents?',
      body: (
        <TextBody>
          Even though control of the account is transferred to your employer,
          others will not get access to your documents. However, your employer
          will have administrative control of your account, and can delete it,
          which will erase all its files.
        </TextBody>
      ),
    }),

    whatHappensToTheTermsAndConditionsForRemarkableAccounts: () => ({
      id: 'what-happens-to-the-terms-and-conditions-for-remarkable-accounts',
      title:
        "What happens to the Terms and Conditions for reMarkable Accounts that I've agreed to?",
      body: (
        <TextBody>
          If you activate an enterprise account, the terms you&apos;ve agreed to
          will end. The contractual relationship will then be between reMarkable
          and your employer.
        </TextBody>
      ),
    }),
    whatHappensIfIHaveAFreeConnectTrial: () => ({
      id: 'what-happens-if-i-have-a-free-connect-trial',
      title: 'What happens if I have a free Connect trial?',
      body: (
        <TextBody>
          You&apos;ll keep your free trial. Once your trial ends, your employer
          will pay for your subscription.
        </TextBody>
      ),
    }),
    iHaveFreeAccessToConnect: () => ({
      id: 'i-have-free-access-to-connect',
      title:
        'I have free access to Connect. What happens if I join Connect for business?',
      body: (
        <TextBody>
          You&apos;ll still get Connect for free, since the subscription will be
          paid by your employer. If you leave your employer&apos;s subscription,
          you&apos;ll lose free access to Connect.
        </TextBody>
      ),
    }),
    whatDoesItMeanForMyEmployerToHaveAdministrativeControlOverMyFiles: () => ({
      id: 'what-does-it-mean-for-my-employer-to-have-administrative-control-over-my-files',
      title:
        'What does it mean for my employer to have "administrative control" over my files?',
      body: (
        <TextBody>
          If you activate your enterprise account, you can still organize, edit,
          and move files as you like. You can even create a private account and
          transfer files there.
          <br />
          Since your employer owns the Connect for business account, they can
          request deletion of your content and account. However, this
          doesn&apos;t mean they can see or read your files.
          <br />
          In terms of compliance with privacy and data protection laws, and to
          the extent the General Data Protection Regulation 2016/679 (GDPR)
          applies, this means that your employer will become a controller of
          processed personal data necessary to provide Connect for business, and
          reMarkable will act as a processor. In other words, your employer will
          be able to determine the purposes and means of the personal data
          processing. To the extent the California Consumer Privacy Act at Cal.
          Civ. Code § 1798.100 et seq. and its implementing regulations (CCPA)
          apply, your employer will act as the business and reMarkable as the
          service provider. Terms such as “process/processing,” “controller,”
          “processor,” “business,” “service provider,” and “personal data” have
          the same meaning depending on what law applies.
        </TextBody>
      ),
    }),
  },
} as const satisfies FAQConfig;
