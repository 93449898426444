import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight, Buildings, User } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { AccountLinkingStarted } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useLoginToLinkableAccount } from 'src/api/queries/user';
import {
  Button,
  Divider,
  FaqSection,
  Feedback,
  NotificationBox,
  Sheet,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { FAQ } from 'src/constants/FAQ';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

export function AccountMigrateConfirm() {
  const loginToLinkableAccount = useLoginToLinkableAccount();
  const { user } = useAuth0();

  return (
    <AppPage.Container>
      <AppPage.Content className="max-w-lxl">
        <div className="flex flex-col gap-64 py-16 lm:py-32 ll:py-64">
          <Sheet className="items-center gap-24 ll:py-64">
            <div className="flex max-w-lm flex-col gap-24 text-center lm:items-center ll:gap-32">
              <div className="flex items-center gap-16">
                <div className="rounded-full bg-neutral-light-5 p-8">
                  <User weight="light" className="text-24 lm:text-32" />
                </div>

                <ArrowRight size={24} />

                <div className="rounded-full bg-neutral-light-5 p-8">
                  <Buildings weight="light" className="text-24 lm:text-32" />
                </div>
              </div>

              <Typography
                as="h1"
                variant="responsive-heading-md"
                className="text-left lm:text-center"
              >
                Start using your enterprise account
              </Typography>

              <Typography
                variant="body-md-regular"
                className="text-left lm:text-center"
              >
                Your account will be managed by your employer. Your employer
                won&apos;t be able to see or read your files.
              </Typography>

              <Divider className="w-full" />

              <div className="text-left">
                <Typography variant="body-sm-regular">
                  By confirming, you agree that:
                </Typography>

                <ul className="list-disc pl-16">
                  <li>
                    <Typography variant="body-sm-regular">
                      Control of your reMarkable account and content will be
                      transferred to your employer
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body-sm-regular">
                      The <i>Terms and Conditions for reMarkable Accounts</i>{' '}
                      you&apos;ve accepted will end
                    </Typography>
                  </li>
                </ul>

                <Typography variant="body-sm-regular">
                  Read more below.
                </Typography>
              </div>

              <div className="flex w-full flex-col-reverse justify-center gap-16 ls:flex-row">
                <Button
                  as="a"
                  to={URLS.ACCOUNT_MIGRATE}
                  variant="secondary"
                  className="w-full ls:w-auto"
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  className="w-full ls:w-auto"
                  loading={
                    loginToLinkableAccount.isPending ||
                    loginToLinkableAccount.isSuccess
                  }
                  disabled={
                    loginToLinkableAccount.isPending ||
                    loginToLinkableAccount.isSuccess
                  }
                  analytics={{
                    location: ComponentLocations.ACCOUNT.MIGRATE_CONFIRM,
                    action: 'log in to confirm account migration',
                    text: 'Log in to confirm',
                  }}
                  onClick={() => {
                    if (!user) {
                      throw new Error('Missing user');
                    }

                    const currentAccountProvider =
                      userHelpers.getUserAccountProvider(user);
                    const isCurrentUserSAML = userHelpers.isSAMLUser(user);
                    const linkableAccountProvider =
                      userHelpers.getLinkableAccountProvider(user);

                    tracker.trackEvent(
                      new AccountLinkingStarted({
                        component_location:
                          ComponentLocations.ACCOUNT.MIGRATE_CONFIRM,
                        account_linking_flow: isCurrentUserSAML
                          ? 'SSO first'
                          : 'Legacy first',
                        primary_account_provider: isCurrentUserSAML
                          ? linkableAccountProvider
                          : currentAccountProvider,
                      })
                    );
                    loginToLinkableAccount.mutate({
                      startLinking: true,
                    });
                  }}
                >
                  Log in to confirm
                </Button>
              </div>

              {loginToLinkableAccount.isError && (
                <NotificationBox variant="error" title="Something went wrong">
                  {loginToLinkableAccount.error.message ??
                    'Please try again later or contact support if the problem persists.'}
                </NotificationBox>
              )}
            </div>
          </Sheet>

          <Feedback
            feedbackTargetId="account-linking-confirm-v1"
            title="Did you find the information you needed?"
          />

          <FaqSection
            sections={[
              FAQ.enterprise.whatHappensToMyFilesIfIActivateMyEnterpriseAccount(),
              FAQ.enterprise.canAnyoneElseGetAccessToMyDocuments(),
              FAQ.enterprise.whatHappensToTheTermsAndConditionsForRemarkableAccounts(),
              FAQ.enterprise.whatDoesItMeanForMyEmployerToHaveAdministrativeControlOverMyFiles(),
              FAQ.enterprise.whatHappensIfIHaveAFreeConnectTrial(),
              FAQ.enterprise.iHaveFreeAccessToConnect(),
            ]}
          />
        </div>
      </AppPage.Content>
    </AppPage.Container>
  );
}
