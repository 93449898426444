import { WebAuth } from 'auth0-js';

import { CONFIG } from 'src/config';

const webAuth = new WebAuth({
  domain: CONFIG.Auth0Domain ?? '',
  clientID: CONFIG.Auth0ClientID ?? '',
});

export const getWebAuth = () => webAuth;
