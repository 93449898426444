import { Typography } from '@remarkable/ark-web';

import { Button, ErrorPage } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const InviterIsPaused = () => {
  return (
    <ErrorPage.Container data-cy="seat-invitation-inviter-paused">
      <ErrorPage.ErrorIcon />
      <ErrorPage.Heading>
        Your company&apos;s subscription is on hold
      </ErrorPage.Heading>

      <ErrorPage.Content>
        <div className="flex flex-col items-center justify-center">
          <Typography variant="body-md-regular">
            The subscription you&apos;ve been invited to is on hold. Please ask
            your admin to update their payment details.
          </Typography>
          <Button as="a" to={URLS.HOME} variant="secondary" className="mt-24">
            Back to home
          </Button>
        </div>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
