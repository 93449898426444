import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export interface ErrorMessages {
  [errorType: string]: string;
}

/**
 * Create an error message resolver with support for custom error types (useful for local and non generic errors).
 */
export const createErrorMessages =
  <T extends ErrorMessages>(errorMessages?: T) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  ): string | undefined => {
    if (!error || typeof error.type !== 'string') return;

    if (error.message) {
      if (typeof error.message === 'string') return error.message;
    }

    const messages: ErrorMessages = {
      default: 'This field is not valid',
      required: 'This field is required',
      zipCode: 'Invalid zip code',
      ...errorMessages,
    };

    return messages[error?.type] ?? messages.default;
  };
