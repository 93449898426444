import { cloudConsentApi } from '../clients/cloudConsentApiClient';

export const getConsent = async () => {
  const response = await cloudConsentApi
    .GET('/consent/v1')
    .then((res) => res ?? null);
  if (response && response.response.status === 204) {
    return null;
  }
  return response.data;
};

export const createConsent = async (
  type: 'sync-content',
  level: 'meta-data' | 'full'
) =>
  cloudConsentApi.POST('/consent/v1', {
    body: {
      type,
      level,
    },
  });

export const revokeConsent = async () => cloudConsentApi.DELETE('/consent/v1');
