import { z } from 'zod';

/**
 * Stripe.confirmPayment redirect params.
 * Used to verify payment method update after Stripe redirect.
 */
const EditPaymentMethodResponse = z.object({
  /** Custom property set by us in the Stripe return url */
  subscription_id: z.string(),
  /** Stripe Client Secret */
  client_secret: z.string(),
  /** Stripe redirect status */
  redirect_status: z.enum(['success', 'failure']),
  /** Stripe setup intent client secret */
  setup_intent_client_secret: z.string(),
});

/**
 * Used to trigger an access denied toast when the user is trying
 * to access a page they don't have access to.
 */
const AccessDenied = z.object({
  access_denied: z.string(),
});

const ShowCookieBanner = z.object({
  show_cookie_banner: z.boolean().optional(),
});

export const SearchParamSchemas = {
  EditPaymentMethodResponse,
  AccessDenied,
  ShowCookieBanner,
};
