import { HTMLProps, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { useForm } from 'react-hook-form';

import {
  useAccountInfo,
  useCancelEmailChangeRequest,
  useEmailChangeStatus,
  useRequestEmailChange,
} from 'src/api/queries/account';
import {
  Button,
  Form,
  Input,
  Link,
  Modal,
  NotificationBox,
  Spinner,
} from 'src/components';
import { userHelpers } from 'src/utils/userHelpers';

import { InvalidEmailException } from '../../../../api/endpoints/storeApi.types';
import { getDateDiffInMinutes } from '../../../../utils/getDateDiffInMinutes';
import { EditEmailModal } from './EditEmailModal';

interface EmailForm {
  email: string;
}

export const EditEmailForm = (props: HTMLProps<HTMLDivElement>) => {
  const { user } = useAuth0();
  const requestEmailChange = useRequestEmailChange();
  const emailChangeStatus = useEmailChangeStatus();
  const cancelEmailChange = useCancelEmailChangeRequest();
  const [isEditable, setIsEditable] = useState(false);
  const accountInfo = useAccountInfo();

  const form = useForm<EmailForm>({
    defaultValues: {
      email: user?.email ?? '',
    },
  });

  const onSubmit = (data: EmailForm) => {
    if (data.email === user?.email) {
      setIsEditable(false);
      form.reset();
      return;
    }

    requestEmailChange.mutate(
      {
        currentEmail: user?.email ?? '',
        newEmail: data.email,
      },
      {
        onSuccess: () => {
          setIsEditable(false);
          form.reset();
          return emailChangeStatus.refetch();
        },
      }
    );
  };

  const onCancelEmailChangeRequest = () => {
    cancelEmailChange.mutate(undefined, {
      onSuccess: () => emailChangeStatus.refetch(),
    });
  };

  if (!user) {
    throw new Error('Missing user');
  }

  const emailDomain = userHelpers.getEmail(user).split('@')[1];
  const isDomainClaimed = userHelpers.isDomainClaimedByConnection(user);
  const identities = accountInfo.data?.identities ?? [];

  const allowedToEdit = userHelpers.canEditEmail(user, identities);

  // Social accounts are managed by the provider, so we don't allow
  // the user to change their email address from myrm
  const isPendingEmailChange = emailChangeStatus.data?.status === 'pending';
  const expireDate = new Date(emailChangeStatus.data?.expiresAt ?? '');
  const minutesLeft = getDateDiffInMinutes(new Date(), expireDate);

  return (
    <div {...props}>
      <Form onSubmit={onSubmit} {...form}>
        <div className="flex flex-col gap-16 lm:flex-row">
          <Input
            label="Email"
            name="email"
            type="email"
            required
            isEditable={isEditable}
            focusOnIsEditable
            data-hj-suppress
            data-cy="email-field"
          />
          {!isEditable && allowedToEdit && (
            <>
              {accountInfo.data?.identities &&
              accountInfo.data.identities.length > 1 ? (
                <Modal.Root>
                  <Modal.Trigger asChild>
                    <Button
                      variant="tertiary"
                      size="medium"
                      className="w-full self-start ls:w-fit"
                      data-cy="edit-email-button-multiple-identities"
                    >
                      Edit
                    </Button>
                  </Modal.Trigger>
                  <EditEmailModal />
                </Modal.Root>
              ) : (
                <Button
                  variant="tertiary"
                  type="button"
                  size="medium"
                  onClick={() => setIsEditable(true)}
                  className="w-full self-start ls:w-fit"
                  data-cy="edit-email-button"
                >
                  Edit
                </Button>
              )}
            </>
          )}
        </div>

        {isEditable && (
          <div className="my-16 flex flex-col gap-16">
            {isDomainClaimed && (
              <NotificationBox
                variant="info"
                title="You'll also have access to your enterprise account"
              >
                If you change your email address, we&apos;ll create a separate
                enterprise account for your @{emailDomain} email. You can move
                files to that account later.
              </NotificationBox>
            )}

            <div className="flex gap-16">
              <Button
                onClick={() => {
                  setIsEditable(false);
                  requestEmailChange.reset();
                  form.reset();
                }}
                type="button"
                variant="secondary"
                data-cy="cancel-email-button"
                size="medium"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={requestEmailChange.isPending}
                data-cy="save-email-button"
                size="medium"
              >
                Save
              </Button>
            </div>
          </div>
        )}

        {isPendingEmailChange && emailChangeStatus.data && (
          <NotificationBox
            title="Please verify your new email address to continue"
            variant="warning"
            className="mb-24"
          >
            <Typography variant="body-sm-regular" as="p">
              A verification email has been sent to:{' '}
              <Typography variant="body-sm-bold" as="strong">
                {emailChangeStatus.data.newEmail}
              </Typography>
            </Typography>
            <Typography variant="body-sm-regular" as="p">
              Follow the instructions to confirm this change to your account
              details. The link is active for {minutesLeft} minute
              {minutesLeft > 1 ? 's' : ''}. Resend if required.
            </Typography>
            <div className="mt-16 flex gap-16">
              <Link onClick={onCancelEmailChangeRequest} as="button" inline>
                {cancelEmailChange.isPending ? <Spinner inline /> : 'Cancel'}
              </Link>
              <Link
                onClick={() =>
                  onSubmit({ email: emailChangeStatus.data.newEmail })
                }
                as="button"
                inline
              >
                {requestEmailChange.isPending ? <Spinner inline /> : 'Resend'}
              </Link>
            </div>
          </NotificationBox>
        )}

        {(requestEmailChange.isError || cancelEmailChange.isError) && (
          <NotificationBox
            title="Something went wrong"
            variant="error"
            className="my-24"
            data-cy="email-change-error"
          >
            <Typography variant="body-sm-regular" as="p">
              {requestEmailChange.error instanceof InvalidEmailException ? (
                requestEmailChange.error.message
              ) : (
                <>
                  Please try again later or contact support if the problem
                  persists.
                </>
              )}
            </Typography>
          </NotificationBox>
        )}
      </Form>
    </div>
  );
};
