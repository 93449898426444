import { HTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import { Label } from 'src/components';

export const TextWithLabel = ({
  label,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & { label: string }) => {
  return (
    <div {...props}>
      <Label className="whitespace-nowrap">{label}</Label>
      <Typography
        variant="body-md-bold"
        className="my-0 whitespace-nowrap font-semibold"
        data-cy="value"
      >
        {children}
      </Typography>
    </div>
  );
};
