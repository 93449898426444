import { ReactNode, useState } from 'react';

import * as TooltipComponent from '@radix-ui/react-tooltip';

export const Tooltip = ({
  children,
  title,
  asChild = true,
  side = 'top',
  sideOffset = 5,
  enabled = true,
  disableHoverableContent = false,
  delay,
  clickable,
}: {
  children: ReactNode;
  title: ReactNode;
  asChild?: boolean;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  enabled?: boolean;
  disableHoverableContent?: boolean;
  delay?: number;
  clickable?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  if (!enabled) return <>{children}</>;

  return (
    <TooltipComponent.Provider>
      <TooltipComponent.Root
        onOpenChange={(isOpen) => setOpen(isOpen)}
        open={open}
        delayDuration={delay}
        disableHoverableContent={disableHoverableContent}
      >
        <TooltipComponent.Trigger
          onClick={() => {
            if (clickable) {
              setOpen(true);
            }
          }}
          asChild={asChild}
        >
          {children}
        </TooltipComponent.Trigger>
        <TooltipComponent.Portal>
          <TooltipComponent.Content
            className="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade z-overEverything animate-fade-in-up-short select-none rounded-[4px] bg-gray-800 px-[15px] py-[10px] text-[15px] leading-none text-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
            sideOffset={sideOffset}
            side={side}
          >
            {title}
            <TooltipComponent.Arrow className="fill-gray-800" />
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      </TooltipComponent.Root>
    </TooltipComponent.Provider>
  );
};
