import { paths } from 'apis/cloud-profile-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { setTectonicUrl } from './middleware/setTectonicUrl';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudProfileApiClient = createClient<paths>({
  baseUrl: CONFIG.CloudApiUrl,
});

cloudProfileApiClient.use(addAuthHeader, setTectonicUrl, throwOnErrorResponse);

export { cloudProfileApiClient };
