import { Typography } from '@remarkable/ark-web';

import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { NotificationBox, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

export function SetupPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />

      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <Typography as="h1" variant="heading-lg">
            Set up enterprise single sign-on
          </Typography>

          <div>
            <Typography as="p" variant="body-md-regular">
              Set up your enterprise account to strengthen security and
              streamline user management. With an enterprise account, you can:
            </Typography>

            <ul className="list-inside list-disc py-16 pl-8">
              <li>
                Enable secure authentication with enterprise single sign-on
                (SSO)
              </li>
              <li>
                Integrate with you current identity provider (IdP) using
                Security Assertion Markup Language (SAML)
              </li>
              <li>
                Claim your domain and transfer ownership of all associated
                accounts and data to your enterprise account
              </li>
            </ul>

            <div className="py-16">Learn more about enterprise accounts.</div>

            <Typography variant="body-md-regular">
              You can begin setup now and continue later. New{' '}
              <LinkExternal inline to={SUPPORT_URL.LEGAL_OVERVIEW}>
                terms and conditions
              </LinkExternal>{' '}
              apply.
            </Typography>
          </div>

          {enrollmentStatus.data && (
            <NotificationBox
              variant="info"
              title="Setup process already in progress"
            >
              <span>
                Continue the setup by clicking the button below or cancel it to
                start over.
              </span>
            </NotificationBox>
          )}

          <div className="flex justify-center gap-16">
            {enrollmentStatus.data && (
              <CancelSetupModal
                variant="secondary"
                buttonText="Cancel setup"
                disableTooltip
              />
            )}

            <Link2
              as="button"
              to={
                enrollmentStatus.data
                  ? URLS.ENTERPRISE
                  : URLS.ENTERPRISE_CREATE_ORGANIZATION
              }
              variant="primary"
              size="medium"
            >
              {enrollmentStatus.data ? 'Continue' : 'Start'} setup
            </Link2>
          </div>
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
