import { ProductConfigured } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import {
  ClientInformation,
  ClientSource,
  ClientType,
  DeviceV1,
  ProfileDeviceV1,
} from 'src/api/endpoints/cloudApi.types';
import { CONFIG } from 'src/config';

import {
  APPLE_ITUNES_URL,
  GOOGLE_PLAY_URL,
  MACOS_ITUNES_URL,
} from './constants';

export type Platform = 'macos' | 'ios' | 'windows' | 'android' | 'linux' | null;

type TabletSku = 'rm100' | 'rm110' | 'RM02';

const tabletClientTypeToSku = (clientType: ClientType): TabletSku => {
  switch (clientType) {
    case ClientType.RM100:
      return 'rm100';
    case ClientType.RM110:
      return 'rm110';
    case ClientType.RM02:
      return 'RM02';
    default:
      throw new Error(`ClientType mapping to sku not supported ${clientType}`);
  }
};

export const getPlatform = (): Platform => {
  if (typeof window === 'undefined') return null;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const windowsPlatforms = /(win32|win64|windows|wince)/i;
  const iosPlatforms = /(iphone|ipad|ipod)/i;

  let os: Platform = null;
  if (macosPlatforms.test(userAgent)) {
    os = 'macos';
  } else if (iosPlatforms.test(userAgent)) {
    os = 'ios';
  } else if (windowsPlatforms.test(userAgent)) {
    os = 'windows';
  } else if (/android/.test(userAgent)) {
    os = 'android';
  } else if (!os && /linux/.test(userAgent)) {
    os = 'linux';
  }

  return os;
};

export const getDesktopLink = () => {
  const platform = getPlatform();
  return platform === 'macos'
    ? MACOS_ITUNES_URL
    : `${CONFIG.DownloadsURL}/latest/windows`;
};

export const getMobileLink = () => {
  const platform = getPlatform();
  return platform === 'ios' ? APPLE_ITUNES_URL : GOOGLE_PLAY_URL;
};

const capitalizePlatform = (platform: string) => {
  switch (platform) {
    case 'macos':
      return 'macOS';
    case 'ios':
      return 'iOS';
    default:
      return platform.replace(/^\w/, (c) => c.toUpperCase());
  }
};

export const isClientPaperTablet = (
  client?: ClientInformation | null
): boolean => {
  if (!client) {
    return false;
  }

  if (client.clientType.startsWith('RM')) {
    return true;
  }

  if (client.clientSource === ClientSource.DeviceV1) {
    return client.clientName === 'remarkable';
  }

  // Handling for emulator devices
  if (client.clientName.includes('emulator')) {
    return true;
  }

  // Handling DeviceV2 assume remarkable-XXX is paperTablet
  const [type] = client.clientName.split('-');
  return type === 'remarkable';
};

export const isClientDesktopApp = (
  client?: ClientInformation | null
): boolean => {
  return client?.clientType === ClientType.Desktop;
};

export const isClientBrowserExtension = (
  client?: ClientInformation | null
): boolean => {
  return client?.clientType === ClientType.Browser;
};

export const isClientMobileApp = (
  client?: ClientInformation | null
): boolean => {
  return client?.clientType === ClientType.Mobile;
};

export const filterActivePaperTabletClients = (
  clients?: ClientInformation[] | null
) =>
  clients?.filter(
    (client) => isClientPaperTablet(client) && isActiveClient(client)
  ) ?? [];

export const filterActiveDesktopAppClients = (
  clients?: ClientInformation[] | null
) =>
  clients?.filter(
    (client) => isClientDesktopApp(client) && isActiveClient(client)
  ) ?? [];

export const filterActiveBrowserExtensionClients = (
  clients?: ClientInformation[] | null
) =>
  clients?.filter(
    (client) => isClientBrowserExtension(client) && isActiveClient(client)
  ) ?? [];

export const filterActiveMobileAppClients = (
  clients?: ClientInformation[] | null
) =>
  clients?.filter(
    (client) => isClientMobileApp(client) && isActiveClient(client)
  ) ?? [];

export const isClientCompanionApp = (
  client?: ClientInformation | null
): boolean => {
  return (
    isClientDesktopApp(client) ||
    isClientMobileApp(client) ||
    isClientBrowserExtension(client)
  );
};

export const isActiveClient = (client?: ClientInformation | null): boolean => {
  if (!client) {
    return false;
  }

  return !('deletedAt' in client) || !client.deletedAt;
};

export const getClientTypeFromV1 = (device: DeviceV1): ClientType => {
  if (device.DeviceID.startsWith('RM110')) {
    return ClientType.RM110;
  } else if (device.DeviceID.startsWith('RM100')) {
    return ClientType.RM100;
  } else if (device.DeviceID.startsWith('RM02')) {
    return ClientType.RM02;
  } else if (device.DeviceDesc.startsWith('desktop')) {
    return ClientType.Desktop;
  } else if (device.DeviceDesc.startsWith('mobile')) {
    return ClientType.Mobile;
  } else if (device.DeviceDesc.startsWith('browser')) {
    return ClientType.Browser;
  } else {
    return ClientType.Unknown;
  }
};

export const getClientTypeFromV2 = (device: ProfileDeviceV1): ClientType => {
  return (device.deviceType.split('-')[1] as ClientType) ?? ClientType.Unknown;
};

export const getClientTitle = (client: ClientInformation) => {
  if (isClientPaperTablet(client)) {
    switch (client.clientType) {
      case ClientType.RM100:
        return 'reMarkable 1';
      case ClientType.RM110:
        return 'reMarkable 2';
      case ClientType.RM02:
        return 'reMarkable Paper Pro';
      default:
        return 'reMarkable 2';
    }
  }

  const [type, platform] = client.clientName.split('-');
  if (type === 'browser') {
    return capitalizePlatform(platform) + ' extension';
  } else {
    return capitalizePlatform(platform) + ' app';
  }
};

const getProductTypeForTracking = (
  client: ClientInformation
):
  | 'paper tablet'
  | 'desktop companion'
  | 'mobile companion'
  | 'read on remarkable' => {
  if (isClientPaperTablet(client)) {
    return 'paper tablet';
  }
  const type = client.clientName.split('-')[0];
  switch (type) {
    case 'browser':
      return 'read on remarkable';
    case 'desktop':
      return 'desktop companion';
    case 'mobile':
      return 'mobile companion';
    default:
      throw new Error(`Unknown device type: ${type}`);
  }
};

export const trackProductConfigured = (
  client: ClientInformation,
  action: 'device_paired' | 'device_unpaired'
) => {
  try {
    const commonProperties = {
      action: action,
      product_type: getProductTypeForTracking(client),
    };

    if (isClientPaperTablet(client)) {
      tracker.trackEvent(
        new ProductConfigured({
          ...commonProperties,
          remarkable_version: tabletClientTypeToSku(client.clientType),
          product_platform: 'remarkable',
        })
      );
    } else {
      const platform = client.clientName.split('-')[1] || 'unknown';
      tracker.trackEvent(
        new ProductConfigured({
          ...commonProperties,
          product_platform: platform,
        })
      );
    }
  } catch (error) {
    console.error('Failed to track product configured', error);
  }
};
