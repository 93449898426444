import { HTMLAttributes, useRef } from 'react';

import { getRouteApi } from '@tanstack/react-router';
import clsx from 'clsx';
import { Plus } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useStripeSubscription } from 'src/api/queries';
import { Button, Modal, Tooltip } from 'src/components';
import { exitAnimationDuration } from 'src/components/Modal';

import { InviteModal } from './InviteModal';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/members');

export const InviteToSeatsButton = ({
  className,
  ...rest
}: HTMLAttributes<HTMLButtonElement>) => {
  const subscription = useStripeSubscription();
  const navigate = routeApi.useNavigate();
  const search = routeApi.useSearch();
  const exitAnimationTimeout = useRef<NodeJS.Timeout | null>(null);

  return (
    <Modal.Root
      onOpenChange={(isOpen) => {
        if (exitAnimationTimeout.current) {
          clearTimeout(exitAnimationTimeout.current);
        }

        if (!isOpen) {
          // Wait for the animation to finish before resetting the page url
          // This is to avoid the modal from collapsing mid-animation
          exitAnimationTimeout.current = setTimeout(() => {
            void navigate({
              search: (prev) => ({ ...prev, invite_by: undefined }),
            });
          }, exitAnimationDuration);

          return;
        }

        if (!search.invite_by) {
          void navigate({ search: { invite_by: 'email' } });
        }
      }}
    >
      <Tooltip
        enabled={subscription.data?.access.canCreateInvitations === false}
        title={
          subscription.data?.status === 'unpaid'
            ? 'Update your payment details to invite new members'
            : "You don't have permission to create invitations"
        }
      >
        <Modal.Trigger asChild>
          <Button
            variant="primary-neutral"
            className={clsx('w-full ls:w-auto', className)}
            data-cy="open-invite-modal-button"
            disabled={subscription.data?.access.canCreateInvitations === false}
            analytics={{
              text: 'Invite',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'open invite modal',
            }}
            {...rest}
          >
            <Plus />
            <span>Invite</span>
          </Button>
        </Modal.Trigger>
      </Tooltip>

      <InviteModal />
    </Modal.Root>
  );
};
