import { useQuery } from '@tanstack/react-query';

import { SKUs } from 'src/utils/skus';

import { storeApi } from '../endpoints';
import { useCountry } from './country';
import { queryKeys } from './queryKeys';

export const useProductPricesOpen = (sku: (typeof SKUs)[keyof typeof SKUs]) =>
  useQuery({
    queryKey: queryKeys.product(sku),
    queryFn: () => storeApi.getProductPricesPublic(sku),
    staleTime: Infinity,
  });

export const useConnectPrice = () => {
  const connectProductPrices = useProductPricesOpen(SKUs.connectMonthly);
  const country = useCountry();
  const isPending = connectProductPrices.isPending || country.isPending;

  const data = connectProductPrices.data?.find(
    (price) => price.currency === country.data?.currency
  );
  return {
    data,
    isPending,
  };
};
