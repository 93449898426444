export const SUPPORT_URL = {
  TERMS_AND_CONDITIONS_INTEGRATIONS:
    'https://support.remarkable.com/s/article/Terms-Conditions-Integrations',
  CONTACT_SUPPORT: 'https://support.remarkable.com/s/contactsupport',
  HOME: 'https://support.remarkable.com/s',
  LEGAL_OVERVIEW: 'https://support.remarkable.com/s/legal',
  PRIVACY_POLICY:
    'https://support.remarkable.com/s/article/reMarkable-s-privacy-policy',
  COOKIE_POLICY:
    'https://support.remarkable.com/s/article/reMarkable-s-cookie-policy',
  CONNECT_FOR_BUSINESS_PILOT_FEEDBACK: 'https://forms.gle/voUgjt5gXHcu8zfZ6',
  ARTICLES: {
    PAYMENT_OPTIONS_FOR_CONNECT:
      'https://support.remarkable.com/s/article/Payment-options-for-Connect',
    REJECTED_PAYMENT:
      'https://support.remarkable.com/s/article/Rejected-payment',
    ROR_GOOGLE_CHROME_EXTENSION:
      'https://support.remarkable.com/s/article/Read-on-reMarkable-Google-Chrome-Extension',
    ROR_WORD_AND_POWERPOINT:
      'https://support.remarkable.com/s/article/Read-on-reMarkable-Word-and-PowerPoint-add-ins',
    KINDLE_ON_REMARKABLE:
      'https://support.remarkable.com/s/article/Can-I-read-Kindle-ebooks-on-reMarkable-2',
    EBOOKS_ON_REMARKABLE: 'https://support.remarkable.com/s/article/Ebooks',
    FILETYPES_REMARKABLE:
      'https://support.remarkable.com/s/article/Which-file-types-are-supported-on-reMarkable',
    PAIR_REMARKABLE_WITH_CLOUD:
      'https://support.remarkable.com/s/article/Pair-your-reMarkable-with-the-cloud',
    ACTIVATE_CONNECT_SUBSCRIPTION:
      'https://support.remarkable.com/s/article/How-to-activate-your-Connect-subscription',
    ABOUT_CONNECT_SUBSCRIPTION:
      'https://support.remarkable.com/s/article/About-Connect-Subscription',
    CONNECT_BILLING_DATE:
      'https://support.remarkable.com/s/article/Connect-billing-date',
    UNEXPECTED_SUBSCRIPTION_CHARGES:
      'https://support.remarkable.com/s/article/Unexpected-subscription-charges',
    INTEGRATIONS: 'https://support.remarkable.com/s/article/Integrations',
    UNLIMITED_STORAGE_MEANS:
      'https://support.remarkable.com/s/article/What-does-unlimited-storage-mean',
    MANAGE_CONNECT_SUBSCRIPTION:
      'https://support.remarkable.com/s/article/How-to-manage-your-Connect-subscription',
    CANCEL_CONNECT_SUBSCRIPTION:
      'https://support.remarkable.com/s/article/How-to-cancel-your-subscription',
    USING_REMARKABLE_WITHOUT_CONNECT:
      'https://support.remarkable.com/s/article/Using-reMarkable-without-a-subscription',
    SATISFACTION_GUARANTEE:
      'https://support.remarkable.com/s/article/How-does-the-satisfaction-guarantee-work',
    ABOUT_THE_REMARKABLE_PROTECTION_PLAN:
      'https://support.remarkable.com/s/article/About-the-reMarkable-Protection-Plan',
    ABOUT_EXCLUSIVE_OFFER:
      'https://support.remarkable.com/s/article/List-of-exclusive-Connect-offers',
    DO_YOU_OFFER_ANNUAL_BILLING:
      'https://support.remarkable.com/s/article/Do-you-offer-annual-billing-for-Connect',
    HOW_TO_UPDATE_YOUR_REMARKABLE:
      'https://support.remarkable.com/s/article/How-to-update-your-reMarkable',
    HOW_TO_FACTORY_RESET_YOUR_REMARKABLE:
      'https://support.remarkable.com/s/article/Performing-a-factory-reset',
  },
} as const;
