import { MyRmSubscriptionMembersHandlersTypes } from 'src/api/endpoints/storeApi.types';

export const getSeatStatus = (
  member: MyRmSubscriptionMembersHandlersTypes.ListMembers.Member
) => {
  // If the member has no seat
  if (!member.seat) {
    return 'Inactive seat';
  }

  return 'Active seat';
};
