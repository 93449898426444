import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from 'src/components';

export const Route = createFileRoute('/(public)/authentication-error')({
  component: () => {
    const auth = useAuth0();

    return (
      <div data-cy="error-page" className="w-full bg-neutral-light-2">
        <div className="m-auto flex max-w-screen-md flex-col items-center gap-24 px-24 py-64 md:px-64 lg:py-128">
          <Typography variant="heading-lg" className="text-center">
            Something went wrong during authentication
          </Typography>

          <Typography variant="body-md-regular" className="text-center">
            Please try again later or login with another account.
          </Typography>

          <Button className="mt-32" onClick={() => auth.logout()}>
            Login with another account
          </Button>
        </div>
      </div>
    );
  },
});
