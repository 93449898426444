import { Spinner, Typography } from '@remarkable/ark-web';
import { CaretRight } from 'phosphor-react';

import { useAccountInfoSuspense } from 'src/api/queries';
import { CardContent, Divider, Link, SomethingWentWrong } from 'src/components';

import { useConfirmUnlinkModal } from './ConfirmUnlinkModal';

export const getAuthProviderDisplayName = (provider: string) => {
  switch (provider) {
    case 'google-oauth2':
      return 'Google';
    case 'facebook':
      return 'Facebook';
    case 'apple':
      return 'Apple';
    default:
      return provider;
  }
};

export const LinkedAccounts = () => {
  const accountInfo = useAccountInfoSuspense();
  const confirmUnlinkModal = useConfirmUnlinkModal();

  if (accountInfo.isPending) return <Spinner />;

  if (accountInfo.isError) return <SomethingWentWrong />;

  if (!accountInfo.data.identities) return null;

  const isAllIdentitiesSocial = accountInfo.data.identities.every(
    (identity) => identity.isSocial
  );
  const isNoneIdentitiesSocial = accountInfo.data.identities.every(
    (identity) => !identity.isSocial
  );

  // We only show the ui when there is a mix of social and non-social identities.
  // This is usually the case when a user logs in through a social login provider
  // after having already created a regular account with email and password, and
  // these two identities are automatically linked together by matching email addresses.
  if (isAllIdentitiesSocial || isNoneIdentitiesSocial) return null;

  return (
    <>
      <Divider />

      <Typography variant="heading-subtle-2xl" as="h2">
        Social login
      </Typography>

      {accountInfo.data.identities
        .filter((identity) => identity.isSocial)
        .map((identity) => (
          <CardContent key={identity.provider}>
            <div className="flex justify-between">
              <Typography variant="body-md-regular">
                {getAuthProviderDisplayName(identity.provider)}
              </Typography>
              <Link
                as="button"
                onClick={() =>
                  confirmUnlinkModal.open({
                    unlinkUserId: identity.user_id,
                    provider: identity.provider,
                  })
                }
              >
                <Typography variant="body-sm-regular">Remove</Typography>
                <CaretRight size={24} className="inline max-h-24" />
              </Link>
            </div>
          </CardContent>
        ))}
    </>
  );
};
