import { Subscription } from 'src/api/endpoints/storeApi.types';

import { isActiveStripeSubscription } from './subscriptionIsActive';

export const subscriptionGetPaymentInterval = (
  sub?: Subscription
): 'monthly' | 'annually' | undefined => {
  if (!isActiveStripeSubscription(sub)) return undefined;

  return sub.paymentInformation?.paymentInterval || undefined;
};
