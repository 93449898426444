import { useEffect, useState } from 'react';

import {
  ConsentLevel,
  getConsentLevel,
  hasSetConsent,
  updateConsentLevel,
} from 'src/consent';

export function useCookies(): [
  boolean,
  (level: ConsentLevel) => void,
  () => void
] {
  const [accepted, setAccepted] = useState(hasSetConsent());

  useEffect(() => {
    const currentConsentLevel = getConsentLevel();
    if (currentConsentLevel === ConsentLevel.ALL) {
      setAccepted(true);
    }
  }, []);

  const accept = (level: ConsentLevel) => {
    setAccepted(true);
    updateConsentLevel(level);
  };

  const reject = () => {
    setAccepted(false);
    updateConsentLevel(ConsentLevel.NECESSARY);
  };

  return [accepted, accept, reject];
}
