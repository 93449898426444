import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queries';

export const useReauthorizePaymentMethod = () => {
  const queryClient = useQueryClient();
  const stripe = useStripe();

  const confirmCardPayment = useMutation<
    PaymentIntentResult | undefined,
    Error,
    string
  >({
    mutationFn: async (clientSecret) => {
      if (clientSecret === '') throw new Error('Invalid client secret');

      const result = await stripe?.confirmCardPayment(clientSecret);

      if (result?.error) {
        throw new Error(
          result.error.type === 'card_error' && result.error.message
            ? result.error.message
            : 'Something went wrong with your payment. Please try again later.'
        );
      }

      return result;
    },
    /* We can only try to manually authorize payment ONCE. If the authorization
       fail, we must ask the customer to edit their payment card instead. This is
       a limitation in Stripe. Because of this, we have to refetch subscriptions
       both on success and failure. */
    onSettled: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.all,
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.settings.paymentHistory,
        }),
      ]);
    },
  });

  return confirmCardPayment;
};
