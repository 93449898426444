import { Middleware } from 'openapi-fetch';

import { HTTPError } from '../../createApiClient.types';

/** Used for enabling react query error handling */
export const throwOnErrorResponse: Middleware = {
  onResponse(res) {
    if (!res.ok) {
      const error = new HTTPError(res, new Request(res.url));
      console.error(error);
      throw error;
    }

    return res;
  },
};
