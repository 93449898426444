import { ReactNode } from 'react';

import { cn } from 'src/utils/classNamesHelper';

interface DividerProps {
  className?: string;
  title?: string | ReactNode;
}

const Line = () => (
  <div className="w-full border-t border-neutral-light-8/70" />
);

export const Divider = ({ className = '', title, ...rest }: DividerProps) => {
  if (title) {
    return (
      <div
        data-cy="divider"
        className={cn('flex w-full items-center gap-12', className)}
        {...rest}
      >
        <Line />
        <span className="interface-sm-caps whitespace-nowrap">{title}</span>
        <Line />
      </div>
    );
  }

  return (
    <div
      className={cn('h-[1px] bg-neutral-light-8/70', className)}
      data-cy="divider"
    />
  );
};
