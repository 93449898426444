import {
  CarouselCanvas,
  Typography,
  useCarouselController,
} from '@remarkable/ark-web';
import { cx } from 'class-variance-authority';
import { ArrowRight, Circle, Timer } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import getStartedJpg from 'src/assets/img/learning/get_started_rmpp.jpg';
import howToApps from 'src/assets/img/learning/how_to_apps.jpg';
import howToCombineHwAndTyped from 'src/assets/img/learning/how_to_combine_hw_and_typed.jpg';
import howToCoverPages from 'src/assets/img/learning/how_to_cover_pages.jpg';
import howToFolderAndTags from 'src/assets/img/learning/how_to_folders_and_tags.jpg';
import howToGestures from 'src/assets/img/learning/how_to_gestures.jpg';
import howToOrganize from 'src/assets/img/learning/how_to_organize.jpg';
import howToTags from 'src/assets/img/learning/how_to_tags.jpg';
import howToWritingTools from 'src/assets/img/learning/how_to_writing_tools.jpg';
import { Link, Sheet } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

interface LearningCardProps {
  image: string;
  time: string;
  title: string;
  link: string;
}

const noConnectNoDeviceCards: LearningCardProps[] = [
  {
    image: getStartedJpg,
    time: '5 min watch',
    title: 'Get started with reMarkable Paper Pro',
    link: URLS.RM_COM_GET_STARTED_WITH_REMARKABLE_PAPER_PRO,
  },
  {
    image: howToGestures,
    time: '5 min read',
    title: 'How to use gestures on reMarkable',
    link: URLS.RM_COM_HOW_TO_GESTURES,
  },
  {
    image: howToWritingTools,
    time: '6 min read',
    title: 'How to use writing tools on reMarkable Paper Pro',
    link: URLS.RM_COM_HOW_TO_WRITING_TOOLS,
  },
];

const hasDeviceNoSubscriptionCards: LearningCardProps[] = [
  {
    image: howToApps,
    time: '2 min read',
    title: 'How to use the reMarkable desktop and mobile apps',
    link: URLS.RM_COM_HOW_TO_APPS,
  },
  {
    image: howToCombineHwAndTyped,
    time: '2 min read',
    title: 'How to combine handwriting and typing on reMarkable',
    link: URLS.RM_COM_HOW_TO_COMBINE_HW_AND_TYPED,
  },
  {
    image: howToTags,
    time: '1 min read',
    title: 'How to use tags on reMarkable',
    link: URLS.RM_COM_HOW_TO_TAGS,
  },
];

const hasDeviceHasSubscriptionCards: LearningCardProps[] = [
  {
    image: howToOrganize,
    time: '5 min read',
    title: '3 pro tips for organizing your paper tablet',
    link: URLS.RM_COM_HOW_TO_ORGANIZE,
  },
  {
    image: howToCoverPages,
    time: '5 min read',
    title: 'Personalize your notes with inspiring cover pages',
    link: URLS.RM_COM_HOW_TO_COVER_PAGE,
  },
  {
    image: howToFolderAndTags,
    time: '6 min read',
    title: 'Level up your organizing skills with folders and tags',
    link: URLS.RM_COM_HOW_TO_FOLDER_AND_TAGS,
  },
];

const LearningCard = ({ image, time, title, link }: LearningCardProps) => {
  return (
    <Sheet className="my-16 flex flex-1 flex-col justify-start gap-0 bg-white !p-0 shadow-lg">
      <Link
        to={link}
        trackingLocation={ComponentLocations.FRONT_PAGE.LEARNING_CONTENT}
      >
        <img src={image} alt={title} className="w-full object-cover" />
      </Link>
      <div className="flex flex-col p-32 text-left">
        <div>
          <Typography
            variant="interface-sm-regular"
            className="mb-16 text-gray-600"
            as="h2"
          >
            <Timer size={18} className="inline" /> <span>{time}</span>
          </Typography>
          <Typography variant="responsive-heading-subtle-md" as="h2">
            {title}
          </Typography>
        </div>

        <Link
          to={link}
          trackingLocation={ComponentLocations.FRONT_PAGE.LEARNING_CONTENT}
          className="mt-24 font-semibold text-pen-blue"
        >
          Start Learning <ArrowRight />
        </Link>
      </div>
    </Sheet>
  );
};

export const LearningContent = ({
  hasDevice,
  hasSubscription,
  className,
}: {
  hasDevice: boolean;
  hasSubscription: boolean;
  className?: string;
}) => {
  const learningCards = hasDevice
    ? hasSubscription
      ? hasDeviceHasSubscriptionCards
      : hasDeviceNoSubscriptionCards
    : noConnectNoDeviceCards;

  const carouselController = useCarouselController({
    count: learningCards.length,
  });

  return (
    <div className={cx('flex flex-col items-start gap-8', className)}>
      <Typography variant="interface-sm-caps-medium" className="text-muted">
        Using remarkable
      </Typography>
      <Typography variant="heading-sm">
        {hasSubscription ? 'Get organized' : 'Learn the basics'}
      </Typography>
      <Typography variant="body-sm-regular" className="text-left">
        {hasSubscription
          ? 'Quick tips to keep your paper tablet uncluttered and distraction-free.'
          : 'Step-by-step walkthroughs of how to set up and get started with reMarkable.'}
      </Typography>

      <div className="hidden w-full gap-32 ll:flex ll:flex-row">
        {learningCards.map((content, index) => (
          <LearningCard key={index} {...content} />
        ))}
      </div>
      <div className="relative flex w-full flex-col items-center justify-center gap-8 ll:hidden">
        <CarouselCanvas
          control={carouselController}
          className="w-full auto-cols-[90%]"
        >
          {learningCards.map((content) => (
            <li key={content.title}>
              <LearningCard {...content} />
            </li>
          ))}
        </CarouselCanvas>
        <div className="flex flex-row gap-32">
          {learningCards.map((_, index) => (
            <Circle
              onClick={() => carouselController.onIndex(index)}
              weight="fill"
              key={index}
              className={cx(
                carouselController.index === index
                  ? 'text-neutral-light-8'
                  : 'text-neutral-dark-1',
                'cursor-pointer'
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
