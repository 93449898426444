import { ReactNode } from 'react';

import {
  FieldValues,
  FormProvider,
  FormProviderProps,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';

interface FormProps<T extends FieldValues> extends FormProviderProps<T> {
  onSubmit: SubmitHandler<T>;
  onInvalid?: SubmitErrorHandler<T>;
  className?: string;
  dataCy?: string;
  children: ReactNode;
}
/**
 * Syntax sugar for simplifying form templates with `react-hook-form`.
 * It specifically allows for `useFormContext` within custom form components.
 * Takes all `useForm` methods as props as well as an `onSubmit` handler.
 *
 * Example usage:
 * ```tsx
 * const form = useForm()
 *
 * return (
 *  <Form
 *    onSubmit={handleSubmit}
 *    onInvalid={handleInvalid}
 *    {...form}
 *  >
 *    ...
 * ```
 */
export const Form = <T extends FieldValues>({
  onSubmit,
  children,
  onInvalid,
  className,
  dataCy,
  ...useFormMethods
}: FormProps<T>) => (
  <FormProvider {...useFormMethods}>
    <form
      className={className}
      onSubmit={(event) => {
        event.stopPropagation(); // Prevents nested forms from submitting
        return useFormMethods.handleSubmit(onSubmit, onInvalid)(event);
      }}
      data-cy={dataCy}
    >
      {children}
    </form>
  </FormProvider>
);
