import { ReactNode } from 'react';

import { Sheet } from './Sheet';

interface StackedSheetProps {
  children: ReactNode;
  dataCy?: string;
}

export const StackedSheet = ({ children, dataCy }: StackedSheetProps) => {
  return (
    <div className="relative flex flex-col items-center" data-cy={dataCy}>
      <Sheet className="relative z-20 lm:!p-64">{children}</Sheet>
      <div className="z-10 w-full px-16">
        <div className="z-10 h-12 rounded-2 bg-neutral-light-4 shadow-sheet-stacked lm:h-20" />
      </div>
      <div className="w-full px-32">
        <div className="h-12 rounded-2 bg-neutral-light-6 shadow-sheet-stacked lm:h-20" />
      </div>
    </div>
  );
};
