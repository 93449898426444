import { Typography } from '@remarkable/ark-web';
import { Row, Table } from '@tanstack/react-table';
import { X } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useRemoveUserFromSubscription } from 'src/api/queries';
import { useDeleteInvitation } from 'src/api/queries/invitations';
import { Button, FlexDivider } from 'src/components';

import {
  Member,
  MemberActual,
  MemberInvitation,
  isInvitationMember,
  isSubscriptionMember,
} from '../utils/useMembersList';

export const MembersBulkActionPanel = ({ table }: { table: Table<Member> }) => {
  const removeUserFromSubscription = useRemoveUserFromSubscription();
  const deleteInvitation = useDeleteInvitation();

  const selectedMembers = table.getSelectedRowModel().flatRows;

  if (selectedMembers.length === 0) return null;

  const seatMembers = selectedMembers.filter((row) =>
    isSubscriptionMember(row.original)
  ) as Row<MemberActual>[];
  const invitationMembers = selectedMembers.filter((row) =>
    isInvitationMember(row.original)
  ) as Row<MemberInvitation>[];

  return (
    <div className="sticky bottom-0 left-0 flex h-64 w-full items-center justify-center overflow-hidden rounded bg-white">
      <div className="flex h-full w-full items-center justify-between gap-16 bg-gray-100/70 p-16">
        <div className="flex items-center justify-center gap-16">
          <Typography variant="interface-sm-caps">Selected</Typography>

          {seatMembers.length > 0 && (
            <>
              <FlexDivider />

              <Typography variant="interface-sm-caps">
                <b>{seatMembers.length}</b> seat
                {seatMembers.length > 1 && 's'}
              </Typography>

              <Button
                variant="tertiary"
                size="small"
                loading={removeUserFromSubscription.isPending}
                disabled={removeUserFromSubscription.isPending}
                onClick={() => {
                  seatMembers.map((member) => {
                    if (!member.original.data.access.canRemoveUser) return;

                    removeUserFromSubscription.mutate(
                      { subscriptionRoleId: member.original.id },
                      {
                        onSuccess: () => {
                          table.setRowSelection({});
                        },
                      }
                    );
                  });
                }}
                analytics={{
                  text: 'Cancel',
                  location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                  action: 'cancel selected members bulk action',
                }}
              >
                Cancel
              </Button>
            </>
          )}

          {invitationMembers.length > 0 && (
            <>
              <FlexDivider />

              <Typography variant="interface-sm-caps">
                <b>{invitationMembers.length}</b> invitations
              </Typography>

              <Button
                variant="tertiary"
                size="small"
                loading={deleteInvitation.isPending}
                disabled={deleteInvitation.isPending}
                onClick={() => {
                  invitationMembers.map((member) => {
                    if (!member.original.data.invitationLink) return;

                    deleteInvitation.mutate(
                      member.original.data.invitationLink,
                      {
                        onSuccess: () => {
                          table.setRowSelection({});
                        },
                      }
                    );
                  });
                }}
                analytics={{
                  text: 'Delete',
                  location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
                  action: 'delete selected invitations bulk action',
                }}
              >
                Delete
              </Button>
            </>
          )}
        </div>

        <Button
          variant="tertiary-neutral"
          size="small"
          onClick={() => {
            table.setRowSelection({});
          }}
          analytics={{
            text: 'cross icon',
            location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
            action: 'clear members selection',
          }}
        >
          <X />
        </Button>
      </div>
    </div>
  );
};
