import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useResetMultiFactorAuth } from 'src/api/queries';
import { Button, Modal2, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useDisableMfaModal = createModalHook('DisableMfa');

export const DisableMfaModal = () => {
  const modal = useDisableMfaModal();
  const resetMfa = useResetMultiFactorAuth();

  const handleResetMfaClick = () => {
    resetMfa.mutate(undefined, {
      onSuccess() {
        modal.close();
      },
    });
    tracker.trackEvent(
      new ButtonClicked({
        component_location: ComponentLocations.ACCOUNT.SECURITY_CARD,
        text: 'Disable MFA',
        action: 'disable MFA',
      })
    );
  };

  if (!modal.isOpen) return null;

  return (
    <Modal2 onClose={modal.close}>
      <Typography variant="heading-md" className="mb-24 ls:mb-32" as="h3">
        Are you sure you want to disable multi-factor authentication?
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        Turning off multi-factor authentication increases the risk of
        unauthorized access and security breaches.
      </Typography>

      {resetMfa.isError && (
        <NotificationBox
          variant="error"
          className="mb-16"
          title="Multi-factor authentication couldn't be turned off"
        >
          Please try again or contact support for assistance.
        </NotificationBox>
      )}

      <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
        <Button
          variant="secondary"
          size="medium"
          className="w-full xs:w-fit"
          onClick={modal.close}
        >
          No, cancel
        </Button>
        <Button
          size="medium"
          className="w-full xs:w-fit"
          onClick={handleResetMfaClick}
          disabled={resetMfa.isPending}
          loading={resetMfa.isPending}
        >
          Yes, disable
        </Button>
      </div>
    </Modal2>
  );
};
