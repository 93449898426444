import { Check } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { Button, Feedback, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { URLS } from 'src/utils/urls/urls';

export function AccountMigrateSuccess() {
  return (
    <AppPage.Container>
      <AppPage.Content className="h-full max-w-lm gap-24 py-32 lm:py-64">
        <Sheet>
          <div className="flex items-center gap-16">
            <div className="rounded-full bg-green-light p-8">
              <Check className="" />
            </div>

            <Typography as="h1" variant="responsive-heading-sm">
              You&apos;re all set
            </Typography>
          </div>

          <Typography variant="body-md-regular">
            You&apos;ve successfully activated your enterprise account and
            single sign-on.
          </Typography>

          <Typography variant="body-md-regular">
            From now on, use your email address to log in with enterprise single
            sign-on.
          </Typography>

          <Button
            as="a"
            to={URLS.HOME}
            variant="primary"
            className="w-full ls:w-fit"
          >
            Go to homepage
          </Button>
        </Sheet>

        <Feedback
          feedbackTargetId="account-linking-completed-v1"
          title="How was the activation process?"
        />
      </AppPage.Content>
    </AppPage.Container>
  );
}
