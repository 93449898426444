import { ComponentProps, EventHandler, MouseEvent, ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Link as ArkLink } from '@remarkable/ark-web';
import { Link as RouterLink } from '@tanstack/react-router';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';

interface LinkProps
  extends Partial<Omit<ComponentProps<typeof ArkLink>, 'href'>> {
  to: string;
  onClick?: EventHandler<MouseEvent<HTMLAnchorElement | HTMLLinkElement>>;
  children: ReactNode;
  className?: string;
  trackingLocation?: string;
}

interface LinkAsButtonProps
  extends Omit<Partial<ComponentProps<typeof ArkLink>>, 'href'> {
  onClick: EventHandler<MouseEvent<HTMLAnchorElement | HTMLLinkElement>>;
  children: ReactNode;
  className?: string;
  trackingLocation?: string;
}

const propsIsLinkAsButtonProps = (
  props: LinkProps | LinkAsButtonProps
): props is LinkAsButtonProps => {
  return (props as LinkProps).as === 'button';
};

/**
 * @deprecated For internal links, use `Link2` instead. For external links, use `LinkExternal` instead.
 * Set trackingLocation to track the link click event to analytics
 */
export const Link = (props: LinkProps | LinkAsButtonProps) => {
  // Handle analytics
  const handleOnClick =
    (
      cb?:
        | undefined
        | ((event: MouseEvent<HTMLLinkElement | HTMLAnchorElement>) => void)
    ) =>
    (event: MouseEvent<HTMLLinkElement | HTMLAnchorElement>) => {
      if (props.trackingLocation) {
        tracker.trackEvent(
          new LinkClicked({
            component_location: props.trackingLocation,
            text: event.currentTarget.innerText,
            link_source: event.currentTarget.href,
          })
        );
      }

      cb?.(event);
    };

  if (propsIsLinkAsButtonProps(props)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, onClick, trackingLocation, ...rest } = props;

    return (
      <ArkLink onClick={handleOnClick(onClick)} {...rest}>
        {children}
      </ArkLink>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, to, trackingLocation, ...rest } = props;
  let isRemarkableDomain = false;
  const isExternal = to.startsWith('http');
  if (isExternal) {
    try {
      isRemarkableDomain = new URL(to).hostname.endsWith('remarkable.com');
    } catch {
      console.error('Invalid URL: ', to);
      // to might not be a valid URL, ignore and keep isRemarkableDomain false
    }
  }

  const addNoReferrerOpener = !isRemarkableDomain && isExternal;

  let externalLinkProps: Partial<LinkProps> = {
    target: isExternal ? '_blank' : '',
    rel: addNoReferrerOpener ? 'noreferrer noopener' : 'noopener',
  };

  if (isExternal) {
    externalLinkProps = {
      ...externalLinkProps,
      href: to,
      as: 'a',
    };
  } else {
    externalLinkProps = {
      ...externalLinkProps,
      to,
      as: RouterLink,
    };
  }
  return (
    <ArkLink
      {...externalLinkProps}
      {...rest}
      onClick={handleOnClick(rest.onClick)}
    >
      {children}
    </ArkLink>
  );
};
