import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';

import { CheckoutTypeParam } from 'src/api/endpoints/storeApi.types';
import { createQuerySubscriptions, useConfirmPayment } from 'src/api/queries';
import { getStripeSubscription } from 'src/api/transformers';

export function useCheckoutConfirmation(options: {
  checkoutType: CheckoutTypeParam;
}) {
  const paymentConfirm = useConfirmPayment();
  const navigate = useNavigate();
  const search = useSearch({ strict: false });

  useEffect(() => {
    if (
      !search.setup_intent_client_secret &&
      !search.payment_intent_client_secret
    ) {
      void navigate({ to: '/' });
    }
  }, [search.setup_intent_client_secret, search.payment_intent_client_secret]);

  useEffect(() => {
    if (
      paymentConfirm.isSuccess ||
      paymentConfirm.isPending ||
      paymentConfirm.isError
    ) {
      return;
    }

    paymentConfirm.mutate({ type: options.checkoutType });
  }, [paymentConfirm]);

  const subscription = useQuery({
    ...createQuerySubscriptions(),
    select: getStripeSubscription,
    enabled: paymentConfirm.isSuccess, // Only fetch subscription after payment is confirmed
  });

  return {
    paymentConfirm: paymentConfirm.data,
    subscription: subscription.data,
    isPending:
      paymentConfirm.isPending ||
      (!paymentConfirm.isError && subscription.isPending),
    isError:
      paymentConfirm.isError || subscription.isError || !subscription.data,
    error: paymentConfirm.error || subscription.error,
  };
}
