import { useQuery } from '@tanstack/react-query';

import { cloudApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const useOneTimeCode = () =>
  useQuery({
    queryKey: queryKeys.oneTimeCode.get,
    queryFn: cloudApi.getOneTimeCode,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
