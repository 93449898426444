import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { cartIdStore } from 'src/api/orderIdStore';
import { ConnectConfirmationPage } from 'src/apps/checkout/app/pages/Connect.Confirmation';

export const Route = createFileRoute(
  '/_auth/_layout/store/connect/confirmation'
)({
  beforeLoad() {
    if (!cartIdStore.get()) {
      throw redirect({ to: '/subscriptions' });
    }
  },
  validateSearch(searchParams) {
    return z
      .object({
        payment_intent_client_secret: z.string(),
        payment_intent: z.string(),
        redirect_status: z.string(),
      })
      .parse(searchParams);
  },
  component: () => <ConnectConfirmationPage />,
});
