/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const getFlagImage = async (countryCode: string): Promise<string> => {
  try {
    return await import(`./flags/${countryCode}.svg`).then(
      (src) => src.default
    );
  } catch (e) {
    throw new Error('Flag image not found');
  }
};
