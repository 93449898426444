import {
  StripeSubscription,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

export const getStripeSubscriptionV2 = (
  sub?: Subscription
): StripeSubscription | null => {
  return sub?.type === 'stripe' ? sub : null;
};
