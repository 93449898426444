import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

import { StepIndicator } from './StepIndicator';

export const MrfStep1 = () => {
  return (
    <div
      className="flex h-full flex-col justify-between text-center"
      data-cy="mrf-step-1"
    >
      <div className="ls:pt-64">
        <Typography variant="heading-subtle-xl">You&apos;ll lose</Typography>
        <Typography variant="responsive-heading-lg" className="my-24" as="h1">
          Seamless note-taking and added device protection
        </Typography>
        <Typography className="ls:pt-16" variant="responsive-body-sm">
          Read more about the benefits with Connect{' '}
          <LinkExternal inline to={URLS.RM_COM_STORE_CONNECT}>
            here
          </LinkExternal>
          .
        </Typography>
        <ul className="mx-auto my-24 grid w-full max-w-[340px] grid-cols-2 grid-rows-2 gap-24 ls:my-64 ls:grid-cols-5 ls:grid-rows-1 ls:justify-between md:max-w-ll">
          {Object.values(valueProps.connect).map((valueProp) => (
            <li
              key={valueProp.id}
              className="flex w-full flex-col items-center justify-center self-baseline ls:w-[120px]"
            >
              <valueProp.icon
                weight="light"
                className="h-32 w-32 ls:h-40 ls:w-40"
              />
              <Typography
                variant="responsive-body-sm"
                className="mt-12 text-center"
              >
                {valueProp.title}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      <div className="grid w-full grid-cols-3 py-4 align-middle">
        <div />
        <StepIndicator numSteps={5} activeStep={0} />

        <div className="flex w-full justify-end">
          <Link2
            size="medium"
            variant="tertiary-neutral"
            data-cy="mrf-next-step-1"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-2' }}
          >
            <>
              <span>Next</span> <ArrowRight />
            </>
          </Link2>
        </div>
      </div>
    </div>
  );
};
