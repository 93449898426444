import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/_layout/enterprise')({
  async beforeLoad({ context }) {
    const auth = await context.auth;

    if (!auth.user) {
      throw new Error('Unauthorized');
    }

    if (!auth.user.email) {
      throw new Error('Email not found');
    }

    const allowedDomains = [
      'remarkable.no',
      'remarkable.com',
      'inbox.testmail.app',
    ];
    const currentDomain = auth.user.email.split('@')[1];

    if (!allowedDomains.includes(currentDomain)) {
      throw redirect({ to: '/', search: { access_denied: 'Unauthorized' } });
    }
  },
});
