import { paths } from 'apis/cloud-enterprise-enrollment-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudEnterpriseEnrolmentApiClient = createClient<paths>({
  baseUrl: CONFIG.CloudEnterpriseApiUrl,
});

cloudEnterpriseEnrolmentApiClient.use(addAuthHeader, throwOnErrorResponse);

export { cloudEnterpriseEnrolmentApiClient };
