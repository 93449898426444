import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { BetaTag, Divider, FaqSection } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { FAQ } from 'src/constants/FAQ';

export const JoinConnectForBusiness = () => {
  return (
    <AppPage.Container backLink>
      <div className="mx-auto max-w-ll pb-16 pt-64">
        <Typography
          variant="responsive-heading-lg"
          as="h1"
          className="text-center"
        >
          Join Connect for business <BetaTag />
        </Typography>
      </div>

      <AppPage.Content className="max-w-ll">
        <div className="flex flex-col gap-24">
          <div>
            <Typography variant="body-md-regular" className="text-center">
              As a team member, you&apos;ll get access to all features and
              benefits included with Connect. Your company will pay for your
              subscription.
            </Typography>
          </div>

          <div className="flex flex-col gap-16 bg-brand-sand-200 p-24">
            <Divider title="How can I join?" />

            <Typography variant="interface-sm-regular" className="text-center">
              To join Connect for business, ask the admin at your company to
              invite you.
            </Typography>

            <Typography
              variant="interface-sm-regular"
              className="mt-8 text-center"
            >
              Want to set up a business subscription?{' '}
              <Link2 inline to="/store/connect-for-business">
                Click here to get started.
              </Link2>
            </Typography>
          </div>
        </div>

        <div className="mt-128">
          <FaqSection
            componentLocation={
              ComponentLocations.CONNECT_FOR_BUSINESS
                .JOIN_CONNECT_FOR_BUSINESS_PAGE
            }
            sections={[
              // FAQ.connectForBusiness.whatHappensToNotesAndFiles(),
              FAQ.connectForBusiness.invitedToConnectForBusinessWillPriceChange(),
              FAQ.connectForBusiness.featuresAndBenefits(),
              FAQ.connectForBusiness.alreadyHaveConnect(),
              FAQ.connectForBusiness.whatHappensWithTrial(),
            ]}
          />
        </div>
      </AppPage.Content>
    </AppPage.Container>
  );
};
