import { createFileRoute, Outlet } from '@tanstack/react-router';

import { createQueryCheckoutAllowed } from 'src/api/queries';
import { CheckoutSteps } from 'src/apps/checkout/app/components/CheckoutSteps';
import { AppPage } from 'src/components/AppPage';

export const Route = createFileRoute('/_auth/_layout/store/dm-offer')({
  async loader({ context }) {
    await context.queryClient.ensureQueryData(createQueryCheckoutAllowed());
  },
  component: () => (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Header title="Sync and transfer files" />
      <CheckoutSteps secondDevice />
      <Outlet />
    </AppPage.Container>
  ),
});
