import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import { ArrowRight, Check, X } from 'phosphor-react';

import { ChangeEmailInfo } from 'src/api/endpoints/storeApi.types';
import {
  Button,
  NotificationBox,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { useUrlSearchParams } from 'src/hooks/useUrlSearchParams';
import { URLS } from 'src/utils/urls/urls';

import { useVerifyEmail } from './useVerifyEmail';
import { createLogoutRedirectUrl, isExpired } from './utils';

interface EmailChangeStatusContentProps {
  emailChangeInfo: ChangeEmailInfo;
  cancelEmailChange: () => void;
  confirmEmailChange: () => void;
  hasError: boolean;
}

export const EmailChangeStatusContent = ({
  emailChangeInfo,
  cancelEmailChange,
  confirmEmailChange,
  hasError,
}: EmailChangeStatusContentProps) => {
  const navigate = useNavigate();

  switch (emailChangeInfo.status) {
    case 'completed':
      return (
        <div className="flex flex-col gap-16" data-cy="completed-email-change">
          <Typography variant="body-md-regular">
            The email address for your account has been successfully updated.
            Please log in with your new email address (
            {emailChangeInfo.newEmail}) to access your my.remarkable account.
          </Typography>
          <Typography variant="body-md-regular">
            You will be logged out in 10 seconds.
          </Typography>
          <Button
            as="a"
            to={createLogoutRedirectUrl(emailChangeInfo.newEmail)}
            className="m-auto"
          >
            <span>Log in with new email</span>
            <ArrowRight color="#fff" />
          </Button>
        </div>
      );
    case 'pending': {
      if (!isExpired(emailChangeInfo)) {
        return (
          <div className="flex flex-col gap-16" data-cy="pending-email-change">
            <Typography variant="body-md-regular">
              The email linked to this account will change from{' '}
              <strong>{emailChangeInfo.oldEmail}</strong> to{' '}
              <strong>{emailChangeInfo.newEmail}</strong>.
            </Typography>
            <Typography variant="body-md-regular">
              If these details are correct, then confirm below or cancel if you
              don{"'"}t wish to proceed.
            </Typography>
            <NotificationBox variant="warning" title="Please note">
              It may take up to 8 hours for your new email address to appear on
              your device and companion apps.
            </NotificationBox>
            <div className="flex w-full flex-col justify-center gap-16 lm:flex-row ">
              <Button
                variant="secondary"
                onClick={cancelEmailChange}
                className="w-full lm:w-fit"
              >
                <span>Cancel</span>
                <X />
              </Button>
              <Button onClick={confirmEmailChange} className="w-full lm:w-fit">
                <span>Change email</span>
                <Check />
              </Button>
            </div>
            {hasError && (
              <NotificationBox variant="error" title="Something went wrong">
                Unfortunately, we were unable to handle your request. You can
                either go to Account settings to start over, or wait and try
                again in a minute. We are sorry about the inconvenience.
              </NotificationBox>
            )}
          </div>
        );
      }
      break;
    }
    case 'expired':
      return (
        <div className="flex flex-col gap-16" data-cy="expired-email-change">
          <NotificationBox variant="warning" title="Verification link expired">
            The link to change your email has expired. Please try again.
          </NotificationBox>
          <Button
            as="a"
            to={URLS.SETTINGS}
            onClick={(e) => {
              e.preventDefault();
              void navigate({ to: '/account' });
            }}
          >
            <span>Account settings</span>

            <ArrowRight color="#fff" />
          </Button>
        </div>
      );
    case 'cancelled':
      return (
        <div className="flex flex-col gap-16" data-cy="cancelled-email-change">
          <NotificationBox
            variant="warning"
            title="Verification link cancelled"
          >
            The link to change your email was cancelled upon request. Please go
            to account settings and initiate a new change of email.
          </NotificationBox>
          <Button
            className="m-auto"
            as="a"
            to={URLS.SETTINGS}
            onClick={(e) => {
              e.preventDefault();
              void navigate({ to: '/account' });
            }}
          >
            <span>Account settings</span>

            <ArrowRight color="#fff" />
          </Button>
        </div>
      );
  }
  return (
    <div className="flex flex-col gap-16" data-cy="error-email-change">
      <NotificationBox
        variant="warning"
        title="Something went wrong verifying your email"
      >
        Unfortunately, we were unable to update your email address. Please go to
        Account settings to try again.
      </NotificationBox>
      <Button
        as="a"
        to={URLS.SETTINGS}
        onClick={(e) => {
          e.preventDefault();
          void navigate({ to: '/account' });
        }}
      >
        <span>Account settings</span>

        <ArrowRight color="#fff" />
      </Button>
    </div>
  );
};

const getHeaderContent = (status: string) => {
  switch (status) {
    case 'completed':
      return {
        title: 'Email Address Updated',
        subtitle: 'Your email address has been successfully updated.',
      };
    case 'pending':
      return {
        title: 'Confirm Email Change',
        subtitle: 'Please confirm your new email address.',
      };
    case 'expired':
      return {
        title: 'Verification Link Expired',
        subtitle:
          'The link to change your email has expired. Please request a new one.',
      };
    case 'cancelled':
      return {
        title: 'Email Change Cancelled',
        subtitle: 'The email change process has been cancelled.',
      };
    default:
      return {
        title: 'Email Change',
        subtitle: 'Manage your email address associated with your account.',
      };
  }
};

export const VerifyEmailChange = () => {
  const {
    emailChangeData,
    cancelEmailChange,
    confirmEmailChange,
    isLoading,
    isError,
  } = useVerifyEmail(useUrlSearchParams()[0]?.['token'] || '');

  if (isLoading) {
    return <Spinner />;
  }

  if (!emailChangeData) {
    return <SomethingWentWrong />;
  }

  const { title, subtitle } = getHeaderContent(emailChangeData.status);

  return (
    <AppPage.Container>
      <AppPage.Header title={title} subtitle={subtitle} />
      <AppPage.Content className="flex items-center justify-center">
        <EmailChangeStatusContent
          emailChangeInfo={emailChangeData}
          cancelEmailChange={cancelEmailChange}
          confirmEmailChange={confirmEmailChange}
          hasError={isError}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
