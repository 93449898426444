import { HTMLProps } from 'react';

import { cx } from 'class-variance-authority';

import { ImageWithFallback, Sheet } from 'src/components';

interface NewsCardProps extends HTMLProps<HTMLDivElement> {
  imageSources: {
    type: string;
    url: string;
  }[];
  imageAlt: string;
  imageLink: string;
  headline?: string;
  imgClassname?: string;
  imageOnClick?: () => void;
}

export const NewsCard = ({
  imageAlt,
  imageLink,
  imageSources,
  children,
  imgClassname,
  imageOnClick,
  headline = 'News',
  ...props
}: NewsCardProps) => {
  return (
    <Sheet {...props} className={`!p-0 lg:flex-row ${props.className ?? ''}`}>
      <div className="max-h-[350px] w-full lg:max-h-full lg:w-[45%]">
        <a
          href={imageLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={imageOnClick}
        >
          <ImageWithFallback
            imgClassName={cx(
              'w-full h-full max-h-[350px] min-h-[220px] lg:max-h-full object-cover',
              imgClassname
            )}
            sources={imageSources}
            alt={imageAlt}
          />
        </a>
      </div>
      <div className="flex w-full flex-col justify-center gap-16 p-32 text-left lg:w-1/2 lg:flex-1">
        <span className="interface-sm-caps text-gray-600">{headline}</span>
        {children}
      </div>
    </Sheet>
  );
};
