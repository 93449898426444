import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useChangePassword } from 'src/api/queries';
import { Button, NotificationBox } from 'src/components';

export const EditPassword = () => {
  const changePassword = useChangePassword();

  return (
    <div id="edit-password">
      <Typography variant="heading-subtle-lg" as="h3">
        Password
      </Typography>

      <div className="flex flex-col justify-between  gap-16 lm:flex-row">
        <Typography variant="body-md-regular">
          Click the button below to change your account password. We&apos;ll
          send you an email with instructions for creating a new one.
        </Typography>

        {!changePassword.isSuccess && (
          <Button
            variant="tertiary"
            className="w-full self-start ls:w-fit"
            size="medium"
            data-cy="change-password-button"
            onClick={() => {
              changePassword.mutate();
              tracker.trackEvent(
                new ButtonClicked({
                  component_location: ComponentLocations.ACCOUNT.SECURITY_CARD,
                  text: 'Change Password',
                  action: 'change password',
                })
              );
            }}
            disabled={changePassword.isPending}
            loading={changePassword.isPending}
          >
            <span className="whitespace-nowrap">Change password</span>
          </Button>
        )}
      </div>

      {changePassword.isSuccess && (
        <NotificationBox
          variant="success"
          data-cy="reset-password-success-notification"
          className="mt-24"
          title="Password reset"
        >
          <Typography variant="body-sm-regular" as="p">
            We sent you an email with instructions to reset your password.
          </Typography>
        </NotificationBox>
      )}

      {changePassword.isError && (
        <NotificationBox
          variant="error"
          className="mt-24"
          title="Something went wrong"
          data-cy="reset-password-error-message"
        >
          <Typography variant="body-sm-regular" as="p">
            Something went wrong while changing your password. Please try again
            later.
          </Typography>
        </NotificationBox>
      )}
    </div>
  );
};
