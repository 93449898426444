import { ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';

import { Button, Modal } from 'src/components';

interface VerifyUnpairModalProps {
  onUnpair: () => void;
  title: string;
  isUnpairing: boolean;
  content?: ReactNode;
}

export const VerifyUnpairModal = ({
  onUnpair,
  title,
  isUnpairing,
  content,
}: VerifyUnpairModalProps) => (
  <Modal.Content>
    <div data-cy="verify-unpair-modal-content">
      <Typography variant="heading-md">{title}</Typography>
      {content}
      <div className="mt-32 flex w-full flex-col items-center gap-16 px-32 md:flex-row md:justify-evenly">
        <Modal.Close asChild>
          <Button
            data-cy="cancel-unpair-device-button"
            variant="secondary"
            className="w-full flex-1"
          >
            Cancel
          </Button>
        </Modal.Close>

        <Button
          data-cy="confirm-unpair-device-button"
          className="w-full flex-1"
          onClick={onUnpair}
          loading={isUnpairing}
        >
          Unpair
        </Button>
      </div>
    </div>
  </Modal.Content>
);
