import { ReactNode } from 'react';

interface CardContentProps {
  className?: string;
  children?: ReactNode;
  border?: boolean;
  borderTop?: boolean;
  dataCy?: string;
}

export const CardContent = ({
  className = '',
  children,
  border = false,
  borderTop,
  dataCy = 'subscription-card-content',
}: CardContentProps) => (
  <div
    data-cy={dataCy}
    className={`card-content ${className} ${
      border ? 'card-content-border' : ''
    } ${borderTop ? 'card-content-border--top' : ''}`}
  >
    {children}
  </div>
);
