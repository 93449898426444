import { Typography } from '@remarkable/ark-web';
import { X } from 'phosphor-react';

import { Button, ErrorPage } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const InvitationDeclined = () => {
  return (
    <ErrorPage.Container>
      <ErrorPage.ErrorIcon>
        <X size={32} />
      </ErrorPage.ErrorIcon>
      <ErrorPage.Heading>You&apos;ve declined the invitation</ErrorPage.Heading>

      <ErrorPage.Content>
        <Typography
          variant="body-md-regular"
          className="mx-auto max-w-lm text-center"
        >
          If you would like to join Connect for business at a later stage,
          please contact the person who sent you the invitation.
        </Typography>

        <Button
          variant="secondary"
          className="mx-auto mt-32"
          as="a"
          to={URLS.HOME}
        >
          Back to home
        </Button>
      </ErrorPage.Content>
    </ErrorPage.Container>
  );
};
