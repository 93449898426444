import { useAtom, useAtomValue } from 'jotai';

import {
  ConfigKey,
  defaultConfig,
  featureToggleKey,
  featureTogglePrefix,
} from 'src/config';
import { atomWithSessionStorage } from 'src/utils/atomWithSessionStorage';

// Only the keys that start with "Enable"
type FeatureToggles = {
  [key in ConfigKey as key extends `Enable${string}` ? key : never]: boolean;
};

export type FeatureToggleKey = keyof FeatureToggles;

const filterTogglesOnly = (config: typeof defaultConfig): FeatureToggles =>
  Object.fromEntries(
    Object.entries(config).filter(([key]) => {
      return key.startsWith(featureTogglePrefix);
    })
  ) as FeatureToggles;

export const environmentVariablesAtom = atomWithSessionStorage(
  featureToggleKey,
  filterTogglesOnly(defaultConfig)
);

export const useSetFeatureToggle = () => {
  const [value, setValue] = useAtom(environmentVariablesAtom);
  const setByKey = (key: keyof FeatureToggles, newValue: string | boolean) => {
    // Don't allow setting environment variables in production
    // We are not referring to the CONFIG.Environment here
    // because it can technically be changed in the devtool
    if (import.meta.env.PUBLIC_ENVIRONMENT === 'production') return;

    setValue({
      ...value,
      [key]: newValue,
    });
  };

  return setByKey;
};

export const useFeatureToggles = () => useAtomValue(environmentVariablesAtom);

export const useChangedFeatureTogglesList = () => {
  const toggles = useFeatureToggles();

  return Object.entries(toggles)
    .filter(
      ([key, value]) =>
        defaultConfig[key as keyof typeof defaultConfig] !== value
    )
    .map(([key]) => key);
};

export const useResetFeatureToggles = () => {
  const [, setValue] = useAtom(environmentVariablesAtom);

  return () => {
    // Don't allow resetting environment variables in production
    if (import.meta.env.PUBLIC_ENVIRONMENT === 'production') return;

    setValue(filterTogglesOnly(defaultConfig));
  };
};
