import { Price } from 'src/api/endpoints/storeApi.types';

export const getFormattedProductPrice = (prices: Price[], currency: string) => {
  const price = prices.find((p) => p.currency === currency.toUpperCase());

  if (!price) {
    return 'Unknown currency';
  }
  const { amount } = price;

  return formatAmount(amount, currency);
};

export const getFractionalAmount = (amount: number, currency: string) => {
  // TODO: Ideally retrieved from the 'countryData' document in Sanity
  const currencyExponent = (() => {
    switch (currency.toUpperCase()) {
      case 'KRW':
      case 'JPY':
        return 0;
      default:
        return 2;
    }
  })();

  return amount / Math.pow(10, currencyExponent);
};

export const formatAmount = (amount: number, currency = 'USD') => {
  const number = getFractionalAmount(amount, currency);

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(number);
};

export const formatAmountBrowserDefaultLocale = (
  amount: number,
  currency = 'USD'
) => {
  const number = getFractionalAmount(amount, currency);

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(number);
};

export const getCurrencyPrice = (
  priceList: Pick<Price, 'amount' | 'currency'>[],
  currency?: string,
  fallback = 'USD'
) => {
  if (priceList.length === 0) return undefined;

  const price =
    priceList?.find((p) => p.currency === currency) ??
    priceList?.find(({ currency }) => currency === fallback) ??
    priceList[0];

  return price;
};
