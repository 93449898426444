import { CheckoutsVerifyIntentResponse } from 'src/api/endpoints/storeApi.types';
import { CA_PROVINCES, CountryData, US_STATES } from 'src/utils/countryList';
import { formatAmount } from 'src/utils/productUtils';

interface ConnectSummaryProps {
  data: CheckoutsVerifyIntentResponse;
  country?: CountryData;
  state?: string;
}

export const ConnectSummary = ({
  data,
  country,
  state,
}: ConnectSummaryProps) => {
  const priceWithTax = formatAmount(
    data.data.cartPrice.total,
    data.data.currency
  );
  const priceWithoutTax = formatAmount(
    data.data.cartPrice.base,
    data.data.currency
  );
  const tax = formatAmount(data.data.cartPrice.tax, data.data.currency);

  const includesTax = data.data.tax.isIncluded;

  const isUs = country?.value === 'US';
  const isCanada = country?.value === 'CA';

  const stateLabel =
    isUs && state
      ? US_STATES.find((s) => s.value === state)?.label
      : isCanada && state
      ? CA_PROVINCES.find((s) => s.value === state)?.label
      : state;

  const countryLabel = isUs && stateLabel ? 'US' : country?.label ?? '';

  const taxLocation = stateLabel
    ? `${stateLabel}, ${countryLabel}`
    : countryLabel;

  return (
    <>
      <div
        className="flex flex-row items-center justify-between font-semibold"
        data-cy="connect-plan-row-price"
      >
        <p className="m-0 text-16">Connect subscription</p>
        <p className="m-0 text-16" data-cy="connect-plan-row-price-amount">
          {includesTax ? priceWithTax : priceWithoutTax}
        </p>
      </div>
      <div className="flex flex-row items-center justify-between">
        <p className="m-0 text-16">
          {isUs ? 'Sales tax' : 'VAT'} to {taxLocation}
        </p>
        <p className="m-0 text-16">{includesTax ? 'included' : tax}</p>
      </div>
      {!includesTax && (
        <div className="flex flex-row items-center justify-between font-semibold">
          <p className="m-0 text-16">Total</p>
          <p className="m-0 text-16">{priceWithTax}</p>
        </div>
      )}
    </>
  );
};
