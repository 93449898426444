import { useRouter } from '@tanstack/react-router';
import { ArrowCircleLeft } from 'phosphor-react';

import { Link2 } from './Link2';

export const BackLink = ({ className }: { className: string }) => {
  const router = useRouter();

  return (
    <Link2
      as="button"
      onClick={() => router.history.back()}
      className={className}
    >
      <ArrowCircleLeft weight="fill" />
      <span>Back</span>
    </Link2>
  );
};
