import {
  Children,
  cloneElement,
  Fragment,
  HTMLProps,
  isValidElement,
  ReactNode,
} from 'react';

import { Check } from '@phosphor-icons/react';
import { Link, useLocation } from '@tanstack/react-router';
import clsx from 'clsx';
import { Circle } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';

export function Step(props: {
  checked?: boolean;
  active?: boolean;
  disabled?: boolean;
  to?: string;
  children: ReactNode;
  orientation?: 'vertical' | 'horizontal';
}) {
  const location = useLocation();
  const isActive = props.active ?? location.pathname === props.to;
  if (props.to) {
    return (
      <div className="relative">
        <Link
          to={props.disabled ? '#' : props.to}
          onClick={(e) => {
            if (props.disabled) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          className={clsx(
            'group outline-none',
            props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          tabIndex={props.disabled ? -1 : undefined}
        >
          <Indicator {...props} active={isActive} />
        </Link>
      </div>
    );
  } else {
    return (
      <div className="relative">
        <Indicator {...props} />
      </div>
    );
  }
}

function Indicator(props: {
  checked?: boolean;
  disabled?: boolean;
  active?: boolean;
  children?: ReactNode;
  orientation?: 'vertical' | 'horizontal';
}) {
  const isVertical = props.orientation == 'vertical';

  return (
    <>
      <div
        className={cn(
          'flex h-[28px] w-[28px] items-center justify-center rounded-full outline-none transition-all',
          {
            'border-neutral-dark-5 bg-neutral-dark-1 text-white group-hover:border-neutral-dark-5 group-hover:bg-neutral-dark-5 group-focus:bg-pen-blue-light group-hover:group-focus:border-neutral-dark-5 group-hover:group-focus:bg-neutral-dark-5':
              props.checked && !props.active,
            'border-pen-blue bg-pen-blue text-white group-hover:border-pen-blue-dark group-hover:bg-pen-blue-dark group-focus:border-pen-blue-light group-focus:bg-pen-blue-light group-hover:group-focus:bg-pen-blue-dark':
              props.checked && props.active,
            'border-2 border-pen-blue group-hover:border-pen-blue-dark group-focus:bg-pen-blue-light group-hover:group-focus:border-pen-blue-dark group-hover:group-focus:bg-transparent':
              !props.checked && props.active,
            'border-2 border-neutral-dark-1 group-focus:border-pen-blue-light':
              !props.checked && !props.active,
            'border-neutral-dark-1': props.disabled,
          }
        )}
      >
        {props.checked ? (
          <Check size={16} weight="bold" />
        ) : props.active ? (
          <Circle size={16} className="text-pen-blue" weight="fill" />
        ) : (
          <svg> </svg>
        )}
      </div>

      <div
        className={cn('absolute', {
          'left-[150%]': isVertical,
          'left-[50%]': !isVertical,
          'bottom-2': isVertical,
          '-bottom-40': !isVertical,
        })}
      >
        <span
          className={clsx('whitespace-nowrap text-center lm:inline', {
            'font-medium text-pen-blue group-hover:text-pen-blue-dark':
              props.active,
            'text-neutral-dark-3': props.disabled,
            'ml-[-50%]': !isVertical,
            hidden: !isVertical,
          })}
        >
          {props.children}
        </span>
      </div>
    </>
  );
}

export function Stepper({
  className,
  children,
  orientation = 'horizontal',
  ...props
}: HTMLProps<HTMLDivElement> & { orientation?: 'vertical' | 'horizontal' }) {
  const isVertical = orientation == 'vertical';
  return (
    <div
      className={cn(
        'flex w-full flex-wrap items-center justify-between gap-4 px-32 pb-32 pt-16 lm:px-64 lm:pb-64',
        className,
        {
          'flex-col': isVertical,
          'content-baseline': isVertical,
        }
      )}
      {...props}
    >
      {Children.toArray(children).map((child, index) => (
        <Fragment key={index}>
          {index > 0 && (
            <div
              className={cn(
                'grow border-l-2 border-t-2 border-neutral-light-8',
                { 'h-16': isVertical }
              )}
            />
          )}
          {isValidElement(child)
            ? //eslint-disable-next-line
              cloneElement(child, { orientation } as {})
            : child}
        </Fragment>
      ))}
    </div>
  );
}
