import { ArrowRight, Timer } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import usingRemarkableWebp from 'src/assets/img/using-remarkable.webp';
import { Button, Link, NewsCard } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

const images = {
  usingRemarkable: [
    {
      type: 'webp',
      url: usingRemarkableWebp,
    },
  ],
};

export function StartLearning() {
  return (
    <div className="flex w-full max-w-screen-lg flex-col items-center px-16">
      <Typography
        as="h2"
        variant="responsive-heading-md"
        className="my-32 text-center"
      >
        Learn the basics
      </Typography>
      <NewsCard
        imageSources={images.usingRemarkable}
        imageAlt={''}
        imageLink={URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_WRITING_TOOLS}
        headline=""
        className="w-full bg-neutral-light-4"
        imageOnClick={() => {
          tracker.trackEvent(
            new LinkClicked({
              component_location: ComponentLocations.CHECKOUT.CONFIRMATION,
              link_source:
                URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_WRITING_TOOLS,
              text: '[image - using remarkable]',
            })
          );
          tracker.identify({
            myrm_utm_parameters: JSON.stringify({
              myrm_utm_source: 'connect confirmation',
              myrm_utm_campaign: 'how to use writing tools',
              myrm_utm_medium: 'learning content',
            }),
          });
        }}
      >
        <Typography
          variant="body-md-regular"
          className="flex items-center gap-8 text-interface-text-muted"
        >
          <Timer className="inline-block" size={24} /> 2 min watch
        </Typography>
        <Typography variant="responsive-heading-md">
          How to use writing tools on reMarkable
        </Typography>

        <Link
          to={URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_WRITING_TOOLS}
          onClick={() => {
            tracker.trackEvent(
              new LinkClicked({
                component_location: ComponentLocations.CHECKOUT.CONFIRMATION,
                link_source:
                  URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_WRITING_TOOLS,
                text: 'Start learning',
              })
            );
            tracker.identify({
              myrm_utm_parameters: JSON.stringify({
                myrm_utm_source: 'connect confirmation',
                myrm_utm_campaign: 'how to use writing tools',
                myrm_utm_medium: 'learning content',
              }),
            });
          }}
        >
          <span>Start learning</span> <ArrowRight className="ml-4" />{' '}
        </Link>
      </NewsCard>
      <Button
        variant="tertiary-neutral"
        size="small"
        as="a"
        className="mt-16"
        onClick={() => {
          tracker.trackEvent(
            new LinkClicked({
              component_location: ComponentLocations.CHECKOUT.CONFIRMATION,
              link_source: URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS,
              text: 'View all (8)',
            })
          );
          tracker.identify({
            myrm_utm_parameters: JSON.stringify({
              myrm_utm_source: 'connect confirmation',
              myrm_utm_campaign: 'how to use writing tools',
              myrm_utm_medium: 'learning content',
            }),
          });
        }}
        to={URLS.RM_COM_USING_REMARKABLE_LEARN_THE_BASICS}
      >
        <span>View all (8)</span> <ArrowRight />
      </Button>
    </div>
  );
}
