import { ComponentProps } from 'react';

import { Typography } from '@remarkable/ark-web';

export const TextBody = ({
  children,
  ...props
}: Partial<ComponentProps<typeof Typography>>) => (
  <Typography as="p" variant="body-md-regular" {...props}>
    {children}
  </Typography>
);
