import { Typography } from '@remarkable/ark-web';
import { Plus } from 'phosphor-react';

import pairDeviceWebp from 'src/assets/img/rM_sync_files_DM.webp';
import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { StackedSheet } from './StackedSheet';

export const WelcomeAndPairDeviceHero = () => {
  return (
    <StackedSheet dataCy="pair-device-hero">
      <div className="flex flex-col gap-32 lm:flex-row lm:gap-0 lm:text-left">
        <div className="m-auto flex flex-col gap-16 lm:w-1/2">
          <Typography variant="responsive-heading-xl" className="m-0">
            Pair your device
          </Typography>
          <Typography variant="body-md-regular">
            Pair a new device with your account, to seamlessly import, and back
            up your files and set up our most powerful cloud features.
          </Typography>
          <Button
            as="a"
            variant="primary"
            className="w-full lm:w-fit"
            to={URLS.DEVICE_REMARKABLE_CONNECT}
            data-cy="pair-device-button"
          >
            <span>Pair device</span> <Plus />
          </Button>
        </div>
        <img
          src={pairDeviceWebp}
          className="max-h-[340px] object-cover lm:w-1/2 lm:pl-32"
        />
      </div>
    </StackedSheet>
  );
};
