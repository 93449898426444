import { ArrowsClockwise } from '@phosphor-icons/react';
import { Cloud, NotePencil, ShieldCheck, Tag } from 'phosphor-react';

export const valueProps = {
  connectForBusiness: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Cloud,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
    exclusiveOffers: {
      title: 'Exclusive offers',
      id: 'exclusiveOffers',
      icon: Tag,
    },
  },
  connect: {
    unlimitedCloudStorage: {
      title: 'Unlimited cloud storage',
      id: 'unlimitedCloudStorage',
      icon: Cloud,
    },
    automaticSync: {
      title: 'Automatic sync',
      id: 'automaticSync',
      icon: ArrowsClockwise,
    },
    createAndEditInOurApps: {
      title: 'Create and edit in our apps',
      id: 'createAndEditInOurApps',
      icon: NotePencil,
    },
    remarkableProtectionPlan: {
      title: 'reMarkable Protection Plan',
      id: 'remarkableProtectionPlan',
      icon: ShieldCheck,
    },
    exclusiveOffers: {
      title: 'Exclusive offers',
      id: 'exclusiveOffers',
      icon: Tag,
    },
  },
};
