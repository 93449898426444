import { ArrowRight } from '@phosphor-icons/react';

import { DeleteAccountWrapper } from 'src/apps/account/app/components/DeleteAccount/DeleteAccountWrapper';
import { DeleteAccountProps } from 'src/apps/account/app/components/DeleteAccount/types';
import { Button } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export function DeleteAccountLanding(props: DeleteAccountProps) {
  const { variant } = props;

  return (
    <DeleteAccountWrapper title="Delete your account?" showDiamondIcon={true}>
      <div className="flex flex-col space-y-32">
        <p className="responsive-body-md my-0">
          Deleting your account, along with all associated data,{' '}
          <strong>is permanent and can&apos;t be undone</strong>. This will also
          end any active subscriptions and terminate the Terms and Conditions
          for reMarkable Accounts.
        </p>
        <p className="responsive-body-md my-0">
          Files you have stored on your paper tablet won&apos;t be deleted.
        </p>
      </div>

      <div className="flex flex-row space-x-16">
        {variant === 'browser' && (
          <Button as="a" to={URLS.HOME} variant="primary" size="medium">
            <span>Keep my account</span>
          </Button>
        )}
        <Button
          as="a"
          to={
            variant === 'browser'
              ? URLS.ACCOUNT_DELETE_CONFIRMATION
              : URLS.ACCOUNT_DELETE_CONFIRMATION_APP
          }
          variant="secondary"
          data-cy="delete-account-continue"
          size="medium"
        >
          <span>Continue</span> <ArrowRight />
        </Button>
      </div>
    </DeleteAccountWrapper>
  );
}
