import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';

import { queryKeys } from 'src/api/queries';
import { RmClaims } from 'src/api/queries/user';

export async function isFleetUser(auth0: Auth0ContextInterface<User>) {
  const accessToken = await auth0.getAccessTokenSilently();

  const info = jwtDecode<RmClaims>(accessToken);

  if (!info) {
    throw new Error("Couldn't get access token");
  }

  if (!info.org_id) {
    return false;
  }

  if (info.permissions?.includes('read:members')) {
    return true;
  }

  return false;
}

export function useIsFleetUser() {
  const auth = useAuth0();
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.user.isFleetUser,
    queryFn: () => {
      if (!auth.user) {
        return false;
      }
      return isFleetUser(auth);
    },
  });
}
