import { HTMLProps, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import { MutateOptions } from '@tanstack/react-query';

import { usePasscodeReset } from 'src/api/queries';
import { Button, Link } from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

const Layout = ({
  children,
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) => (
  <div
    className={
      'my-24 border border-gray-300 p-24 text-left shadow-md ' + className
    }
    {...rest}
  >
    {children}
  </div>
);

export const PasscodeReset = () => {
  const { pendingRequests, approveRequest, denyRequest } = usePasscodeReset();
  const [isError, setIsError] = useState(false);

  // On approve or deny request
  const mutateOptions: MutateOptions<unknown, unknown, string> = {
    onSuccess: () => setIsError(false),
    onError: () => setIsError(true),
  };

  const requestId = pendingRequests.data?.[0]?.RequestID;

  if (approveRequest.isSuccess) {
    return (
      <Layout data-cy="passcode-reset-success-card">
        <Typography variant="body-lg-bold" as="h3">
          Passcode has been reset
        </Typography>
        <Typography variant="body-md-regular" as="p">
          Follow the instructions on the device to set a new passcode.
        </Typography>

        <Button
          className="mt-16"
          variant="secondary"
          size="medium"
          onClick={() => {
            approveRequest.reset();
          }}
          as="button"
        >
          Close
        </Button>
        <div className="reset-row"></div>
      </Layout>
    );
  }

  if (!requestId) return null;

  if (isError)
    return (
      <Layout data-cy="passcode-reset-error-card">
        <Typography variant="body-lg-bold" as="h3">
          Something went wrong
        </Typography>
        <Typography variant="body-md-regular" as="p">
          Please try to reset the passcode from your device again, or{' '}
          <Link to={SUPPORT_URL.HOME} inline>
            contact support
          </Link>{' '}
          if the problem persists. We are sorry about the inconvenience.
        </Typography>
        <Button
          className="mt-16"
          variant="tertiary"
          size="medium"
          onClick={() => {
            approveRequest.isError
              ? approveRequest.mutate(requestId, mutateOptions)
              : denyRequest.mutate(requestId, mutateOptions);
          }}
          loading={approveRequest.isPending || denyRequest.isPending}
          as="button"
        >
          Retry
        </Button>
      </Layout>
    );

  return (
    <Layout data-cy="passcode-reset-card">
      <Typography variant="body-lg-bold" as="h3">
        Reset passcode
      </Typography>
      <Typography variant="body-md-regular" as="p">
        Are you trying to reset the passcode on your device?
      </Typography>
      <div className="mt-16 flex gap-16">
        <Button
          data-cy="deny-passcode-reset-button"
          variant="secondary"
          size="medium"
          onClick={() => denyRequest.mutate(requestId, mutateOptions)}
          loading={denyRequest.isPending}
        >
          Deny
        </Button>
        <Button
          variant="tertiary"
          data-cy="reset-passcode-button"
          size="medium"
          onClick={() => approveRequest.mutate(requestId, mutateOptions)}
          loading={approveRequest.isPending}
        >
          Approve
        </Button>
      </div>
    </Layout>
  );
};
