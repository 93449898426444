import clsx from 'clsx';

export const StepIndicator = ({
  numSteps,
  activeStep,
  className,
}: {
  numSteps: number;
  activeStep: number;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center space-x-8 ls:space-x-16',
        className
      )}
    >
      {Array.from({ length: numSteps }, (_, index) => (
        <div
          key={index}
          className={`h-8 w-8 rounded-full ls:h-12 ls:w-12 ${
            activeStep === index
              ? 'bg-black'
              : 'border border-black bg-transparent'
          }`}
        />
      ))}
    </div>
  );
};
