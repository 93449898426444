import { FAQ, FAQLine } from 'src/components';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

export const IntegrationsFAQ = () => {
  return (
    <FAQ>
      <FAQLine
        text="How do I set up Google Drive, Dropbox and OneDrive?"
        target={SUPPORT_URL.ARTICLES.INTEGRATIONS}
      />

      <FAQLine
        text="How to update your reMarkable"
        target={SUPPORT_URL.ARTICLES.HOW_TO_UPDATE_YOUR_REMARKABLE}
      />
    </FAQ>
  );
};
