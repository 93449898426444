import { User } from '@auth0/auth0-react';

import { Identity } from 'src/api/endpoints/storeApi.types';
import { RmClaims } from 'src/api/queries/user';

const getEmail = (data: User) => data?.email ?? '';
const getEmailFromClaims = (data: RmClaims) =>
  data['https://auth.remarkable.com/email'] ?? '';

const getLinkableUserId = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/linkable'];

const isLinkable = (data: User | RmClaims) => !!getLinkableUserId(data);

const isAuth0User = (data: User | RmClaims) => !!data.sub?.startsWith('auth0|');

const isGoogleUser = (data: User | RmClaims) =>
  !!data.sub?.startsWith('google-oauth2|');

const isAppleUser = (data: User | RmClaims) => !!data.sub?.startsWith('apple|');

const isFacebookUser = (data: User | RmClaims) =>
  !!data.sub?.startsWith('facebook|');

const isSocialUser = (data: User | RmClaims) =>
  isGoogleUser(data) || isAppleUser(data) || isFacebookUser(data);

const isSAMLUser = (data: User | RmClaims) => !!data.sub?.startsWith('samlp|');

/** Used for tracking */
const getUserAccountProvider = (data: User | RmClaims) => {
  if (isAuth0User(data)) return 'Auth0';
  if (isGoogleUser(data)) return 'Google';
  if (isAppleUser(data)) return 'Apple';
  if (isFacebookUser(data)) return 'Facebook';
  if (isSAMLUser(data)) return 'SAML';
  return 'unknown';
};

const getLinkableAccountProvider = (data: User | RmClaims) => {
  const linkableUser: User = { sub: getLinkableUserId(data) };

  return getUserAccountProvider(linkableUser);
};

const getOrganizationId = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/organization'];

const isOrganizationMember = (data: User | RmClaims) =>
  !!getOrganizationId(data);

const isLoggedInToOrganization = (data: User | RmClaims) => !!data.org_id;

const getDomainClaimedConnection = (data: User | RmClaims) =>
  data['https://auth.remarkable.com/domain_claimed_by_connection'];

const isDomainClaimedByConnection = (data: User | RmClaims) =>
  !!getDomainClaimedConnection(data);

const getSAMLIdentity = (identities: Identity[]) =>
  identities.find((i) => i.provider === 'samlp');

const hasSAMLIdentity = (identities: Identity[]) =>
  !!getSAMLIdentity(identities);

const canEditName = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const canEditEmail = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const canEditPassword = (data: User | RmClaims, identities: Identity[]) =>
  isAuth0User(data) && !hasSAMLIdentity(identities);

const getPermissions = (data: User | RmClaims) => data['permissions'];

const hasPermissionReadMembers = (data: User | RmClaims) =>
  !!getPermissions(data)?.includes('read:members');

export const userHelpers = {
  canEditEmail,
  canEditName,
  canEditPassword,
  getDomainClaimedConnection,
  getEmail,
  getEmailFromClaims,
  getLinkableAccountProvider,
  getLinkableUserId,
  getOrganizationId,
  getPermissions,
  getSAMLIdentity,
  getUserAccountProvider,
  hasPermissionReadMembers,
  hasSAMLIdentity,
  isAppleUser,
  isAuth0User,
  isDomainClaimedByConnection,
  isFacebookUser,
  isGoogleUser,
  isLinkable,
  isLoggedInToOrganization,
  isOrganizationMember,
  isSAMLUser,
  isSocialUser,
};
