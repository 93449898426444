export type BeforeRequestHook = (
  requestConfigDraft: RequestConfig
) => Promise<RequestConfig>;

export type AfterResponseHook = (
  response: Response
) => Response | Promise<Response>;

export type BeforeErrorHook = (
  error: HTTPError | Error
) => void | Promise<void>;

export type Params = Record<
  string,
  string | number | boolean | undefined | null
>;

export interface CreateApiClientOptions {
  prefixUrl?: string;
  headers?: Params;
  searchParams?: Params;
  hooks?: {
    beforeRequest?: BeforeRequestHook[];
    afterResponse?: AfterResponseHook[];
    beforeError?: BeforeErrorHook[];
  };
  credentials?: RequestCredentials;
}

export interface ApiClientResponse extends Promise<Response> {
  json: <T>() => Promise<T>;
  text: () => Promise<string>;
}

type JSONValue = string | number | boolean | null | JSONObject | JSONArray;
type JSONObject = { [key: string]: JSONValue };
type JSONArray = JSONValue[];

export type RequestConfig = Omit<RequestInit, 'headers'> & {
  json?: unknown;
  timeout?: number;
  searchParams?: Params;
  headers?: Params;
  prefixUrl: string;
  path: string;
};

export type ApiClientOptions = Partial<RequestConfig>;

export interface ApiClient {
  post: (path: string, options?: ApiClientOptions) => ApiClientResponse;
  put: (path: string, options?: ApiClientOptions) => ApiClientResponse;
  get: (path: string, options?: ApiClientOptions) => ApiClientResponse;
  delete: (path: string, options?: ApiClientOptions) => ApiClientResponse;
  patch: (path: string, options?: ApiClientOptions) => ApiClientResponse;
}

/** Extended Promise that exposes `.json` and `.text` methods directly */
export class ApiClientResponse extends Promise<Response> {
  /** Fallbacks to null if parsing fails */
  json = <T>() =>
    this.then((response) => response.json().catch(() => null) as Promise<T>);
  /** Fallbacks to empty string if conversion fails */
  text = () => this.then((response) => response.text().catch(() => ''));

  constructor(
    executor: (
      resolve: (value: Response | PromiseLike<Response>) => void,
      reject: (reason?: unknown) => void
    ) => void
  ) {
    super(executor);
  }
}

/** Extension of Error that is thrown when a Response is not ok */
export class HTTPError extends Error {
  constructor(public response: Response, public request: Request) {
    super(response.statusText);
  }
}
