import { useEffect } from 'react';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { RouterProvider } from '@tanstack/react-router';

import { UserAuthenticated } from './ampli';
import { tracker } from './analytics/tracker';
import { ApiProvider } from './api/useApi';
import { DragStateProvider } from './apps/myFiles/app/providers/DragStateProvider';
import { auth0Client, resolveAuth0Client } from './auth0Client';
import { CONFIG } from './config';
import { router } from './router';

export function App() {
  if (!CONFIG.Auth0ClientID || !CONFIG.Auth0Domain) {
    throw new Error('Missing auth0 config');
  }

  return (
    <Auth0Provider
      clientId={CONFIG.Auth0ClientID}
      domain={CONFIG.Auth0Domain}
      skipRedirectCallback={
        // Integration authentication flows return with code and state params
        // that should be ignored by Auth0.

        // Google Drive and Dropbox will return to the same url, but with a
        // callback search param.
        (window.location.pathname.includes('/integrations') &&
          window.location.search.includes('callback')) ||
        // Microsoft OneDrive returns on a `/callback` subroute, which on our
        // end is redirected back to the parent route with the same callback
        // search param as the other two integration flows. Who knows why it
        // is different.
        window.location.pathname.includes('/integrations/callback')
      }
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: CONFIG.Auth0Audience || CONFIG.CloudApiUrl,
      }}
    >
      <ApiProvider withDevtools={true}>
        <DragStateProvider>
          <InnerApp />
        </DragStateProvider>
      </ApiProvider>
    </Auth0Provider>
  );
}

function InnerApp() {
  const auth = useAuth0();

  useEffect(() => {
    if (auth.isLoading) return;

    async function trackAuthenticated() {
      const token = await auth.getAccessTokenSilently();
      void tracker.trackEvent(
        new UserAuthenticated({
          auth0_jwt: token,
        })
      );
    }

    if (auth.isAuthenticated) {
      void trackAuthenticated();
    }

    resolveAuth0Client(auth);
  }, [auth, auth.isLoading]);

  return <RouterProvider router={router} context={{ auth: auth0Client }} />;
}
