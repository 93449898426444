import { Typography } from '@remarkable/ark-web';
import { useAtom } from 'jotai';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import {
  PaymentInterval,
  Subscription,
} from 'src/api/endpoints/storeApi.types';
import { atomWithLocalStorage } from 'src/utils/atomWithLocalStorage';
import { URLS } from 'src/utils/urls/urls';

import { Link } from '.';
import { isStripeSubscription } from '../utils/isStripeSubscription';
import { NotificationBanner } from './NotificationBanner';

const notificationDismissedAtom = atomWithLocalStorage<boolean>(
  'annualNotificationDismissed',
  false
);

interface ChangeToAnnualBannerProps {
  subscription?: Subscription;
  componentLocation: string;
  className?: string;
}

export const ChangeToAnnualBanner = ({
  subscription,
  componentLocation,
  className,
}: ChangeToAnnualBannerProps) => {
  const [notificationDismissed, setNotificationDismissed] = useAtom(
    notificationDismissedAtom
  );

  if (!subscription || !isStripeSubscription(subscription)) {
    return null;
  }

  // Seats subscriptions are not supported yet
  if (subscription.managementType === 'seats') return null;

  const hasMonthlySubscription =
    subscription.paymentInformation?.paymentInterval ===
    PaymentInterval.MONTHLY;

  const canChangePaymentInterval = subscription.access.canChangePaymentInterval;

  if (
    !hasMonthlySubscription ||
    notificationDismissed ||
    !canChangePaymentInterval
  ) {
    return null;
  }

  const linkText = 'switch to annual billing';

  return (
    <div className={className} data-cy="change-to-annual-banner">
      <NotificationBanner onClick={() => setNotificationDismissed(true)}>
        <div>
          <Typography variant="heading-subtle-lg" as="h3">
            Annual billing now available
          </Typography>

          <Typography variant="body-md-regular">
            Get two months free when you{' '}
            <Link
              inline
              onClick={() =>
                tracker.trackEvent(
                  new LinkClicked({
                    component_location: componentLocation,
                    link_source: URLS.SUBSCRIPTION_PAYMENT,
                    text: linkText,
                  })
                )
              }
              to={URLS.SUBSCRIPTION_PAYMENT}
              data-cy="change-to-annual-banner-link"
            >
              {linkText}
            </Link>
          </Typography>
        </div>
      </NotificationBanner>
    </div>
  );
};
