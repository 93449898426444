import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { URLS } from 'src/utils/urls/urls';

import { Button } from './Button';
import { Divider } from './Divider';
import { Sheet } from './Sheet';
import { BetaTag } from './Tag';

export const ConnectForBusinessLinkCard = () => {
  return (
    <Sheet data-cy="connect-for-business-card" className="gap-24">
      <Typography
        variant="interface-sm-caps-medium"
        className="my-0 text-pen-blue"
      >
        NEW
      </Typography>

      <div className="flex items-center gap-16">
        <Typography as="h3" variant="heading-subtle-2xl">
          Connect for business
        </Typography>

        <BetaTag />
      </div>

      <Divider className="!my-0" />

      <Typography variant="body-md-regular" className="my-0">
        Manage your team&apos;s subscriptions from one account. Subscription
        administration and centralized billing, all in one place.
      </Typography>

      <Typography variant="body-md-regular" className="my-0">
        Team members get access to all features and benefits included with
        Connect.
      </Typography>

      <Button
        variant="tertiary"
        size="medium"
        data-cy="navigate-to-c4b-button"
        as="a"
        to={URLS.STORE_CONNECT_FOR_BUSINESS_LANDING}
      >
        <span>Learn more</span> <ArrowRight />
      </Button>
    </Sheet>
  );
};
