import { Typography } from '@remarkable/ark-web';
import { ArrowLeft, ArrowRight } from 'phosphor-react';

import protectionPlanPng from 'src/assets/img/protection_plan.png';
import protectionPlanWebp from 'src/assets/img/protection_plan.webp';
import { ImageWithFallback } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { LinkExternal } from 'src/components/LinkExternal';
import { URLS } from 'src/utils/urls/urls';

import { StepIndicator } from './StepIndicator';

export const MrfStep3 = () => {
  return (
    <div className="flex h-full flex-col justify-between" data-cy="mrf-step-3">
      <div className="grid grid-rows-2 text-center ls:px-64 ls:pt-64 ls:text-left lm:grid-cols-2 lm:grid-rows-1">
        <div>
          <Typography variant="heading-subtle-xl">You&apos;ll lose</Typography>
          <Typography variant="responsive-heading-lg" className="my-16" as="h1">
            Device protection
          </Typography>
          <Typography variant="responsive-body-sm">
            Your reMarkable Protection Plan cover is only valid with Connect.
          </Typography>
          <Typography className="ls:mt-16" variant="responsive-body-sm">
            Read more about the benefits with Connect{' '}
            <LinkExternal inline to={URLS.RM_COM_STORE_CONNECT}>
              here
            </LinkExternal>
            .
          </Typography>
        </div>
        <ImageWithFallback
          className="h-[250px] lm:h-auto"
          sources={[
            { url: protectionPlanPng, type: 'image/png' },
            { url: protectionPlanWebp, type: 'image/webp' },
          ]}
          alt="Full backup"
        />
      </div>
      <div className="grid w-full grid-cols-3 py-4 align-middle">
        <div>
          <Link2
            size="medium"
            variant="tertiary-neutral"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-2' }}
          >
            <>
              <ArrowLeft /> <span> Back</span>
            </>
          </Link2>
        </div>
        <StepIndicator numSteps={5} activeStep={2} />

        <div className="flex w-full justify-end">
          <Link2
            size="medium"
            variant="tertiary-neutral"
            data-cy="mrf-next-step-3"
            as="button"
            to="."
            search={{ cancel_subscription: 'step-4' }}
          >
            <>
              <span>Next</span> <ArrowRight />
            </>
          </Link2>
        </div>
      </div>
    </div>
  );
};
