/* tslint:disable */

/* eslint-disable */
// @ts-nocheck

/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull my_remarkable'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/remarkable/my%20remarkable/implementation/my_remarkable)
 */
import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: '36b3eb12a35000de60ece02daaeec554',
  production: '8a94f84b9a06bb96bb7c6cc6fb26c780',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'my_remarkable',
    versionId: '9388a389-3daa-485b-8694-6a59310c8100',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
  serverZone: amplitude.Types.ServerZone.EU,
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Set when  authenticating, same as user id. Workaround to get user id on every event in bigquery
   */
  authenticated_user_id?: string;
  /**
   * Property to assing users to experiments. Splitting on the way in so
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  experiments?: {
    id?: string;
    name?: string;
    variant?: string;
  }[];
  myrm_has_referral?: string;
  myrm_utm_campaign?: string;
  myrm_utm_content?: string;
  myrm_utm_id?: string;
  myrm_utm_medium?: string;
  myrm_utm_source?: string;
  myrm_utm_term?: string;
  myrm_utm_variant?: string;
  subscription?: string;
  /**
   * Describes the payment interval of the subscription.
   *  **'monthly' | 'annually' | 'none'**
   */
  subscription_payment_interval?: string;
  /**
   * Type of role the user has.
   *
   * **'owner' | 'member' | 'none'**
   */
  subscription_role?: string;
  /**
   * The status of the subscription as given by Stripe.
   *
   * **'active' | 'trialing' | 'past_due' | 'canceled' | 'unpaid' | 'incomplete' | 'none'**
   */
  subscription_status?: string;
  /**
   * Describes the type of subscription the user has.
   *
   * **'free connect' | 'connect' | 'connect for business' | 'none'**
   */
  subscription_type?: string;
  test_sfmc_contact_key?: string;
  test_sfmc_contact_key_email?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  utm_variant?: string;
}

export interface AccountCreatedProperties {
  created_at: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  origin?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pathname?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
}

export interface AccountLinkingFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Legacy first, SSO first |
   */
  account_linking_flow: 'Legacy first' | 'SSO first';
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  error_message: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  primary_account_provider?: string;
}

export interface AccountLinkingStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Legacy first, SSO first |
   */
  account_linking_flow: 'Legacy first' | 'SSO first';
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  primary_account_provider?: string;
}

export interface AccountLinkingSucceededProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Legacy first, SSO first |
   */
  account_linking_flow: 'Legacy first' | 'SSO first';
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  primary_account_provider?: string;
}

export interface AccountUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  action?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  origin?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pathname?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  updated_at?: string;
}

export interface ButtonClickedProperties {
  /**
   * Used to describe what the button does...
   *  i.e text: "Cancel", action: "cancel subscription"
   */
  action?: string;
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  /**
   * Where the user ends up after clicking a link
   */
  link_source?: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  /**
   * Text of the component, that the user sees.
   */
  text?: string;
}

export interface CheckoutStepCompletedProperties {
  checkout_step: string;
  /**
   * Property used for discounts and promotional campaigns for our products.
   */
  coupon?: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  products?: any[];
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface CheckoutStepFailedProperties {
  checkout_step: string;
  /**
   * Property used for discounts and promotional campaigns for our products.
   */
  coupon?: string;
  error_code: string;
  error_message: string;
  error_name: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface EnrolledToConnectForBusinessProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  previous_subscription_status: string;
  previous_subscription_type: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  subscription_created_at: string;
}

export interface ExperimentAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experiment_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  experiment_variant?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  origin?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pathname?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
}

export interface InvitationAcceptedProperties {
  invitation_type: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface InvitationSentProperties {
  invitation_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  number_of_invitations: number;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface LinkClickedProperties {
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  link_source: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  /**
   * Text of the component, that the user sees.
   */
  text?: string;
}

export interface PageviewProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface PaymentFailedProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface PaymentSucceededProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $price?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $revenue?: number;
  /**
   * Property used for discounts and promotional campaigns for our products.
   */
  coupon?: string;
  currency: string;
  order_id: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  products: {
    margin?: {
      [k: string]: any;
    };
    name?: {
      [k: string]: any;
    };
    quantity?: {
      [k: string]: any;
    };
    sku?: {
      [k: string]: any;
    };
  }[];
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  revenue?: string;
}

export interface ProductConfiguredProperties {
  action: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * The platform for the paired device or app. e.g. chrome, ios, windows etc.
   */
  product_platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | paper tablet, mobile companion, desktop companion, read on remarkable |
   */
  product_type:
    | 'paper tablet'
    | 'mobile companion'
    | 'desktop companion'
    | 'read on remarkable';
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  remarkable_version?: string;
}

export interface SeatUpdatedProperties {
  action: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface SetupCompletedProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export interface SetupSkippedProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  /**
   * name of the current step engaged with
   */
  step_name: string;
  /**
   * nubmer of the current step engaged with
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  step_number: number;
}

export interface SetupStepClickedProperties {
  /**
   * Property to describe where on a page / in what component an event is triggered. This is to supplement info such as Pathname, where we need to know if a button was clicked in a header or in a footer, as an example..
   */
  component_location: string;
  /**
   * Where the user ends up after clicking a link
   */
  link_source?: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  /**
   * name of the current step engaged with
   */
  step_name: string;
  /**
   * nubmer of the current step engaged with
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  step_number: number;
  /**
   * Text of the component, that the user sees.
   */
  text?: string;
}

export interface SetupStepCompletedProperties {
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  /**
   * name of the current step engaged with
   */
  step_name: string;
  /**
   * nubmer of the current step engaged with
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  step_number: number;
}

export interface SubscriptionCanceledProperties {
  expires_at: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  payment_interval: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  subscription_canceled_at: string;
  subscription_created_at: string;
  subscription_name: string;
  subscription_status: string;
}

export interface SubscriptionCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  origin?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pathname?: string;
  payment_interval: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  subscription_created_at: string;
  subscription_expires_at?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  subscription_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  subscription_status?: string;
}

export interface SubscriptionUpdateFailedProperties {
  action: string;
  error_api_url: string;
  error_code: string;
  error_message: string;
  error_name: string;
  expires_at: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  payment_interval: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
  subscription_created_at: string;
  subscription_name: string;
  subscription_status: string;
}

export interface SubscriptionUpdatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  action?: string;
  created_at: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  expires_at?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  origin?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  pathname?: string;
  payment_interval: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  subscription_created_at: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  subscription_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  subscription_status?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  updated_at?: string;
}

export interface UserAuthenticatedProperties {
  /**
   * This property is never sent to Amplitude, and is only utilized between the client and our backend tracking service. This is used to send the auth0 token as part of the event payload instead of storing it as a cookie. It is parsed from the event property payload, and instead put in the header of the request to our backend service.
   */
  auth0_jwt?: string;
  /**
   * The url of the website i.e https://my.remarkable.com
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  origin?: string;
  /**
   * path (/settings, /account) when event was triggered.
   *
   *  NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  pathname?: string;
  /**
   * Where the user comes from before visiting my.remarkable.com.
   *  This comes from the browser, and will not always be set.
   *
   *
   * NB: This is automatically tracked by the backend service, not set explicitly from client.
   */
  referrer?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created';

  constructor(public event_properties: AccountCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountLinkingFailed implements BaseEvent {
  event_type = 'Account Linking Failed';

  constructor(public event_properties: AccountLinkingFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountLinkingStarted implements BaseEvent {
  event_type = 'Account Linking Started';

  constructor(public event_properties: AccountLinkingStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountLinkingSucceeded implements BaseEvent {
  event_type = 'Account Linking Succeeded';

  constructor(public event_properties: AccountLinkingSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountUpdated implements BaseEvent {
  event_type = 'Account Updated';

  constructor(public event_properties?: AccountUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class ButtonClicked implements BaseEvent {
  event_type = 'Button Clicked';

  constructor(public event_properties: ButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStepCompleted implements BaseEvent {
  event_type = 'Checkout Step Completed';

  constructor(public event_properties: CheckoutStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStepFailed implements BaseEvent {
  event_type = 'Checkout Step Failed';

  constructor(public event_properties: CheckoutStepFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class EnrolledToConnectForBusiness implements BaseEvent {
  event_type = 'Enrolled to Connect for Business';

  constructor(public event_properties: EnrolledToConnectForBusinessProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentApplied implements BaseEvent {
  event_type = 'Experiment Applied';

  constructor(public event_properties?: ExperimentAppliedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvitationAccepted implements BaseEvent {
  event_type = 'Invitation Accepted';

  constructor(public event_properties: InvitationAcceptedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvitationSent implements BaseEvent {
  event_type = 'Invitation Sent';

  constructor(public event_properties: InvitationSentProperties) {
    this.event_properties = event_properties;
  }
}

export class LinkClicked implements BaseEvent {
  event_type = 'Link Clicked';

  constructor(public event_properties: LinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class Pageview implements BaseEvent {
  event_type = 'Pageview';

  constructor(public event_properties?: PageviewProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentFailed implements BaseEvent {
  event_type = 'Payment Failed';

  constructor(public event_properties?: PaymentFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentSucceeded implements BaseEvent {
  event_type = 'Payment Succeeded';

  constructor(public event_properties: PaymentSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class ProductConfigured implements BaseEvent {
  event_type = 'Product Configured';

  constructor(public event_properties: ProductConfiguredProperties) {
    this.event_properties = event_properties;
  }
}

export class SeatUpdated implements BaseEvent {
  event_type = 'Seat Updated';

  constructor(public event_properties: SeatUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class SetupCompleted implements BaseEvent {
  event_type = 'Setup Completed';

  constructor(public event_properties?: SetupCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class SetupSkipped implements BaseEvent {
  event_type = 'Setup Skipped';

  constructor(public event_properties: SetupSkippedProperties) {
    this.event_properties = event_properties;
  }
}

export class SetupStepClicked implements BaseEvent {
  event_type = 'Setup Step Clicked';

  constructor(public event_properties: SetupStepClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SetupStepCompleted implements BaseEvent {
  event_type = 'Setup Step Completed';

  constructor(public event_properties: SetupStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionCanceled implements BaseEvent {
  event_type = 'Subscription Canceled';

  constructor(public event_properties: SubscriptionCanceledProperties) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionCreated implements BaseEvent {
  event_type = 'Subscription Created';

  constructor(public event_properties: SubscriptionCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionUpdateFailed implements BaseEvent {
  event_type = 'Subscription Update Failed';

  constructor(public event_properties: SubscriptionUpdateFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionUpdated implements BaseEvent {
  event_type = 'Subscription Updated';

  constructor(public event_properties: SubscriptionUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserAuthenticated implements BaseEvent {
  event_type = 'User Authenticated';

  constructor(public event_properties?: UserAuthenticatedProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Account%20Created)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. created_at)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Account Linking Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Account%20Linking%20Failed)
   *
   * Attempt to link legacy account to SAML account failed.
   *
   * @param properties The event's properties (e.g. account_linking_flow)
   * @param options Amplitude event options.
   */
  accountLinkingFailed(
    properties: AccountLinkingFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountLinkingFailed(properties), options);
  }

  /**
   * Account Linking Started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Account%20Linking%20Started)
   *
   * User initiates the account linking process and sent to login on their linkable account. This could be a SSO provider or legacy account login in Auth0.
   *
   * @param properties The event's properties (e.g. account_linking_flow)
   * @param options Amplitude event options.
   */
  accountLinkingStarted(
    properties: AccountLinkingStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountLinkingStarted(properties), options);
  }

  /**
   * Account Linking Succeeded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Account%20Linking%20Succeeded)
   *
   * User successfully linked their legacy account to a SAML account.
   *
   * @param properties The event's properties (e.g. account_linking_flow)
   * @param options Amplitude event options.
   */
  accountLinkingSucceeded(
    properties: AccountLinkingSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountLinkingSucceeded(properties), options);
  }

  /**
   * Account Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Account%20Updated)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  accountUpdated(
    properties?: AccountUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountUpdated(properties), options);
  }

  /**
   * Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Button%20Clicked)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * Checkout Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Checkout%20Step%20Completed)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. checkout_step)
   * @param options Amplitude event options.
   */
  checkoutStepCompleted(
    properties: CheckoutStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStepCompleted(properties), options);
  }

  /**
   * Checkout Step Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Checkout%20Step%20Failed)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. checkout_step)
   * @param options Amplitude event options.
   */
  checkoutStepFailed(
    properties: CheckoutStepFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStepFailed(properties), options);
  }

  /**
   * Enrolled to Connect for Business
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Enrolled%20to%20Connect%20for%20Business)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  enrolledToConnectForBusiness(
    properties: EnrolledToConnectForBusinessProperties,
    options?: EventOptions,
  ) {
    return this.track(new EnrolledToConnectForBusiness(properties), options);
  }

  /**
   * Experiment Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Experiment%20Applied)
   *
   * Event triggered when a user is assigned to an experiment for the first time.
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. experiment_id)
   * @param options Amplitude event options.
   */
  experimentApplied(
    properties?: ExperimentAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExperimentApplied(properties), options);
  }

  /**
   * Invitation Accepted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Invitation%20Accepted)
   *
   * Event indicating that a user has accepted an invitation. Invitation type states what kind of invitation was accepted.
   *
   * @param properties The event's properties (e.g. invitation_type)
   * @param options Amplitude event options.
   */
  invitationAccepted(
    properties: InvitationAcceptedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvitationAccepted(properties), options);
  }

  /**
   * Invitation Sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Invitation%20Sent)
   *
   * Event to track when an invitation is sent for a subscription. Invitation type states what invitation was sent.
   *
   * @param properties The event's properties (e.g. invitation_type)
   * @param options Amplitude event options.
   */
  invitationSent(
    properties: InvitationSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvitationSent(properties), options);
  }

  /**
   * Link Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Link%20Clicked)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. component_location)
   * @param options Amplitude event options.
   */
  linkClicked(
    properties: LinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkClicked(properties), options);
  }

  /**
   * Pageview
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Pageview)
   *
   * When a page is loaded / rendered.
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  pageview(
    properties?: PageviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new Pageview(properties), options);
  }

  /**
   * Payment Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Payment%20Failed)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  paymentFailed(
    properties?: PaymentFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentFailed(properties), options);
  }

  /**
   * Payment Succeeded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Payment%20Succeeded)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. $price)
   * @param options Amplitude event options.
   */
  paymentSucceeded(
    properties: PaymentSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentSucceeded(properties), options);
  }

  /**
   * Product Configured
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Product%20Configured)
   *
   * Owner: Simen Viken Grini
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  productConfigured(
    properties: ProductConfiguredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProductConfigured(properties), options);
  }

  /**
   * Seat Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Seat%20Updated)
   *
   * Event indicating that a seat's information has been updated in the seat management system for a subscription.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  seatUpdated(
    properties: SeatUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeatUpdated(properties), options);
  }

  /**
   * Setup Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Setup%20Completed)
   *
   * Event indicating that the initial setup process has been successfully completed by the user
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  setupCompleted(
    properties?: SetupCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupCompleted(properties), options);
  }

  /**
   * Setup Skipped
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Setup%20Skipped)
   *
   * Event indicating that the user skipped the initial setup process upon device activation
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  setupSkipped(
    properties: SetupSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupSkipped(properties), options);
  }

  /**
   * Setup Step Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Setup%20Step%20Clicked)
   *
   * Event indicating that a user has clicked on a setup step during their interaction with the platform
   *
   * @param properties The event's properties (e.g. component_location)
   * @param options Amplitude event options.
   */
  setupStepClicked(
    properties: SetupStepClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupStepClicked(properties), options);
  }

  /**
   * Setup Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Setup%20Step%20Completed)
   *
   * Event indicating that a setup step has been successfully completed
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  setupStepCompleted(
    properties: SetupStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupStepCompleted(properties), options);
  }

  /**
   * Subscription Canceled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Subscription%20Canceled)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. expires_at)
   * @param options Amplitude event options.
   */
  subscriptionCanceled(
    properties: SubscriptionCanceledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionCanceled(properties), options);
  }

  /**
   * Subscription Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Subscription%20Created)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  subscriptionCreated(
    properties: SubscriptionCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionCreated(properties), options);
  }

  /**
   * Subscription Update Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Subscription%20Update%20Failed)
   *
   * Owner: Fredric Waadeland
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  subscriptionUpdateFailed(
    properties: SubscriptionUpdateFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionUpdateFailed(properties), options);
  }

  /**
   * Subscription Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/Subscription%20Updated)
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  subscriptionUpdated(
    properties: SubscriptionUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionUpdated(properties), options);
  }

  /**
   * User Authenticated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/my%20remarkable/events/main/latest/User%20Authenticated)
   *
   * When a user is authenticated by auth0. There is no difference between logging in and authorizing upon refreshing a page. Meaning that this event can be triggered both at the start of a session, and throughout a session.
   *
   * Owner: Adrian Ribe
   *
   * @param properties The event's properties (e.g. auth0_jwt)
   * @param options Amplitude event options.
   */
  userAuthenticated(
    properties?: UserAuthenticatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserAuthenticated(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
