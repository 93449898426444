import { ReactNode } from 'react';

import { WarningDiamond } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { Trash } from 'phosphor-react';

import { CardContent } from 'src/components';

interface DeleteAccountWrapperProps {
  children: ReactNode;
  title: string;
  showDiamondIcon?: boolean;
  showTrashIcon?: boolean;
}

export const DeleteAccountWrapper = (props: DeleteAccountWrapperProps) => {
  const { children, title, showDiamondIcon, showTrashIcon } = props;

  return (
    <div className="flex w-full flex-col items-center justify-center px-16 py-64">
      <div className="flex flex-col">
        <CardContent className="my-12 flex max-w-3xl flex-col items-center space-y-32 text-center">
          {showDiamondIcon && (
            <div className="rounded-full bg-neutral-light-5 p-12 text-center text-white">
              <WarningDiamond size={40} className="text-gray-600" />
            </div>
          )}
          {showTrashIcon && (
            <div className="rounded-full bg-neutral-light-5 p-12 text-center text-white">
              <Trash size={40} className="text-gray-600" />
            </div>
          )}

          <Typography
            variant="responsive-heading-md"
            className="mb-16 mt-0 ls:mb-20"
            as="h1"
          >
            {title}
          </Typography>

          {children}
        </CardContent>
      </div>
    </div>
  );
};
