import PropTypes from 'prop-types';

export const Step = ({ number, children }) => {
  return (
    <div className="msp-stepContainer">
      <div className="msp-stepCircle">{number}</div>
      {children}
    </div>
  );
};

Step.propTypes = {
  number: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
