import { MouseEvent, ReactNode } from 'react';

import { X } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { CheckCircle, Info, Warning } from 'phosphor-react';

import { cn } from 'src/utils/classNamesHelper';
import { UnreachableCodeError } from 'src/utils/unreachableCode';

import { Button } from './Button';

type NotificationVariants = 'error' | 'warning' | 'info' | 'success';

interface BestNotificationBoxProps {
  variant: NotificationVariants;
  muted?: boolean;
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  title: string | ReactNode;
  children?: ReactNode;
  className?: string;
}

const notificationBoxVariants = cva('flex rounded md:flex-row', {
  variants: {
    variant: {
      error: 'bg-[#F9DADB] [&>svg]:fill-feedback-red-500',
      warning: 'bg-[#FBEBD7]',
      info: 'bg-neutral-light-3',
      success: 'bg-[#E5EEE3]',
    },
    muted: {
      true: 'flex-row gap-8 bg-transparent',
      false: 'flex-col items-center p-16',
    },
    align: {
      left: 'md:items-start',
    },
  },
  defaultVariants: {
    variant: 'info',
    muted: false,
  },
});

const getIconElement = (variant: NotificationVariants) => {
  switch (variant) {
    case 'error':
      return Warning;
    case 'warning':
    case 'info':
      return Info;
    case 'success':
      return CheckCircle;
    default:
      throw new UnreachableCodeError(variant);
  }
};

export const NotificationBox = ({
  title,
  children,
  variant,
  muted = false,
  className = '',
  onClose,
  ...rest
}: BestNotificationBoxProps) => {
  const align = children ? 'left' : null;
  const Icon = getIconElement(variant);

  return (
    <div
      data-cy="notification-box"
      {...rest}
      className={notificationBoxVariants({ variant, muted, align, className })}
    >
      <div>
        <Icon
          weight={muted ? 'light' : 'regular'}
          className={cn(muted ? 'h-[28px] w-[28px] px-2' : 'h-32 w-32', {
            'text-feedback-red-500': variant === 'error',
            'text-feedback-orange-500': variant === 'warning',
            'text-gray-800': variant === 'info',
            'text-feedback-green-500': variant === 'success',
          })}
        />
      </div>
      <div
        className={clsx(
          'body-sm-regular flex w-full min-w-0 flex-col items-center text-center md:text-left',
          {
            'md:ml-16': !muted,
            'mt-2': muted,
            'md:items-start': !!children,
          }
        )}
      >
        <div className="flex w-full items-center justify-between gap-16">
          <Typography
            variant={muted ? 'body-md-regular' : 'body-md-bold'}
            className={muted ? 'text-left' : ''}
            as="h2"
          >
            {title}
          </Typography>
          {onClose && (
            <Button variant="tertiary-neutral" onClick={onClose} size="small">
              <X />
            </Button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
