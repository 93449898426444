import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import devicesStandingWebp from 'src/assets/img/paper-pro-apps.webp';
import { Button, NewsCard } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const AppsPromo = () => {
  return (
    <NewsCard
      imageSources={[{ type: 'webp', url: devicesStandingWebp }]}
      imageAlt={'Download our desktop app'}
      imageLink={URLS.DEVICE_DESKTOP}
      headline="APPS"
    >
      <div className="flex flex-col gap-16">
        <Typography variant="responsive-heading-md">
          Take notes from anywhere
        </Typography>
        <Typography variant="body-md-regular">
          View, organize, and import files with our mobile and desktop apps.
          Connect subscribers can also take and edit notes in the apps.
        </Typography>
        <Button
          as="a"
          to={URLS.DEVICE_MOBILE}
          className="w-full lm:hidden"
          variant="secondary"
        >
          <span>Download apps</span>
          <ArrowRight />
        </Button>
        <Button
          as="a"
          to={URLS.DEVICE_DESKTOP}
          className="hidden lm:flex"
          variant="secondary"
        >
          <span>Download apps</span>
          <ArrowRight />
        </Button>
      </div>
    </NewsCard>
  );
};
