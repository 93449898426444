import { useEffect, useState } from 'react';

/**
 * Simple countdown hook
 * @param initialTime - The initial countdown time in milliseconds
 */
export const useCountdown = (initialTime: number) => {
  const interval = initialTime > 1000 ? 1000 : 10; // we have a variable interval so that tests can run faster
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (timeLeft > 0) {
      timeoutId = setTimeout(() => {
        setTimeLeft((timeLeft) => timeLeft - interval);
      }, interval);
    } else {
      setIsFinished(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeLeft]);

  const reset = () => {
    setTimeLeft(initialTime);
    setIsFinished(false);
  };

  // We want to round up the displayed seconds left as we don't show the milliseconds
  const totalSecondsLeft = Math.ceil(timeLeft / 1000);

  const minutesLeft = Math.floor(totalSecondsLeft / 60);
  const secondsLeft = totalSecondsLeft % 60;
  const formattedTimeLeft = `${minutesLeft}min ${secondsLeft}s`;

  return {
    timeLeft,
    formattedTimeLeft,
    isFinished,
    reset,
  };
};
