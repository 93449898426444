import PropTypes from 'prop-types';

export const CardDescription = ({ className, children }) => (
  <div className={`card-description ${className}`}>{children}</div>
);

CardDescription.defaultProps = {
  className: '',
};

CardDescription.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
