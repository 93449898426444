import {
  Checkbox as CheckboxComponent,
  CheckboxProps,
} from '@remarkable/ark-web';
import clsx from 'clsx';

export const Checkbox = ({
  preview,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}: CheckboxProps & {
  preview?: boolean;
}) => (
  <CheckboxComponent
    className={clsx('rounded', { 'ring ': preview })}
    {...props}
  />
);
