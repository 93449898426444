import { Navigate } from '@tanstack/react-router';

import { CheckoutTypeParam } from 'src/api/endpoints/storeApi.types';
import { SomethingWentWrong, Spinner } from 'src/components';

import { useCheckoutConfirmation } from '../useCheckoutConfirmation';

export const ConnectForBusinessConfirmationPage = () => {
  const { isPending, isError, paymentConfirm, subscription } =
    useCheckoutConfirmation({
      checkoutType: CheckoutTypeParam.CONNECT_FOR_BUSINESS,
    });

  if (isPending) {
    return <Spinner />;
  }
  if (isError || !paymentConfirm || !subscription) {
    return <SomethingWentWrong />;
  }

  return <Navigate to="/subscriptions/members" />;
};
