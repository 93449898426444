import { ConsentStatus } from '@remarkable/tracking-browser';

import { tracker } from './analytics/tracker';

export enum ConsentLevel {
  NECESSARY = 'necessary',
  ALL = 'all',
}

export interface ConsentCookie {
  time: string;
  level: ConsentLevel;
}

export const hasSetConsent = () => {
  return localStorage.getItem('cookie-settings-v1') !== null;
};

export const getConsentLevel = (): ConsentLevel => {
  try {
    const cookie = localStorage.getItem('cookie-settings-v1');
    const consent = cookie ? (JSON.parse(cookie) as ConsentCookie) : null;
    return consent ? consent.level : ConsentLevel.NECESSARY;
  } catch (e) {
    console.error('[Consent]: Could not read cookie:', e);
    return ConsentLevel.NECESSARY;
  }
};

const applyConsent = (consentLevel: ConsentLevel) => {
  if (consentLevel === ConsentLevel.ALL) {
    tracker.updateUserConsentStatus(ConsentStatus.CONSENTED);
  } else {
    tracker.updateUserConsentStatus(ConsentStatus.REJECTED);
  }
};

export const initializeConsent = () => {
  const consentLevel = getConsentLevel();
  applyConsent(consentLevel);
};

export const updateConsentLevel = (newConsentLevel: ConsentLevel) => {
  localStorage.setItem(
    'cookie-settings-v1',
    JSON.stringify({ time: new Date().toISOString(), level: newConsentLevel })
  );
  if (newConsentLevel === ConsentLevel.ALL) {
    tracker.updateUserConsentStatus(ConsentStatus.CONSENTED);
  } else {
    tracker.updateUserConsentStatus(ConsentStatus.REJECTED);
  }

  applyConsent(newConsentLevel);
};

initializeConsent();
