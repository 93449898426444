import { HTMLAttributes } from 'react';

import { Typography } from '@remarkable/ark-web';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useCurrentMember,
  usePaymentIntervals,
  useStripeSubscription,
} from 'src/api/queries';
import {
  Button,
  Divider,
  FlexDivider,
  Modal,
  Sheet,
  TextWithLabel,
} from 'src/components';
import { formatAmountBrowserDefaultLocale } from 'src/utils/productUtils';

import { CardHeading } from './CardHeading';
import { ChangePaymentIntervalModal } from './ChangePaymentIntervalForm';

export const ChangePaymentInterval = ({
  componentLocation,
  ...props
}: HTMLAttributes<HTMLDivElement> & { componentLocation?: string }) => {
  const subscription = useStripeSubscription();
  const seat = useCurrentMember();
  const paymentIntervals = usePaymentIntervals();

  if (!subscription.data || !seat || !paymentIntervals.data) return null;

  if (paymentIntervals.data.canChangePaymentInterval === false) return null;

  if (paymentIntervals.data.optionsList.length < 1) return null;

  const annualPrice = formatAmountBrowserDefaultLocale(
    paymentIntervals.data.options.annually.amount.total ?? 0,
    paymentIntervals.data.options.annually.amount.currency
  );

  const monthlyPrice = formatAmountBrowserDefaultLocale(
    paymentIntervals.data.options.monthly.amount.total ?? 0,
    paymentIntervals.data.options.monthly.amount.currency
  );

  const confirmText = `Switch to ${
    paymentIntervals.data.isMonthly ? 'annual' : 'monthly'
  } billing`;

  const onClose = (text: string) => () => {
    if (componentLocation) {
      tracker.trackEvent(
        new ButtonClicked({
          component_location:
            ComponentLocations.MANAGE_CONNECT.PAYMENT_INTERVAL_MODAL,
          text,
          action: 'close change payment interval modal',
        })
      );
    }
  };

  return (
    <Sheet {...props}>
      <CardHeading>Payment interval</CardHeading>

      <Divider />

      <div className="flex w-full flex-col flex-wrap justify-between gap-16 lm:flex-row">
        <TextWithLabel label="Current interval" className="flex-1">
          {paymentIntervals.data.isMonthly ? 'Monthly' : 'Annually'}
        </TextWithLabel>

        <FlexDivider />

        {paymentIntervals.data.isMonthly && (
          <>
            <TextWithLabel
              label="Price per month"
              className="flex-1 shrink-0 whitespace-nowrap"
            >
              {formatAmountBrowserDefaultLocale(
                paymentIntervals.data.options.monthly.amount.total ?? 0,
                paymentIntervals.data.options.monthly.amount.currency
              )}{' '}
              <span className="font-light">
                (
                {formatAmountBrowserDefaultLocale(
                  (paymentIntervals.data.options.monthly.amount.total ?? 0) *
                    12,
                  paymentIntervals.data.options.monthly.amount.currency
                )}
                /year)
              </span>
            </TextWithLabel>
          </>
        )}

        {paymentIntervals.data.isAnnual && (
          <>
            <TextWithLabel label="Price per year" className="flex-1">
              {formatAmountBrowserDefaultLocale(
                paymentIntervals.data.options.annually.amount.total ?? 0,
                paymentIntervals.data.options.annually.amount.currency
              )}{' '}
              <span className="font-light">
                (
                {formatAmountBrowserDefaultLocale(
                  (paymentIntervals.data.options.annually.amount.total ?? 0) /
                    12,
                  paymentIntervals.data.options.annually.amount.currency
                )}
                /month)
              </span>
            </TextWithLabel>
          </>
        )}

        <div>
          <Modal.Root
            onOpenChange={(open) =>
              !open && onClose('close change payment interval modal')
            }
          >
            <Modal.Trigger asChild>
              <Button
                onClick={() => {
                  if (componentLocation) {
                    tracker.trackEvent(
                      new ButtonClicked({
                        component_location: componentLocation,
                        text: confirmText,
                        action: 'open change payment interval modal',
                      })
                    );
                  }
                }}
                variant={
                  paymentIntervals.data.isMonthly
                    ? 'primary-neutral'
                    : 'tertiary'
                }
                size="medium"
                className="w-full sm:w-auto "
                data-cy="change-payment-interval-button"
              >
                {confirmText}
              </Button>
            </Modal.Trigger>

            <ChangePaymentIntervalModal />
          </Modal.Root>
        </div>
      </div>

      {paymentIntervals.data.isMonthly && (
        <Typography
          as="p"
          variant="body-sm-regular"
          className="text-center font-regular italic xs:text-left"
        >
          <span>Want to pay annually?</span>&nbsp; Pay{' '}
          <b className="font-bold" data-cy="annual-price">
            {annualPrice}/year
          </b>{' '}
          - <span className="whitespace-nowrap">Get two months free.</span>
        </Typography>
      )}

      {paymentIntervals.data.isAnnual && (
        <Typography
          as="p"
          variant="body-sm-regular"
          className="text-center font-regular italic xs:text-left"
        >
          <span>Want to pay monthly?</span>&nbsp; Pay{' '}
          <b className="font-bold" data-cy="monthly-price">
            {monthlyPrice}/month
          </b>
        </Typography>
      )}
    </Sheet>
  );
};
