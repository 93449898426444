import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { AcceptFreeConnectTermsAndConditionsPage } from 'src/apps/acceptFreeAccessTerms/app/AcceptFreeConnectTermsAndConditionsPage';

const SearchSchema = z.object({
  return_to: z.string().optional(),
});

export const Route = createFileRoute('/_auth/_layout/free-access-terms')({
  validateSearch: zodSearchValidator(SearchSchema),
  component: () => <AcceptFreeConnectTermsAndConditionsPage />,
});
