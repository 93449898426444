import {
  ApiClient,
  ApiClientOptions,
  ApiClientResponse,
  CreateApiClientOptions,
  HTTPError,
  Params,
  RequestConfig,
} from './createApiClient.types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterUndefined = ([_, value]: [string, unknown]) =>
  value !== undefined && value !== null;

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
} as const;

/**
 * Fetch wrapper with hooks
 * ---
 * Inspired by ky (which we used before this), but addresses some issues:
 *  - BeforeRequest hooks handles an extended RequestInit object instead of a Request object which gives us the ability to modify all properties of the request, including the body.
 *  - Some behaviour in ky is incompatible with how HttpRequestMock works which led to a limitation in which we could not mock a request with a body. This is now fixed.
 *
 * It does also some minor cleanup of headers and searchParams for convenience.
 * */
export const createApiClient = (
  options?: CreateApiClientOptions
): ApiClient => {
  const {
    prefixUrl,
    headers: mainHeaders,
    hooks,
    searchParams: mainSearchParams,
    credentials,
  } = options || {};

  const callFetch = (
    path: string,
    method: string,
    options?: ApiClientOptions
  ) =>
    new ApiClientResponse(async (resolve, reject) => {
      const {
        json,
        headers: optionHeaders,
        searchParams: optionSearchParams,
        timeout = 20000,
        ...rest
      } = options || {};
      const body = json ? JSON.stringify(json) : undefined;

      const initialUrl = new URL(path, prefixUrl);

      // Merge headers
      const headers = Object.fromEntries(
        Object.entries({
          ...defaultHeaders,
          ...mainHeaders,
          ...optionHeaders,
        }).filter(filterUndefined)
      );

      // Merge searchParams
      const searchParams: Params = {
        ...mainSearchParams,
        ...Object.fromEntries(initialUrl.searchParams.entries()),
        ...optionSearchParams,
      };

      let requestConfig: RequestConfig = {
        prefixUrl: initialUrl.origin ?? '',
        path: initialUrl.pathname,
        searchParams,
        method,
        headers,
        body,
        timeout,
        ...rest,
        ...(credentials ? { credentials: credentials } : {}),
      };

      for (const hook of hooks?.beforeRequest ?? []) {
        requestConfig = await hook(requestConfig);
      }

      // Build final url
      const finalSearchParams = createSearchParamsString(
        requestConfig.searchParams
      );
      const url = `${requestConfig.prefixUrl}${requestConfig.path}${finalSearchParams}`;

      const request = new Request(url, requestConfig as RequestInit);

      Promise.race([
        createTimeoutPromise(requestConfig.timeout ?? 0),
        fetch(url, requestConfig as RequestInit),
      ])
        .then(async (response) => {
          if (!response.ok) {
            const error = new HTTPError(response, request);

            for (const hook of hooks?.beforeError ?? []) {
              await hook(error);
            }
            return reject(error);
          }

          // Run afterResponse hooks if any
          for (const hook of hooks?.afterResponse ?? []) {
            response = await hook(response);
          }

          return resolve(response);
        })
        .catch(async (error: Error) => {
          // Run beforeError hooks if any
          for (const hook of hooks?.beforeError ?? []) {
            await hook(error);
          }

          reject(error);
        });
    });

  return {
    post: (path, options) => callFetch(path, 'POST', options),
    put: (path, options) => callFetch(path, 'PUT', options),
    get: (path, options) => callFetch(path, 'GET', options),
    delete: (path, options) => callFetch(path, 'DELETE', options),
    patch: (path, options) => callFetch(path, 'PATCH', options),
  };
};

/** Builds a search param string which filters out keys with undefined  */
function createSearchParamsString(searchParamsConfig: Params = {}): string {
  const search = Object.entries(searchParamsConfig)
    .filter(filterUndefined)
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value.toString())}` : key
    )
    .join('&');

  return search ? '?' + search : '';
}

function createTimeoutPromise(timeout: number): Promise<never> {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, timeout);
  });
}
