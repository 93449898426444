import { Divider, Sheet } from 'src/components';

import { CardHeading } from './CardHeading';
import { NextBillingDetails } from './NextBillingDetails';

export const NextBillingCard = () => {
  return (
    <Sheet>
      <div className="flex items-start gap-8 xs:justify-between">
        <CardHeading>Next billing</CardHeading>
      </div>

      <Divider />

      <NextBillingDetails />
    </Sheet>
  );
};
