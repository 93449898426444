import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { DeviceMobile } from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useDevices, useHasEverPairedDevice } from 'src/api/queries';
import appStoreLogo from 'src/assets/img/app-store.svg';
import googlePlayLogo from 'src/assets/img/google-play.svg';
import {
  CardContent,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { URLS } from 'src/utils/urls/urls';

import { APPLE_ITUNES_URL, GOOGLE_PLAY_URL } from '../utils/constants';
import { filterActiveMobileAppClients, getMobileLink } from '../utils/utils';
import { DeviceTable } from './DeviceTable';
import { OneTimeCode } from './OneTimeCode';

const routeApi = getRouteApi('/_auth/_layout/device');

export const Mobile = () => {
  const { showOtp } = routeApi.useSearch();

  const activeMobileClients = useDevices({
    select: filterActiveMobileAppClients,
  });
  const hasEverPairedDevice = useHasEverPairedDevice();

  if (activeMobileClients.isPending || hasEverPairedDevice.isPending) {
    return <Spinner />;
  }

  if (activeMobileClients.isError || hasEverPairedDevice.isError) {
    return <SomethingWentWrong />;
  }

  return (
    <div className="flex flex-col gap-16 lm:gap-24">
      <Sheet>
        {!showOtp ? (
          <>
            <CardContent className="my-12 flex flex-row items-center justify-between lm:mt-0">
              <div className="flex flex-row items-center">
                <DeviceMobile size={32} className="mr-16" />
                <Typography variant="heading-subtle-2xl" as="h2">
                  Mobile app
                </Typography>
              </div>
              <div data-cy="download-mobile-app-button">
                <div className="hidden md:flex md:flex-row md:gap-16">
                  <DownloadLink link={APPLE_ITUNES_URL} />
                  <DownloadLink link={GOOGLE_PLAY_URL} />
                </div>
                <div className="md:hidden">
                  <DownloadLink link={getMobileLink()} />
                </div>
              </div>
            </CardContent>
            <CardContent borderTop className="text-left">
              <p>
                Use the mobile app to access your files from wherever you are.
              </p>
            </CardContent>
          </>
        ) : (
          <CardContent>
            <OneTimeCode
              successRedirectUrl={URLS.DEVICE_MOBILE}
              description="Enter this one-time code in the companion app on your mobile phone to pair it with your reMarkable and sync your notes."
              allowPairing={hasEverPairedDevice.data}
              deviceTypeString="mobile app"
            />
          </CardContent>
        )}
      </Sheet>

      {!showOtp && <DeviceTable deviceType="mobile-app" />}
    </div>
  );
};

interface DownloadLinkProps {
  link: string;
}

const DownloadLink = ({ link }: DownloadLinkProps) => (
  <div>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      onClick={() =>
        tracker.trackEvent(
          new ButtonClicked({
            action:
              link === APPLE_ITUNES_URL
                ? 'download ios app'
                : 'download android app',
            component_location: ComponentLocations.DEVICE.MOBILE,
            text:
              link === APPLE_ITUNES_URL ? 'App Store logo' : 'Google Play logo',
          })
        )
      }
    >
      <img
        className="h-full w-96 md:w-144"
        alt="Download on the App Store"
        src={link === APPLE_ITUNES_URL ? appStoreLogo : googlePlayLogo}
      />
    </a>
  </div>
);
