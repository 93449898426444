import { createFileRoute, useSearch } from '@tanstack/react-router';

import { Desktop } from 'src/apps/device/components';

export const Route = createFileRoute('/_auth/_layout/device/desktop')({
  component: () => {
    const search = useSearch({ strict: false });

    return <Desktop showOtp={search.showOtp === true} />;
  },
});
