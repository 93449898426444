import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { createQuerySubscriptions } from 'src/api/queries';
import { MembersPage } from 'src/apps/manageSubscription/app/pages/MembersPage';
import { subscriptionIsConnectForBusiness } from 'src/utils/subscriptionIsConnectForBusiness';

const subscriptionMembersSearchSchema = z.object({
  invite_by: z.enum(['email', 'link', 'import']).optional(),
});

export const Route = createFileRoute('/_auth/_layout/subscriptions/members')({
  async beforeLoad({ context }) {
    const subscription = await context.queryClient.ensureQueryData(
      createQuerySubscriptions()
    );

    if (
      !subscriptionIsConnectForBusiness(subscription) ||
      !subscription.access.canSeeMembers
    ) {
      throw redirect({ to: '/subscriptions/plan' });
    }
  },
  validateSearch: zodSearchValidator(subscriptionMembersSearchSchema),
  component: () => <MembersPage />,
});
