import { DropboxLogo, GoogleDriveLogo } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import {
  ArrowRight,
  Cloud,
  Eyeglasses,
  GoogleChromeLogo,
  MicrosoftPowerpointLogo,
  PuzzlePiece,
} from 'phosphor-react';

import { Divider, Link } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

export const AppsAndExtensions = () => {
  return (
    <div className="flex flex-col p-16 lm:flex-row">
      <div className="flex-1 flex-col gap-16 border-neutral-light-8 text-left lm:flex lm:border-r-[1px] lm:pr-32">
        <Typography variant="heading-xs">
          <span>
            <Eyeglasses
              className="mr-8 hidden text-muted lm:inline-block"
              size={32}
              weight="light"
            />
            Read articles on your paper tablet
          </span>
        </Typography>
        <Typography variant="body-md-regular">
          Download articles directly from your browser to your paper tablet with
          our Google Chrome extension and Microsoft Office add-in.
        </Typography>
        <div className="flex flex-row gap-16">
          <GoogleChromeLogo size={32} className="text-muted" weight="light" />
          <MicrosoftPowerpointLogo
            size={32}
            className="text-muted"
            weight="light"
          />
        </div>
        <Link className="font-semibold text-pen-blue" to={URLS.DEVICE_BROWSER}>
          Download extensions <ArrowRight />
        </Link>
      </div>
      <Divider className="my-16 lm:hidden" />
      <div className="flex flex-1 flex-col gap-16 text-left lm:pl-32">
        <Typography variant="heading-xs">
          <span>
            <PuzzlePiece
              className="mr-8 hidden text-muted lm:inline-block"
              weight="light"
              size={32}
            />
            Sync with personal cloud storage
          </span>
        </Typography>
        <Typography variant="body-md-regular">
          Easily access, sync, and store files with cloud storage services such
          as Google Drive, Dropbox, and OneDrive.
        </Typography>
        <div className="flex flex-row gap-16">
          <DropboxLogo size={32} className="text-muted" weight="light" />
          <GoogleDriveLogo size={32} className="text-muted" weight="light" />
          <Cloud size={32} className="text-muted" weight="light" />
        </div>
        <Link className="font-semibold text-pen-blue" to={URLS.INTEGRATIONS}>
          Add integrations
          <ArrowRight />
        </Link>
      </div>
    </div>
  );
};
