import { ComponentProps } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Link } from '@remarkable/ark-web';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { DEV_URLS, EXTERNAL_URLS, RM_COM_URLS } from 'src/utils/urls/urls';

const { ARTICLES: SUPPORT_ARTICLES, ...OTHER_SUPPORT_URLS } = SUPPORT_URL;

export type ExternalURL =
  | (typeof SUPPORT_ARTICLES)[keyof typeof SUPPORT_ARTICLES]
  | (typeof OTHER_SUPPORT_URLS)[keyof typeof OTHER_SUPPORT_URLS]
  | (typeof EXTERNAL_URLS)[keyof typeof EXTERNAL_URLS]
  | (typeof RM_COM_URLS)[keyof typeof RM_COM_URLS]
  | (typeof DEV_URLS)[keyof typeof DEV_URLS];

type LinkExternalProps = Partial<ComponentProps<typeof Link>> & {
  /** Enables LinkClicked tracking events when set */
  componentLocation?: string;
  to: ExternalURL;
};

export function LinkExternal({
  componentLocation: trackingLocation,
  onClick,
  to,
  ...props
}: LinkExternalProps) {
  const isRemarkableDomain = to.includes('remarkable.com');

  return (
    <Link
      target="_blank"
      rel={isRemarkableDomain ? 'noopener' : 'noopener noreferrer'}
      href={to}
      onClick={(event) => {
        if (trackingLocation) {
          tracker.trackEvent(
            new LinkClicked({
              component_location: trackingLocation,
              text: (event.nativeEvent.currentTarget as HTMLAnchorElement)
                .innerText,
              link_source: to ?? '',
            })
          );
        }
        onClick?.(event);
      }}
      {...props}
    />
  );
}
