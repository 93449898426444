import DropboxIcon from 'src/assets/icons/dropbox.svg';
import GoogleDriveIcon from 'src/assets/icons/googledrive.svg';
import OneNoteIcon from 'src/assets/icons/oneNote.svg';
import OneDriveIcon from 'src/assets/icons/onedrive.svg';

// Maps provider iconId to icon component
export const providerIconByProviderIdMap: Record<string, string> = {
  '4c20fc9a-0737-453f-bac1-7263015b3143': OneNoteIcon,
  '0f94d1f7-d617-4769-b185-f7d3979cdd0a': DropboxIcon,
  '05e96edc-745b-4d1a-bd57-f755728a936f': GoogleDriveIcon,
  '42d436f9-354c-43da-89f6-bd8a75bee440': OneDriveIcon,
};

// Maps icons by provider name
export const providerIconByNameMap: Record<string, string> = {
  OneNote: OneNoteIcon,
  Dropbox: DropboxIcon,
  GoogleDrive: GoogleDriveIcon,
  OneDrive: OneDriveIcon,
};
