import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { storeApi } from '../endpoints';
import { FreeConnectTermsStatus } from '../endpoints/storeApi.types';
import { queryKeys } from './queryKeys';

const CURRENT_FREE_CONNECT_TERMS_VERSION = 'v1';

export const hasAcceptedFreeConnectTerms = (
  freeConnectTermHistory: FreeConnectTermsStatus[]
) =>
  freeConnectTermHistory.some(
    (t) => t.terms_version === CURRENT_FREE_CONNECT_TERMS_VERSION
  );

export const useFreeConnectTerms = () =>
  useQuery({
    queryKey: queryKeys.freeConnectTerms.acceptTerms,
    queryFn: storeApi.getFreeConnectTerms,
    staleTime: Infinity, // Invalidated by acceptLifetimeTerms
  });

export const useAcceptFreeConnectTerms = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      storeApi.acceptLifetimeTerms({
        termsVersion: CURRENT_FREE_CONNECT_TERMS_VERSION,
      }),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.freeConnectTerms.acceptTerms,
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.subscriptions.all,
        }),
      ]),
  });
};
