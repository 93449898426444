import { useEffect, useState } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from 'src/queryClient';
import { useFeatureToggles } from 'src/utils/useFeatureToggles';
import { useKeyPress } from 'src/utils/useKeyPress';

import { ApiProviderProps } from './endpoints/storeApi.types';

export const ApiProvider = ({
  withDevtools = false,
  children,
}: ApiProviderProps) => {
  const pressedKeyCombo = useKeyPress('•'); // alt + q
  const [showDevtools, setShowDevtools] = useState(false);
  const featureToggles = useFeatureToggles();

  useEffect(() => {
    if (featureToggles.EnableReactQueryDevtools && pressedKeyCombo) {
      setShowDevtools(!showDevtools);
    }
  }, [pressedKeyCombo]);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {showDevtools && withDevtools && (
        <div className="absolute">
          <ReactQueryDevtools initialIsOpen={true} />
        </div>
      )}
    </QueryClientProvider>
  );
};
