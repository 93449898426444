import { useQuery } from '@tanstack/react-query';

import { COUNTRIES } from 'src/utils/countryList';
import { getFlagImage } from 'src/utils/flagHelper';

export const useFlagImage = (countryValue: string) => {
  const flagName = COUNTRIES.find(
    (country) => country.value === countryValue
  )?.flag;

  return useQuery({
    queryKey: ['flag', flagName],
    queryFn: () => getFlagImage(flagName ?? ''),
    enabled: !!flagName,
  });
};
