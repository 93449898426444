import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { useForm } from 'react-hook-form';

import { useUpdateIntegration } from 'src/api/queries';
import { Button, Form, Input } from 'src/components';

interface IntegrationNameForm {
  name: string;
}

export const EditIntegrationNamePopover = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const form = useForm<IntegrationNameForm>();
  const updateIntegration = useUpdateIntegration();
  const onSubmit = () => {
    updateIntegration.mutate({
      id: id,
      name: form.getValues('name'),
    });
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="tertiary-neutral"
          className="w-full sm:w-auto"
          size="medium"
          data-cy="rename-integration-button"
        >
          Rename
        </Button>
      </PopoverTrigger>

      <PopoverContent
        align="center"
        sideOffset={8}
        side="left"
        collisionPadding={24}
        className="z-20 ml-[-1px] rounded bg-neutral-light-1 px-20 py-16 shadow-sheet transition-all"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <Form onSubmit={onSubmit} {...form}>
          <div className="flex flex-col gap-16">
            <Input
              name="name"
              type="text"
              data-cy="integration-name-input"
              placeholder={name}
              maxLength={14}
              required
              data-hj-suppress
            />
            <div className="flex w-full flex-row gap-8">
              <PopoverClose asChild className="flex-1">
                <Button
                  type="button"
                  size="medium"
                  variant="tertiary"
                  className="w-full"
                >
                  Cancel
                </Button>
              </PopoverClose>

              <Button
                type="submit"
                size="medium"
                className="flex-1"
                loading={updateIntegration.isPending}
                disabled={updateIntegration.isPending}
                data-cy="save-rename-integration-button"
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </PopoverContent>
    </Popover>
  );
};
