import { useAccountInfo } from 'src/api/queries';
import { useAuth0Claims } from 'src/api/queries/user';
import { userHelpers } from 'src/utils/userHelpers';

export function useHasLinkedAccounts() {
  const claims = useAuth0Claims();
  const accountInfo = useAccountInfo();

  if (claims.isPending || accountInfo.isPending) {
    return {
      isPending: true,
      isError: false,
      isSuccess: false,
      error: claims.error || accountInfo.error,
      data: null,
    } as const;
  }

  if (claims.isError || accountInfo.isError) {
    return {
      isPending: false,
      isError: true,
      isSuccess: false,
      error: claims.error || accountInfo.error,
      data: null,
    } as const;
  }

  return {
    isPending: false,
    isError: false,
    isSuccess: true,
    error: claims.error || accountInfo.error,
    data: {
      hasSignedInToOrganization: userHelpers.isLoggedInToOrganization(
        claims.data
      ),
      isLinkable: userHelpers.isLinkable(claims.data),
      isLinked:
        accountInfo.data.identities.length > 1 &&
        userHelpers.hasSAMLIdentity(accountInfo.data.identities),
    },
  };
}
