import { paths } from 'apis/rm-store-api-public';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const storeApiClient = createClient<paths>({
  baseUrl: CONFIG.StoreAPIURL,
});

storeApiClient.use(addAuthHeader, throwOnErrorResponse);

export { storeApiClient };
