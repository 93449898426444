export interface PasscodeResetRequest {
  DeviceName: string;
  DeviceID: string;
  RequestID: string;
  Approved: boolean;
}

export type PasscodeResetsPendingResponse = PasscodeResetRequest[];

export interface DeviceV1 {
  DeviceDesc: string;
  DeviceID: string;
  Modified: string;
  DeletedAt?: string;
}

export type DevicesV1ConnectedResponse = DeviceV1[];

export interface ProfileDeviceV1 {
  deviceId: string;
  deviceType: string;
  verified: boolean;
  registeredAt: string;
}

export type ProfileV1ConnectedResponse = {
  isTabletOwner: boolean;
  devices: ProfileDeviceV1[];
};

export type IntegrationListResponse = {
  integrations: Integration[];
};

export type IntegrationProviderId =
  | 'GoogleDrive'
  | 'Dropbox'
  | 'OneDrive'
  | 'OneNote';

export type Integration = {
  name: string;
  id: string;
  provider: IntegrationProviderId;
  added: string;
  issues?: IntegrationIssue[];
};

export type IntegrationIssue = {
  type: 'providerRevokedAccess'; // There are probably more, but we only care about this one for now
  transient: string;
  level: string;
  timestamp: string;
  messages: IntegrationIssueMessage[];
};

export type IntegrationIssueMessage = {
  locale: string;
  text: string;
};

export type IntegrationToSave = {
  state: string;
  scope?: string;
  code: string;
  name?: string;
};

export type IntegrationSaveRequest = {
  integration: IntegrationToSave;
  subPath: string;
};

export type ProviderDetails = {
  displayName: string;
  typeName: IntegrationProviderId;
  subPath: string;
  iconId: string;
};

export type ProviderAuthURIResponse = string;

export type IntegrationTermsOfServiceStatusResponse = {
  version: number;
  acceptedDate: string;
} | null;

export const IntegrationExceptions = {
  ALREADY_EXISTS: 'Integration already exists.',
  ACCOUNT_MISMATCH: 'Account does not match with existing integration.',
};

export type IntegrationExceptionType = keyof typeof IntegrationExceptions;

export class IntegrationException extends Error {
  constructor(public type: IntegrationExceptionType) {
    super(IntegrationExceptions[type]);
  }
}

export enum ClientType {
  RM100 = 'rm100',
  RM110 = 'rm110',
  RM02 = 'RM02',
  Browser = 'browser',
  Mobile = 'mobile',
  Desktop = 'desktop',
  Unknown = 'unknown',
}

export enum ClientSource {
  DeviceV1 = 'deviceV1',
  ProfileV1 = 'profileV1',
}

export interface ClientInformation {
  clientName: string;
  clientId: string;
  clientType: ClientType;
  clientSource: ClientSource;
  // Both timestamps below are ISO strings because we loose precision converting to a Date object
  // Since the devicesV1 deletion endpoint requires an exact registeredAt timestamp to match, we
  // cannot tolerate a loss in precision.
  registeredAt: string;
  deletedAt: string | null;
}
