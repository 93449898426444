import { Typography } from '@remarkable/ark-web';
import { cx } from 'class-variance-authority';
import { Plus, Spinner } from 'phosphor-react';

import { ProviderDetails } from 'src/api/endpoints/cloudApi.types';
import {
  useGetProviderAuthURI,
  useIntegrationProviders,
  useIntegrationTermsOfService,
  useIntegrations,
} from 'src/api/queries';
import {
  Button,
  NotificationBox,
  Sheet,
  SomethingWentWrong,
} from 'src/components';

import { providerIconByProviderIdMap } from '../utils/iconMap';
import { isMaxIntegrations } from '../utils/isMaxIntegrations';

export const ProviderList = () => {
  const integrations = useIntegrations();
  const providers = useIntegrationProviders();
  const tos = useIntegrationTermsOfService();

  if (integrations.isPending || providers.isPending) return <Spinner />;
  if (integrations.isError || providers.isError) return <SomethingWentWrong />;

  return (
    <Sheet
      className={cx(tos.hasAccepted ? '' : 'cursor-not-allowed opacity-70')}
    >
      {isMaxIntegrations(integrations.data) ? (
        <NotificationBox
          className="mx-16"
          variant="info"
          title="Maximum number of integrations reached"
          data-cy="max-integrations-reached"
        >
          At the moment we only allow five integrations at a time. If you want
          to integrate a new service, please remove one of your current
          integrations.
        </NotificationBox>
      ) : (
        <div className="flex flex-col gap-32">
          {providers.data?.map((provider) => (
            <ProviderItem
              key={provider.displayName}
              provider={provider}
              disabled={
                isMaxIntegrations(integrations.data) || !tos.hasAccepted
              }
            />
          ))}
        </div>
      )}
    </Sheet>
  );
};

const ProviderItem = ({
  provider,
  disabled = false,
}: {
  provider: ProviderDetails;
  disabled?: boolean;
}) => {
  const providerAuth = useGetProviderAuthURI();

  const handleAdd = (provider: ProviderDetails) => () => {
    providerAuth.mutate(provider.subPath, {
      onSuccess: (response) => {
        /* TODO: Fix redirect_uri

        The url we receive from the API does not point to the active
        environment. We need to fix this in the API or whitelist
        the relevant domains in the Auth provider so that we can
        use the redirect_uri param from the client:
        */
        location.href = response;
      },
    });
  };

  const icon: string = providerIconByProviderIdMap[provider.iconId] ?? '';

  return (
    <div className="flex flex-row items-center justify-between">
      <span className="flex">
        <img src={icon} alt={provider.displayName} className="mr-16 w-32" />
        <Typography
          variant="heading-subtle-lg"
          className="md:heading-subtle-2xl"
          as="h2"
        >
          {provider.displayName}
        </Typography>
      </span>
      <Button
        variant="secondary"
        onClick={handleAdd(provider)}
        loading={providerAuth.isPending}
        disabled={disabled}
        data-cy={`add-provider-button`}
        size="medium"
      >
        <Plus />
        <span>Add</span>
      </Button>
    </div>
  );
};
