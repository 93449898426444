import { HTMLProps } from 'react';

import { useLocation } from '@tanstack/react-router';
import clsx from 'clsx';

import { Link2, Link2Props } from './Link2';

export const Navbar = ({
  children,
  className,
  ...rest
}: HTMLProps<HTMLDivElement>) => {
  return (
    <nav className={clsx(className, 'ark-navbar')} {...rest}>
      <ul>{children}</ul>
    </nav>
  );
};

export const NavTab = ({
  to,
  disabled,
  children,
  className,
  ...rest
}: Link2Props) => {
  const { pathname } = useLocation();
  const active = pathname === to;
  return (
    <li
      key={to}
      className={clsx(className, {
        'pointer-events-none cursor-not-allowed opacity-50': !!disabled,
        active,
      })}
    >
      <Link2
        className="hover:no-underline"
        to={to}
        disabled={disabled}
        aria-disabled={disabled}
        tab-index={disabled ? -1 : undefined}
        data-selected={active}
        {...rest}
      >
        {children}
      </Link2>
    </li>
  );
};
