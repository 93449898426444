import { ReactNode, useEffect, useRef, useState } from 'react';

import { cx } from 'class-variance-authority';

const CORNER_CLOSE_HEIGHT_BREAKPOINT_PX = 125;

interface NotificationBannerProps {
  onClick?: () => void;
  children: ReactNode;
}
export const NotificationBanner = ({
  children,
  onClick,
}: NotificationBannerProps) => {
  const [show, setShow] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const showTopRightClose = containerHeight < CORNER_CLOSE_HEIGHT_BREAKPOINT_PX;

  useEffect(() => {
    setContainerHeight(containerRef.current?.offsetHeight ?? 0);
  });

  return show ? (
    <div
      ref={containerRef}
      className="relative mt-20 flex w-full flex-row rounded border border-gray-300 p-24 text-left"
    >
      {children}

      <button
        title="Close"
        data-cy="notification-banner-close-button"
        className={cx(
          'absolute right-0 top-0 cursor-pointer bg-none p-16 text-22 leading-4 text-gray-800',
          { 'top-1/2 -translate-y-1/2 p-32': showTopRightClose }
        )}
        onClick={() => {
          onClick && onClick();
          setShow(false);
        }}
      >
        ✕
      </button>
    </div>
  ) : null;
};
