import { createFileRoute } from '@tanstack/react-router';

import { createQueryCheckoutAllowed } from 'src/api/queries';
import { handleCheckoutAllowedRedirect } from 'src/utils/getCheckoutAllowedRedirect';

export const Route = createFileRoute('/_auth/_layout/store/dm-offer/_allowed')({
  async beforeLoad({ context }) {
    const allowedData = await context.queryClient.ensureQueryData(
      createQueryCheckoutAllowed()
    );
    handleCheckoutAllowedRedirect('dmOffer', allowedData);
  },
});
