import { PaymentElementProps } from '@stripe/react-stripe-js';

export const paymentElementOptions: PaymentElementProps['options'] = {
  terms: {
    // We show our own terms message
    // This tells Stripe not to show their generic terms messaging
    card: 'never',
    auBecsDebit: 'never',
    bancontact: 'never',
    ideal: 'never',
    sepaDebit: 'never',
    sofort: 'never',
    usBankAccount: 'never',
  },
  fields: {
    // Already collected during checkout and passed to confirmPayment call
    // This tells Stripe not to ask for billing details in their own form
    billingDetails: {
      address: 'never',
      email: 'never',
      name: 'never',
      phone: 'never',
    },
  },
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
};
