import { ChangeEventHandler, MouseEventHandler } from 'react';

import {
  CellContext,
  ColumnHelper,
  RowData,
  TableMeta,
} from '@tanstack/react-table';
import { atom, useAtom } from 'jotai';

import { Checkbox } from 'src/components/TableCheckbox';

import { useKeyPress } from './useKeyPress';

const lastSelectedRowIdAtom = atom<string>('');
const previewRowSelectionAtom = atom<{ [key: string]: boolean }>({});

/**
 * Provides multi-row selection functionality (with preview) to any React Table.
 * Simply hold shift to select multiple rows.
 *
 * @example
 * const table = useReactTable({
 *  data: rows,
 * meta: useMultiRowSelect(),
 */
export const useMultiRowSelect = <T,>(): Partial<TableMeta<T>> => {
  const [lastSelectedRowId, setLastSelectedRowId] = useAtom(
    lastSelectedRowIdAtom
  );
  const [previewRowSelection, setPreviewRowSelection] = useAtom(
    previewRowSelectionAtom
  );
  const showPreview = useKeyPress('Shift');

  const createRowMultiSelectHandler: <TData extends RowData, TValue>(
    context: CellContext<TData, TValue>
  ) => ChangeEventHandler<HTMLInputElement> = (context) => (e) => {
    if (!(e.nativeEvent as PointerEvent).shiftKey) {
      context.row.toggleSelected(e.target.checked);
      setLastSelectedRowId(context.row.id);
      return;
    }

    context.table.setRowSelection((prev) => ({
      ...prev,
      ...getRowsToBeSelected(context, lastSelectedRowId),
    }));
  };

  const createRowMultiSelectPreviewHandler: <TData extends RowData, TValue>(
    context: CellContext<TData, TValue>
  ) => MouseEventHandler<HTMLInputElement> = (context) => () => {
    setPreviewRowSelection(getRowsToBeSelected(context, lastSelectedRowId));
  };

  return {
    createRowMultiSelectHandler,
    createRowMultiSelectPreviewHandler,
    previewRowSelection,
    showRowSelectionPreview: showPreview,
  };
};

/**
 * Creates a column that allows for row selection.
 * If used together with `useMultiRowSelect`, it will also allow for multi-row selection
 * by holding shift.
 */
export const createSelectColumn = <T,>(columnHelper: ColumnHelper<T>) =>
  columnHelper.display({
    id: 'select',
    meta: {
      align: 'center',
      colWidth: 'w-48',
      truncate: false,
    },
    header: (info) => (
      <Checkbox
        checked={info.table.getIsAllRowsSelected()}
        onChange={info.table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: (info) => {
      const handler =
        info.table.options.meta?.createRowMultiSelectHandler?.(info);

      const previewRowSelection =
        info.table.options.meta?.showRowSelectionPreview &&
        typeof info.table.options.meta?.previewRowSelection?.[info.row.id] !==
          'undefined';

      return (
        <Checkbox
          checked={info.row.getIsSelected()}
          preview={previewRowSelection}
          onChange={handler}
          onMouseOver={info.table.options.meta?.createRowMultiSelectPreviewHandler?.(
            info
          )}
        />
      );
    },
  });

const getRowsToBeSelected = <TData extends RowData, TValue>(
  { table, row }: CellContext<TData, TValue>,
  lastSelectedRowId: string
) => {
  const rows = table.getRowModel().rows,
    selectedRows = table.getFilteredSelectedRowModel().rowsById,
    lastIndex = rows.findIndex((r) => r.id === lastSelectedRowId),
    currentIndex = rows.findIndex((r) => r.id === row.id),
    rowsToBeSelected = rows.slice(
      Math.min(lastIndex, currentIndex),
      Math.max(lastIndex, currentIndex) + 1
    ),
    hasUnselectedRows = rowsToBeSelected.some((r) => !selectedRows[r.id]);

  return rowsToBeSelected.reduce(
    (acc, { id }) => ({ ...acc, [id]: hasUnselectedRows }),
    {}
  );
};
