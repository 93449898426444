import { createFileRoute, redirect } from '@tanstack/react-router';

import { ConnectForBusinessOpenValueProp } from 'src/apps/standalone/app/pages/ConnectForBusinessPublicValueProp';

export const Route = createFileRoute('/(public)/_layout/connect-for-business')({
  beforeLoad: async ({ context }) => {
    if ((await context.auth).isAuthenticated) {
      throw redirect({ to: '/store/connect-for-business' });
    }
  },
  component: () => <ConnectForBusinessOpenValueProp />,
});
