import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useDeleteInvitation } from 'src/api/queries/invitations';
import { Button, Modal2, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useCancelInvitationModal = createModalHook<{ id: string }>(
  'CancelInvitation'
);

export const CancelInvitationModal = ({ id }: { id: string }) => {
  const modal = useCancelInvitationModal();
  const deleteInvitation = useDeleteInvitation();

  if (!modal.isOpen || modal.data.id !== id) return null;

  return (
    <Modal2 onClose={modal.close}>
      <Typography variant="heading-md" className="mb-24">
        Cancel invitation
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        Are you sure you want to cancel the invitation?
      </Typography>

      {deleteInvitation.isError && (
        <NotificationBox
          className="my-16"
          variant="error"
          title="Something went wrong"
        >
          Something went wrong while cancelling the invitation. Please try again
          later or contact support if the problem persists.
        </NotificationBox>
      )}

      <div className="mt-24 flex justify-center gap-16">
        <Button
          variant="secondary"
          onClick={modal.close}
          data-cy="keep-invitation-button"
          analytics={{
            text: 'Keep invitation',
            location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
            action: 'keep invitation',
          }}
        >
          Keep invitation
        </Button>

        <Button
          onClick={() =>
            modal.data?.id &&
            deleteInvitation.mutate(modal.data.id, {
              onSuccess() {
                modal.close();
              },
            })
          }
          loading={deleteInvitation.isPending}
          disabled={!modal.data?.id}
          data-cy="cancel-invitation-button"
          analytics={{
            text: 'Cancel invitation',
            location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
            action: 'cancel invitation',
          }}
        >
          Cancel invitation
        </Button>
      </div>
    </Modal2>
  );
};
