import {
  EventHandler,
  HTMLAttributes,
  MouseEvent,
  PropsWithChildren,
} from 'react';

import { cn } from 'src/utils/classNamesHelper';

export const Notification = ({
  children,
  onClick,
  className = '',
  ...props
}: PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & { onClick?: EventHandler<MouseEvent> }
>) => {
  return (
    <div
      className={cn(
        'relative flex w-full animate-fade-in-down flex-row rounded border border-gray-300 p-24 text-left',
        className
      )}
      {...props}
    >
      <div className="w-full">{children}</div>
      {onClick && (
        <button
          title="Close"
          className="absolute right-0 top-0 cursor-pointer bg-none p-12 text-18 leading-4 text-gray-800"
          onClick={onClick}
        >
          ✕
        </button>
      )}
    </div>
  );
};
