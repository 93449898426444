import { useActivePaperTablets } from 'src/api/queries';
import { Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';

import { SetupComplete } from '../components/SetupComplete';

export const ConnectOfferSetupComplete = () => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) return <Spinner />;

  return (
    <AppPage.Content className="max-w-ll">
      <SetupComplete
        secondDevice={
          !!(activePaperTablets.data && activePaperTablets.data.length > 1)
        }
      />
    </AppPage.Content>
  );
};
