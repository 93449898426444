import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

import { FileRejection } from 'react-dropzone';

export interface DragStateInterface {
  active: boolean;
  accepted: boolean;
  rejected: boolean;
  targetName?: string;
  dropped: boolean;
  importFiles: boolean;
  acceptFiles: Array<File> | null; // used for imports from the home page
  rejectFiles: Array<FileRejection> | null; // used for imports from the home page
}

type Action =
  | { type: 'set_active'; payload: boolean }
  | { type: 'set_import_files'; payload: boolean }
  | { type: 'set_accepted'; payload: boolean }
  | { type: 'set_rejected'; payload: boolean }
  | { type: 'set_target_name'; payload: string }
  | { type: 'set_dropped'; payload: boolean }
  | { type: 'clear_accepted_files' }
  | { type: 'reset' }
  | {
      type: 'upload_files';
      payload: { acceptFiles: Array<File>; rejectFiles: Array<FileRejection> };
    };

const reducer = (
  state: DragStateInterface,
  action: Action
): DragStateInterface => {
  switch (action.type) {
    case 'set_active':
      return {
        ...state,
        active: action.payload,
      };
    case 'set_accepted':
      return {
        ...state,
        accepted: action.payload,
      };
    case 'set_rejected':
      return {
        ...state,
        rejected: action.payload,
      };
    case 'set_target_name':
      return {
        ...state,
        targetName: action.payload,
      };
    case 'set_dropped':
      return {
        ...state,
        active: false,
        importFiles: false,
        dropped: action.payload,
      };
    case 'set_import_files':
      return {
        ...state,
        importFiles: action.payload,
      };
    case 'upload_files':
      return {
        ...state,
        acceptFiles: action.payload.acceptFiles,
        rejectFiles: action.payload.rejectFiles,
      };
    case 'clear_accepted_files':
      return {
        ...state,
        acceptFiles: null,
        rejectFiles: null,
      };
    case 'reset':
      return initialState;

    default:
      return state;
  }
};

const initialState = {
  active: false,
  accepted: false,
  rejected: false,
  targetName: '',
  dropped: false,
  importFiles: false,
  acceptFiles: null,
  rejectFiles: null,
};

export const DragContext = createContext<{
  state: DragStateInterface;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const DragStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    active: false,
    accepted: false,
    rejected: false,
    targetName: '',
    dropped: false,
    importFiles: false,
    acceptFiles: null,
    rejectFiles: null,
  });

  return (
    <DragContext.Provider value={{ state, dispatch }}>
      {children}
    </DragContext.Provider>
  );
};

function useDragState() {
  const context = useContext(DragContext);
  if (context === undefined) {
    throw new Error('useDragState must be used within a DragStateProvider');
  }
  return context;
}

export { DragStateProvider, useDragState };
