import { Spinner, Typography } from '@remarkable/ark-web';
import { UseQueryResult } from '@tanstack/react-query';
import { CheckCircle, Warning } from 'phosphor-react';

import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Subscription } from 'src/api/endpoints/storeApi.types';
import { getPaymentCardExpiresInNumMonths } from 'src/utils/getPaymentCardExpired';
import { URLS } from 'src/utils/urls/urls';
import { useVerifyPaymentMethod } from 'src/utils/useVerifyPaymentMethod';

import { ChangeCreditCardButton } from './ChangeCreditCardButton';
import { Link } from './Link';
import { NotificationBanner } from './NotificationBanner';

export const UpdatePaymentCardBanner = ({
  subscription,
}: {
  subscription: UseQueryResult<Subscription>;
}) => {
  const verifyPaymentMethod = useVerifyPaymentMethod();

  const NUM_MONTHS_BEFORE_EXPIRATION_TO_SHOW_BANNER = 3;

  if (subscription.isPending) return null;

  const numMonthsUntilPaymentMethodExpires = getPaymentCardExpiresInNumMonths(
    subscription.data
  );

  if (numMonthsUntilPaymentMethodExpires === null) return null;

  const showBanner =
    numMonthsUntilPaymentMethodExpires <=
      NUM_MONTHS_BEFORE_EXPIRATION_TO_SHOW_BANNER ||
    verifyPaymentMethod.isSuccess;

  if (!showBanner) return null;

  tracker.identify({
    myrm_utm_parameters: JSON.stringify({
      myrm_show_payment_banner: true,
    }),
  });

  const renderNotificationBanner = (content: JSX.Element) => (
    <NotificationBanner data-cy="update-payment-card-banner">
      {content}
    </NotificationBanner>
  );

  if (verifyPaymentMethod.isPending) {
    return renderNotificationBanner(
      <div className="flex flex-row gap-12">
        <Spinner />
        <div className="mt-4">
          <Typography variant="heading-subtle-lg" as="h3">
            Updating your payment information
          </Typography>
          <Typography variant="body-md-regular" as="p">
            Please wait.
          </Typography>
        </div>
      </div>
    );
  }

  if (verifyPaymentMethod.isSuccess) {
    return renderNotificationBanner(
      <div className="flex flex-row gap-12">
        <CheckCircle
          size={32}
          weight="fill"
          className="text-feedback-green-500"
        />
        <div className="mt-4">
          <Typography variant="heading-subtle-lg" as="h3">
            Your payment information has been updated
          </Typography>
          <Typography variant="body-md-regular" as="p">
            Go to your{' '}
            <Link inline to={URLS.SUBSCRIPTION}>
              Subscription page
            </Link>{' '}
            to see your payment details.
          </Typography>
        </div>
      </div>
    );
  }

  return renderNotificationBanner(
    <div className="flex flex-col gap-16">
      <div>
        <Typography variant="heading-subtle-lg" as="h3">
          {numMonthsUntilPaymentMethodExpires <= 0
            ? 'Your payment card has expired'
            : `Your payment card is expiring in ${numMonthsUntilPaymentMethodExpires} ${
                numMonthsUntilPaymentMethodExpires === 1 ? 'month' : 'months'
              }`}
        </Typography>

        <Typography variant="body-md-regular" as="p">
          Update your payment card to keep using our services.
        </Typography>
      </div>

      <ChangeCreditCardButton
        componentLocation={ComponentLocations.NOTIFICATIONS.UPDATE_PAYMENT_CARD}
      />

      {verifyPaymentMethod.isError && (
        <div className="flex flex-row gap-8 text-feedback-red-500">
          <Warning size={32} />
          <Typography
            variant="body-md-regular"
            className="text-feedback-red-500"
            as="p"
          >
            We couldn{"'"}t update your payment information. Please try again
            later.
          </Typography>
        </div>
      )}
    </div>
  );
};
