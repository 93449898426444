import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';
import { ArrowCounterClockwise, X } from 'phosphor-react';
import toast from 'react-hot-toast';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCurrentMember, useSubscriptionSuspense } from 'src/api/queries';
import { isCanceledSubscription } from 'src/utils/isCanceledSubscription';
import { subscriptionIsConnectForBusiness } from 'src/utils/subscriptionIsConnectForBusiness';
import { URLS } from 'src/utils/urls/urls';

import {
  Button,
  Divider,
  Link,
  NotificationBox,
  Sheet,
} from '../../../../components';
import { isStripeSubscription } from '../../../../utils/isStripeSubscription';
import { subscriptionIsFreeConnect } from '../../../../utils/subscriptionisFreeConnect';
import {
  AbortCancelSubscriptionModal,
  useAbortCancelSubscriptionModal,
} from './AbortCancelSubscriptionModal';
import { CancelSubscriptionButton } from './CancelSubscriptionButton';
import {
  CancelSubscriptionModal,
  useCancelSubscriptionModal,
} from './CancelSubscriptionModal';
import { ConnectSeatsDetails } from './ConnectPlanSeatsDetails';
import { ConnectSimpleDetails } from './ConnectPlanSimpleDetails';
import { FreeConnectDetails } from './FreeConnectDetails';
import {
  LeaveSubscriptionModal,
  useLeaveSubscriptionModal,
} from './LeaveSubscriptionModal';
import { NoSubscriptionDetails } from './NoSubscriptionCard';

const routeApi = getRouteApi('/_auth/_layout/subscriptions/plan');

export const SubscriptionCard = () => {
  const subscription = useSubscriptionSuspense();
  const leaveSubscriptionModal = useLeaveSubscriptionModal();
  const cancelSubscriptionModal = useCancelSubscriptionModal();
  const abortCancelSubscriptionModal = useAbortCancelSubscriptionModal();
  const member = useCurrentMember();
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  const shouldSeeLeaveButton =
    member.data?.access.canRemoveUser &&
    !subscription.data?.access.canSeeMembers;

  const isCanceled = isCanceledSubscription(subscription.data);

  if (search.cancel_subscription === 'confirmation') {
    toast.success('Subscription cancelled');
    void navigate({ search: { cancel_subscription: undefined } });
  }

  return (
    <Sheet>
      <SubscriptionDetails />
      {isCanceled && (
        <>
          <Divider className="my-24" />
          <NotificationBox variant="info" title="Help us get better">
            <Typography variant="body-sm-regular" as="p">
              Help us understand why Connect wasn&apos;t for you by answering{' '}
              <Link inline to={URLS.CANCELLED_SUBSCRIPTION_SURVEY}>
                this survey
              </Link>
            </Typography>
          </NotificationBox>
        </>
      )}

      {(shouldSeeLeaveButton ||
        subscription.data?.access.canCancel ||
        subscription.data?.access.canReactivate) && (
        <Divider className="my-24" />
      )}

      <div className="flex gap-16">
        {shouldSeeLeaveButton && (
          <Button
            variant="tertiary"
            onClick={leaveSubscriptionModal.open}
            size="medium"
            analytics={{
              text: 'Leave subscription',
              location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
              action: 'open leave subscription modal',
            }}
          >
            <X /> <span>Leave subscription</span>
          </Button>
        )}

        {subscription.data?.access.canCancel &&
          !isCanceled &&
          (subscriptionIsConnectForBusiness(subscription.data) ? (
            <Button
              variant="secondary"
              onClick={cancelSubscriptionModal.open}
              size="medium"
              analytics={{
                text: 'Cancel subscription',
                location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
                action: 'open cancel subscription modal',
              }}
            >
              <X />
              <span>Cancel subscription</span>
            </Button>
          ) : (
            <CancelSubscriptionButton />
          ))}

        {subscription.data?.access.canReactivate && isCanceled && (
          <Button
            variant="primary-neutral"
            onClick={abortCancelSubscriptionModal.open}
            size="medium"
            analytics={{
              text: 'Undo cancelation',
              location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
              action: 'open undo cancelation modal',
            }}
          >
            <ArrowCounterClockwise />
            <span>Undo cancelation</span>
          </Button>
        )}
      </div>

      <CancelSubscriptionModal />
      <AbortCancelSubscriptionModal />
      <LeaveSubscriptionModal />
    </Sheet>
  );
};

const SubscriptionDetails = () => {
  const subscriptions = useSubscriptionSuspense();

  if (subscriptions.isError) {
    return (
      <NotificationBox variant="error" title="Something went wrong">
        Error loading subscriptions
      </NotificationBox>
    );
  }

  if (isStripeSubscription(subscriptions.data)) {
    if (subscriptions.data.managementType === 'seats') {
      return <ConnectSeatsDetails />;
    }

    return <ConnectSimpleDetails />;
  }

  if (subscriptionIsFreeConnect(subscriptions.data)) {
    return <FreeConnectDetails />;
  }

  return <NoSubscriptionDetails />;
};
