import { useCallback, useMemo } from 'react';

import { RM1, RM2, RMpp } from '@remarkable/ark-icons';
import { Typography } from '@remarkable/ark-web';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  CaretRight,
  Check,
  Desktop,
  DeviceMobile,
  GoogleChromeLogo,
} from 'phosphor-react';

import { ClientInformation } from 'src/api/endpoints/cloudApi.types';
import { useDeleteDevice, useDevices } from 'src/api/queries';
import { MAXIMUM_DEVICES_ALLOWED } from 'src/apps/device/utils/constants';
import {
  Button,
  Divider,
  Modal,
  NotificationBox,
  Spinner,
  Table,
  Tag,
} from 'src/components';
import { formatDate, formatShortDate } from 'src/utils/formatDate';

import {
  filterActiveBrowserExtensionClients,
  filterActiveDesktopAppClients,
  filterActiveMobileAppClients,
  filterActivePaperTabletClients,
  getClientTitle,
  trackProductConfigured,
} from '../utils/utils';
import { VerifyUnpairModal } from './VerifyUnpairModal';

const columnHelper = createColumnHelper<ClientInformation>();

const getDeviceIcon = (clientType: ClientInformation) => {
  switch (clientType.clientType) {
    case 'desktop':
      return <Desktop size={24} />;
    case 'rm100':
      return <RM1 size={24} />;
    case 'rm110':
      return <RM2 size={24} />;
    case 'RM02':
      return <RMpp size={24} />;
    case 'mobile':
      return <DeviceMobile size={24} />;
    case 'browser':
      return <GoogleChromeLogo size={24} />;
    case 'unknown':
    default:
      return <RM2 size={24} />;
  }
};

type DeviceType =
  | 'paper-tablet'
  | 'desktop-app'
  | 'mobile-app'
  | 'read-on-remarkable';

const filterDevices = (
  deviceType: DeviceType,
  connectedDevices?: ClientInformation[]
) => {
  if (!connectedDevices) return [];
  switch (deviceType) {
    case 'paper-tablet':
      return filterActivePaperTabletClients(connectedDevices);
    case 'desktop-app':
      return filterActiveDesktopAppClients(connectedDevices);
    case 'mobile-app':
      return filterActiveMobileAppClients(connectedDevices);
    case 'read-on-remarkable':
      return filterActiveBrowserExtensionClients(connectedDevices);
    default:
      return [];
  }
};

const DevicePaperTabletDividerText = ({
  connectedDevices,
}: {
  connectedDevices: ClientInformation[];
}) => {
  const activeDevices = useMemo(
    () => filterActivePaperTabletClients(connectedDevices),
    [connectedDevices]
  );

  return (
    <>
      Your devices
      <Tag variant="success" data-cy="device-count-tag" className="ml-16">
        <Check />
        {`${activeDevices.length} / ${MAXIMUM_DEVICES_ALLOWED}`}
      </Tag>
    </>
  );
};

export const DeviceTable = ({ deviceType }: { deviceType: DeviceType }) => {
  const connectedDevices = useDevices();
  const deleteDeviceMutation = useDeleteDevice();

  const handleUnpair = useCallback(
    (device: ClientInformation) => {
      deleteDeviceMutation.mutate(device, {
        onSuccess: () => {
          trackProductConfigured(device, 'device_unpaired');
        },
      });
    },
    [deleteDeviceMutation]
  );

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('clientName', {
        header:
          deviceType === 'paper-tablet'
            ? 'Paper tablet'
            : deviceType === 'read-on-remarkable'
            ? 'Extension'
            : 'Application',
        meta: {
          colWidth: 'w-[250px]',
        },
        cell: (info) => (
          <span className="flex flex-row items-center gap-8">
            {getDeviceIcon(info.row.original)}
            <Typography variant="interface-md-medium">
              {getClientTitle(info.row.original)}
            </Typography>
          </span>
        ),
      }),
      columnHelper.accessor((client) => formatDate(client.registeredAt), {
        header: 'Registered',
      }),
      columnHelper.display({
        header: ' ',
        meta: {
          align: 'center',
          colWidth: 'w-[100px]',
          truncate: false,
        },
        cell: (info) => (
          <Modal.Root>
            <VerifyUnpairModal
              onUnpair={() => handleUnpair(info.row.original)}
              title={`Unpair ${getClientTitle(info.row.original)}`}
              isUnpairing={deleteDeviceMutation.isPending}
            />
            <Modal.Trigger asChild>
              <Button size="small" variant="tertiary-neutral">
                <span>Unpair</span> <CaretRight />
              </Button>
            </Modal.Trigger>
          </Modal.Root>
        ),
      }),
    ];

    if (deviceType === 'paper-tablet') {
      cols.splice(
        2,
        0,
        columnHelper.accessor((client) => client.clientId, {
          header: 'Serial number',
        })
      );
    }

    return cols;
  }, [deviceType]);

  const filteredDevices = useMemo(() => {
    return filterDevices(deviceType, connectedDevices.data) || [];
  }, [deviceType, connectedDevices.data]);

  const table = useReactTable({
    data: filteredDevices,
    columns: columns as ColumnDef<ClientInformation>[],
    getRowId: (clientInformation) => clientInformation.clientId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    renderFallbackValue: () => '-',
  });

  if (connectedDevices.isPending) return <Spinner />;

  if (connectedDevices.isError)
    return (
      <NotificationBox
        className="mt-24"
        variant="error"
        title="Something went wrong. Please try again later."
      />
    );

  if (filterDevices(deviceType, connectedDevices.data).length === 0) {
    return null;
  }

  return (
    <>
      <Divider
        title={
          deviceType === 'paper-tablet' ? (
            <DevicePaperTabletDividerText
              connectedDevices={connectedDevices.data}
            />
          ) : deviceType === 'read-on-remarkable' ? (
            'Your extensions'
          ) : (
            'Your apps'
          )
        }
        className="my-32"
      />
      <Table table={table} data-cy="device-table" className="hidden lm:table" />
      <ul className="border-t border-gray-100 lm:hidden" data-cy="device-list">
        {table.getRowModel().rows.map((row) => (
          <li
            data-cy={`device-row-${row.id}`}
            key={row.id}
            className="border-b border-gray-100 py-12"
          >
            <div className="flex w-full flex-row items-center justify-between ">
              <div>
                <Typography variant="body-sm-regular" className="truncate">
                  <span className="font-semibold">
                    {getClientTitle(row.original)}
                  </span>
                  <span className="mx-4">|</span>
                  {formatShortDate(row.original.registeredAt, {
                    showYear: true,
                  })}
                </Typography>
                {deviceType === 'paper-tablet' && (
                  <Typography variant="body-sm-regular">
                    {row.original.clientId}
                  </Typography>
                )}
              </div>

              <Modal.Root>
                <VerifyUnpairModal
                  onUnpair={() => handleUnpair(row.original)}
                  title={`Unpair ${getClientTitle(row.original)}`}
                  isUnpairing={deleteDeviceMutation.isPending}
                />
                <Modal.Trigger asChild>
                  <Button variant="tertiary-neutral" size="medium">
                    <span>Unpair</span> <CaretRight />
                  </Button>
                </Modal.Trigger>
              </Modal.Root>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
