import { HTMLAttributes } from 'react';

export const Label = ({
  className = '',
  children,
  ...rest
}: HTMLAttributes<HTMLLabelElement>) => {
  return (
    <label
      className={`inline-block text-14 font-regular text-gray-600 ${className}`}
      {...rest}
    >
      {children}
    </label>
  );
};
