import { ReactNode } from 'react';

export const formatDate = <T extends ReactNode = string>(
  date?: Date | string | null,
  options?: { fallback?: T }
) =>
  date
    ? new Date(date).toLocaleDateString('en-us', {
        dateStyle: 'long',
      })
    : options?.fallback ?? '';

export const formatShortDate = <T extends ReactNode = string>(
  date?: Date | string | null,
  options?: { showTime?: boolean; showYear?: boolean; fallback?: T }
) =>
  date
    ? new Date(date).toLocaleDateString('en-us', {
        year: options?.showYear ? 'numeric' : undefined,
        month: 'short',
        day: 'numeric',
        hour: options?.showTime ? 'numeric' : undefined,
        minute: options?.showTime ? 'numeric' : undefined,
        hour12: false,
      })
    : options?.fallback ?? '';
