import { Typography } from '@remarkable/ark-web';

import { CardContent, Sheet } from 'src/components';

export const ConfirmSyncCard = () => (
  <Sheet className="mb-32">
    <CardContent className="flex flex-col gap-16">
      <Typography variant="heading-lg">
        We will now sync your content
      </Typography>

      <Typography variant="body-md-regular">
        Complete this step to transfer your documents and files to your new
        paper tablet.
      </Typography>
    </CardContent>
  </Sheet>
);
