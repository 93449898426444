import { ComponentLocations } from 'src/analytics/trackingTypes';
import { AppPage } from 'src/components/AppPage';
import { Offer } from 'src/components/Offers/Offer';

export const ConnectLandingPage = () => {
  return (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Content className="max-w-l2xl pt-64">
        <Offer
          checkoutType="store"
          componentLocation={ComponentLocations.CHECKOUT.LANDING}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
