import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCancelSubscription, useSubscriptionMembers } from 'src/api/queries';
import { Button, Modal2, NotificationBox } from 'src/components';
import { createModalHook } from 'src/utils/useModal';

export const useCancelSubscriptionModal = createModalHook('CancelSubscription');

export const CancelSubscriptionModal = () => {
  const modal = useCancelSubscriptionModal();
  const cancelSubscription = useCancelSubscription();
  const members = useSubscriptionMembers();

  const memberCount =
    members.data?.filter((member) => member.email).length ?? 0;

  if (!modal.isOpen) return null;

  return (
    <Modal2 onClose={modal.close}>
      <Typography variant="heading-md" className="mb-24 ls:mb-32">
        Cancel subscription?
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        Are you sure you want to cancel the subscription
        {memberCount > 1 && <b> for all {memberCount} members</b>}?
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        At the end on the billing period, members will lose access to all
        features, such as note-taking in the mobile and desktop apps, and
        unlimited cloud storage and sync.
      </Typography>

      <Typography variant="body-md-regular" className="mb-16">
        Files already stored in the cloud will still be accessible for team
        members.
      </Typography>

      {cancelSubscription.isError && (
        <NotificationBox
          variant="error"
          title="Something went wrong"
          className="mt-16"
        >
          Please try again or contact support if the problem persists.
        </NotificationBox>
      )}

      <div className="mt-32 flex flex-col justify-center gap-16 ls:flex-row">
        <Button
          variant="primary"
          size="medium"
          className="w-full xs:w-fit"
          onClick={modal.close}
          analytics={{
            text: 'Keep subscription',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'keep subscription (close modal)',
          }}
        >
          Keep subscription
        </Button>

        <Button
          variant="secondary"
          size="medium"
          className="w-full xs:w-fit"
          loading={cancelSubscription.isPending}
          onClick={() => {
            cancelSubscription.mutate(undefined, {
              onSuccess: () => {
                modal.close();
              },
            });
          }}
          analytics={{
            text: 'Cancel subscription',
            location: ComponentLocations.MANAGE_CONNECT.SUBSCRIPTION_PAGE,
            action: 'cancel subscription',
          }}
        >
          Cancel subscription
        </Button>
      </div>
    </Modal2>
  );
};
