import { useMemo } from 'react';

import { Subscription } from 'src/api/endpoints/storeApi.types';
import { formatDate, formatShortDate } from 'src/utils/formatDate';
import { formatAmountBrowserDefaultLocale } from 'src/utils/productUtils';

export const useFormattedPaymentInfo = (sub?: Subscription) => {
  const paymentInformation = useMemo(() => {
    if (sub?.type !== 'stripe' || !sub?.paymentInformation) return null;

    const paymentInformation = sub.paymentInformation.payment;
    //todo: pay by invoice
    if (
      !paymentInformation ||
      paymentInformation.type !== 'card' ||
      !paymentInformation.details
    )
      return null;

    const { cardInformation } = paymentInformation;

    const nextBillingDate = sub.paymentInformation.nextInvoice?.dueDate;

    const nextBilling = formatDate(nextBillingDate);

    const nextBillingShort = formatShortDate(nextBillingDate);

    const amount = sub.paymentInformation.nextInvoice?.amount;
    const nextBillingAmount = amount
      ? formatAmountBrowserDefaultLocale(amount.total, amount.currency)
      : 'N/A';

    return {
      cardBrand: (cardInformation?.brand ?? '').toUpperCase(),
      lastDigits: cardInformation?.last4,
      expireMonth: cardInformation?.expireMonth.toString().padStart(2, '0'),
      expireYear: cardInformation?.expireYear.toString().slice(-2),
      hasExpired: false, // TODO: Figure out if we need this
      nextBilling,
      nextBillingShort,
      nextBillingAmount,
    };
  }, [sub]);

  return paymentInformation;
};
