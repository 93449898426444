import { useMemo } from 'react';

import { FeedbackTargetValues } from 'src/api/endpoints/storeApi.types';
import { useStripeSubscriptionSuspense } from 'src/api/queries';
import { useHasLinkedAccounts } from 'src/apps/enterprise/app/utils/useHasLinkedAccounts';
import { ConnectForBusinessLinkCard, Feedback } from 'src/components';

import { SubscriptionCard } from '../components/SubscriptionCard';
import { SubscriptionFAQ } from '../components/SubscriptionFAQ';

export const SubscriptionPage = () => {
  const subscription = useStripeSubscriptionSuspense();
  const hasLinkedAccountStatus = useHasLinkedAccounts();

  const feedbackTargetId = useMemo((): FeedbackTargetValues | null => {
    if (!subscription.data) return null;

    if (subscription.data.managementType === 'simple') {
      return 'connect-plan-page-v1';
    }

    // TODO: Check the member seat instead for the actual role name
    if (subscription.data.access.canSeeMembers) {
      return 'c4b-plan-page-for-owner-v1';
    }

    return 'c4b-plan-page-for-member-v1';
  }, [subscription.data]);

  return (
    <div className="flex flex-col gap-24">
      <SubscriptionCard />
      {subscription.data?.managementType !== 'seats' && (
        <>
          {!hasLinkedAccountStatus.data?.isLinked && (
            <ConnectForBusinessLinkCard />
          )}

          <SubscriptionFAQ />
        </>
      )}
      {feedbackTargetId && (
        <Feedback
          feedbackTargetId={feedbackTargetId}
          title="Did you find what you were looking for?"
        />
      )}
    </div>
  );
};
