import { FC } from 'react';

import { Typography } from '@remarkable/ark-web';
import { WarningCircle } from 'phosphor-react';

import { Button, Modal } from 'src/components';
import { LinkExternal } from 'src/components/LinkExternal';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

export const VerifyMultiplePairModal: FC = () => (
  <Modal.Content className="max-w-[850px]">
    <WarningCircle
      className="mx-auto rounded-full bg-[#00000080] p-6"
      color="white"
      height={43}
      width={43}
    />
    <div className="p-32" data-cy="verify-multiple-pair-modal-content">
      <Typography variant="heading-md">
        Have you paired this device before?
      </Typography>
      <Typography className="my-32" variant="body-md-regular">
        If the device has been paired to your account before, we recommend a
        factory reset through Account settings on your reMarkable. Data and
        unsynced changes are at the risk of being lost.
      </Typography>
      <p>
        <LinkExternal
          inline
          to={SUPPORT_URL.ARTICLES.HOW_TO_FACTORY_RESET_YOUR_REMARKABLE}
          className="m-auto"
        >
          Read more about performing a factory reset
        </LinkExternal>
      </p>
      <div className=" mx-auto mt-32 flex w-fit flex-col items-center gap-16 px-32 md:flex-row md:justify-evenly">
        <Modal.Close asChild>
          <Button
            data-cy="cancel-multiple-pair-device-button"
            variant="secondary"
            className="w-full flex-1"
          >
            Cancel
          </Button>
        </Modal.Close>
        <Button
          data-cy="confirm-multiple-pair-device-button"
          as="a"
          to={URLS.DEVICE_REMARKABLE_CONNECT}
          className="flex"
        >
          <span>Pair device</span>
        </Button>
      </div>
    </div>
  </Modal.Content>
);
