import { PropsWithChildren } from 'react';

import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';

import {
  hasAcceptedFreeConnectTerms,
  useAcceptFreeConnectTerms,
  useFreeConnectTerms,
  useSubscription,
} from 'src/api/queries';
import {
  Button,
  CardContent,
  Link,
  NotificationBox,
  Sheet,
  Spinner,
} from 'src/components';
import { subscriptionIsFreeConnect } from 'src/utils/subscriptionisFreeConnect';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';
import { valueProps } from 'src/utils/valueProps';

const AcceptFreeAccessCardWrapper = ({ children }: PropsWithChildren) => (
  <Sheet data-cy="accept-free-terms-conditions-card">
    <Typography variant="heading-md" as="h1" className="mb-16">
      Terms and conditions
    </Typography>
    {children}
  </Sheet>
);

const routeApi = getRouteApi('/_auth/_layout/free-access-terms');

export const AcceptFreeConnectTermsAndConditionsPage = () => {
  const subscription = useSubscription();
  const freeConnectTerms = useFreeConnectTerms();
  const navigate = routeApi.useNavigate();
  const search = routeApi.useSearch();
  const acceptFreeConnectTerms = useAcceptFreeConnectTerms();

  if (subscription.isPending || freeConnectTerms.isPending) {
    return <Spinner />;
  }

  const hasAcceptedTerms = hasAcceptedFreeConnectTerms(
    freeConnectTerms.data ?? []
  );

  const freeConnectSubscription = subscriptionIsFreeConnect(subscription.data);

  // Navigate away customers that does not have Free Connect
  if (!freeConnectSubscription) {
    void navigate({ to: '/' });

    return <Spinner />;
  }

  const handleAcceptTerms = () => {
    acceptFreeConnectTerms.mutate(undefined, {
      onSuccess: () => {
        const returnUrl = search.return_to;

        // only allow relative urls
        if (
          typeof returnUrl === 'string' &&
          returnUrl.length > 1 &&
          returnUrl[0] === '/' &&
          returnUrl[1] !== '/'
        ) {
          return navigate({ to: returnUrl });
        } else {
          return navigate({ to: '/subscriptions' });
        }
      },
    });
  };

  // This will happen if the customer clicks the 'I accept' button
  if (freeConnectSubscription && acceptFreeConnectTerms.isSuccess) {
    return <Spinner />;
  }

  if (freeConnectTerms.isError) {
    return (
      <NotificationBox
        variant="error"
        title="Something went wrong"
        data-cy="terms-conditions-history-error"
        className="mb-32"
      >
        <Typography variant="body-sm-regular" as="p">
          Please try again later, or{' '}
          <a href={SUPPORT_URL.HOME} target="_blank" rel="noreferrer">
            contact support
          </a>{' '}
          if the problem persists. We are sorry about the inconvenience.
        </Typography>
      </NotificationBox>
    );
  }

  // Check if user has already accepted terms and conditions
  if (
    hasAcceptedTerms &&
    !acceptFreeConnectTerms.isPending &&
    !acceptFreeConnectTerms.isSuccess
  ) {
    return (
      <AcceptFreeAccessCardWrapper>
        <CardContent dataCy="already-accepted" border={true}>
          <Typography variant="body-md-regular" as="p">
            You have already accepted the{' '}
            <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
              terms and conditions
            </Link>
            .
          </Typography>
        </CardContent>
        <CardContent className="pt-16">
          <Button variant="secondary" as="a" to={URLS.HOME}>
            Go to frontpage
          </Button>
        </CardContent>
      </AcceptFreeAccessCardWrapper>
    );
  }

  return (
    <AcceptFreeAccessCardWrapper>
      <CardContent dataCy="not-accepted" className="pt-16" border={true}>
        <Typography variant="body-md-regular" as="p">
          As a valued early customer, we&apos;re offering you{' '}
          <Typography variant="body-md-bold" as="strong">
            full, free access to Connect
          </Typography>
          . It&apos;s our way to say thanks for believing in us from the start.
          You&apos;ll enjoy:
        </Typography>
        <ul className="my-16 ml-16 list-disc">
          {Object.values(valueProps.connect).map((valueProp) => (
            <Typography variant="body-md-regular" as="li" key={valueProp.id}>
              {valueProp.title}
            </Typography>
          ))}
        </ul>
        <Typography variant="body-md-regular" as="p">
          To secure your free access, please accept our{' '}
          <Link to={SUPPORT_URL.LEGAL_OVERVIEW} inline>
            terms and conditions
          </Link>
          .
        </Typography>
      </CardContent>
      <CardContent className="pt-16">
        <Button
          data-cy="accept-terms-conditions-button"
          onClick={handleAcceptTerms}
          as="button"
          disabled={
            freeConnectTerms.isPending || acceptFreeConnectTerms.isPending
          }
          loading={
            freeConnectTerms.isPending || acceptFreeConnectTerms.isPending
          }
        >
          I accept
        </Button>
        {acceptFreeConnectTerms.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            className="mt-16"
          >
            <Typography
              variant="body-sm-regular"
              as="p"
              data-cy="accept-terms-conditions-error"
            >
              Something went wrong while accepting the terms. We are sorry about
              the inconvenience.
            </Typography>
          </NotificationBox>
        )}
      </CardContent>
    </AcceptFreeAccessCardWrapper>
  );
};
