function createError(name: string, message: string): Error {
  const newError = new Error(message);
  newError.name = name;
  return newError;
}

export const errIntAlreadyAdded = createError(
  'Integration already added',
  'This account is identical to one of your current integrations'
);

export const errAccountNotMatching = createError(
  'Account does not match our records',
  'The email address for the integration that you’re trying to reauthorize is different from what you have entered previously.'
);

export type DecodeExceptionType =
  | 'INVALID_URL'
  | 'ACCESS_DENIED'
  | 'GENERIC_ERROR'
  | 'MISSING_STATE_OR_CODE'
  | 'DECODE_ERROR';

type DecodeError = {
  title: string;
  message: string;
};

const getErrorMessage = (type: DecodeExceptionType): DecodeError => {
  switch (type) {
    case 'ACCESS_DENIED':
      return {
        title: 'Unable to access your files',
        message:
          'You need to give reMarkable Integrations permission to access to the files in your chosen service for this feature to work correctly.',
      };
    case 'INVALID_URL':
    case 'MISSING_STATE_OR_CODE':
    case 'GENERIC_ERROR':
    case 'DECODE_ERROR':
    default:
      return {
        title: 'Oops, something went wrong',
        message:
          'We’re currently trying to fix this issue. Please try again later.',
      };
  }
};

export class DecodeProviderCallbackException {
  public title: string;
  public message: string;

  constructor(public type: DecodeExceptionType) {
    const e = getErrorMessage(type);

    this.title = e.title;
    this.message = e.message;
  }
}
