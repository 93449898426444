import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { cloudEnterpriseEnrollmentApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export function useEnterpriseEnrollmentStatus() {
  return useQuery({
    queryKey: queryKeys.enterpriseEnrollment.status,
    queryFn: () => cloudEnterpriseEnrollmentApi.getStatus(),
    refetchInterval(query) {
      if (query.state.data?.domainVerification === 'IN_PROGRESS') {
        return 10_000; // 10 seconds
      }

      return false;
    },
  });
}

export function useEnterpriseCreateEnrollment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.init,
    mutationFn: cloudEnterpriseEnrollmentApi.initEnrollment,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseSubmitConnection() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.connection,
    mutationFn: cloudEnterpriseEnrollmentApi.submitConnection,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseEnrollmentVerify() {
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.verify,
    mutationFn: cloudEnterpriseEnrollmentApi.verifyDomain,
  });
}

export function useEnterpriseCancelEnrollment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.cancel,
    mutationFn: cloudEnterpriseEnrollmentApi.cancelEnrollment,
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: queryKeys.enterpriseEnrollment.status,
      });
    },
  });
}

export function useEnterpriseFinalize() {
  return useMutation({
    mutationKey: queryKeys.enterpriseEnrollment.finalize,
    mutationFn: cloudEnterpriseEnrollmentApi.finalizeEnrollment,
  });
}
