import { createFileRoute, Navigate } from '@tanstack/react-router';

// This Route is primarily hit by the Microsoft OneDrive auth flow
export const Route = createFileRoute('/_auth/_layout/integrations/callback')({
  component: () => {
    const search = Route.useSearch();

    return (
      <Navigate to="/integrations" search={{ callback: true, ...search }} />
    );
  },
});
