// {
//   email?: string;
//   email_verified?: boolean;
//   family_name?: string;
//   given_name?: string;
//   /**
//    * iso datestring representing when the user was created
//    * @example 2024-09-05T13:10:42.821Z
//    */
//   'https://auth.remarkable.com/created_at'?: string;
//   /**
//    * How many times the user has logged in
//    */
//   'https://auth.remarkable.com/logins_count'?: number;
//   /**
//    * org id, present when user is member of an org
//    * @example org_fdjEgks3nv2dki49I
//    */
//   'https://auth.remarkable.com/organization'?: string;
//   /**
//    * specifies region for cloud apis and is injected into the base url
//    * @example eu | us | dev
//    */
//   'https://auth.remarkable.com/tectonic'?: string;
//   /**
//    * User ID for another account that can be linked to the current one
//    * @example auth0|asf334j2-fjd2-05bk-nsk4-1p0exaej44g1
//    */
//   'https://auth.remarkable.com/linkable'?: string;
//   /**
//    * iso datestring
//    * @example 2024-09-05T13:10:42.821Z
//    */
//   'https://auth.remarkable.com/last_login'?: string;

//   /**
//    * User email address
//    */
//   'https://auth.remarkable.com/email'?: string;

//   /**
//    * User email verified status
//    */
//   'https://auth.remarkable.com/email_verified'?: boolean;

//   /**
//    * Email domain claimed
//    */
//   'https://auth.remarkable.com/domain_claimed_by_connection'?: string;
//   name?: string;
//   nickname?: string;
//   /**
//    * Present when the user is logged in with org access (by populating
//    * the `organization` property during login)
//    * @example org_fdjEgks3nv2dki49I
//    */
//   org_id?: string;
//   /**
//    * Url to image source
//    */
//   picture?: string;
//   /**
//    * User ID
//    * @example auth0|asf334j2-fjd2-05bk-nsk4-1p0exaej44g1
//    */
//   sub?: string;
//   /**
//    * iso datestring
//    * @example 2024-09-05T14:30:42.815Z
//    */
//   updated_at?: string;

//   permissions?: EnterprisePermission[];
// }

export type MutationOnSuccessOption<TData = unknown> = {
  onSuccess?: (data: TData) => Promise<void> | void;
};

export class NoStripeSubscriptionException {}

export class RetryException extends Error {
  constructor(message?: string, public attempts?: number) {
    super(message ? `Retry: ${message}` : 'Retry');
  }
}

export class TooManyRequestsException extends Error {
  constructor(message?: string) {
    super(message ? `Too many requests: ${message}` : 'Too many requests');
  }
}
