import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Spinner, Typography } from '@remarkable/ark-web';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { VerifyEmail } from 'src/components/VerifyEmail';
import { userHelpers } from 'src/utils/userHelpers';

export const Route = createFileRoute('/_auth')({
  async beforeLoad({ context, location }) {
    const auth = await context.auth; // Wait for the auth context to be ready

    if (auth.error) {
      const searchParams = new URLSearchParams(location.searchStr);
      const errorParam = searchParams.get('error');

      switch (errorParam) {
        case 'login_required': {
          throw redirect({
            to: '/login',
            search: {
              login_hint: auth.user && userHelpers.getEmail(auth.user),
              prompt: 'none',
              redirect: location.pathname + location.searchStr + location.hash,
            },
          });
        }
        case 'access_denied': {
          throw redirect({ to: '/access-denied' });
        }
        default: {
          /*
          Handle Auth0 'invalid state' error by first doing a no-prompt login
          attempt, then doing a regular login attempt, and finally redirecting
          to an error page if the issue persists.


          Reference:
          https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/9

          "[...] In summary, before a login transaction starts auth0-spa-js
          library creates a random state variable and stores it in a cookie.
          Then, it makes a redirect to Auth0 server with this state parameter.
          After the user is authenticated Auth0 returns a code along with the
          same state parameter. Auth-spa-js library checks if the state returned
          matches with the state stored in the cookie and if it matches makes a
          code exchange call to get the issued tokens.

          auth0-spa-js deletes the state cookie just before the code exchange
          request. If the user refreshes the browser before the code exchange
          completes, same code block in the library runs again but this time due
          to the state cookie being deleted, library throws a state mismatch
          error. [...]"

          ---

          It is unclear exactly why we trigger this error, it could be that we
          have some race conditions that interferes with Auth0, but until we
          can figure out the root cause, we will just attempt to handle the
          error when it occurs.
          */
          const loginAttemptsCookie = 'login_attempts=';

          if (
            auth.error.name === 'Error' &&
            auth.error.message === 'Invalid state'
          ) {
            // We need to keep track of the number of login attempts
            // to prevent an infinite loop of login attempts
            const cookies = document.cookie.split(';');
            let loginAttempts = Number(
              cookies
                .find((cookie) => cookie.includes(loginAttemptsCookie))
                ?.split('=')[1]
            );
            loginAttempts = isNaN(loginAttempts) ? 0 : loginAttempts;

            if (loginAttempts < 2) {
              document.cookie = `${loginAttemptsCookie}${loginAttempts + 1};`;
              throw redirect({
                to: '/login',
                search: {
                  login_hint: auth.user && userHelpers.getEmail(auth.user),
                  prompt: loginAttempts === 0 ? 'none' : undefined,
                  redirect:
                    location.pathname + location.searchStr + location.hash,
                },
              });
            } else {
              document.cookie = `${loginAttemptsCookie}0;`;

              throw redirect({
                to: '/authentication-error',
                search: {
                  error: 'Unable to authenticate',
                  error_description: 'Invalid state',
                },
              });
            }
          }

          // Reset login-attempts cookie
          document.cookie = `${loginAttemptsCookie}0;`;

          throw redirect({
            to: '/authentication-error',
            search: {
              error: errorParam,
              error_description: searchParams.get('error_description'),
            },
          });
        }
      }
    }
  },

  component: () => <OutletWithAuth />,
});

const OutletWithEmailVerification = () => {
  const auth = useAuth0();

  if (
    !auth.user?.email_verified &&
    auth.user &&
    !userHelpers.isSAMLUser(auth.user)
  ) {
    return <VerifyEmail />;
  }

  return <Outlet />;
};

const OutletWithAuth = withAuthenticationRequired(OutletWithEmailVerification, {
  onRedirecting: () => (
    <div className="flex min-h-[100vh] flex-col items-center justify-center">
      <Spinner
        key="spinner-auth-redirect"
        size="large"
        className="animate-fade-in-up"
      />
      <div className="flex justify-center">
        <Typography
          variant="interface-sm-regular"
          className="absolute mt-24 animate-fade-in-down text-muted"
        >
          Redirecting...
        </Typography>
      </div>
    </div>
  ),
});
