import { Middleware } from 'openapi-fetch';

import { auth0Client } from 'src/auth0Client';
import { CONFIG } from 'src/config';

/** Changes the base url based on tectonic claim, if found in the IdToken */
export const setTectonicUrl: Middleware = {
  async onRequest(req) {
    const auth0 = await auth0Client;

    const claims = await auth0.getIdTokenClaims();
    const tectonic = claims?.[CONFIG.TectonicTokenKey] as string;
    const tectonicUrl = `https://web.${tectonic}.tectonic.remarkable.com`;

    if (tectonic && typeof tectonic === 'string' && tectonic != '') {
      const requestUrl = new URL(req.url);
      let body: unknown = null;

      try {
        // Get the request body, if any
        body = await req.json();
      } catch (e) {
        // Probably not containing a request body, which is ok.
      }

      const newUrl = tectonicUrl + requestUrl.pathname;

      // Spreading req doesn't work as expected so we need to copy each property one by one.
      return new Request(newUrl, {
        body: body != null ? JSON.stringify(body) : undefined, // Single equal on purpose so that it includes undefined.
        cache: req.cache,
        credentials: req.credentials,
        headers: req.headers,
        integrity: req.integrity,
        keepalive: req.keepalive,
        method: req.method,
        mode: req.mode,
        redirect: req.redirect,
        referrer: req.referrer,
        referrerPolicy: req.referrerPolicy,
        signal: req.signal,
      });
    }

    return req;
  },
};
