import { useEffect, useRef, RefObject } from 'react';

export const useKeyboardNavigation = (
  externalRefs?: RefObject<(HTMLElement | null)[]>,
  options?: {
    onEnter?: (event: KeyboardEvent, index: number) => void;
  }
) => {
  const internalRefs = useRef<(HTMLElement | null)[]>([]);
  const elementsRef = externalRefs || internalRefs;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLElement;
      const currentIndex = elementsRef.current
        ? elementsRef.current.indexOf(activeElement)
        : -1;

      if (
        event.key === 'ArrowDown' &&
        currentIndex < (elementsRef.current?.length ?? 0) - 1
      ) {
        elementsRef.current?.[currentIndex + 1]?.focus();
        event.preventDefault();
      }

      if (event.key === 'ArrowUp' && currentIndex > 0) {
        elementsRef.current?.[currentIndex - 1]?.focus();
        event.preventDefault();
      }

      if (event.key === 'Enter' && currentIndex !== -1) {
        options?.onEnter?.(event, currentIndex);
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [elementsRef]);

  return {
    getRef: (index: number, el: HTMLElement | null) => {
      if (elementsRef.current) {
        elementsRef.current[index] = el;
      }
    },
  };
};
