import { DragEvent, useCallback, useState } from 'react';

import { useNavigate } from '@tanstack/react-router';
import { FileRejection, useDropzone } from 'react-dropzone';

import { useDragState } from 'src/apps/myFiles/app/providers/DragStateProvider';

import { DropAccept } from './DropAccept';
import { DropInfo } from './DropInfo';
import { DropReject } from './DropReject';

export const DropZone = () => {
  const dragState = useDragState();
  const navigate = useNavigate();
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      dragState.dispatch({
        type: 'upload_files',
        payload: {
          acceptFiles: acceptedFiles,
          rejectFiles: rejectedFiles,
        },
      });
      void navigate({ to: '/myfiles/$' });
    },
    []
  );
  const [singleFile, setSingleFile] = useState(false);
  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    setSingleFile(e.dataTransfer.items.length === 1);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDragEnter,
    accept: {
      'application/pdf': ['.pdf'],
      'application/epub+zip': ['.epub'],
      'image/*': ['.jpeg', '.png'],
    },
    noClick: true,
    maxSize: 104857600,
  });

  return (
    <div data-cy="dropzone" {...getRootProps()}>
      <input data-cy="dropzone-input" {...getInputProps()} />
      {/*Safari issue is causing isDragAccept and isDragReject to always be false */}
      {(isDragAccept || (isDragActive && !isDragAccept && !isDragReject)) && (
        <DropAccept />
      )}
      {isDragReject && <DropReject singleFile={singleFile} />}
      {!isDragActive && <DropInfo importFiles={open} />}
    </div>
  );
};
