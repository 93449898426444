import {
  useIntegrationProviders,
  useIntegrationTermsOfService,
  useIntegrations,
} from 'src/api/queries';
import { Divider, SomethingWentWrong, Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';

import { IntegrationsFAQ } from './components/IntegrationsFAQ';
import { IntegrationsList } from './components/IntegrationsList';
import { ProviderList } from './components/ProviderList';
import { SaveIntegrationModal } from './components/SaveIntegrationModal';
import { TermsOfServiceForm } from './components/TermsOfServiceForm';

export const IntegrationsApp = () => {
  const integrations = useIntegrations();
  const providers = useIntegrationProviders();
  const tos = useIntegrationTermsOfService();

  if (integrations.isError || providers.isError) {
    return <SomethingWentWrong />;
  }

  if (integrations.isPending || providers.isPending) {
    return <Spinner />;
  }

  return (
    <AppPage.Container>
      <AppPage.Header
        title="Manage Integrations"
        subtitle="Access and store your files with integrations like Google Drive,
              Dropbox, and OneDrive."
      />
      <AppPage.Content className="max-w-lxl gap-24">
        <TermsOfServiceForm />
        <IntegrationsList />
        <Divider title="Add services" className="my-40" />
        <ProviderList />
        {tos.hasAccepted && (
          <>
            <SaveIntegrationModal />
            <IntegrationsFAQ />
          </>
        )}
      </AppPage.Content>
    </AppPage.Container>
  );
};
