import { StripeSubscription } from 'src/api/endpoints/storeApi.types';

export function PaymentInformation({
  subscription,
}: {
  subscription?: StripeSubscription | null;
}) {
  if (!subscription) {
    return null;
  }

  switch (subscription.paymentInformation?.payment?.type) {
    case 'card':
      return (
        <>
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-16">Payment card:</p>
            <p className="m-0 text-16">{`${
              subscription.paymentInformation.payment.cardInformation?.brand ??
              'N/A'
            } *${
              subscription.paymentInformation.payment.cardInformation?.last4 ??
              '(Not available)'
            }`}</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="m-0 text-16">Valid to:</p>
            <p className="m-0 text-16">{`${String(
              subscription.paymentInformation.payment.cardInformation
                ?.expireMonth
            ).padStart(2, '0')} / ${
              subscription.paymentInformation.payment.cardInformation
                ?.expireYear ?? ''
            }`}</p>
          </div>
        </>
      );
    case 'invoice':
      return <p className="m-0 text-16">Paying with invoice</p>;
    default:
      return null;
  }
}
