import { Typography } from '@remarkable/ark-web';

import { CheckoutTypeParam } from 'src/api/endpoints/storeApi.types';
import { useActivePaperTablets } from 'src/api/queries';
import {
  MyRmExperienceCardRow,
  SomethingWentWrong,
  Spinner,
  Step,
  Stepper,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { URLS } from 'src/utils/urls/urls';

import { ConfirmSyncCard } from '../components/ConfirmSyncCard';
import { SubscriptionSummaryCard } from '../components/SubscriptionSummaryCard';
import { useCheckoutConfirmation } from '../useCheckoutConfirmation';

export const RetailOfferConfirmationPage = () => {
  const { isPending, isError, paymentConfirm, subscription } =
    useCheckoutConfirmation({
      checkoutType: CheckoutTypeParam.RETAIL_OFFER,
    });
  const activePaperTablets = useActivePaperTablets();

  if (isPending || activePaperTablets.isPending) {
    return <Spinner />;
  }
  if (isError || !paymentConfirm || !subscription) {
    return <SomethingWentWrong />;
  }

  const nextBillingDate = new Date(subscription.createdAt ?? '');
  nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);

  const title =
    activePaperTablets.data && activePaperTablets.data.length > 1
      ? 'Sync and transfer files'
      : 'Setting up';

  return (
    <AppPage.Container>
      <AppPage.Header title={title} />
      <Stepper className="mx-auto max-w-lg pb-32 pt-0 lm:pb-96">
        <Step disabled checked to={URLS.STORE_CONNECT_OFFER}>
          Pair your device
        </Step>
        <Step disabled checked to={URLS.STORE_RETAIL_OFFER_CHECKOUT}>
          Activate Connect
        </Step>
        <Step checked to={URLS.STORE_RETAIL_OFFER_CONFIRMATION}>
          Setup complete
        </Step>
      </Stepper>
      <AppPage.Content className="max-w-ll">
        {activePaperTablets.data && activePaperTablets.data.length > 1 ? (
          <ConfirmSyncCard />
        ) : null}
        <SubscriptionSummaryCard
          subscription={subscription}
          paymentConfirm={paymentConfirm}
          checkoutType="retailOffer"
          to={URLS.HOME}
        />
      </AppPage.Content>

      <AppPage.Content className="max-w-lxl">
        <div className="max-w-screen-lg px-16 lm:px-0">
          <Typography
            as="h2"
            variant="responsive-heading-lg"
            className="mb-32  text-center"
          >
            Explore the reMarkable universe
          </Typography>
          <MyRmExperienceCardRow />
        </div>
      </AppPage.Content>
    </AppPage.Container>
  );
};
