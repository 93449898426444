import { HTMLProps } from 'react';

export const Description = ({
  children,
  className = '',
  ...props
}: HTMLProps<HTMLDivElement>) => (
  <div className={'flex flex-col gap-16 ' + className} {...props}>
    <h2 className="heading-sm">Integrations</h2>
    <P>
      Access documents stored in popular file-sharing services with
      Integrations. Add your Google Drive, Dropbox, and OneDrive accounts to the
      sidebar menu on your reMarkable.
    </P>
    <P>
      From here you can browse your documents, copy them to My files to work on
      them without any distractions, or just upload your new notes straight to
      your chosen service.
    </P>
    <P>
      <b>We care about your data. Here&apos;s what we&apos;ll use:</b>
    </P>
    <ul className="list-disc pl-16">
      <li>Your username, so that we can log you in</li>
      <li>
        Access to your stored files so that you can either browse or download
        them
      </li>
      <li>Write access, so you can upload notes</li>
    </ul>
    {children}
  </div>
);

const P = ({
  children,
  className = '',
  ...props
}: HTMLProps<HTMLParagraphElement>) => (
  <p className={'body-md-regular ' + className} {...props}>
    {children}
  </p>
);
