import { auth0Client } from 'src/auth0Client';

import { BeforeRequestHook } from './createApiClient.types';

export const addAuthHeaders: BeforeRequestHook = async (requestConfig) => {
  if (requestConfig.headers && 'Authorization' in requestConfig.headers) {
    return requestConfig;
  }

  const auth0 = await auth0Client;
  const token = await auth0.getAccessTokenSilently();

  const newRequestConfig = {
    ...requestConfig,
    headers: {
      Authorization: `Bearer ${token}`,
      ...requestConfig.headers, // Don't overwrite existing headers
    },
  };

  return newRequestConfig;
};
