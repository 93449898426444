import { ConsentStatus } from '@remarkable/tracking-browser';
import { initTracker } from '@remarkable/tracking-browser/lib/ampli';

import { CONFIG } from '../config';

if (CONFIG.TrackingAPI === undefined) {
  console.error('TrackingAPI is not defined');
}

export const tracker = initTracker({
  apiEndpoint: CONFIG.TrackingAPI,
  consentStatus: typeof window === 'undefined' ? null : ConsentStatus.CONSENTED,
  ...(CONFIG.TrackingCookieDomain
    ? { cookieDomain: CONFIG.TrackingCookieDomain }
    : {}),
});
