import jwtDecode from 'jwt-decode';

import { DecodeProviderCallbackException } from './decodeErrors';

type CallbackRequest = {
  type: 'reauthorize' | 'create';
  provider: string;
  state: string;
  code: string;
  scope: string | undefined;
};

const GOOGLE_DRIVE_PROVIDER = 'GoogleDrive';
const GOOGLE_DRIVE_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive';

export function decodeCallbackRequest({
  code,
  error,
  scope,
  state,
}: {
  callback?: boolean;
  state?: string;
  code?: string;
  error?: string;
  scope?: string;
}): CallbackRequest {
  interface URLState {
    token: string;
    provider: string;
    integrationID?: string;
  }

  if (error) {
    if (error === 'access_denied') {
      throw new DecodeProviderCallbackException('ACCESS_DENIED');
    }

    throw new DecodeProviderCallbackException('GENERIC_ERROR');
  }

  if (!state || !code) {
    throw new DecodeProviderCallbackException('MISSING_STATE_OR_CODE');
  }

  let decodedState: URLState;
  try {
    decodedState = jwtDecode(state, { header: true });
  } catch (e) {
    throw new DecodeProviderCallbackException('DECODE_ERROR');
  }

  // For Google auth callbacks, we have to check of the user actually granted the required scope
  if (decodedState.provider === GOOGLE_DRIVE_PROVIDER) {
    const scopes = scope?.split(' ');
    if (!scopes || !scopes.includes(GOOGLE_DRIVE_DRIVE_SCOPE)) {
      throw new DecodeProviderCallbackException('ACCESS_DENIED');
    }
  }

  return {
    type: decodedState.integrationID ? 'reauthorize' : 'create',
    provider: decodedState.provider,
    state,
    code,
    scope,
  };
}
