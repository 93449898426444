import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Info } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { queryKeys } from 'src/api/queries';
import {
  useEnterpriseCreateEnrollment,
  useEnterpriseEnrollmentStatus,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  Divider,
  Form,
  Input,
  NotificationBox,
  Sheet,
  Tooltip,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { capitalize } from 'src/utils/capitalize';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';
import { getEmailInfo } from '../utils/getEmailDomain';

interface FormData {
  name: string;
}

const routeApi = getRouteApi('/_auth/_layout/enterprise/create-organization');

export function CreateOrganizationPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const navigate = routeApi.useNavigate();
  const { user } = useAuth0();
  const createEnterpriseEnrollment = useEnterpriseCreateEnrollment();
  const emailInfo = getEmailInfo(user?.email ?? '');
  const exampleName = emailInfo.domain.split('.')[0];
  const form = useForm<FormData>();
  const queryClient = useQueryClient();

  const onSubmit = async (data: FormData) => {
    if (enrollmentStatus.isSuccess && !!enrollmentStatus.data) {
      // Enrollment already initiated
      return navigate({ to: '/enterprise/verify-domain' });
    }

    createEnterpriseEnrollment.mutate(
      {
        orgName: data.name,
        domain: emailInfo.domain,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.enterpriseEnrollment.status,
          });
          return navigate({ to: '/enterprise/verify-domain' });
        },
      }
    );
  };

  const orgName = enrollmentStatus.data?.displayName;

  useEffect(() => {
    if (!orgName) return;

    form.reset({
      name: orgName,
    });
  }, [orgName]);

  return (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Header title="Single sign-on setup" className="max-w-l2xl" />

      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />
        <Sheet className="gap-16">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Create organization
            </Typography>

            <CancelSetupModal />
          </div>

          <Divider />

          <Typography variant="body-md-regular" className="pl-16">
            {emailInfo.domain}
          </Typography>

          <Divider />

          <Typography variant="body-md-regular">
            Give your organisation a name{' '}
            <Tooltip title="Needs to be unique">
              <Info className="inline-block" />
            </Tooltip>
          </Typography>

          <Form {...form} onSubmit={onSubmit} className="flex flex-col gap-24">
            <Input
              name="name"
              placeholder={capitalize(exampleName)}
              required={!enrollmentStatus.data}
              disabled={!!enrollmentStatus.data}
            />

            <div className="flex justify-center gap-24">
              <Button as="a" variant="secondary" to={URLS.ENTERPRISE_SETUP}>
                Previous
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={createEnterpriseEnrollment.isPending}
                disabled={createEnterpriseEnrollment.isPending}
              >
                Continue
              </Button>
            </div>
          </Form>

          {createEnterpriseEnrollment.isError && (
            <NotificationBox
              variant="error"
              title={createEnterpriseEnrollment.error.name}
            >
              {createEnterpriseEnrollment.error.message}
            </NotificationBox>
          )}
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
