import {
  PaymentInterval,
  Subscription,
} from 'src/api/endpoints/storeApi.types';

import { SKUs } from './skus';

interface SubscriptionInfo {
  name: string;
  sku: string;
}

export const deriveSubscriptionName = (
  subscription: Subscription
): SubscriptionInfo | null => {
  if (!subscription) return null;

  if (subscription.type === 'free-connect') {
    return {
      name: 'Free Connect',
      sku: 'free-connect', // Free connect doesn't have a sku
    };
  }

  if (subscription.managementType === 'simple') {
    if (
      subscription.paymentInformation?.paymentInterval ===
      PaymentInterval.MONTHLY
    ) {
      return {
        name: 'Connect',
        sku: SKUs.connectMonthly,
      };
    }

    if (
      subscription.paymentInformation?.paymentInterval ===
      PaymentInterval.ANNUALLY
    ) {
      return {
        name: 'Connect',
        sku: SKUs.connectAnnually,
      };
    }

    // We don't really know, but this is the default case
    return {
      name: 'Connect',
      sku: SKUs.connectMonthly,
    };
  }

  if (subscription.managementType === 'seats') {
    if (
      subscription.paymentInformation?.paymentInterval ===
      PaymentInterval.MONTHLY
    ) {
      return {
        name: 'Connect for Business',
        sku: SKUs.connectForBusinessMonthly,
      };
    }

    if (
      subscription.paymentInformation?.paymentInterval ===
      PaymentInterval.ANNUALLY
    ) {
      return {
        name: 'Connect for Business',
        sku: SKUs.connectForBusinessAnnually,
      };
    }

    // We don't really know, but this is the default case
    return {
      name: 'Connect for Business',
      sku: SKUs.connectForBusinessAnnually,
    };
  }

  return null;
};
