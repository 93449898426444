import { HTMLAttributes } from 'react';

import { ClockCountdown } from '@phosphor-icons/react';
import { Check, XCircle } from 'phosphor-react';

import { SubscriptionStatus } from 'src/api/endpoints/storeApi.types';
import { Tag } from 'src/components';

export const StatusTag = ({
  status,
  className,
}: HTMLAttributes<HTMLDivElement> & { status?: SubscriptionStatus }) => {
  if (!status) {
    return null;
  }

  if (status === 'active') {
    return (
      <Tag variant="success" className={className}>
        <Check /> Active
      </Tag>
    );
  }

  if (status === 'unpaid') {
    return (
      <Tag className={className}>
        <ClockCountdown /> On hold
      </Tag>
    );
  }

  if (status === 'canceled') {
    return (
      <Tag variant="error" className={className}>
        <XCircle /> Cancelled
      </Tag>
    );
  }

  return null;
};
