import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { HandWaving } from 'phosphor-react';

export const WelcomeBanner = () => {
  const { user } = useAuth0();

  return (
    <div className="my-48 text-left">
      <div className="mb-16 flex flex-row items-center gap-16">
        <HandWaving
          size={40}
          weight="light"
          className="hidden animate-hand-wave text-muted lm:block"
        />
        <Typography variant="responsive-heading-lg">
          {user?.family_name && user?.given_name
            ? `Welcome, ${user.given_name} ${user.family_name}`
            : 'Welcome to my.reMarkable'}
        </Typography>
      </div>

      <Typography variant="body-md-regular" className="max-w-xl text-muted">
        Set up and manage your reMarkable universe. Take advantage of these
        features to help make reMarkable a central part of your daily routine.{' '}
      </Typography>
    </div>
  );
};
