import { CheckoutType } from 'src/api/endpoints/storeApi.types';
import { useActivePaperTablets } from 'src/api/queries';

import { SomethingWentWrong } from '../SomethingWentWrong';
import { Spinner } from '../Spinner';
import { ActivateOffer } from './ActivateOffer';
import { DmOffer } from './DmOffer';
import { TimeToSync } from './TimeToSync';

const OFFER_TYPES = {
  RETAIL: 'retailOffer',
  SUBSCRIPTION: 'subscriptionOffer',
  CONNECT: 'connectOffer',
  DM: 'dmOffer',
  STORE: 'store',
};

const getTitleText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.SUBSCRIPTION:
    case OFFER_TYPES.RETAIL:
    case OFFER_TYPES.CONNECT:
    case OFFER_TYPES.STORE:
    default:
      return 'Never lose track of your notes';
  }
};

const getOfferText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.SUBSCRIPTION:
      return 'With a 1-year free Connect trial, your notes are safely stored, synced, and ready to use on your paper tablet and in the apps.';
    case OFFER_TYPES.RETAIL:
      return 'With a 1-year free Connect trial, your notes are safely stored, synced, and ready to use on your paper tablet and in the apps.';
    case OFFER_TYPES.CONNECT:
      return 'With a 100-day free Connect trial, your notes are safely stored, synced, and ready to use on your paper tablet and in the apps.';
    case OFFER_TYPES.STORE:
      return 'With Connect, your notes are safely stored, synced, and ready to use on your paper tablet and in the apps. ';
    default:
      return 'Never lose track of your notes';
  }
};

const getTimeToSyncText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.SUBSCRIPTION:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a free 1-year Connect trial.';
    case OFFER_TYPES.RETAIL:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a free Connect trial.';
    case OFFER_TYPES.CONNECT:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a free 100-day Connect trial.';
    case OFFER_TYPES.STORE:
      return 'Smoothly transfer all your notebooks and files to your new paper tablet with a Connect subscription.';
    default:
      return 'Never lose track of your notes';
  }
};

const getButtonText = (checkoutType: CheckoutType) => {
  switch (checkoutType) {
    case OFFER_TYPES.STORE:
      return 'Subscribe to Connect';
    case OFFER_TYPES.CONNECT:
    case OFFER_TYPES.SUBSCRIPTION:
    case OFFER_TYPES.RETAIL:
      return 'Activate free trial';
    case OFFER_TYPES.DM:
      return 'Sync files';
    default:
      return 'Activate Connect';
  }
};

export const HeroOffer = ({
  checkoutType,
  componentLocation,
}: {
  checkoutType: CheckoutType;
  componentLocation: string;
}) => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) {
    return <Spinner />;
  }

  if (activePaperTablets.isError) {
    return <SomethingWentWrong />;
  }

  return checkoutType === OFFER_TYPES.DM ? (
    <DmOffer
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect for sync',
      }}
    />
  ) : activePaperTablets.data.length > 1 ? (
    <TimeToSync
      title={getTitleText(checkoutType)}
      type={checkoutType}
      text={getTimeToSyncText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect for sync',
      }}
    />
  ) : (
    <ActivateOffer
      type={checkoutType}
      title={getTitleText(checkoutType)}
      text={getOfferText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect',
      }}
    />
  );
};

export const Offer = ({
  checkoutType,
  componentLocation,
}: {
  checkoutType: CheckoutType;
  componentLocation: string;
}) => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) {
    return <Spinner />;
  }

  if (activePaperTablets.isError) {
    return <SomethingWentWrong />;
  }

  return checkoutType === OFFER_TYPES.DM ? (
    <DmOffer
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect for sync',
      }}
    />
  ) : activePaperTablets.data.length > 1 ? (
    <TimeToSync
      title={getTitleText(checkoutType)}
      type={checkoutType}
      text={getTimeToSyncText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect for sync',
      }}
    />
  ) : (
    <ActivateOffer
      type={checkoutType}
      title={getTitleText(checkoutType)}
      text={getOfferText(checkoutType)}
      buttonText={getButtonText(checkoutType)}
      buttonFixed
      analytics={{
        location: `${componentLocation}-${checkoutType}`,
        text: getButtonText(checkoutType),
        action: 'Activate connect',
      }}
    />
  );
};
