import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { cloudApi } from '../endpoints';
import { queryKeys } from './queryKeys';

export const usePasscodeReset = () => {
  const queryClient = useQueryClient();

  const pendingRequests = useQuery({
    queryKey: queryKeys.passcodeReset.requests,
    queryFn: () => cloudApi.getPasscodeResetRequests(),
    placeholderData: [],
    staleTime: 30000,
  });

  const approveRequest = useMutation({
    mutationKey: queryKeys.passcodeReset.approve,
    mutationFn: cloudApi.passcodeResetApprove,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.passcodeReset.requests, data);
    },
  });

  const denyRequest = useMutation({
    mutationKey: queryKeys.passcodeReset.deny,
    mutationFn: cloudApi.passcodeResetDeny,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.passcodeReset.requests, data);
    },
  });

  return {
    pendingRequests,
    approveRequest,
    denyRequest,
  };
};
