import { MouseEvent } from 'react';

import { Cookie } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { LinkClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { Link } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { RemarkableLogo } from 'src/components/RemarkableLogo';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

const linkSections = [
  {
    sectionTitle: 'News and info',
    links: [
      { title: 'Our story', href: URLS.RM_COM_ABOUT },
      {
        title: 'FAQ and Support',
        href: SUPPORT_URL.HOME,
      },
      { title: 'Blog', href: URLS.RM_COM_BLOG },
      { title: 'Contact', href: URLS.RM_COM_CONTACT },
    ],
  },
  {
    sectionTitle: 'Legal',
    links: [
      {
        title: 'Legal overview',
        href: SUPPORT_URL.LEGAL_OVERVIEW,
      },
      {
        title: 'Terms and conditions',
        href: SUPPORT_URL.LEGAL_OVERVIEW,
      },
      {
        title: 'Privacy policy',
        href: SUPPORT_URL.PRIVACY_POLICY,
      },
      {
        title: 'Subscription terms and conditions',
        href: SUPPORT_URL.LEGAL_OVERVIEW,
      },
      {
        title: 'End user licence agreement',
        href: SUPPORT_URL.LEGAL_OVERVIEW,
      },
      {
        title: 'Cookie policy',
        href: SUPPORT_URL.COOKIE_POLICY,
      },
    ],
  },
];

export const Footer = () => (
  <footer
    data-cy="footer"
    className="flex w-full animate-fade-in-up justify-center bg-neutral-dark-8 p-80 text-white shadow-[0_400px_0_0_rgb(33,30,28)]"
  >
    <div className="flex w-full max-w-7xl flex-col gap-64">
      <RemarkableLogo color="white" className="float-left w-[155px]" />
      <div className="flex flex-col gap-40 lg:grid lg:grid-cols-2">
        <div className="hidden lg:block">
          {/* This is the left column which will be empty or minimal to push the content to the right */}
        </div>
        <div className="flex w-full flex-col  justify-between gap-40 lm:flex-row">
          {/* Iterate over your linkSections to render the content on the right */}
          {linkSections.map(({ sectionTitle, links }) => (
            <div key={sectionTitle}>
              <Typography variant="body-lg-bold" className="text-white">
                {sectionTitle}
              </Typography>
              {links.map((link) => (
                <Link
                  className="my-12 block"
                  key={link.title}
                  to={link.href}
                  onClick={(event: MouseEvent<HTMLLinkElement>) => {
                    tracker.trackEvent(
                      new LinkClicked({
                        component_location: 'footer - ' + sectionTitle,
                        text: event.currentTarget.innerText,
                        link_source: event.currentTarget.href,
                      })
                    );
                  }}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>

      <Typography
        variant="responsive-body-md"
        className="mt-80 text-left text-white"
      >
        © {new Date().getFullYear()} reMarkable™. All rights reserved.
      </Typography>
    </div>

    <Link2
      search={{ show_cookie_banner: true }}
      className="absolute bottom-0 right-0 flex items-center gap-8 p-16"
      data-cy="cookie-banner-settings"
    >
      <Cookie size={24} className="max-h-24 text-white" />
      <span className=" text-14 text-white ">Cookie settings</span>
    </Link2>
  </footer>
);
