import { getRouteApi, useLocation, useNavigate } from '@tanstack/react-router';

import { DecodeProviderCallbackException } from './decodeErrors';
import { decodeCallbackRequest } from './decodeProviderCallback';

const integrationsRouteApi = getRouteApi('/_auth/_layout/integrations');

export type UseProviderCallbackReturn = {
  isCallbackUrl: boolean;
  reset: () => void;
  data?: {
    provider: string;
    state: string;
    code: string;
    scope: string | null | undefined;
  };
  error?: DecodeProviderCallbackException;
};

export const useProviderCallback = (): UseProviderCallbackReturn => {
  const search = integrationsRouteApi.useSearch();
  const location = useLocation();
  const navigate = useNavigate();
  const isCallbackUrl =
    search.callback === true ||
    location.pathname.endsWith('/callback') ||
    location.pathname.endsWith('/callback/');

  const reset = () => navigate({ search: undefined });

  if (isCallbackUrl) {
    try {
      return {
        isCallbackUrl,
        reset,
        data: decodeCallbackRequest(search),
      };
    } catch (e) {
      if (e instanceof DecodeProviderCallbackException) {
        return {
          isCallbackUrl,
          reset,
          error: e,
        };
      } else {
        return {
          isCallbackUrl,
          reset,
          error: new DecodeProviderCallbackException('GENERIC_ERROR'),
        };
      }
    }
  }

  return { isCallbackUrl, reset };
};
