import { useEffect } from 'react';

import { Typography } from '@remarkable/ark-web';
import clsx from 'clsx';
import { Check, Copy } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { SeatInvitation } from 'src/api/endpoints/storeApi.types';
import { Button, Tooltip } from 'src/components';
import { formatRemainingTime } from 'src/utils/formatRemainingTime';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';

export const InvitationStatus = ({
  invitation,
}: {
  invitation?: SeatInvitation;
}) => {
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    if (copyToClipboard.isSuccess) {
      setTimeout(() => {
        copyToClipboard.reset();
      }, 2000);
    }
  }, [copyToClipboard.isSuccess]);

  if (!invitation) return null;

  if (invitation.isDeclined)
    return (
      <Typography variant="body-sm-regular">
        <span className="hidden italic lm:inline">Invitation declined</span>
        <span className="italic lm:hidden">Declined</span>
      </Typography>
    );

  if (invitation.isExpired)
    return (
      <Typography variant="body-sm-regular">
        <span className="hidden italic lm:inline">Invitation expired</span>
        <span className="italic lm:hidden">Expired</span>
      </Typography>
    );

  const expirationTime = formatRemainingTime(invitation.expiresAt);

  return (
    <>
      <div className="lm:hidden">
        <Typography
          variant="body-sm-regular"
          className="my-0 whitespace-nowrap italic lm:hidden"
        >
          Pending
          <span className="ls:hidden">
            {' '}
            ⋅ Invitation expires in {expirationTime}
          </span>
        </Typography>
      </div>

      <div className="hidden items-center gap-12 lm:flex">
        <div className="flex flex-col gap-8 ls:gap-0">
          <Typography
            variant="body-sm-regular"
            className="my-0 whitespace-nowrap italic"
          >
            Invitation pending
          </Typography>
          <Typography
            variant="body-sm-regular"
            className="my-0 ls:interface-xs-regular ls:inline"
          >
            <span className="hidden text-gray-500 ls:inline">
              Expires in {expirationTime}
            </span>

            <span className="ls:hidden">({expirationTime})</span>
          </Typography>
        </div>

        <Tooltip title="Copy link">
          <Button
            aria-label="Copy link"
            variant="tertiary-neutral"
            size="small"
            disabled={invitation.isDeclined || invitation.isExpired}
            className={clsx('mr-2 hidden ls:flex', {
              invisible: invitation.isDeclined || invitation.isExpired,
            })}
            onClick={() => {
              copyToClipboard.mutate(invitation.invitationLink);
            }}
            data-cy="copy-link-button"
            analytics={{
              text: 'Copy link',
              location: ComponentLocations.MANAGE_CONNECT.MEMBERS_PAGE,
              action: 'copy invitation link',
            }}
          >
            {copyToClipboard.isSuccess ? (
              <Check className="text-feedback-green-500" />
            ) : (
              <Copy />
            )}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
