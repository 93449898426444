import { ComponentProps, HTMLAttributes } from 'react';

import { VariantProps, cva, cx } from 'class-variance-authority';

const tagVariants = cva(
  [
    'interface-sm-caps-medium inline-flex gap-8 rounded align-middle not-italic',
  ],
  {
    variants: {
      size: {
        xs: 'rounded-full px-8 py-4 text-[10px]',
        sm: 'rounded-full px-[10px] py-6 text-12',
        md: 'rounded-full px-12 py-[9px] text-14',
      },
      variant: {
        default: 'bg-neutral-light-6 text-neutral-dark-5',
        success: 'bg-green-light text-green-dark-6',
        error: 'bg-red-light text-pen-red-dark',
        warning: 'bg-orange-light text-[#592A00]',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
);

export const Tag = ({
  size,
  children,
  variant,
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement> & VariantProps<typeof tagVariants>) => {
  return (
    <span
      className={cx(
        tagVariants({ size, variant, className }),
        'flex flex-row items-center'
      )}
      {...props}
    >
      {children}
    </span>
  );
};

export const BetaTag = ({ children, ...props }: ComponentProps<typeof Tag>) => (
  <Tag {...props}>{children ?? 'Beta'}</Tag>
);
