import { useMemo } from 'react';

import { Typography } from '@remarkable/ark-web';

import { useStripeSubscription, useSubscriptionMembers } from 'src/api/queries';
import { useInvitations } from 'src/api/queries/invitations';
import { Sheet } from 'src/components';
import { FlexDivider } from 'src/components/FlexDivider';
import { isBillableSeat } from 'src/utils/isBillableSeat';

import { useFormattedPaymentInfo } from '../utils/getFormattedPaymentInfo';

export interface SeatsInfo {
  baselineSeats: number;
  activeSeats: number;
  stepSize: number;
  transfers: number;
}

export const SubscriptionSeatsInfo = () => {
  const subscription = useStripeSubscription();
  const invitations = useInvitations();
  const members = useSubscriptionMembers();
  const sub = subscription.data;

  const numberOfMembers =
    members.data?.filter((member) => member.email).length ?? 0;

  const billableSeats = useMemo(
    () => members.data?.filter((member) => isBillableSeat(member.seat)) ?? [],
    [members.data]
  );

  const numberOfPendingInvitations =
    invitations.data?.filter(
      (invitation) => !invitation.isDeclined && !invitation.isExpired
    )?.length ?? 0;

  const isSeatManagement = sub?.managementType === 'seats';

  const paymentInfo = useFormattedPaymentInfo(subscription.data);

  if (!isSeatManagement || !subscription.data?.access.canSeeMembers)
    return null;

  const nextBillingValue = subscription.data?.paymentInformation?.isDeactivated
    ? 'On hold'
    : paymentInfo?.nextBillingShort || '-';

  return (
    <Sheet className="ls:flex-row">
      <div className="flex flex-1">
        <DashboardNumber
          dataCy="members-count"
          value={numberOfMembers}
          label="Total members"
        />

        <FlexDivider />

        <DashboardNumber
          dataCy="invitations-count"
          value={numberOfPendingInvitations}
          label={`Pending invitation${
            numberOfPendingInvitations === 1 ? '' : 's'
          }`}
        />
      </div>

      {subscription.data.access.canSeeNextInvoiceAmount && (
        <div className="flex flex-1">
          <FlexDivider className="hidden ls:flex" />

          <DashboardNumber
            label="Seats on next billing"
            value={billableSeats.length}
          />
          <FlexDivider />

          <DashboardNumber value={nextBillingValue} label="Next billing" />
        </div>
      )}
    </Sheet>
  );
};

const DashboardNumber = ({
  value,
  label,
  dataCy = 'dashboard-number',
}: {
  value: string | number;
  label: string;
  dataCy?: string;
}) => (
  <div
    data-cy={dataCy}
    className="flex flex-1 flex-col items-center justify-center pb-8 text-center lm:py-16"
  >
    <span data-cy="value" className="body-lg-bold ls:body-2xl-bold">
      {value}
    </span>
    <Typography
      variant="interface-sm-regular"
      className="px-8 font-[300] text-gray-600"
    >
      {label}
    </Typography>
  </div>
);
